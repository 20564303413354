import React, { useEffect, useState } from 'react'
import Error from '@/shared/components/form/Error'
import styled, { keyframes } from 'styled-components'
import parse from 'style-to-object'
import ReactHtmlParser from 'react-html-parser'
import { OnetoOneValidate } from '@/utils/validate/customCenter/oneToOneValidate'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import 'react-quill/dist/quill.snow.css' // Quill의 스타일 시트
import { createOnetoOne, deleteOnetoOneList, memberConfirmOneToOneAnswer } from '../../api/customCenter/customCenterApi'
import WrapperLoading from '../../containers/WrapperLoading'
import './customStyle.css'
import { decodeAccessToken } from '../../utils/token'
import { fetchOneToOneList } from '../../redux/customCenterSlice'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat, convertToKstDateTime } from '../../utils/dateTime'
import { OneToOneAnswerStatusEnums } from '../../utils/enums/Operation/OneToOneEnums'
import { fetchOneToOneSize } from '../../redux/memberInfoSlice'

const OneToOnePage = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [isWriteOnetoOneShow, setWriteOnetoOneShow] = useState(false)

  /**
   *  1:1문의 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [oneToOneParams, setOneToOneParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setOneToOneParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [checkRadio, setCheckRadio] = useState([])
  const [content, setContent] = useState([])
  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.oneToOneInfo.totalElement,
      apiContent: customCenter.oneToOneInfo.content,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.oneToOneKey === item.oneToOneKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchOneToOneList = async () => {
    try {
      dispatch(fetchOneToOneList(oneToOneParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchOneToOneList()
  }, [oneToOneParams])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = oneToOneKey => {
    setContent(prev => {
      const idx = prev.findIndex(item => item.oneToOneKey === oneToOneKey)

      if (idx !== -1) {
        // 원래의 state를 복사하여 새로운 배열을 만듭니다.
        const newState = [...prev]
        newState[idx] = {
          ...newState[idx],
          isVisible: !newState[idx].isVisible,
        }
        const idx2 = content.findIndex(item => item.oneToOneKey === oneToOneKey)
        if (idx2 !== -1 && content[idx2]?.confirmStatus === 'INCOMPLETE') {
          memberConfirmOneToOneAnswer({
            oneToOneKey: content[idx2].oneToOneKey,
          }).then(res => {
            fetchOneToOneList()
            dispatch(fetchOneToOneSize())
          })
        }
        // 새로운 배열을 반환합니다.
        return newState
      }

      // 인덱스를 찾지 못한 경우, 원래의 state를 그대로 반환합니다.
      return prev
    })
    // const idx = content.findIndex(item => item.oneToOneKey === oneToOneKey)
    // if (idx !== -1) {
    //   // isVisible 상태를 업데이트합니다.
    //   setContent(prevContent => {
    //     const newContent = [...prevContent]
    //     newContent[idx] = {
    //       ...newContent[idx],
    //       isVisible: !newContent[idx].isVisible,
    //     }
    //     return newContent
    //   })
    // }
  }

  const [questionLoading, setQuestionLoading] = useState(false)
  const [questionTitle, setQuestionTitle] = useState('')
  const onChangeQuestionTitle = e => {
    setQuestionTitle(e.target.value)
  }

  // 문의 내용
  const [questionContent, setQuestionContent] = useState('')
  const onChangeQuestionContentHandler = editorState => {
    setQuestionContent(editorState)
  }
  const onSubmit = e => {
    // TODO userID에 로그인한사람 ID박아주기
    if (loading) return
    setLoading(true)
    const body = {
      questionTitle,
      questionContent,
    }
    createOnetoOne(body)
      .then(res => {
        alert('1:1문의가 접수되었습니다.')
        setLoading(false)
        window.location.reload()
        // fetchSearchOneToOneList()
        // setQuestionTitle('')
        // setQuestionContent('문의가 완료됐습니다.')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(`${message}`)
            break
          default:
            alert('1:1문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setLoading(false)
      })
  }

  // 하나 지우기
  const [deleteLoading, setDeleteLoading] = useState(false)
  const onDeleteHandler = oneToOneKey => {
    if (loading) return

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setLoading(true)
      deleteOnetoOneList({
        oneToOneKeyList: [oneToOneKey],
      })
        .then(res => {
          setLoading(false)
          alert('삭제가 완료 되었습니다.')
          fetchSearchOneToOneList()
        })
        .catch(error => {
          setLoading(false)
          alert('삭제가 실패했습니다.')
          fetchSearchOneToOneList()
        })
    }
  }

  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  return (
    <div className="main-content">
      <div className="aniFrame">
        <div className="titlePic">
          <div className="titlePwrapper">
            <div className="leftZone">
              <span>1:1문의</span>
            </div>
            <div className="line" />
          </div>
        </div>
        {loading && <WrapperLoading isLoading={loading} />}
        <div className="main_content_wrap main_content_wrap_notice">
          <div className="noticeFrame">
            <Form
              onSubmit={onSubmit}
              initialValues={{
                userId: decodeAccessToken().userId,
                questionTitle,
                questionContent,
              }}
              validate={OnetoOneValidate}
            >
              {({ handleSubmit, form: { reset } }) => (
                <form className="animated fadeIn delay2" name="frm_qna" method="post" onSubmit={handleSubmit}>
                  <div className="info-wrap">
                    <div className="input-wrap">
                      <div className="desc">제목</div>
                      <Field name="questionTitle">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <CustomCenterFormInput
                              {...input}
                              type="text"
                              value={questionTitle}
                              onChange={e => {
                                input.onChange(e)
                                onChangeQuestionTitle(e)
                              }}
                              autoComplete="off"
                              // placeholder="제목을 작성하세요"
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </div>
                    <div className="input-wrap">
                      <div className="desc">내용</div>
                      <div className="content">
                        <Field name="questionContent">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <ReactQuill
                                placeholder={`해당 영역에 문의내용을 입력해주세요 \n\n매너 있는 채팅을 부탁드리며 '욕설 및 협박' 등의 내용을 기재 시 회원자격이 박탈됩니다`}
                                // editorState={questionContent}
                                onChange={value => {
                                  input.onChange(value)
                                  setQuestionContent(value) // questionContent 업데이트
                                }}
                                modules={{
                                  toolbar: [
                                    [{ header: '1' }, { header: '2' }, { font: [] }],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['bold', 'italic', 'underline'],
                                    ['link'],
                                  ],
                                }}
                                // wrapperClassName="wrapper"
                                // editorClassName="OneToOneEditor"
                                // toolbarClassName="toolbar"
                                // localization={{
                                //   locale: 'ko',
                                // }}
                              />
                              {meta.touched && meta.error && <Error error={meta.error} />}
                            </FormInputWrap>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="button-wrap mt20">
                    <button type="submit" className="btnN redB">
                      문의하기
                    </button>
                  </div>
                </form>
              )}
            </Form>
            <div>
              <div className="listZone">
                <table>
                  <thead>
                    <tr>
                      <th className="ac number" width="10%">
                        No.
                      </th>
                      <th className="ac">제목</th>
                      <th className="ac">작성일</th>
                      <th className="ac">상태</th>
                      <th className="ac">삭제</th>
                    </tr>
                  </thead>
                  <tbody>
                    {content.map(item => (
                      <>
                        <tr>
                          <td onClick={() => onClickRowHandler(item.oneToOneKey)}>{item.num}</td>
                          <td>
                            <a
                              onClick={() => onClickRowHandler(item.oneToOneKey)}
                              className="tit"
                              data-id="41143"
                              data-status="2"
                            >
                              {item.questionTitle}
                            </a>
                          </td>
                          <td className="pc" onClick={() => onClickRowHandler(item.oneToOneKey)}>
                            {convertToKstDateTime(item.registrationDate)}
                          </td>
                          <td className="mob" onClick={() => onClickRowHandler(item.oneToOneKey)}>
                            {convertToKstByFormat(item.registrationDate, 'MM-DD HH:mm')}
                          </td>

                          <td className="td-status" onClick={() => onClickRowHandler(item.oneToOneKey)}>
                            <span className="badge badge-light">{OneToOneAnswerStatusEnums[item.answerStatus]}</span>
                          </td>
                          <td>
                            <button
                              className="btn darken-hover"
                              onClick={() => {
                                onDeleteHandler(item.oneToOneKey)
                              }}
                              type="button"
                            >
                              <span className="icon-iconTrash" />
                            </button>
                          </td>
                        </tr>
                        {item.isVisible && (
                          <>
                            <tr>
                              <td
                                colSpan={2}
                                style={{ borderRight: 'solid 1px #8a8a8a', background: '#b3b3b3', color: '#fff' }}
                              >
                                문의내용
                              </td>
                              <td colSpan={3} style={{ background: '#b3b3b3', color: '#fff' }}>
                                답변내용
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="ac"
                                colSpan={2}
                                style={{ borderRight: 'solid 1px #8a8a8a', background: '#dbdbdb' }}
                              >
                                <CustomerCenterContentDetailBox>
                                  {ReactHtmlParser(item.questionContent, { transform: transformFn })}
                                </CustomerCenterContentDetailBox>
                              </td>
                              <td className="ac" colSpan={3} style={{ background: '#dbdbdb' }}>
                                <CustomerCenterContentDetailBox>
                                  {ReactHtmlParser(item.answerContent, { transform: transformFn })}
                                </CustomerCenterContentDetailBox>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>

                {totalElement === 0 && (
                  <div className="tb_empty">
                    <div className="pic">
                      <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
                    </div>
                    <h2>데이터 없음</h2>
                    <h3>Sorry, Empty Data</h3>
                  </div>
                )}
                {totalElement !== 0 && (
                  <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OneToOnePage

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #000;
  min-height: 45px;
  padding: 20px;
  justify-content: center;
  align-items: center;
`

export const FormInputWrap = styled.div`
  width: 100%;
`

export const CustomCenterFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;

  color: black;

  border: none;
  background: none;

  outline: none;

  &:disabled {
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`
