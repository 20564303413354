import axios from 'axios'
import { isMobile } from 'react-device-detect'
import { getAccessToken, getCookie, getRefreshToken, removeCookie, removeToken, setCookie } from '../../utils/cookie'
import { clearLocalStorage } from '../../utils/enums/authority'

// export const API_URL = 'http://localhost:5052'

// 서버
export const API_URL = ''

let sessionFlag = false

let isAlreadyFetchingAccessToken = false
let subscribers = []

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 50000,
})

axiosInstance.interceptors.request.use(
  config => {
    // 요청 바로 직전
    // axios 설정값에 대해 작성합니다.

    config.headers.Accept = 'application/json'
    config.headers.ContentType = 'application/json'

    const token = getAccessToken()

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    // eslint-disable-next-line no-useless-escape
    config.headers['X-Client-Host'] = window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')

    return config
  },
  error => {
    return Promise.reject(error)
  },
)

/**
 * 토큰 재발급
 * @see : https://thinkforthink.tistory.com/372
 */
function addSubscriber(callback) {
  subscribers.push(callback)
}

function onAccessTokenFetched(accessToken) {
  subscribers.forEach(callback => callback(accessToken))
  subscribers = []
}

async function reissueToken(error) {
  try {
    const { response } = error
    const prevRefreshToken = getCookie('refreshToken')

    if (!prevRefreshToken) throw Error

    const retryOriginalRequest = new Promise((resolve, reject) => {
      addSubscriber(async accessToken => {
        try {
          response.config.headers.Authorization = `Bearer ${accessToken}`
          resolve(axiosInstance(response.config))
        } catch (err) {
          reject(err)
        }
      })
    })

    // refresh token을 이용해서 access token 요청
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true // 문닫기 (한 번만 요청)

      const { accessToken, refreshToken } = await axiosInstance({
        url: '/tyson/member/v1/auth/reissue-token',
        method: 'POST',
        data: {
          refreshToken: getRefreshToken(),
        },
      })
        .then(res => {
          return res.data
        })
        .catch(err => {
          console.error('Token reissue failed:', err)
          throw err
        })

      // setCookie('accessToken', accessToken, {
      //   path: '/',
      //   secure: true,
      //   sameSite: 'none',
      // })

      // setCookie('refreshToken', refreshToken, {
      //   path: '/',
      //   secure: true,
      //   sameSite: 'none',
      // })

      if (window.location.host.includes('localhost')) {
        setCookie('accessToken', accessToken, {
          path: '/',
          secure: true,
          sameSite: 'none',
        })

        setCookie('refreshToken', refreshToken, {
          path: '/',
          secure: true,
          sameSite: 'none',
        })
      } else {
        // eslint-disable-next-line no-useless-escape
        const domain = `.${window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')}`

        setCookie('accessToken', accessToken, {
          path: '/',
          secure: true,
          domain,
          sameSite: 'none',
        })

        setCookie('refreshToken', refreshToken, {
          path: '/',
          secure: true,
          domain,
          sameSite: 'none',
        })
      }

      isAlreadyFetchingAccessToken = false // 문열기 (초기화)
      sessionFlag = false
      onAccessTokenFetched(accessToken)
    }
    return retryOriginalRequest
  } catch (err) {
    console.error('Error in reissueToken:', err)

    clearLocalStorage()
    removeToken()
    window.location.href = '/'
    return Promise.reject(err)
  }
}

/*
    2. 응답 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 응답 정성 - 인자값: http response
    2) 응답 에러 - 인자값: http error
*/
axiosInstance.interceptors.response.use(
  response => {
    /*
        http status가 200인 경우
        응답 바로 직전에 대해 작성합니다. 
        .then() 으로 이어집니다.
    */
    return response
  },

  error => {
    const errResponseStatus = error.response?.status
    const errResponseData = error.response?.data
    const prevRequest = error.config

    const errorCode = error.response?.data?.errorCode

    if (errorCode !== null) {
      switch (errorCode) {
        case 'TOKEN-1003':
        case 'TOKEN-1004':
        case 'TOKEN-1005':
        case 'TOKEN-1006':
          clearLocalStorage()
          removeToken()
          if (!sessionFlag) {
            // alert('세션이 만료되었습니다. 다시 로그인 해주세요.')
            sessionFlag = true
          }

          window.location.href = '/'
          return Promise.reject(error)
        case 'TOKEN-1007':
        case 'TOKEN-1008':
          clearLocalStorage()
          removeToken()
          if (!sessionFlag) {
            alert('다른 환경에서 중복 로그인되었습니다.')
            sessionFlag = true
          }

          window.location.href = '/'

          return Promise.reject(error)
        default:
      }
    }

    // token 관련 에러인 경우 재발급 요청
    if (errResponseStatus === 401) {
      return reissueToken(error)
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
