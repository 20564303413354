import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { searchUserHoldingAsset } from '../api/memberManage/memberManageApi'
import { searchAnswerCompleteOneToOne, searchNoReadNoteCount } from '../api/customCenter/customCenterApi'

export const fetchMemberInfo = createAsyncThunk('memberInfo/fetchMemberInfo', async () => {
  const res = await searchUserHoldingAsset()
  return res.data
})

export const fetchNoteSize = createAsyncThunk('memberInfo/fetchNoteSize', async () => {
  const res = await searchNoReadNoteCount()
  return res.data
})

export const fetchOneToOneSize = createAsyncThunk('memberInfo/fetchOneToOneSize', async () => {
  const res = await searchAnswerCompleteOneToOne()
  return res.data
})

const memberInfoSlice = createSlice({
  name: 'memberInfo',
  initialState: {
    memberHoldingMoney: 0,
    memberTotalGameMoney: 0,
    holdemMoneyAmount: 0,
    memberHoldingPoint: 0,
    noteRedisSize: 0,
    oneToOneRedisSize: 0,
    userId: '',
    userName: '',
    bankName: '',
    accountNum: '',
    accountHolder: '',
    depositLevel: '',
    miniGameBettingInfo: {},
    sportsGameBettingInfo: {},
    noteMessageInfo: '',
    oneToOneMessageInfo: '',
    reloadCount: 0,
    reloadTime: 0,
    currentAccessGame: false,
    tempCurrentAccessGame: false,
  },
  reducers: {
    setTempCurrentAccessGame: (state, action) => {
      // 접속: true, 미접속: false
      state.tempCurrentAccessGame = action.payload
    },

    setNoteMessageInfo: (state, action) => {
      state.noteMessageInfo = action.payload
    },
    setOneToOneMessageInfo: (state, action) => {
      state.oneToOneMessageInfo = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchMemberInfo.fulfilled, (state, action) => {
      const {
        holdingMoney,
        holdingMemberRollingPoint,
        holdingTotalGameMoney,
        holdemMoneyAmount,
        userId,
        userName,
        bankName,
        accountNum,
        accountHolder,
        depositLevel,
        miniGameMinBetting,
        miniGameMaxBetting,
        miniGameMaxWinning,
        sportsGameMaxBetting,
        sportsGameMinBetting,
        sportsGameMaxWinning,
        sportsGameMinFolder,
        sportsGameMaxFolder,
        liveSportsGameMaxBetting,
        liveSportsGameMinBetting,
        liveSportsGameMaxWinning,
        liveSportsGameMinFolder,
        liveSportsGameMaxFolder,
        bettingPerMaxCriteria,
        noteRedisSize,
        oneToOneRedisSize,
        reloadCount,
        reloadTime,
        currentAccessGame,
      } = action.payload
      state.memberHoldingMoney = holdingMoney
      state.memberTotalGameMoney = holdingTotalGameMoney
      state.holdemMoneyAmount = holdemMoneyAmount
      state.memberHoldingPoint = holdingMemberRollingPoint
      state.noteRedisSize = noteRedisSize
      state.oneToOneRedisSize = oneToOneRedisSize
      state.userId = userId
      state.userName = userName
      state.bankName = bankName
      state.accountNum = accountNum
      state.accountHolder = accountHolder
      state.depositLevel = depositLevel
      state.miniGameBettingInfo = {
        miniGameMinBetting,
        miniGameMaxBetting,
        miniGameMaxWinning,
      }
      state.sportsGameBettingInfo = {
        sportsGameMaxBetting,
        sportsGameMinBetting,
        sportsGameMaxWinning,
        sportsGameMinFolder,
        sportsGameMaxFolder,
        liveSportsGameMaxBetting,
        liveSportsGameMinBetting,
        liveSportsGameMaxWinning,
        liveSportsGameMinFolder,
        liveSportsGameMaxFolder,
        bettingPerMaxCriteria,
      }

      state.currentAccessGame = currentAccessGame

      state.reloadCount = reloadCount
      state.reloadTime = reloadTime
    })

    builder.addCase(fetchNoteSize.fulfilled, (state, action) => {
      const { noteSize } = action.payload
      state.noteRedisSize = noteSize
    })
    builder.addCase(fetchOneToOneSize.fulfilled, (state, action) => {
      const { oneToOneSize } = action.payload
      state.oneToOneRedisSize = oneToOneSize
    })
  },
})

export const { setOneToOneMessageInfo, setNoteMessageInfo, setTempCurrentAccessGame } = memberInfoSlice.actions

export default memberInfoSlice.reducer
