import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { fetchEventBoardList } from '../../redux/customCenterSlice'
import WrapperLoading from '../../containers/WrapperLoading'
import EventDetailModal from './EventDetailModal'

const EventPage = () => {
  const [apiLoading, setApiLoading] = useState(false)
  const dispatch = useDispatch()

  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)

  const [boardParams, setBoardParams] = useState({
    boardType: 'EVENT',
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [content, setContent] = useState([])
  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.eventInfo.totalElement,
      apiContent: customCenter.eventInfo.content,
    }
  })

  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.boardKey === item.boardKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchBoardList = async () => {
    try {
      dispatch(fetchEventBoardList(boardParams))
      setApiLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setApiLoading(false)
    }
  }

  useEffect(() => {
    setApiLoading(true)
    fetchSearchBoardList()
  }, [boardParams])

  // row 클릭 이벤트 핸들러
  const [hangData, setHandData] = useState({})
  const onClickRowHandler = item => {
    setHandData({
      boardContent: item.boardContent,
      boardTitle: item.boardTitle,
      imgUrl: item.imgUrl,
    })
  }

  const [isModalShow, setModalShow] = useState(false)
  const onChangeModalState = () => {
    setModalShow(prev => !prev)
  }

  return (
    <>
      {apiLoading && <WrapperLoading isLoading={apiLoading} />}
      {isModalShow && (
        <EventDetailModal isModalShow={isModalShow} onChangeModalState={onChangeModalState} hangData={hangData} />
      )}
      <div className="main-content">
        <div className="aniFrame">
          <div className="titlePic">
            <div className="titlePwrapper">
              <div className="leftZone">
                <span>이벤트</span>
              </div>
              <div className="line" />
            </div>
          </div>
          <div className="main_content">
            <div className="main_content">
              <div className="main_content_wrap main_content_wrap_notice">
                <div className="noticeFrame">
                  <div>
                    <div className="listZone">
                      <ul className="imgList">
                        {content.map(item => {
                          return (
                            <li className="goPromotion">
                              <div className="upZone">
                                <a
                                  onClick={() => {
                                    onChangeModalState()
                                    onClickRowHandler(item)
                                  }}
                                >
                                  <p>
                                    <img src={item.imgUrl} alt="" width="719" height="290" />
                                  </p>
                                </a>
                              </div>
                              <div className="bottomZone">
                                <div className="txtFrame">
                                  <h3>
                                    <font color="#000">{item.boardTitle}</font>
                                    <br />
                                  </h3>
                                </div>
                                <a
                                  className="btnP"
                                  onClick={() => {
                                    onChangeModalState()
                                    onClickRowHandler(item)
                                  }}
                                >
                                  상세보기
                                </a>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EventPage
