import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMiniGameBettingPer, setMiniGameBettingType } from '../../../redux/miniGameBettingSlice'

const MiniGameNtryEosPowerBallOne = ({ powerBallInfo }) => {
  const dispatch = useDispatch()

  const handleBettingCart = event => {
    const miniGameBettingPer = event.currentTarget.getAttribute('name') || 0
    const miniGameBettingType = event.currentTarget.id
    dispatch(setMiniGameBettingPer(miniGameBettingPer))
    dispatch(setMiniGameBettingType(miniGameBettingType))
  }

  const getMiniGameBettingInfo = state => {
    const { miniGameType } = state

    if (miniGameType === 'NTRY_POWER_BALL') {
      return state.ntryPowerBallInfo
    }
    if (miniGameType === 'NTRY_POWER_LADDER') {
      return state.ntryPowerLadderInfo
    }
    if (miniGameType === 'NTRY_SPEED_KENO') {
      return state.ntrySpeedKenoInfo
    }
    if (miniGameType === 'NTRY_KENO_LADDER') {
      return state.ntryKenoLadderInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_1M') {
      return state.ntryEosPowerBallOneInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_2M') {
      return state.ntryEosPowerBallTwoInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_3M') {
      return state.ntryEosPowerBallThreeInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_4M') {
      return state.ntryEosPowerBallFourInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_5M') {
      return state.ntryEosPowerBallFiveInfo
    }

    return null
  }

  const { miniGameBettingType } = useSelector(state => {
    const { miniGameBetting } = state
    const miniGameBettingInfo = getMiniGameBettingInfo(miniGameBetting)
    if (miniGameBettingInfo) {
      return {
        miniGameBettingType: miniGameBettingInfo.miniGameBettingType,
      }
    }
    return {
      miniGameBettingType: '',
    }
  })

  return (
    <div className="pick_section">
      <div className="pick_list">
        <div className="pick_wrap">
          <h4 className="pick_title">파워볼</h4>
          <div className="pick_bet grid-2">
            <div
              className={miniGameBettingType === 'POWER_BALL_ODD' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_ODD"
              name={powerBallInfo?.miniGameBettingPer?.powerOddPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerOddPer || 0}</span>
              <div className="mark">
                <span className="ic_odd">홀</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_EVEN' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_EVEN"
              name={powerBallInfo?.miniGameBettingPer?.powerEvenPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerEvenPer || 0}</span>
              <div className="mark">
                <span className="ic_even">짝</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_UNDER' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_UNDER"
              name={powerBallInfo?.miniGameBettingPer?.powerUnderPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerUnderPer || 0}</span>
              <div className="mark">
                <span className="ic_under">언더</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_OVER' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_OVER"
              name={powerBallInfo?.miniGameBettingPer?.powerOverPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerOverPer || 0}</span>
              <div className="mark">
                <span className="ic_over">오버</span>
              </div>
            </div>
          </div>
        </div>

        <div className="pick_wrap">
          <h4 className="pick_title">일반볼</h4>
          <div className="pick_bet grid-2">
            <div
              className={miniGameBettingType === 'NORMAL_BALL_ODD' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_ODD"
              name={powerBallInfo?.miniGameBettingPer?.normalOddPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.normalOddPer || 0}</span>
              <div className="mark">
                <span className="ic_odd">홀</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_EVEN' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_EVEN"
              name={powerBallInfo?.miniGameBettingPer?.normalEvenPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.normalEvenPer || 0}</span>
              <div className="mark">
                <span className="ic_even">짝</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_UNDER' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_UNDER"
              name={powerBallInfo?.miniGameBettingPer?.normalUnderPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.normalUnderPer || 0}</span>
              <div className="mark">
                <span className="ic_under">언더</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_OVER' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_OVER"
              name={powerBallInfo?.miniGameBettingPer?.normalOverPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.normalOverPer || 0}</span>
              <div className="mark">
                <span className="ic_over">오버</span>
              </div>
            </div>
          </div>
        </div>

        <div className="pick_wrap">
          <h4 className="pick_title">일반볼 구간별</h4>
          <div className="pick_bet grid-3">
            <div
              className={miniGameBettingType === 'NORMAL_BALL_MAJOR' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_MAJOR"
              name={powerBallInfo?.miniGameBettingPer?.normalMajorPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.normalMajorPer || 0}</span>
              <div className="mark">
                <span className="ic_lg">대</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_MIDDLE' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_MIDDLE"
              name={powerBallInfo?.miniGameBettingPer?.normalMiddlePer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.normalMiddlePer || 0}</span>
              <div className="mark">
                <span className="ic_md">중</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_MINOR' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_MINOR"
              name={powerBallInfo?.miniGameBettingPer?.normalMinorPer || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.normalMinorPer || 0}</span>
              <div className="mark">
                <span className="ic_sm">소</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pick_wrap">
          <h4 className="pick_title">파워볼 숫자</h4>
          <div className="pick_bet grid-5">
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_0' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_0"
              name={powerBallInfo?.miniGameBettingPer?.powerNum0Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum0Per || 0}</span>
              <div className="mark">
                <span className="ic_num">0</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_1' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_1"
              name={powerBallInfo?.miniGameBettingPer?.powerNum1Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum1Per || 0}</span>
              <div className="mark">
                <span className="ic_num">1</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_2' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_2"
              name={powerBallInfo?.miniGameBettingPer?.powerNum2Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum2Per || 0}</span>
              <div className="mark">
                <span className="ic_num">2</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_3' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_3"
              name={powerBallInfo?.miniGameBettingPer?.powerNum3Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum3Per || 0}</span>
              <div className="mark">
                <span className="ic_num">3</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_4' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_4"
              name={powerBallInfo?.miniGameBettingPer?.powerNum4Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum4Per || 0}</span>
              <div className="mark">
                <span className="ic_num">4</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_5' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_5"
              name={powerBallInfo?.miniGameBettingPer?.powerNum5Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum5Per || 0}</span>
              <div className="mark">
                <span className="ic_num">5</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_6' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_6"
              name={powerBallInfo?.miniGameBettingPer?.powerNum6Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum6Per || 0}</span>
              <div className="mark">
                <span className="ic_num">6</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_7' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_7"
              name={powerBallInfo?.miniGameBettingPer?.powerNum7Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum7Per || 0}</span>
              <div className="mark">
                <span className="ic_num">7</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_8' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_8"
              name={powerBallInfo?.miniGameBettingPer?.powerNum8Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum8Per || 0}</span>
              <div className="mark">
                <span className="ic_num">8</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_NUM_9' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_NUM_9"
              name={powerBallInfo?.miniGameBettingPer?.powerNum9Per || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.powerNum9Per || 0}</span>
              <div className="mark">
                <span className="ic_num">9</span>
              </div>
            </div>
          </div>
        </div>

        <div class="pick_wrap">
          <h4 class="pick_title">파워볼 조합</h4>
          <div class="pick_bet">
            <div
              className={miniGameBettingType === 'POWER_BALL_ODD_OVER' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_ODD_OVER"
              name={powerBallInfo?.miniGameBettingPer?.powerOddOverPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.powerOddOverPer || 0}</span>
              <div class="mark">
                <span class="ic_odd">홀</span> <span class="ic_over">오버</span>
              </div>
              <span class="bedd-title">홀+오버</span>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_ODD_UNDER' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_ODD_UNDER"
              name={powerBallInfo?.miniGameBettingPer?.powerOddUnderPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.powerOddUnderPer || 0}</span>
              <div class="mark">
                <span class="ic_odd">홀</span> <span class="ic_under">언더</span>
              </div>
              <span class="bedd-title">홀+언더</span>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_EVEN_OVER' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_EVEN_OVER"
              name={powerBallInfo?.miniGameBettingPer?.powerEvenOverPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.powerEvenOverPer || 0}</span>
              <div class="mark">
                <span class="ic_even">짝</span> <span class="ic_over">오버</span>
              </div>
              <span class="bedd-title">짝+오버</span>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_EVEN_UNDER' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_EVEN_UNDER"
              name={powerBallInfo?.miniGameBettingPer?.powerEvenUnderPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.powerEvenUnderPer || 0}</span>
              <div class="mark">
                <span class="ic_even">짝</span> <span class="ic_over">언더</span>
              </div>
              <span class="bedd-title">짝+언더</span>
            </div>
          </div>
        </div>
        <div class="pick_wrap">
          <h4 class="pick_title">파워볼 일반볼 조합</h4>
          <div class="pick_bet">
            <div
              className={miniGameBettingType === 'POWER_BALL_ODD_NORMAL_BALL_ODD' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_ODD_NORMAL_BALL_ODD"
              name={powerBallInfo?.miniGameBettingPer?.powerOddNormalOddPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.powerOddNormalOddPer || 0}</span>
              <div class="mark">
                <span class="ic_odd">홀</span> <span class="ic_odd">홀</span>
              </div>
              <span class="bedd-title">홀+홀</span>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_ODD_NORMAL_BALL_EVEN' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_ODD_NORMAL_BALL_EVEN"
              name={powerBallInfo?.miniGameBettingPer?.powerOddNormalEvenPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.powerOddNormalEvenPer || 0}</span>
              <div class="mark">
                <span class="ic_odd">홀</span> <span class="ic_even">짝</span>
              </div>
              <span class="bedd-title">홀+짝</span>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_EVEN_NORMAL_BALL_ODD' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_EVEN_NORMAL_BALL_ODD"
              name={powerBallInfo?.miniGameBettingPer?.powerEvenNormalOddPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.powerEvenNormalOddPer || 0}</span>
              <div class="mark">
                <span class="ic_even">짝</span> <span class="ic_odd">홀</span>
              </div>
              <span class="bedd-title">짝+홀</span>
            </div>
            <div
              className={miniGameBettingType === 'POWER_BALL_EVEN_NORMAL_BALL_EVEN' ? 'btnBet on' : 'btnBet'}
              id="POWER_BALL_EVEN_NORMAL_BALL_EVEN"
              name={powerBallInfo?.miniGameBettingPer?.powerEvenNormalEvenPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.powerEvenNormalEvenPer || 0}</span>
              <div class="mark">
                <span class="ic_even">짝</span> <span class="ic_even">짝</span>
              </div>
              <span class="bedd-title">짝+짝</span>
            </div>
          </div>
        </div>

        <div class="pick_wrap">
          <h4 class="pick_title">일반볼 조합 (1)</h4>
          <div class="pick_bet">
            <div
              className={miniGameBettingType === 'NORMAL_BALL_ODD_OVER' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_ODD_OVER"
              name={powerBallInfo?.miniGameBettingPer?.normalOddOverPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalOddOverPer || 0}</span>
              <div class="mark">
                <span class="ic_odd">홀</span> <span class="ic_over">오버</span>
              </div>
              <span class="bedd-title">홀+오버</span>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_ODD_UNDER' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_ODD_UNDER"
              name={powerBallInfo?.miniGameBettingPer?.normalOddUnderPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalOddUnderPer || 0}</span>
              <div class="mark">
                <span class="ic_odd">홀</span> <span class="ic_under">언더</span>
              </div>
              <span class="bedd-title">홀+언더</span>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_EVEN_OVER' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_EVEN_OVER"
              name={powerBallInfo?.miniGameBettingPer?.normalEvenOverPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalEvenOverPer || 0}</span>
              <div class="mark">
                <span class="ic_even">짝</span> <span class="ic_over">오버</span>
              </div>
              <span class="bedd-title">짝+오버</span>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_EVEN_UNDER' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_EVEN_UNDER"
              name={powerBallInfo?.miniGameBettingPer?.normalEvenUnderPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalEvenUnderPer || 0}</span>
              <div class="mark">
                <span class="ic_even">짝</span> <span class="ic_over">언더</span>
              </div>
              <span class="bedd-title">짝+언더</span>
            </div>
          </div>
        </div>

        <div class="pick_wrap">
          <h4 class="pick_title">일반볼 조합 (2)</h4>
          <div class="pick_bet grid-3">
            <div
              className={miniGameBettingType === 'NORMAL_BALL_ODD_MINOR' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_ODD_MINOR"
              name={powerBallInfo?.miniGameBettingPer?.normalOddMinorPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalOddMinorPer || 0}</span>
              <div class="mark">
                <span class="ic_odd">홀</span> <span class="ic_sm">소</span>
              </div>
              <span class="bedd-title">홀+소</span>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_ODD_MIDDLE' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_ODD_MIDDLE"
              name={powerBallInfo?.miniGameBettingPer?.normalOddMiddlePer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalOddMiddlePer || 0}</span>
              <div class="mark">
                <span class="ic_odd">홀</span> <span class="ic_md">중</span>
              </div>
              <span class="bedd-title">홀+중</span>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_ODD_MAJOR' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_ODD_MAJOR"
              name={powerBallInfo?.miniGameBettingPer?.normalOddMajorPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalOddMajorPer || 0}</span>
              <div class="mark">
                <span class="ic_odd">홀</span> <span class="ic_lg">대</span>
              </div>
              <span class="bedd-title">홀+대</span>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_EVEN_MINOR' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_EVEN_MINOR"
              name={powerBallInfo?.miniGameBettingPer?.normalEvenMinorPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalEvenMinorPer || 0}</span>
              <div class="mark">
                <span class="ic_even">짝</span> <span class="ic_sm">소</span>
              </div>
              <span class="bedd-title">짝+소</span>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_EVEN_MIDDLE' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_EVEN_MIDDLE"
              name={powerBallInfo?.miniGameBettingPer?.normalEvenMiddlePer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalEvenMiddlePer || 0}</span>
              <div class="mark">
                <span class="ic_even">짝</span> <span class="ic_md">중</span>
              </div>
              <span class="bedd-title">짝+중</span>
            </div>
            <div
              className={miniGameBettingType === 'NORMAL_BALL_EVEN_MAJOR' ? 'btnBet on' : 'btnBet'}
              id="NORMAL_BALL_EVEN_MAJOR"
              name={powerBallInfo?.miniGameBettingPer?.normalEvenMajorPer || 0}
              onClick={handleBettingCart}
            >
              <span class="bedd">{powerBallInfo?.miniGameBettingPer?.normalEvenMajorPer || 0}</span>
              <div class="mark">
                <span class="ic_even">짝</span> <span class="ic_lg">대</span>
              </div>
              <span class="bedd-title">짝+대</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniGameNtryEosPowerBallOne
