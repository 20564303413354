import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import WrapperLoading from '../../containers/WrapperLoading'
import { convertToKstByFormat, convertToKstDateTime } from '../../utils/dateTime'
import CustomPagination from '../../shared/components/CustomPagination'
import { searchMemberMoneyWithdrawal } from '../../api/memberHistory/memberHistoryApi'
import {
  createUserMoneyWithdrawal,
  searchMinimumAmount,
  updateMemberMoneyHistoryDelete,
  searchWithdrawalRule,
} from '../../api/memberManage/memberManageApi'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import { MoneyWithdrawalEnumsStatusEnums } from '../../utils/enums/MyPage/myPageEnums'
import { RollingStatusEnums, BonusTypeEnums } from '../../utils/enums/MemberHistory/MemberDepositEnums'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'

const MoneyWithdrawalPage = () => {
  const [apiLoading, setApiLoading] = useState(false)
  const dispatch = useDispatch()

  /**
   * 회원 머니출금내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  // const { totalElement, content } = useSearchMemberMoneyWithdrawal(params)

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberMoneyWithdrawal = async () => {
    setApiLoading(true)
    await searchMemberMoneyWithdrawal(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberMoneyWithdrawal()
  }, [historyParams])

  // 최소 출금액
  const [minimumWithdrawal, setMinimumWithdrawal] = useState('0')
  const [withdrawalMaxAmount, setWithdrawalMaxAmount] = useState('0')
  const [withdrawlTerm, setWithdrawalMaxTerm] = useState(0)

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })

  useEffect(() => {
    setApiLoading(true)

    searchMinimumAmount()
      .then(res => {
        setMinimumWithdrawal(res.data.minimumWithdrawal)
        setWithdrawalMaxAmount(res.data.withdrawalMaxAmount)
        setWithdrawalMaxTerm(res.data.withdrawlTerm)
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }, [])

  // 출금 금액
  const [applyMoney, setApplyMoney] = useState('0')

  // 출금 비밀번호
  const [withdrawalPassword, setWithdrawalPassword] = useState('')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  const [bonusPer, setBonusPer] = useState(0)
  const [bonusType, setBonusType] = useState(null)
  const [totalDepositAmount, setTotalDepositAmount] = useState(0)
  const [withdrawRollingStatus, setWithdrawRollingStatus] = useState(null)
  const [currentRollingRatio, setCurrentRollingRatio] = useState(0)
  const [necessarySportsSingleLiveBettingAmount, setNecessarySportsSingleLiveBettingAmount] = useState(0)
  const [necessarySportsTwoBettingAmount, setNecessarySportsTwoBettingAmount] = useState(0)
  const [necessarySportsMultiBettingAmount, setNecessarySportsMultiBettingAmount] = useState(0)
  const [necessaryVirtualBettingAmount, setNecessaryVirtualBettingAmount] = useState(0)
  const [necessaryMiniGameBettingAmount, setNecessaryMiniGameBettingAmount] = useState(0)
  const [necessaryCasinoBettingAmount, setNecessaryCasinoBettingAmount] = useState(0)
  const [necessarySlotBettingAmount, setNecessarySlotBettingAmount] = useState(0)
  const [necessaryHoldemBettingAmount, setNecessaryHoldemBettingAmount] = useState(0)

  useEffect(() => {
    searchWithdrawalRule().then(res => {
      setBonusPer(res.data.withdrawalRollingModel.bonusPer)
      setBonusType(res.data.withdrawalRollingModel.bonusType)
      setTotalDepositAmount(res.data.withdrawalRollingModel.totalDepositAmount)
      setWithdrawRollingStatus(res.data.withdrawalRollingModel.withdrawRollingStatus)
      setCurrentRollingRatio(res.data.withdrawalRollingModel.currentRollingRatio)
      setNecessarySportsSingleLiveBettingAmount(res.data.withdrawalRollingModel.necessarySportsSingleLiveBettingAmount)
      setNecessarySportsTwoBettingAmount(res.data.withdrawalRollingModel.necessarySportsTwoBettingAmount)
      setNecessarySportsMultiBettingAmount(res.data.withdrawalRollingModel.necessarySportsMultiBettingAmount)
      setNecessaryVirtualBettingAmount(res.data.withdrawalRollingModel.necessaryVirtualBettingAmount)
      setNecessaryMiniGameBettingAmount(res.data.withdrawalRollingModel.necessaryMiniGameBettingAmount)
      setNecessaryCasinoBettingAmount(res.data.withdrawalRollingModel.necessaryCasinoBettingAmount)
      setNecessarySlotBettingAmount(res.data.withdrawalRollingModel.necessarySlotBettingAmount)
      setNecessaryHoldemBettingAmount(res.data.withdrawalRollingModel.necessaryHoldemBettingAmount)
    })
  }, [])

  const onSubmit = e => {
    if (apiLoading) return
    if (withdrawRollingStatus !== 'COMPLETE' && withdrawRollingStatus !== 'MANUAL_COMPLETE') {
      return
    }
    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }
    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) > Number(withdrawalMaxAmount)) {
      alert(`최대 ${withdrawalMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 출금 가능합니다.`)
      return
    }

    if (Number(checkMoney) > Number(holdingMoney)) {
      alert('보유머니를 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) < Number(minimumWithdrawal)) {
      alert(`최소 ${minimumWithdrawal?.toString().replace(commonReg2, ',')}원 이상으로 출금 가능합니다.`)
      return
    }

    setApiLoading(true)

    const body = {
      applyMoney: checkMoney,
    }

    createUserMoneyWithdrawal(body)
      .then(res => {
        alert('출금신청이 완료되었습니다.')
        setApiLoading(false)
        fetchSearchMemberMoneyWithdrawal()
        dispatch(fetchMemberInfo())
        setApplyMoney('0')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1011':
            alert('출금이 불가능한 시간입니다.')
            break
          case 'ASSET-1005':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('출금신청이 실패했습니다. 다시 시도해주세요.')
        }
        setApiLoading(false)
        fetchSearchMemberMoneyWithdrawal()
        setApplyMoney('0')
      })
  }

  const onDeleteAllHandler = () => {
    if (apiLoading) return

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setApiLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'WITHDRAWAL',
        moneyHistoryKeyList: content.map(item => item.moneyWithdrawalKey),
      })
        .then(res => {
          setApiLoading(false)
          alert('삭제 완료 되었습니다.')
          fetchSearchMemberMoneyWithdrawal()
        })
        .catch(error => {
          setApiLoading(false)
          alert('삭제 실패했습니다.')
          fetchSearchMemberMoneyWithdrawal()
        })
    }
  }

  const onDeleteHandler = moneyWithdrawalKey => {
    if (apiLoading) return

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setApiLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'WITHDRAWAL',
        moneyHistoryKeyList: [moneyWithdrawalKey],
      })
        .then(res => {
          setApiLoading(false)
          alert('삭제 완료 되었습니다.')
          fetchSearchMemberMoneyWithdrawal()
        })
        .catch(error => {
          setApiLoading(false)
          alert('삭제 실패했습니다.')
          fetchSearchMemberMoneyWithdrawal()
        })
    }
  }

  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  function formatAmount(amount) {
    if (amount != null) {
      return amount >= 0 ? `${amount.toString().replace(commonReg2, ',')} 원` : '0 원'
    }
    return '0 원'
  }

  return (
    <>
      {apiLoading && <WrapperLoading isLoading={apiLoading} />}
      <div className="main-content">
        <div className="aniFrame">
          <div className="titlePic">
            <div className="titlePwrapper">
              <div className="leftZone">
                <span>출금신청</span>
              </div>
              <div className="line" />
            </div>
          </div>
          <div className="main_content_wrap main_content_wrap_notice">
            <div className="noticeFrame">
              <PageOverlayWrap>
                {withdrawRollingStatus !== 'COMPLETE' && withdrawRollingStatus !== 'MANUAL_COMPLETE' && (
                  <PageOverlay>
                    <PageOverlayImg src={`${process.env.PUBLIC_URL}/img/rolling/prohibit.png`} />
                    <PageOverlayContent>롤링 완료 후 출금이 가능합니다.</PageOverlayContent>
                  </PageOverlay>
                )}
                <Form
                  onSubmit={onSubmit}
                  initialValues={{
                    memberId: userId,
                    memberName: userName,
                    bankName,
                    accountHolder: accountNum,
                    applyMoney,
                    withdrawalPassword,
                    // TODO 지갑머니 < 출금머니가 클때 validate 체크할려고 넣은 변수
                    minimumWithdrawal,
                    holdingMoney: holdingMoney.toString().replace(commonReg2, ','),
                  }}
                  // validate={MoneyDepositValidate}
                >
                  {({ handleSubmit, form: { reset } }) => (
                    <form className="animated fadeIn delay2" name="frm_cash_out" method="post" onSubmit={handleSubmit}>
                      <div className="info-wrap memo pc" style={{ textAlign: 'left' }}>
                        <div className="tit">확인/필독사항</div>
                        <div className="content">
                          * 출금 최소액은{' '}
                          <span style={{ color: 'red' }}>
                            {' '}
                            {minimumWithdrawal?.toString().replace(commonReg2, ',') || 0}원
                          </span>
                          , 1회 출금 최대액
                          <span style={{ color: 'red' }}> (레벨별 상이)</span>은{' '}
                          <span style={{ color: 'red' }}>
                            {' '}
                            {withdrawalMaxAmount?.toString().replace(commonReg2, ',') || 0}원
                          </span>{' '}
                          , 출금 간격은 <span style={{ color: 'red' }}> {withdrawlTerm || 0}분</span>
                          입니다.
                          <br />※ 회원님들의 배팅이 몰리는 대상 경기가 끝난 후에는 많은 환전량이 발생되어 환전이 잠시
                          지연될수 있습니다.
                        </div>
                      </div>
                      <div className="info-wrap memo mob" style={{ textAlign: 'left' }}>
                        <div className="tit">확인/필독사항</div>
                        <div className="content" style={{ fontSize: '12px' }}>
                          ※ 출금 최소액은{' '}
                          <span style={{ color: 'red' }}>
                            {' '}
                            {minimumWithdrawal?.toString().replace(commonReg2, ',') || 0}원
                          </span>
                          , 출금 간격은 <span style={{ color: 'red' }}> {withdrawlTerm || 0}분</span>,
                          <br /> 1회 출금 최대액
                          <span style={{ color: 'red' }}> (레벨별 상이)</span>은{' '}
                          <span style={{ color: 'red' }}>
                            {' '}
                            {withdrawalMaxAmount?.toString().replace(commonReg2, ',') || 0}원
                          </span>{' '}
                          입니다.
                          <br />※ 회원님들의 배팅이 몰리는 대상 경기가 끝난 후에는 많은 환전량이 발생되어 환전이 잠시
                          지연될수 있습니다.
                        </div>
                      </div>

                      <div className="info-wrap cash mt15" style={{ textAlign: 'left' }}>
                        <div className="input-wrap">
                          <div className="desc">은행명</div>
                          <Field name="bankName">
                            {({ input, meta }) => <input {...input} type="text" readOnly />}
                          </Field>
                        </div>

                        <div className="input-wrap">
                          <div className="desc">예금주</div>
                          <Field name="memberName">
                            {({ input, meta }) => <input {...input} type="text" readOnly />}
                          </Field>
                        </div>

                        <div className="input-wrap">
                          <div className="desc">보유 금액</div>

                          <Field name="holdingMoney">
                            {({ input, meta }) => <input {...input} type="text" className="userCashAmount" readOnly />}
                          </Field>
                        </div>

                        {/* <div className="input-wrap">
                        <div className="desc">
                          출금비밀번호 <span>*</span>
                        </div>
                        <Field name="withdrawalPassword">
                          {({ input, meta }) => (
                            <input
                              {...input}
                              type="text"
                              value={withdrawalPassword}
                              onChange={e => {
                                setWithdrawalPassword(e.target.value)
                              }}
                            />
                          )}
                        </Field>
                      </div> */}

                        <div className="input-wrap">
                          <div className="desc">
                            출금 금액 <span>*</span>
                          </div>
                          <Field name="applyMoney">
                            {({ input, meta }) => (
                              <input
                                {...input}
                                type="text"
                                value={applyMoney}
                                name="amount"
                                onChange={e => {
                                  const { value } = e.target
                                  const onlyNumber = value.replace(/[^0-9]/g, '')
                                  setApplyMoney(onlyNumber.toString().replace(commonReg2, ','))
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="money-wrap">
                          <button
                            type="button"
                            className="money btn_plus"
                            data-value="10000"
                            onClick={e => {
                              onClickMoneyHandler('10000')
                            }}
                          >
                            1만원
                          </button>
                          <button
                            type="button"
                            className="money btn_plus"
                            data-value="50000"
                            onClick={e => {
                              onClickMoneyHandler('50000')
                            }}
                          >
                            5만원
                          </button>
                          <button
                            type="button"
                            className="money btn_plus"
                            data-value="100000"
                            onClick={e => {
                              onClickMoneyHandler('100000')
                            }}
                          >
                            10만원
                          </button>
                          <button
                            type="button"
                            className="money btn_plus"
                            data-value="500000"
                            onClick={e => {
                              onClickMoneyHandler('500000')
                            }}
                          >
                            50만원
                          </button>
                          <button
                            type="button"
                            className="money btn_plus"
                            data-value="1000000"
                            onClick={e => {
                              onClickMoneyHandler('1000000')
                            }}
                          >
                            100만원
                          </button>
                          <button
                            type="button"
                            className="money btn_plus"
                            data-value="5000000"
                            onClick={e => {
                              onClickMoneyHandler('5000000')
                            }}
                          >
                            500만원
                          </button>
                          <button
                            type="button"
                            className="money btn_reset"
                            data-other="reset"
                            onClick={() => {
                              onClearClickHandler()
                            }}
                          >
                            초기화
                          </button>
                        </div>
                      </div>

                      <div className="button-wrap mt20">
                        <button type="submit" className="btnN blueB">
                          출금신청
                        </button>
                      </div>
                    </form>
                  )}
                </Form>
              </PageOverlayWrap>
              <RollingTopWrap>
                <RollingWrap>
                  <RollingHeaderWrap>
                    <RollingHeaderInfo1>
                      {BonusTypeEnums[bonusType]} {bonusPer}% &nbsp;-
                    </RollingHeaderInfo1>
                    <RollingHeaderInfo2>
                      &nbsp;{totalDepositAmount?.toString().replace(commonReg2, ',')}&nbsp;원
                    </RollingHeaderInfo2>
                  </RollingHeaderWrap>
                  <RollingContentWrap1>
                    <RollingContentBox>
                      <RollingCompleteImg src={`${process.env.PUBLIC_URL}/img/rolling/rollingcheck.png`} />
                      {withdrawRollingStatus === 'COMPLETE' || withdrawRollingStatus === 'MANUAL_COMPLETE' ? (
                        <RollingContentTitleWrap>
                          <RollingContentTitle1 style={{ color: 'red' }}>
                            {RollingStatusEnums[withdrawRollingStatus]}
                          </RollingContentTitle1>
                          <RollingContentTitle2 style={{ color: 'red' }}>
                            * 롤링을 완료했으므로 출금이 가능합니다.
                          </RollingContentTitle2>
                        </RollingContentTitleWrap>
                      ) : (
                        <RollingContentTitleWrap>
                          <RollingContentTitle1 style={{ color: 'red' }}>
                            {RollingStatusEnums[withdrawRollingStatus]}
                          </RollingContentTitle1>
                          <RollingContentTitle2 style={{ color: 'red' }}>
                            * 나머지 롤링을 채운 후 출금이 가능합니다.
                          </RollingContentTitle2>
                        </RollingContentTitleWrap>
                      )}
                    </RollingContentBox>
                  </RollingContentWrap1>
                  <RollingContentWrap2>
                    <ProgressBarContainer>
                      <FilledBar percentage={currentRollingRatio} />
                      <PercentageText>
                        {currentRollingRatio >= 100 ? '100%' : `${currentRollingRatio}%`} &nbsp;완료
                      </PercentageText>
                    </ProgressBarContainer>
                  </RollingContentWrap2>
                  <RollingContentWrap3>
                    <RollingContentTitle3>보너스 롤링완료까지 필요한 배팅금액:</RollingContentTitle3>
                    <RollingContentSportWrap className="money-wrap">
                      {/* <RollingContentSportTypeBox>
                        <RollingContentSportTypeImg src={`${process.env.PUBLIC_URL}/img/rolling/rolling_sports.png`} />
                        <RollingContentSportTypeTitle>
                          스포츠 <br />
                          실시간/단폴더
                        </RollingContentSportTypeTitle>
                        <RollingContentSportTypeMoney>
                          {formatAmount(necessarySportsSingleLiveBettingAmount)}
                        </RollingContentSportTypeMoney>
                      </RollingContentSportTypeBox>
                      <RollingContentSportTypeBox>
                        <RollingContentSportTypeImg src={`${process.env.PUBLIC_URL}/img/rolling/rolling_sports.png`} />
                        <RollingContentSportTypeTitle>스포츠 두폴더</RollingContentSportTypeTitle>
                        <RollingContentSportTypeMoney>
                          {formatAmount(necessarySportsTwoBettingAmount)}
                        </RollingContentSportTypeMoney>
                      </RollingContentSportTypeBox>
                      <RollingContentSportTypeBox>
                        <RollingContentSportTypeImg src={`${process.env.PUBLIC_URL}/img/rolling/rolling_sports.png`} />
                        <RollingContentSportTypeTitle>스포츠 다폴더</RollingContentSportTypeTitle>
                        <RollingContentSportTypeMoney>
                          {formatAmount(necessarySportsMultiBettingAmount)}
                        </RollingContentSportTypeMoney>
                      </RollingContentSportTypeBox>
                      <RollingContentSportTypeBox>
                        <RollingContentSportTypeImg src={`${process.env.PUBLIC_URL}/img/rolling/rolling_mini.png`} />
                        <RollingContentSportTypeTitle>미니게임</RollingContentSportTypeTitle>
                        <RollingContentSportTypeMoney>
                          {formatAmount(necessaryMiniGameBettingAmount)}
                        </RollingContentSportTypeMoney>
                      </RollingContentSportTypeBox> */}
                      <RollingContentSportTypeBox>
                        <RollingContentSportTypeImg src={`${process.env.PUBLIC_URL}/img/rolling/rolling_live.png`} />
                        <RollingContentSportTypeTitle>카지노</RollingContentSportTypeTitle>
                        <RollingContentSportTypeMoney>
                          {formatAmount(necessaryCasinoBettingAmount)}
                        </RollingContentSportTypeMoney>
                      </RollingContentSportTypeBox>
                      <RollingContentSportTypeBox>
                        <RollingContentSportTypeImg src={`${process.env.PUBLIC_URL}/img/rolling/rolling_slot.png`} />
                        <RollingContentSportTypeTitle>슬롯</RollingContentSportTypeTitle>
                        <RollingContentSportTypeMoney>
                          {formatAmount(necessarySlotBettingAmount)}
                        </RollingContentSportTypeMoney>
                      </RollingContentSportTypeBox>

                      {/* <RollingContentSportTypeBox>
                                <RollingContentSportTypeImg
                                  src={`${process.env.PUBLIC_URL}/img/rolling/rolling_holdem.png`}
                                />
                                <RollingContentSportTypeTitle>홀덤</RollingContentSportTypeTitle>
                                <RollingContentSportTypeMoney>
                                  {necessaryHoldemBettingAmount?.toString().replace(commonReg2, ',')} 원
                                </RollingContentSportTypeMoney>
                              </RollingContentSportTypeBox> */}
                    </RollingContentSportWrap>
                  </RollingContentWrap3>
                </RollingWrap>
              </RollingTopWrap>
              <div>
                <div className="listZone">
                  <table>
                    <thead>
                      <tr>
                        <th className="ac number" width="10%">
                          No.
                        </th>
                        <th className="ac" scope="col">
                          출금액
                        </th>
                        <th className="ac">처리일</th>
                        <th className="ac">상태</th>
                        <th className="ac">
                          <button
                            className={
                              totalElement === 0
                                ? 'btn darken-hover btn-delete-all disabled'
                                : 'btn darken-hover btn-delete-all'
                            }
                            type="button"
                            onClick={() => {
                              onDeleteAllHandler()
                            }}
                          >
                            <span className="icon-iconTrash" />
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {content.map(item => (
                        <tr>
                          <td>{item.num}</td>
                          <td>
                            <p className="money">{item.applyMoney?.toString().replace(commonReg2, ',')}</p>
                          </td>
                          <td className="pc">{convertToKstDateTime(item.executeDate)}</td>
                          <td className="mob">{convertToKstByFormat(item.executeDate, 'MM-DD')}</td>
                          <td>{MoneyWithdrawalEnumsStatusEnums[item.status]}</td>
                          <td>
                            <button
                              className="btn badge darken-hover"
                              type="button"
                              onClick={() => {
                                onDeleteHandler(item.moneyWithdrawalKey)
                              }}
                            >
                              <span className="icon-iconTrash" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {totalElement === 0 && (
                    <div className="tb_empty">
                      <div className="pic">
                        <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
                      </div>
                      <h2>데이터 없음</h2>
                      <h3>Sorry, Empty Data</h3>
                    </div>
                  )}

                  {totalElement !== 0 && (
                    <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MoneyWithdrawalPage

const PageOverlayWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  // align-items: center;
  text-align: center;
  padding-bottom: 10px;
`

const PageOverlayImg = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 15px;
`

const PageOverlayContent = styled.div`
  color: #fff;
  font-size: 16px;
`

const PageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// endregion
const RollingTopWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-right: 20px;
  margin-top: 20px;
`
const RollingWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px 10px;
  justify-content: center;
  gap: 20px;
  background: rgba(47, 52, 56);
`

const RollingHeaderWrap = styled.div`
  // height: 50px;
  width: 100%;
  display: flex;
  align-items: flex-start;
`

const RollingHeaderInfo1 = styled.div`
  font-size: 15px;
  color: #fff;
`

const RollingHeaderInfo2 = styled.div`
  font-size: 15px;
  color: red;
`

const RollingContentWrap1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RollingContentBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
`

const RollingCompleteImg = styled.img`
  width: 36px;
  height: 36px;
`

const RollingContentTitleWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`

const RollingContentTitle1 = styled.div`
  font-size: 14px;
  color: red;
  font-weight: 500;
`

const RollingContentTitle2 = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const RollingContentWrap2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const ProgressBarContainer = styled.div`
  width: 100%;
  height: 35px;
  background-color: #d6d6d6;
  position: relative;
  border-radius: 7px;
`

const FilledBar = styled.div`
  height: 100%;
  width: ${props => (props.percentage >= 100 ? '100%' : `${props.percentage}%`)};
  background-color: ${props => (props.percentage >= 100 ? 'green' : '#000')};
  border-radius: 7px;
`

const PercentageText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 가로, 세로 중앙 정렬 */
  color: #000;
  font-size: 14px;
  font-weight: 700;
`

const RollingContentWrap3 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const RollingContentTitle3 = styled.div`
  font-size: 12px;
  color: #fff;
`

const RollingContentSportWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`

const RollingContentSportTypeBox = styled.div`
  min-width: 300px;
  max-width: 350px;

  height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 5px;
`

const RollingContentSportTypeImg = styled.img`
  width: 50px;
  height: auto;
`
const RollingContentSportTypeTitle = styled.div`
  font-size: 14px;
  color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const RollingContentSportTypeMoney = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: red;
`
