import jwtDecode from 'jwt-decode'
import { getAccessToken } from './cookie'

export const decodeAccessToken = () => {
  const accessToken = getAccessToken()

  if (!accessToken) return null

  return jwtDecode(accessToken)
}
