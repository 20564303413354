import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import WrapperLoading from '../../containers/WrapperLoading'
import {
  deleteSportsBettingHistory,
  cancelSportsBettingHistory,
  searchSportsBettingHistory,
} from '../../api/game/sportsGameApi'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import { SportTypeEnums, SportsResultStatusEnums } from '../../utils/enums/SportsGame/SportsGameEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'

const SportsBettingHistoryPage = ({ deleteLoading, setDeleteLoading }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [sportsBettingHistoryContent, setSportsBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchSportsBettingHistory = async params => {
    setDeleteLoading(true)
    await searchSportsBettingHistory(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setSportsBettingHistoryContent(res.data.content)
        setDeleteLoading(false)
      })
      .catch(error => setDeleteLoading(false))
  }

  useEffect(() => {
    fetchSportsBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case marketName.includes('승무패') || marketName.includes('승패'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
            alt=""
            style={{ width: '10px', height: '7px' }}
          />
        )
      case marketName.includes('핸디'):
        return (
          // <img src={`${process.env.PUBLIC_URL}/img/sports/icon_h.png`} alt="" style={{ width: '7px', height: '7px' }} />
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('언더'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('오버'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      default:
    }
  }

  const getOddNameKrComponent = (marketName, oddNameKr, pointName) => {
    switch (true) {
      case oddNameKr === '홀':
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr === '짝':
        return <span>[{oddNameKr}] &nbsp; </span>
      case oddNameKr === '예':
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr === '아니오':
        return <span>[{oddNameKr}] &nbsp; </span>
      case oddNameKr.includes('/언더'):
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr.includes('/오버'):
        return <span>[{oddNameKr}] &nbsp; </span>
      case marketName.includes('/양팀모두득점') && oddNameKr.includes('예'):
        return <span>&nbsp; [{oddNameKr}]</span>
      case marketName.includes('/양팀모두득점') && oddNameKr.includes('아니오'):
        return <span>[{oddNameKr}]&nbsp;</span>
      case marketName.includes('언더오버 3-Way') && oddNameKr.includes('언더'):
        return <span>&nbsp;[{pointName}]</span>
      case marketName.includes('언더오버 3-Way') && oddNameKr.includes('오버'):
        return <span>[{pointName}]&nbsp;</span>
      case marketName.includes('핸디캡 3-Way') && oddNameKr.includes('홈'):
        return <span>&nbsp;[{pointName}]</span>
      case marketName.includes('핸디캡 3-Way') && oddNameKr.includes('원정'):
        return <span>[{pointName}]&nbsp;</span>
      default:
    }
  }

  const onClickBettingHistoryCancelHandler = item => {
    // 현재시간
    const currentTime = moment().tz('Asia/Seoul')
    // 취소 가능 시간

    const availableCancelTime = moment(item.availableCancelTime).tz('Asia/Seoul')

    if (currentTime >= availableCancelTime) {
      alert('해당 배팅의 취소가능 시간이 지나서 배팅 취소가 불가능합니다.')
      return
    }

    // 경기가 시작된 것이 하나라도 있는 지
    const findIdx = item.detailBettingLogs.findIndex(log => moment(log.startAt).tz('Asia/Seoul') < currentTime)

    if (findIdx !== -1) {
      // 이미 시작된 경기가 존재하는 상황
      alert('이미 시작된 경기가 있어, 배팅 취소가 불가능합니다.')
      return
    }

    setDeleteLoading(true)
    setLoadingInfo('배팅 취소 처리중입니다.')

    cancelSportsBettingHistory({
      sportsBettingLogKey: item.sportsBettingLogKey,
      bettingAmount: item.bettingAmount,
    })
      .then(res => {
        alert('해당 배팅건에 대해 취소 처리 되었습니다.')
        fetchSportsBettingHistory(sportsBettingHistoryParams)
        dispatch(fetchMemberInfo())
        setDeleteLoading(false)
        setLoadingInfo('')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'SPORTS-1017':
            alert(`${message}`)
            break
          default:
            alert('해당 배팅건에 대해 취소 처리 실패하였습니다.')
        }

        setDeleteLoading(false)
        setLoadingInfo('')
      })
  }
  const [checkRadio, setCheckRadio] = useState([])

  // 삭제하기 버튼
  const onDeleteHandler = sportsBettingLogKey => {
    if (deleteLoading) return

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteSportsBettingHistory({
        sportsBettingLogKeyList: [sportsBettingLogKey],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  const onAllDeleteHandler = () => {
    if (deleteLoading) return

    // if (checkRadio.length === 0) {
    //   alert('삭제 할 내역을 선택해주세요.')
    //   return
    // }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)

      deleteSportsBettingHistory({
        sportsBettingLogKeyList: sportsBettingHistoryContent
          .filter(value => value.sportsResultStatus !== 'ING')
          .map(value => value.sportsBettingLogKey),
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  const getColor = bettingResult => {
    switch (true) {
      case bettingResult === 'WIN':
        return 'blue'
      case bettingResult === 'LOSE':
        return 'red'
      case bettingResult === 'DRAW':
        return 'grey'
      case bettingResult === 'ING':
        return 'lightblue'
      case bettingResult === 'CANCEL':
        return 'red'
      default:
        return 'white'
    }
  }

  return (
    <>
      <div className="btn-wrap">
        <button
          className=""
          onClick={() => {
            onAllDeleteHandler()
          }}
          type="button"
        >
          전체내역삭제
        </button>
      </div>

      <div className="bet-list-wrap listZone">
        <div className="bet-content bet-id-39 pc">
          {sportsBettingHistoryContent.map((item, index) => (
            <>
              <table className="styled" key={item.sportsBettingLogKey}>
                <thead>
                  <tr>
                    <th className="ac">경기시간</th>
                    <th className="ac">리그명</th>
                    <th className="ac">홈팀</th>
                    <th className="ac">무</th>
                    <th className="ac">원정팀</th>
                    <th className="ac">타입</th>
                    <th className="ac">스코어</th>
                    <th className="ac">상태</th>
                  </tr>
                </thead>
                <tbody>
                  {item.detailBettingLogs.map(detail => (
                    <>
                      {detail.awayOddName ? (
                        <tr key={detail.sportsDetailBettingLogKey}>
                          <td>{convertToKstByFormat(detail.startAt, 'MM-DD HH:mm')}</td>
                          <td>
                            <div className="league-wrap">
                              {detail.sportsType !== 'ETC' && (
                                <img
                                  width="23"
                                  src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${detail.sportsType}.png`}
                                  alt=""
                                />
                              )}

                              <img width="23" src={detail.leagueImage} alt="" />
                              <span>{detail.leagueName || '-'}</span>
                            </div>
                          </td>
                          <td
                            className={`team-info-wrap ${
                              detail.homeOddName === detail.selectedOddName ? 'sel-pick' : ''
                            }`}
                          >
                            <span className="team-name">
                              {detail.homeName}{' '}
                              {getOddNameKrComponent(detail.marketName, detail.homeOddNameKr, detail.pointName)}
                            </span>
                            <span className="rate"> [{detail.homeOddValue}]</span>
                            <br />
                            <span className="baseline" />
                          </td>
                          <td className={`${detail.selectedOddName === 'Draw' ? 'sel-pick' : ''}`}>
                            {(detail.drawOddName && detail.drawOddValue) || detail.pointName || 'VS'}
                          </td>
                          <td
                            className={`team-info-wrap ${
                              detail.awayOddName === detail.selectedOddName ? 'sel-pick' : ''
                            }`}
                          >
                            <span className="team-name">
                              {getOddNameKrComponent(detail.marketName, detail.awayOddNameKr, detail.pointName)}{' '}
                              {detail.awayName}
                            </span>
                            <span className="rate"> [{detail.awayOddValue}]</span>
                            <br />
                            <span className="baseline" />
                          </td>
                          <td>
                            {detail.marketName}
                            <br />
                          </td>
                          <td>
                            <span>{detail.resultScore || '-'}</span>
                          </td>
                          <td rowSpan="1">
                            <CustomResultSpan
                              className="status bet-status-win"
                              backgroundColor={getColor(detail.sportsResultStatus)}
                            >
                              {SportsResultStatusEnums[detail.sportsResultStatus]}
                            </CustomResultSpan>
                          </td>
                        </tr>
                      ) : (
                        <tr key={detail.sportsDetailBettingLogKey}>
                          <td>{convertToKstByFormat(detail.startAt, 'MM-DD HH:mm')}</td>
                          <td>
                            <div className="league-wrap">
                              {detail.sportsType !== 'ETC' && (
                                <img
                                  width="23"
                                  src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${detail.sportsType}.png`}
                                  alt=""
                                />
                              )}
                              {detail.leagueImage && <img width="23" src={detail.leagueImage} alt="" />}
                              <span>{detail.leagueName || '-'}</span>
                            </div>
                          </td>
                          <td colSpan={3} className="sel-pick team-info-wrap">
                            {detail.homeName && (
                              <span className="team-name">
                                {detail.homeName} VS {detail.awayName}
                              </span>
                            )}
                            {detail.selectedOddNameKr && !detail.marketName.includes('정확한') && (
                              <span className="team-name">[{detail.selectedOddNameKr}]</span>
                            )}

                            {detail.pointName && <span style={{ marginRight: '10px' }}> [{detail.pointName}]</span>}
                            <span className="rate"> [{detail.homeOddValue}]</span>
                            <br />
                            <span className="baseline" />
                          </td>
                          {/* <td className="">
                                {(detail.drawOddName && detail.drawOddValue) || detail.pointName || 'VS'}
                              </td>
                              <td className=" team-info-wrap">
                                <span className="team-name">
                                  {getOddNameKrComponent(detail.marketName, detail.awayOddNameKr, detail.pointName)}{' '}
                                  {detail.awayName}
                                </span>
                                <span className="rate"> [{detail.awayOddValue}]</span>
                                <br />
                                <span className="baseline" />
                              </td> */}
                          <td>
                            {detail?.marketName === '보너스 배당' && (
                              <img width="23" src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`} alt="" />
                            )}
                            {detail.marketName}
                            <br />
                          </td>
                          <td>
                            <span>{detail.resultScore || '-'}</span>
                          </td>
                          <td rowSpan="1">
                            <CustomResultSpan
                              className="status bet-status-win"
                              backgroundColor={getColor(detail.sportsResultStatus)}
                            >
                              {SportsResultStatusEnums[detail.sportsResultStatus]}
                            </CustomResultSpan>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
              <div className="bet-result sports">
                <div className="info">
                  <CustomResultSpan
                    className="status bet-status-win"
                    backgroundColor={getColor(item.sportsResultStatus)}
                  >
                    {SportsResultStatusEnums[item.sportsResultStatus]}
                  </CustomResultSpan>
                  <span>베팅시간 : {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}</span>
                  <span>
                    예상당첨금액 :{' '}
                    <span>
                      <span className="sp-number">
                        {item.bettingExpectedAmount.toString().replace(commonReg2, ',')}
                      </span>
                    </span>{' '}
                  </span>
                  <span>
                    결과당첨금액 :{' '}
                    <span className="sp-number">{item.bettingResultAmount.toString().replace(commonReg2, ',')}</span>
                  </span>
                </div>
                <div>
                  {moment(item.availableCancelTime).tz('Asia/Seoul') > moment().tz('Asia/Seoul') && (
                    <button
                      className="btn-cancel hide"
                      onClick={() => {
                        onClickBettingHistoryCancelHandler(item)
                      }}
                      type="button"
                    >
                      배팅취소
                    </button>
                  )}
                  <button
                    className="btn-delete"
                    onClick={() => onDeleteHandler(item.sportsBettingLogKey)}
                    type="button"
                  >
                    내역삭제
                  </button>

                  {/* <div className="chk-wrap btn-sel">
                        <input type="checkbox" id="sel-bet-39" name="sel-bet" value="39" />
                        <label htmlFor="sel-bet-39" />
                      </div> */}
                </div>
              </div>
            </>
          ))}
        </div>

        <div class="slip bet-id-39 mob">
          {sportsBettingHistoryContent.map((item, index) => (
            <>
              <div class="title">
                <span class="txt">{item.detailBettingLogs.length} 폴더</span>
                <div class="time">{convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}</div>
                <div class="chk-wrap btn-sel">&nbsp;</div>
              </div>
              <div class="content">
                {item.detailBettingLogs.map(detail => (
                  <>
                    {detail.awayOddName ? (
                      <div class="ballZone">
                        <div class="team-wrap">
                          <div class="team">
                            {detail.sportsType !== 'ETC' && (
                              <img
                                width="23"
                                src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${detail.sportsType}.png`}
                                alt=""
                              />
                            )}
                            <img width="23" src={detail.leagueImage} alt="" />
                            {detail.leagueName || '-'}
                          </div>
                        </div>
                        <div class="time-wrap">
                          <span class="time"> {convertToKstByFormat(detail.startAt, 'MM-DD HH:mm')} </span>
                          <span class="mark"> {detail.marketName} </span>
                        </div>
                        <div class="bet-pick-wrap">
                          <div class={`home-pick ${detail.homeOddName === detail.selectedOddName ? 'sel-pick' : ''}`}>
                            <span class="team-name">
                              {detail.homeName}{' '}
                              {getOddNameKrComponent(detail.marketName, detail.homeOddNameKr, detail.pointName)}
                            </span>
                            <span class="pick-mark" />
                            <span class="odds">{detail.homeOddValue}</span>
                          </div>
                          <div class={`base-line ${detail.selectedOddName === 'Draw' ? 'sel-pick' : ''}`}>
                            {(detail.drawOddName && detail.drawOddValue) || detail.pointName || 'VS'}
                          </div>
                          <div class={`away-pick ${detail.awayOddName === detail.selectedOddName ? 'sel-pick' : ''}`}>
                            <span class="team-name">
                              {getOddNameKrComponent(detail.marketName, detail.awayOddNameKr, detail.pointName)}{' '}
                              {detail.awayName}
                            </span>
                            <span class="pick-mark" />
                            <span class="odds">{detail.awayOddValue}</span>
                          </div>
                        </div>
                        <div class="score-wrap">
                          <span>
                            최종점수 <span class="score">{detail.resultScore || '-'}</span>
                          </span>
                          <span class="score">
                            <span>&nbsp;</span>{' '}
                          </span>
                          <CustomResultSpan
                            class="status status bet-status-win"
                            backgroundColor={getColor(detail.sportsResultStatus)}
                          >
                            {SportsResultStatusEnums[detail.sportsResultStatus]}
                          </CustomResultSpan>
                        </div>
                      </div>
                    ) : (
                      <div class="ballZone">
                        <div class="team-wrap">
                          <div class="team">
                            {detail.sportsType !== 'ETC' && (
                              <img
                                width="23"
                                src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${detail.sportsType}.png`}
                                alt=""
                              />
                            )}
                            <img width="23" src={detail.leagueImage} alt="" />
                            {detail.leagueName || '-'}
                          </div>
                        </div>
                        <div class="time-wrap">
                          <span class="time"> {convertToKstByFormat(detail.startAt, 'MM-DD HH:mm')} </span>
                          <span class="mark">
                            {' '}
                            {detail?.marketName === '보너스 배당' && (
                              <img width="23" src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`} alt="" />
                            )}
                            {detail.marketName}{' '}
                          </span>
                        </div>
                        <div class="bet-pick-wrap">
                          <div class="home-pick sel-pick">
                            {detail.homeName && (
                              <span className="team-name">
                                {detail.homeName} VS {detail.awayName}
                              </span>
                            )}
                            {detail.selectedOddNameKr && !detail.marketName.includes('정확한') && (
                              <span className="team-name">[{detail.selectedOddNameKr}]</span>
                            )}
                            {detail.pointName && <span style={{ marginRight: '10px' }}> [{detail.pointName}]</span>}
                            <span class="pick-mark" />
                            <span class="odds">{detail.homeOddValue}</span>
                          </div>
                        </div>
                        <div class="score-wrap">
                          <span>
                            점수 <span class="score">{detail.resultScore || '-'}</span>
                          </span>
                          <CustomResultSpan
                            class="status status bet-status-win"
                            backgroundColor={getColor(detail.sportsResultStatus)}
                          >
                            {' '}
                            {SportsResultStatusEnums[detail.sportsResultStatus]}
                          </CustomResultSpan>
                        </div>
                      </div>
                    )}
                  </>
                ))}
                <div class="resultZone">
                  <div class="result">
                    <span>총 배당률 {item.totalOddValue}</span>
                    <CustomResultDiv
                      backgroundColor={getColor(item.sportsResultStatus)}
                      class="status status bet-status-win"
                    >
                      {SportsResultStatusEnums[item.sportsResultStatus]}
                    </CustomResultDiv>
                  </div>
                  <div class="result">
                    {' '}
                    베팅금
                    <span>
                      <span class="sp-number">{item.bettingAmount.toString().replace(commonReg2, ',')} </span> 원{' '}
                    </span>
                  </div>
                  <div class="result">
                    {' '}
                    결과당첨금
                    <span>
                      <span class="sp-number">{item.bettingResultAmount.toString().replace(commonReg2, ',')} </span> 원{' '}
                    </span>
                  </div>
                  <div class="button-wrap">
                    <button
                      class="btn-cancel hide"
                      onClick={() => {
                        onClickBettingHistoryCancelHandler(item)
                      }}
                      type="button"
                    >
                      {' '}
                      취소{' '}
                    </button>
                    <button class="btn-cancel " onClick={() => onDeleteHandler(item.sportsBettingLogKey)} type="button">
                      {' '}
                      삭제{' '}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      {totalElements === 0 && (
        <div className="tb_empty">
          <div className="pic">
            <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
          </div>
          <h2>데이터 없음</h2>
          <h3>Sorry, Empty Data</h3>
        </div>
      )}
      {totalElements !== 0 && (
        <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
      )}
    </>
  )
}

export default SportsBettingHistoryPage

const CustomResultDiv = styled.div`
  color: #fff;
  width: max-content;
  padding: 3px;
  background: ${props => props.backgroundColor || 'white'} !important;
`

const CustomResultSpan = styled.span`
  color: #fff;
  background: ${props => props.backgroundColor || 'white'} !important;
`
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: #000;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`
