import React, { useEffect, useState, Fragment, useRef } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import Portal from '../../../shared/components/Portal'
import { siginIn } from '../../../api/authManage/authManageApi'

const SignInPopup = ({ visible = false, onCloseHandler = () => {}, onShowSignUpModal = () => {} }) => {
  const [memberId, setMemberId] = useState('')
  const [memberPassword, setMemberPassword] = useState('')
  const [loginIp, setLoginIp] = useState('')
  const [userAgent, setUserAgent] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/').then(res => {
      setLoginIp(res.data.IPv4 || res.data.IPv6)
    })
    setUserAgent(window.navigator.userAgent)
  }, [])

  const resetData = () => {
    setMemberId('')
    setMemberPassword('')
  }

  const onSubmit = e => {
    if (!memberId) {
      alert('아이디를 입력하세요.')
      resetData()
      return
    }
    if (!memberPassword) {
      alert('비밀번호를 입력하세요.')
      resetData()
      return
    }

    if (loading) {
      return
    }
    setLoading(true)
    const body = {
      memberId,
      password: memberPassword,
      loginIp,
      userAgent,
    }

    siginIn(body)
      .then(res => {
        setLoading(false)
        alert(`${memberId}님 환영합니다.`)
        window.location.reload()
      })
      .catch(error => {
        const { errorCode } = error.response.data

        switch (errorCode) {
          case 'MEMBER-1005':
            alert('현재 가입승인 처리중입니다. 잠시 후 시도해 주세요.')
            break
          default:
            alert('로그인 실패하였습니다. 다시 시도해주세요.')
        }

        setLoading(false)
        window.location.reload()
      })
  }

  /**
   * 아래는 스타일 코드
   */
  const blockerStyle = visible && { opacity: '1' }

  const loginPopStyle = visible
    ? { opacity: '1', display: 'inline-block', backgroundColor: 'transparent' }
    : { opacity: '1', display: 'none' }

  return (
    <Portal elementId="signin-modal">
      <div className="undefined blocker current" style={blockerStyle}>
        <div id="login_pop" className="modal" style={loginPopStyle}>
          <div className="NAV_log">
            <div className="pupop_content fadeIn animated">
              <div className="popWrapper">
                <h5
                  className="XX icon-x-modal"
                  onClick={() => {
                    onCloseHandler()
                  }}
                >
                  <span className="icon-iconCross" />
                </h5>
                <div className="leftZone pc">
                  <div className="pic">
                    <img src={`${process.env.PUBLIC_URL}/img/main/login.png`} alt="" />
                  </div>
                </div>
                <div className="rightZone">
                  <div className="top">
                    <img src={`${process.env.PUBLIC_URL}/img/common/logo.png`} alt="" />
                  </div>
                  <div className="title">
                    <h2>로그인</h2>
                  </div>

                  <Form
                    onSubmit={onSubmit}
                    initialValues={{
                      memberId,
                      memberPassword,
                    }}
                  >
                    {({ handleSubmit, form: { reset } }) => (
                      <form name="pop_login" method="post" onSubmit={handleSubmit}>
                        <div className="up">
                          <div className="input_zone">
                            <div className="inputFrame">
                              <div className="input_content">
                                <Field name="memberId">
                                  {({ input, meta }) => (
                                    <input
                                      {...input}
                                      type="text"
                                      className="account txtColor010 bgColor09 bdColor03"
                                      value={memberId}
                                      placeholder="아이디"
                                      onChange={e => {
                                        setMemberId(e.target.value)
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="inputFrame">
                              <div className="input_content">
                                <Field name="memberPassword">
                                  {({ input, meta }) => (
                                    <input
                                      {...input}
                                      type="password"
                                      inputMode="latin"
                                      className="pw txtColor010 bgColor09 bdColor03"
                                      value={memberPassword}
                                      placeholder="비밀번호"
                                      onChange={e => {
                                        setMemberPassword(e.target.value)
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                          <div className="btnZone">
                            <div className="btnFrame">
                              <button type="submit" className="btn_p">
                                로그인
                              </button>
                              <a
                                className="btn_p join-link"
                                onClick={() => {
                                  onShowSignUpModal()
                                }}
                              >
                                회원가입
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default SignInPopup
