import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'

const MainContentBanner = () => {
  const [mainCarousel, setMainCarousel] = useState([{ num: '1' }])

  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    fade: true,
    cssEase: '1s',
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrow: false,
          dots: false,
          cssEase: 'linear',
          fade: false,
          useTransform: false,
          speed: 500,
        },
      },
    ],
  }

  return (
    <BannerWrap {...settings}>
      {mainCarousel.map((value, index) => (
        <div key={value.num}>
          <BannerImg src={`${process.env.PUBLIC_URL}/img/main/banner0${index + 1}.png`} alt="" />
        </div>
      ))}
    </BannerWrap>
  )
}

export default MainContentBanner

const BannerImg = styled.img`
  max-width: 100%;
  width: 100vw;
  height: 100%;
  object-fit: fill;
`

const BannerWrap = styled(Slider)`
  .slick-slide {
    height: unset;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .slick-slide[tabindex='-1'] {
    transition: 0s all !important;
    -webkit-transform: scale(0.8) !important;
    transform: scale(0.8) !important;
  }

  .slick-slide.slick-active {
    transition: 1s all !important;
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
  }

  .slick-slide:not(.slick-active):not([tabindex='-1']) {
    transition: 1s all !important;
    -webkit-transform: scale(1.5) !important;
    transform: scale(1.5) !important;
  }

  &:hover {
    .slick-arrow {
      display: inline-block !important;
      cursor: pointer;
    }
  }

  .slick-list {
    max-height: 100%;
  }

  .slick-arrow {
    display: none !important;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 39px;
    height: 80px;
    opacity: 0.5;
    background-color: #596077;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    text-indent: -9999px;
  }

  .slick-arrow.slick-prev {
    left: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAADIBAMAAAD/xRkGAAAAMFBMVEWJiYkAAAD8/Pz19fWtra2Pj4+GhoaPj4+fn5/5+fno6Oj+/v79/f3x8fHm5ub////HgfL/AAAAD3RSTlMaAMWTIgsTEAizWfPYg11YKk3WAAAA00lEQVRo3u3aPRIBURBF4auMnxJ5gbKMEQtlMhKxRM4OLMEOBHZIiGmmrKBPYoL75a+OMkkHV+Vrp4RD+6J9dlTK6PfsrKRx+2ymtG1RG8vnVAQUzQWcNBXQ00bAQHsBQ5mZmZmZmVmn3JYC1hEXpVV1xFtpq4h4oVg8USzuKBYNisUCxR6OOeZYKpZ0RbEJi/VBDDzDP5L/JfwDVLVzzjn3n1wDjyd4qsHDEJ6h8OiFJ7aZmZmZmZl1Apu4wUEdnO/RsWARAIeQcHbJRp54UgoHrB/sGBG2XXNafQAAAABJRU5ErkJggg==);
  }

  .slick-arrow.slick-next {
    right: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAADICAMAAAA6NfQHAAAAM1BMVEWJiYkAAAD19fWtra37+/uPj4+fn5/5+fno6OiGhoaPj4/+/v79/f39/f3x8fHm5ub///9PQzy4AAAAEHRSTlMaAJMixAsIs1kTEPPYzINdOr41PgAAAVlJREFUeNrt3F1qAkEQReHOmPkzMen9rzZB0TtNgS9SFyzO2cCHQ6vT9VDt49G8t6T2+SY8sHNL7XzAlpbecsfmZmi+YUuztFyx5ir3bESs2bKcDmF7s/XsKYKBgYGBxcDAwMDAYmBgYGBgMTAwMDCwGBgYGBhYDCwJ26Zpc2Fr/289WbCffu3z5MC+u7R07KtLS8cuXVo69tuDloe16QVNmEETZtCEGTRhBk2YQRNm0IQZNGEGTZhBE2bQhBk0YQZNmEETZtCEGTRhDk3Y69qaiEVty8OiNtXCjo+x0gEZjn6hL/VgFfohHqxCf56DVeiFZ7AKvaQOVqGLxWAVugwOVqEL/GBVGrpcDlatQZlGgNWGm/exbcGBdOG5PhjYITAwMDCwGBgYGBhYDAwMDAwsBgYGBgYWAwMDe8uFaHN72hsvsXOu5zN+NOtKReeySOcaTPOCT+vq0j8IXCOHqhsmFAAAAABJRU5ErkJggg==);
  }

  .slick-arrow img {
    max-height: 100%;
  }

  .slick-dots {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    bottom: 15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    gap: 30px;
  }

  .slick-dots .slick-active [role='tab'] {
    background-image: url('${process.env.PUBLIC_URL}/img/common/button.png');
  }

  .slick-dots [role='tab']:hover {
    background-image: url('${process.env.PUBLIC_URL}/img/common/button.png');
  }

  .slick-dots [role='presentation'] {
    position: relative;
  }

  .slick-dots [role='tab'] {
    width: 20px;
    height: 20px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAMAAACf4xmcAAAA+VBMVEUAAAD8///9/v7+///9//78/f/9///8/v/7/vz+///8///9///+///////9///////8/v719/n+///+//75/fv9///3+//9/v/+///////5/v////+pq7qorL2orLqprLb9///////8/f+nqLqmqrumqbnl5u6mqbyoq7anqbaqrbyrrrn5/f/3+v/q6/Tq7PGsrr62uL6usLqmq7mqrLOoqrP8///4+/6jpbalp7OjpbP09/jc3+fFyNGoqb6lqrjy9vzt8Pjm6O3g5Ojf4uXT1t7O0duztsOoq8D5/Pjr7fXZ29/Lz9TAw87AxMi7vci5usKwtLmgo6u8rSxCAAAAHHRSTlMAQib65NC2hEDqqw/3jo5mZj8gCwv39+erICAPSj5xLAAAAV1JREFUOMvl09dygkAUgGEVFbsmmr7ALrDSpQgIWGI3vb3/w2QZM7kQMeXW//qb3TmzZzNHXK5YzP2Iqg0KQqpRPayaZWSsRvzgonUAlQoImrYbvnURLJykoNOKr0/HjqPJmvM8Q3rleg9q02fIf9I8LcIB5qJobRjndCcxX57Xl67LcYAkYIyDzQjy+d2Zs3Bov28VYaQAWK9TPbvLkOnIRH0xSRIkwJmDBBvMtZCIbzYRhHCRZHyXAwzDsgxJFEVJkrDS41MYs2Wxw7dKD6WyuH5fFHEaA5O7vzFAziWTyD2Ycum/mJlk/nzL+iyrqjGTZc+EpV12P3z5UC1GIKeoFgCWoob2EJUSTw/hwgEBESRLVbTNyED5xLp36BrU12rEkWRF0VYGrNHtfWt5CfWHccw8bzzzjcpV6pJD3rQ91+4iRJY8vVaZR8tHHpabmYPd1CmEqHr1d9/5ePsElaZAughqfI4AAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% 100%;
    transition: 0.2s;
    color: transparent;
    opacity: 1;
    cursor: pointer;
    text-indent: -9999px;
  }

  .slick-dots [role='tab'] + img {
    position: absolute;
    z-index: 11;
    max-width: unset;
    height: 100px;
    bottom: 25px;
    left: 15px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 6px;
    box-shadow: var(--shadow-primary);
  }
`
