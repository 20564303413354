import { configureStore } from '@reduxjs/toolkit'
import memberInfoSlice from './memberInfoSlice'
import miniGameBettingSliceReducer from './miniGameBettingSlice'
import sportsGameBettingSlice from './sportsGameBettingSlice'
import customCenterSlice from './customCenterSlice'
import modalSlice from './modalSlice'

const store = configureStore({
  reducer: {
    miniGameBetting: miniGameBettingSliceReducer,
    memberInfo: memberInfoSlice,
    customCenter: customCenterSlice,
    sportsGameBetting: sportsGameBettingSlice,
    modalInfo: modalSlice,
  },
  devTools: true,
})

export default store
