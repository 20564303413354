import React, { useEffect, useState } from 'react'
import Error from '@/shared/components/form/Error'
import styled, { keyframes } from 'styled-components'
import { Field, Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { searchMemberInfo, updateMemberInfo } from '../../api/memberManage/memberManageApi'
import { siginOut } from '../../api/authManage/authManageApi'
import { MemberDepositLevelEnum } from '../../utils/enums/MemberManage/MemberInfoEnums'
import { memberInfoModifyValidate } from '../../utils/validate/myPage/myPageValidate'
import WrapperLoading from '../../containers/WrapperLoading'

const MyPage = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [writeLoading, setWriteLoading] = useState(false)
  // 새로운 비밀번호
  const [nowPassword, setNowPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')

  // 아이디
  const [userId, setUserId] = useState('')
  // 닉네임
  const [nickName, setNickName] = useState('')
  // 이름
  const [memberName, setMemberName] = useState('')
  // 연락처
  const [memberPhoneNum, setMemberPhoneNum] = useState('')
  // 닉네임
  const [userBirthDay, setUserBirthDay] = useState('')

  // 입금 레벨
  const [depositLevel, setDepositLevel] = useState('')

  // 예금주명
  const [accountHolder, setAccountHolder] = useState('')
  // 은행명
  const [bankName, setBankName] = useState('')
  // 계좌번호
  const [accountNum, setAccountNum] = useState('')

  useEffect(() => {
    setLoading(true)
    searchMemberInfo({})
      .then(res => {
        setUserId(res.data.content.memberId)
        setMemberName(res.data.content.memberName)
        setMemberPhoneNum(res.data.content.phoneNum)
        setNickName(res.data.content.nickName)
        setDepositLevel(MemberDepositLevelEnum[res.data.content.depositLevel])
        setUserBirthDay(res.data.content.userBirthDay)
        setAccountHolder(res.data.content.memberName)
        setBankName(res.data.content.bankName)
        setAccountNum(res.data.content.accountNum)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const onSubmit = e => {
    console.log('dd')
    if (writeLoading) return
    const body = {
      userId,
      password: nowPassword,
      newPassword,
    }
    setWriteLoading(true)
    updateMemberInfo(body)
      .then(res => {
        setWriteLoading(false)
        alert('회원 정보가 수정됐습니다. 다시 로그인해주세요.')
        siginOut()
          .then(resp => {
            navigate('/', { replace: true })
          })
          .catch(error => {})
      })
      .catch(error => {
        setWriteLoading(false)
        alert('회원 정보 수정에 실패하였습니다. 비밀번호 확인후 다시 시도해주세요.')
        setNowPassword('')
        setNewPassword('')
        setNewPasswordConfirm('')
      })
  }
  return (
    <div className="main_content_continer" id="sub">
      <div className="main-content">
        <div className="aniFrame">
          <div className="titlePic">
            <div className="titlePwrapper">
              <div className="leftZone">
                <span>정보수정</span>
              </div>
              <div className="line" />
            </div>
          </div>
          {writeLoading && <WrapperLoading isLoading={writeLoading} />}
          <div className="main_content_wrap main_content_wrap_notice">
            <div className="noticeFrame">
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  userId,
                  memberPhoneNum: memberPhoneNum || '-',
                  level: `${depositLevel}`,
                  nickName,
                  memberName,
                  bankName: bankName || '-',
                  accountNum: accountNum || '-',
                  accountHolder: accountHolder || '-',
                  nowPassword,
                  newPassword,
                  newPasswordConfirm,
                }}
                validate={memberInfoModifyValidate}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <form
                    className="animated fadeIn delay2"
                    name="frm_mypage"
                    method="post"
                    data-parsley-validate=""
                    novalidate=""
                    onSubmit={handleSubmit}
                  >
                    <div className="info-wrap memo">
                      <div className="tit">확인/필독사항</div>
                      <div className="content">
                        ※ 아이디는 어떤 경우에도 변경 불가능합니다.
                        <br />
                        ※ 닉네임, 은행정보 등 기본정보들은 운영자에게 문의하여 변경바랍니다.
                        <br />
                        ※ 회원가입시 등록된 계좌로만 입금 가능합니다. <br />
                      </div>
                    </div>
                    <div className="info-wrap letter mt15">
                      <div className="input-wrap">
                        <div className="desc">아이디(닉네임)</div>
                        <input
                          type="text"
                          name="username"
                          value={`${userId}(${nickName})`}
                          readonly=""
                          data-parsley-id="8417"
                        />
                        <ul className="parsley-errors-list" id="parsley-id-8417" />
                      </div>

                      <div className="input-wrap">
                        <div className="desc">예금주</div>
                        <input type="text" name="bank_holder" value={memberName} readonly="" data-parsley-id="3814" />
                        <ul className="parsley-errors-list" id="parsley-id-3814" />
                      </div>

                      <div className="input-wrap">
                        <div className="desc">은행명</div>
                        <input type="text" name="bank_name" value={bankName} readonly="" data-parsley-id="2285" />
                        <ul className="parsley-errors-list" id="parsley-id-2285" />
                      </div>

                      {/* <div className="input-wrap">
                        <div className="desc">계좌번호</div>
                        <input type="text" name="bank_name" value={accountNum} readonly="" data-parsley-id="9778" />
                        <ul className="parsley-errors-list" id="parsley-id-9778" />
                      </div> */}

                      <div className="input-wrap">
                        <div className="desc">
                          현재 비밀번호 <span>*</span>
                        </div>
                        <Field name="nowPassword">
                          {({ input, meta }) => (
                            <>
                              <MyInfoFormInputWrap>
                                <MyInfoFormInput
                                  {...input}
                                  type="password"
                                  inputMode="latin"
                                  value={nowPassword}
                                  onChange={e => {
                                    setNowPassword(e.target.value)
                                  }}
                                />
                              </MyInfoFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} />}
                            </>
                          )}
                        </Field>
                        <ul className="parsley-errors-list" id="parsley-id-7452" />
                      </div>

                      <div className="input-wrap">
                        <div className="desc">
                          변경 비밀번호 <span>*</span>
                        </div>
                        <Field name="newPassword">
                          {({ input, meta }) => (
                            <>
                              <MyInfoFormInputWrap>
                                <MyInfoFormInput
                                  {...input}
                                  type="password"
                                  inputMode="latin"
                                  value={newPassword}
                                  onChange={e => {
                                    setNewPassword(e.target.value)
                                  }}
                                />
                              </MyInfoFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} />}
                            </>
                          )}
                        </Field>
                        <ul className="parsley-errors-list" id="parsley-id-0684" />
                      </div>

                      <div className="input-wrap">
                        <div className="desc">
                          변경 비번확인 <span>*</span>
                        </div>
                        <Field name="newPasswordConfirm">
                          {({ input, meta }) => (
                            <>
                              <MyInfoFormInputWrap>
                                <MyInfoFormInput
                                  {...input}
                                  type="password"
                                  inputMode="latin"
                                  value={newPasswordConfirm}
                                  onChange={e => {
                                    setNewPasswordConfirm(e.target.value)
                                  }}
                                />
                              </MyInfoFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} />}
                            </>
                          )}
                        </Field>
                        <ul className="parsley-errors-list" id="parsley-id-3767" />
                      </div>
                    </div>
                    <div className="button-wrap mt20">
                      <button type="submit" className="btnN redB">
                        변 경
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyPage

// sign up form input wrap
const MyInfoFormInputWrap = styled.div`
  width: 100%;
  // border: 1px solid #4a4a4a;
  position: relative;
  // height: 35px;
  height: 35px;
`

// sign up form input
const MyInfoFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 35px;

  color: #000;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`
