import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse } from 'react-bootstrap'
import styled, { keyframes } from 'styled-components'
import { isMobile } from 'react-device-detect'
import ReactHtmlParser from 'react-html-parser'
import parse from 'style-to-object'
import { fetchNoticeBoardList } from '../../redux/customCenterSlice'
import WrapperLoading from '../../containers/WrapperLoading'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import { HermesMainText } from '../../utils/palette'

const NoticePage = () => {
  const [apiLoading, setApiLoading] = useState(false)
  const dispatch = useDispatch()

  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [boardParams, setBoardParams] = useState({
    boardType: 'NOTICE',
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [content, setContent] = useState([])

  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.noticeInfo.totalElement,
      apiContent: customCenter.noticeInfo.content,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.boardKey === item.boardKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchBoardList = async () => {
    try {
      await dispatch(fetchNoticeBoardList(boardParams))
        .unwrap()
        .then(res => {})
      setApiLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setApiLoading(false)
    }
  }

  useEffect(() => {
    setApiLoading(true)
    fetchSearchBoardList()
  }, [boardParams])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = boardKey => {
    setContent(prev => {
      const idx = prev.findIndex(item => item.boardKey === boardKey)

      if (idx !== -1) {
        // 원래의 state를 복사하여 새로운 배열을 만듭니다.
        const newState = [...prev]
        newState[idx] = {
          ...newState[idx],
          isVisible: !newState[idx].isVisible,
        }

        // 새로운 배열을 반환합니다.
        return newState
      }

      // 인덱스를 찾지 못한 경우, 원래의 state를 그대로 반환합니다.
      return prev
    })
  }

  /**
   * 페이징처리
   */
  // const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}

      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }

      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }

      // 이미지 태그인 경우 기존 스타일 유지
      if (isMobile) {
        return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  return (
    <>
      {apiLoading && <WrapperLoading isLoading={apiLoading} />}
      <div className="main-content">
        <div className="aniFrame">
          <div className="titlePic">
            <div className="titlePwrapper">
              <div className="leftZone">
                <span>공지사항</span>
              </div>
              <div className="line" />
            </div>
          </div>
          <div className="main_content_wrap main_content_wrap_notice">
            <div className="noticeFrame">
              <div>
                <div className="listZone">
                  <table>
                    <thead>
                      <tr>
                        <th className="ac number" width="10%">
                          No.
                        </th>
                        <th className="ac" width="60%">
                          제목
                        </th>
                        <th className="ac name " width="30%">
                          작성일
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {content.map(item => {
                        return (
                          <>
                            <tr onClick={() => onClickRowHandler(item.boardKey)}>
                              <td className="ac">
                                <span>{item.num}</span>
                              </td>
                              <td className="al" style={{ textAlign: 'center' }}>
                                <a className="tit" style={{ cursor: 'pointer' }}>
                                  <font color="#000">{item.boardTitle}</font>
                                  <br />
                                </a>
                              </td>
                              <td className="ac">{convertToKstByFormat(item.registrationDate, 'YYYY-MM-DD')}</td>
                            </tr>
                            {item.isVisible && (
                              <tr>
                                <td
                                  className="ac"
                                  colSpan={3}
                                  style={{ padding: '0px', lineHeight: '30px', background: '#dbdbdb' }}
                                >
                                  <CustomerCenterContentDetailBox>
                                    {ReactHtmlParser(item.boardContent, { transform: transformFn })}
                                  </CustomerCenterContentDetailBox>
                                </td>
                              </tr>
                            )}

                            {/* <Collapse in={item.isVisible} /> */}
                          </>
                        )
                      })}
                    </tbody>
                  </table>

                  {totalElement === 0 && (
                    <div className="tb_empty">
                      <div className="pic">
                        <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
                      </div>
                      <h2>데이터 없음</h2>
                      <h3>Sorry, Empty Data</h3>
                    </div>
                  )}

                  {totalElement !== 0 && (
                    <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoticePage

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
  justify-content: center;
  align-items: center;
`
