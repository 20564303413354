import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import WrapperLoading from '../../containers/WrapperLoading'
import { convertToKstDateTime, minusDayToKst } from '../../utils/dateTime'
import CustomPagination from '../../shared/components/CustomPagination'
import { searchMemberPointExchange } from '../../api/memberHistory/memberHistoryApi'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import { createMemberPointExchange } from '../../api/memberManage/memberManageApi'
import { commonReg2 } from '../../utils/validate/commonValidate'

const PointExchangePage = () => {
  const [apiLoading, setApiLoading] = useState(false)
  const dispatch = useDispatch()

  const { moneyAmount, pointAmount } = useSelector(state => {
    const { memberInfo } = state

    return {
      moneyAmount: memberInfo.memberHoldingMoney,
      pointAmount: memberInfo.memberHoldingPoint,
    }
  })

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [pointWeekHistoryParams, setPointWeekHistoryParams] = useState({
    pointType: 'ROLLING',
    page,
    size,
    // startDate: minusDayToKst(7),
  })

  useEffect(() => {
    setPointWeekHistoryParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberPointExchange = async () => {
    setApiLoading(true)
    await searchMemberPointExchange(pointWeekHistoryParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberPointExchange()
  }, [pointWeekHistoryParams])

  const onSubmit = () => {
    if (apiLoading) return

    if (pointAmount === 0) return

    setApiLoading(true)

    createMemberPointExchange()
      .then(res => {
        setApiLoading(false)
        alert('포인트 전환이 완료되었습니다.')
        fetchSearchMemberPointExchange()
        dispatch(fetchMemberInfo())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('포인트 전환이 실패했습니다. 다시 시도해주세요.')
        }
        setApiLoading(false)
      })
  }

  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  return (
    <>
      {apiLoading && <WrapperLoading isLoading={apiLoading} />}
      <div className="main-content">
        <div className="aniFrame">
          <div className="titlePic">
            <div className="titlePwrapper">
              <div className="leftZone">
                <span>포인트전환</span>
              </div>
              <div className="line" />
            </div>
          </div>
          <div className="main_content_wrap main_content_wrap_notice">
            <div className="noticeFrame">
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  moneyAmount: moneyAmount.toString().replace(commonReg2, ','),
                  pointAmount: pointAmount.toString().replace(commonReg2, ','),
                }}
                // validate={MoneyDepositValidate}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <form className="animated fadeIn delay2" name="frm_point_xchg" method="post" onSubmit={handleSubmit}>
                    <div className="info-wrap memo">
                      <div className="tit">확인/필독사항</div>
                      <div className="content">
                        ※ 포인트를 전환하면 즉시 <b style={{ color: 'red' }}>전액</b> 보유금액으로 이동됩니다.
                        {/* <br />※ 전환 최소 포인트는 <b style={{ color: '#00a7ed' }}>1,000 P</b> 입니다.
                  <br /> */}
                      </div>
                    </div>
                    <div className="info-wrap cash mt15">
                      <div className="input-wrap">
                        <div className="desc">보유 머니</div>
                        <Field name="moneyAmount">
                          {({ input, meta }) => <input {...input} type="text" className="userCashAmount" readOnly />}
                        </Field>
                      </div>

                      <div className="input-wrap">
                        <div className="desc">보유 포인트</div>
                        <Field name="pointAmount">
                          {({ input, meta }) => <input {...input} type="text" className="userCashPoint" readOnly />}
                        </Field>
                      </div>
                    </div>
                    <div className="button-wrap mt20">
                      <button type="submit" className="btnN redB">
                        포인트전환
                      </button>
                    </div>
                  </form>
                )}
              </Form>
              <div>
                <div className="listZone">
                  <table>
                    <thead>
                      <tr>
                        <th className="ac number" width="10%">
                          No.
                        </th>
                        <th className="ac">전환액</th>
                        <th className="ac">처리일</th>
                      </tr>
                    </thead>
                    <tbody>
                      {content.map(item => (
                        <tr>
                          <td>{item.num}</td>
                          <td>
                            <p className="money">{item.changePoint?.toString().replace(commonReg2, ',')}</p>
                          </td>
                          <td>{convertToKstDateTime(item.executeDate)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {totalElement === 0 && (
                    <div className="tb_empty">
                      <div className="pic">
                        <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
                      </div>
                      <h2>데이터 없음</h2>
                      <h3>Sorry, Empty Data</h3>
                    </div>
                  )}

                  {totalElement !== 0 && (
                    <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PointExchangePage
