import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import WrapperLoading from '../../containers/WrapperLoading'
import { searchHoldemGameUrl } from '../../api/game/gameApi'

const HoldemPage = () => {
  const [apiLoading, setApiLoading] = useState(false)

  const holdemGameStartHandler = game => {
    if (apiLoading) return

    setApiLoading(true)
    searchHoldemGameUrl()
      .then(res => {
        const { gameUrl } = res.data

        window.open(
          `${gameUrl}`,
          '',
          `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
        )
        setApiLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'GAME-1001':
            alert('이용이 불가능합니다. 관리자에게 문의하세요.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('잠시후 다시 시도해주세요.')
        }
        setApiLoading(false)
      })
  }

  return (
    <>
      {apiLoading && <WrapperLoading isLoading={apiLoading} />}
      <div className="main_content" id="mini">
        <div className="titlePic">
          <div className="titlePwrapper">
            <div className="leftZone">
              <span>라이브 홀덤</span>
            </div>
            <div className="line" />
          </div>
        </div>
        <div className="gameZone holdem">
          <div className="gameWrapper">
            <div className="content">
              <div className="leftFrame pc">
                <div className="leftZone aniLogo01">
                  <div className="obj aniLogo" />
                </div>
              </div>
              <div className="rightZone">
                <div className="txt pc">
                  <h2>라이브 홀덤</h2>
                </div>
                <div className="btns">
                  <ul>
                    <li>
                      <a onClick={holdemGameStartHandler}>
                        <div
                          className="model modelA"
                          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/holdem/holdem.png)` }}
                        />
                        <div className="txt01">
                          <h2>라이브 홀덤</h2>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="obj aniLogo02" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HoldemPage
