import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSportsBonusFolder, fetchSportsEventCount, setSportsType } from '../../../redux/sportsGameBettingSlice'

const CrossSportsSideMenu = () => {
  const dispatch = useDispatch()

  const { sportsTypeInfo } = useSelector(state => {
    const { sportsGameBetting } = state

    return {
      sportsTypeInfo: sportsGameBetting.sportsTypeInfo,
    }
  })
  const [currentTime, setCurrentTime] = useState(moment().tz('Asia/Seoul'))

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().tz('Asia/Seoul'))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    dispatch(fetchSportsEventCount())
    dispatch(fetchSportsBonusFolder())
  }, [])

  const onClickSportsEventType = info => {
    dispatch(setSportsType(info))
  }

  return (
    <>
      <div className="chooseZone01 pc" id="left-selector">
        <div className="head-top mob">
          <div className="">리그정보</div>
          <span className="close icon-delet" />
        </div>
        <div className="title">
          <span>현재 시간</span>
          <span className="sports-time">{currentTime.locale('ko').format('MM-DD HH:mm:ss')}</span>
        </div>
        <div className="sideMenu01">
          {sportsTypeInfo.map(item => {
            if (item.sportsType === 'ALL') return
            return (
              <div className="ballFrame" key={`${item.sportsType}-${item.typeKr}`}>
                <div
                  className="ballTitle switcher"
                  onClick={() => {
                    onClickSportsEventType(item.sportsType === 'ALL' ? null : item.sportsType)
                  }}
                >
                  <div className="ball">
                    <img src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${item.sportsType}.png`} alt="" />
                    {item.typeKr}
                  </div>
                  {/* <div className="arrowB">
                      <span className="icon-iconARupA" />
                    </div> */}
                  <div className="txt">
                    (<span className="total-count-football">{item.count}</span>)
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default CrossSportsSideMenu
