import React, { useEffect, useState, Fragment, useRef } from 'react'
import { ko } from 'date-fns/esm/locale'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import { Field, Form } from 'react-final-form'
import Portal from '../../../shared/components/Portal'
import {
  confirmNickName,
  confirmRecommendCode,
  confirmUserId,
  searchRecommendCode,
  siginUp,
} from '../../../api/authManage/authManageApi'
import { searchBankList } from '../../../api/bank/bankApi'
import { getRecommendCode } from '../../../utils/enums/authority'
import { convertToKst } from '../../../utils/dateTime'
import {
  colorAccent,
  colorAccentHover,
  colorBackground,
  colorBorder,
  colorHover,
  colorLightAccent,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '../../../utils/palette'
import Error from '../../../shared/components/form/Error'
import { CustomSelect } from '../../../shared/components/CustomSelect'
import WrapperLoading from '../../WrapperLoading'

const SignUpPopup = ({ visible = false, onCloseHandler = () => {}, onShowSignInModal = () => {} }) => {
  // 비밀번호
  const [password, setPassword] = useState('')
  // 비밀번호 확인
  const [confirmPassword, setConfirmPassword] = useState('')
  // 이름
  const [memberName, setMemberName] = useState('')
  // 연락처
  const [memberPhoneNum, setMemberPhoneNum] = useState('')
  const [userBirthDay, setUserBirthDay] = useState('')
  const [accountNum, setAccountNum] = useState('')

  // 은행
  const [bankNameOptionValue, setBankNameOptionValue] = useState(null)
  const [bankNameOption, setBankNameOption] = useState([])
  const onChangeBankNameHandler = option => {
    setBankNameOptionValue(option)
  }

  const [nickName, setNickName] = useState('')
  const [isConfirmNickName, setIsConfirmNickName] = useState(false)
  const [isProcessNickName, setIsProcessNickName] = useState(false)

  const onClickConfirmNickNameHandler = () => {
    if (!nickName) {
      alert('닉네임을 먼저 입력해주세요.')
      return
    }

    if (isProcessNickName) {
      return
    }

    setIsProcessNickName(true)

    confirmNickName(nickName)
      .then(res => {
        const { result, message } = res
        setIsConfirmNickName(true)
        setIsProcessNickName(false)
        alert(message)
      })
      .catch(error => {
        const { message } = error.response.data
        alert(message)
        setIsConfirmNickName(false)
        setIsProcessNickName(false)
      })
  }

  // 아이디
  const [userId, setUserId] = useState('')
  // 아이디 중복확인
  const [isConfirmUserId, setIsConfirmUserId] = useState(false)
  const [isProcessUserId, setIsProcessUserId] = useState(false)
  const onClickConfirmUserIdHandler = () => {
    if (!userId) {
      alert('아이디를 먼저 입력해주세요.')
      return
    }

    if (isProcessUserId) {
      return
    }

    setIsProcessUserId(true)

    confirmUserId({
      userId,
    })
      .then(res => {
        const { result, message } = res
        setIsConfirmUserId(true)
        setIsProcessUserId(false)
        alert(message)
      })
      .catch(error => {
        const { message } = error.response.data
        alert(message)
        setIsConfirmUserId(false)
        setIsProcessUserId(false)
      })
  }

  // 추천 코드
  const [recommendCode, setRecommendCode] = useState()

  // 추천코드 확인
  const [isConfirmRecommendCode, setIsConfirmRecommendCode] = useState(false)
  const [isProcessRecommendCode, setIsProcessRecommendCode] = useState(false)

  const onClickConfirmRecommendCodeHandler = () => {
    if (!recommendCode) {
      alert('추천코드를 먼저 입력하세요')
      return
    }

    if (isProcessRecommendCode) {
      return
    }

    setIsProcessRecommendCode(true)

    confirmRecommendCode({
      recommendCode,
    })
      .then(res => {
        setIsConfirmRecommendCode(true)
        setIsProcessRecommendCode(false)
        alert('확인 되었습니다.')
      })
      .catch(error => {
        setIsConfirmRecommendCode(false)
        setIsProcessRecommendCode(false)
        alert('유효하지 않는 추천코드입니다.')
      })
  }

  useEffect(() => {
    if (!visible) return

    searchBankList().then(res => {
      res.data.bankList.map(item =>
        setBankNameOption(prev => [
          ...prev,
          {
            value: item.bankId,
            label: item.bankName,
          },
        ]),
      )
    })

    searchRecommendCode().then(res => {
      const rec = res.data.recommendCode
      const temp = rec || getRecommendCode()
      if (temp) {
        setIsConfirmRecommendCode(true)
      }
      setRecommendCode(temp)
    })
  }, [visible])

  const [siginUpApiLoading, setSiginUpApiLoading] = useState(false)
  const [loginIp, setLoginIp] = useState('')
  useEffect(() => {
    axios.get('https://geolocation-db.com/json/').then(res => {
      setLoginIp(res.data.IPv4 || res.data.IPv6)
    })
  }, [])

  const onSubmit = () => {
    if (siginUpApiLoading) return

    if (!userId || (userId && !isConfirmUserId)) {
      alert('아이디를 다시 확인해주세요.')
      return
    }

    if (!password || password !== confirmPassword) {
      alert('비밀번호를 다시 확인해주세요.')
      return
    }

    if (!nickName || (nickName && !isConfirmNickName)) {
      alert('닉네임을 확인해주세요.')
      return
    }

    if (!userBirthDay) {
      alert('생년월일을 확인해주세요.')
      return
    }

    if (!memberPhoneNum) {
      alert('휴대폰 번호를 확인해주세요.')
      return
    }

    if (!bankNameOptionValue) {
      alert('은행을 확인해주세요.')
      return
    }

    if (!accountNum) {
      alert('계좌번호를 확인해주세요.')
      return
    }

    if (!memberName) {
      alert('예금주를 확인해주세요.')
      return
    }

    if (!recommendCode) {
      alert('추천코드를 확인해주세요.')
      return
    }

    if (!recommendCode || (recommendCode && !isConfirmRecommendCode)) {
      alert('추천코드를 확인해주세요.')
      return
    }

    setSiginUpApiLoading(true)
    const body = {
      signUpIp: loginIp,
      userId,
      nickName,
      password,
      memberName,
      memberPhoneNum,
      bankName: bankNameOptionValue?.label,
      accountNum,
      recommendCode: !recommendCode ? null : recommendCode,
      userBirthDay: userBirthDay ? convertToKst(userBirthDay) : null,
    }
    siginUp(body)
      .then(res => {
        alert('회원가입에 성공했습니다. \n잠시 후 로그인 부탁드립니다.')
        setSiginUpApiLoading(false)
        window.location.reload()
        // close()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        if (errorCode === 'SIGN-1004') {
          alert(`${message}\n잠시후 다시 시도해주세요.`)
          window.location.reload()
        } else {
          alert('회원가입을 실패했습니다. \n잠시후 다시 시도해주세요.')
          window.location.reload()
        }
        setSiginUpApiLoading(false)
      })
  }

  /**
   * 아래는 스타일 코드
   */
  const blockerStyle = visible && { opacity: '1' }

  const loginPopStyle = visible
    ? { opacity: '1', display: 'inline-block', backgroundColor: 'transparent' }
    : { opacity: '1', display: 'none' }

  return (
    <Portal elementId="signup-modal">
      {siginUpApiLoading && <WrapperLoading />}
      <div className="undefined blocker current" style={blockerStyle}>
        <div id="join_pop" className="modal" style={loginPopStyle}>
          <div className="NAV_reg">
            <div className="vfm__content pupop_content pupop_registered fadeIn">
              <h5
                className="XX icon-x-modal"
                onClick={() => {
                  onCloseHandler()
                }}
              >
                <span className="icon-iconCross" />
              </h5>
              <div className="reg">
                <div className="topFrame">
                  <div className="top">
                    <img
                      style={{ width: '150px', height: 'auto' }}
                      src={`${process.env.PUBLIC_URL}/img/common/logo.png`}
                      alt=""
                    />
                  </div>
                </div>
                <div className="upFrame">
                  <div className="up">
                    <Form
                      onSubmit={onSubmit}
                      initialValues={
                        {
                          // memberId,
                          // memberPassword,
                        }
                      }
                    >
                      {({ handleSubmit, form: { reset } }) => (
                        <form name="frm_join" method="post" onSubmit={handleSubmit}>
                          <div className="input_zone">
                            <div className="input-Out-all">
                              <div className="input-out-frame verifiUse">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconMan" />
                                    <div className="txt">
                                      ID<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content btnUse">
                                      <Field name="userId">
                                        {({ input, meta }) => (
                                          <>
                                            <SignUpModalFormInputWrap>
                                              <SignUpModalFormInput
                                                {...input}
                                                type="text"
                                                value={userId}
                                                readOnly={isConfirmUserId}
                                                placeholder="아이디"
                                                onChange={e => {
                                                  const { value } = e.target
                                                  const onlyEnglish = value.replace(/[^A-Za-z0-9]/gi, '')
                                                  if (onlyEnglish.length > 15) {
                                                    return
                                                  }
                                                  setUserId(onlyEnglish)
                                                }}
                                                autoComplete="off"
                                              />
                                            </SignUpModalFormInputWrap>
                                            {meta.touched && meta.error && <Error error={meta.error} />}
                                          </>
                                        )}
                                      </Field>
                                      <div className="checkUse ko-KR-member">
                                        <span className="icon-icconCHECK" />
                                        <CustomButton
                                          type="button"
                                          className="regBtn blueB"
                                          disabled={isConfirmUserId}
                                          onClick={() => {
                                            onClickConfirmUserIdHandler()
                                          }}
                                        >
                                          중복
                                        </CustomButton>
                                      </div>
                                    </div>
                                    <div className="msg-wrap username">
                                      <ul className="parsley-errors-list" id="parsley-id-0722" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="input-out-frame remindUse">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconPW" />
                                    <div className="txt">
                                      비밀번호<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content">
                                      <Field name="password">
                                        {({ input, meta }) => (
                                          <>
                                            <SignUpModalFormInputWrap>
                                              <SignUpModalFormInput
                                                {...input}
                                                type="password"
                                                inputMode="latin"
                                                value={password}
                                                onChange={e => {
                                                  setPassword(e.target.value)
                                                }}
                                                autoComplete="new-password"
                                                placeholder="15글자 미만으로 가능(영문+숫자)"
                                              />
                                            </SignUpModalFormInputWrap>
                                            {meta.touched && meta.error && <Error error={meta.error} />}
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                    <div className="msg-wrap password">
                                      <ul className="parsley-errors-list" id="parsley-id-8389" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="input-out-frame">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconPW" />
                                    <div className="txt">
                                      비밀번호 확인<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content">
                                      <Field name="confirmPassword">
                                        {({ input, meta }) => (
                                          <>
                                            <SignUpModalFormInputWrap>
                                              <SignUpModalFormInput
                                                {...input}
                                                type="password"
                                                inputMode="latin"
                                                value={confirmPassword}
                                                onChange={e => {
                                                  setConfirmPassword(e.target.value)
                                                }}
                                                placeholder="비밀번호를 확인하세요."
                                                autoComplete="new-password"
                                              />
                                            </SignUpModalFormInputWrap>
                                            {meta.touched && meta.error && <Error error={meta.error} />}
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                    <div className="msg-wrap pwdconfirm">
                                      <ul className="parsley-errors-list" id="parsley-id-3536" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="input-out-frame">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconMan" />
                                    <div className="txt">
                                      닉네임<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content">
                                      <Field name="nickName">
                                        {({ input, meta }) => (
                                          <>
                                            <SignUpModalFormInputWrap>
                                              <SignUpModalFormInput
                                                {...input}
                                                type="text"
                                                value={nickName}
                                                placeholder="닉네임을 입력하세요."
                                                onChange={e => {
                                                  const { value } = e.target
                                                  if (value.length > 15) {
                                                    return
                                                  }
                                                  setNickName(value)
                                                }}
                                                autoComplete="off"
                                              />
                                            </SignUpModalFormInputWrap>
                                            {meta.touched && meta.error && <Error error={meta.error} />}
                                          </>
                                        )}
                                      </Field>
                                      <div className="checkUse ko-KR-member">
                                        <span className="icon-icconCHECK" />
                                        <CustomButton
                                          type="button"
                                          className="regBtn blueB"
                                          disabled={isConfirmNickName}
                                          onClick={() => {
                                            onClickConfirmNickNameHandler()
                                          }}
                                        >
                                          중복
                                        </CustomButton>
                                      </div>
                                    </div>
                                    <div className="msg-wrap nickname">
                                      <ul className="parsley-errors-list" id="parsley-id-8585" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="input-out-frame">
                                <div className="inputFrame">
                                  <div className="info">
                                    <span className="ficon icon-icconPARTNER" />
                                    <div className="txt">
                                      생년월일<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content">
                                      <Field name="userBirthDay">
                                        {({ input, meta }) => (
                                          <>
                                            <DatePickerWrap>
                                              <DatePicker
                                                selected={userBirthDay}
                                                onChange={date => setUserBirthDay(date)}
                                                dateFormat="yyyy.MM.dd"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={100}
                                                dropDownMode="select"
                                                popperPlacement="bottom-start"
                                                placeholderText="생년월일을 선택하세요."
                                                locale={ko}
                                              />
                                            </DatePickerWrap>
                                            {meta.touched && meta.error && <Error error={meta.error} />}
                                          </>
                                        )}
                                      </Field>
                                    </div>

                                    {/* <div className="input_content">ss</div> */}
                                    {/* <div className="input_content">
                                        <select
                                          className="frm_input combos-month"
                                          data-parsley-required="true"
                                          data-parsley-required-message="[생년월일] 월을 입력해주세요."
                                          data-parsley-errors-container="#join_pop .msg-wrap.birthday"
                                          name="birthday_mm"
                                          title="월"
                                          onchange="combosDatePicker.onChangeMonth(this);"
                                          data-parsley-id="3852"
                                        >
                                          <option value=""> - 월</option>

                                          <option value="01">1 월</option>

                                          <option value="02">2 월</option>

                                          <option value="03">3 월</option>

                                          <option value="04">4 월</option>

                                          <option value="05">5 월</option>

                                          <option value="06">6 월</option>

                                          <option value="07">7 월</option>

                                          <option value="08">8 월</option>

                                          <option value="09">9 월</option>

                                          <option value="10">10 월</option>

                                          <option value="11">11 월</option>

                                          <option value="12">12 월</option>
                                        </select>
                                      </div>
                                      <div className="input_content">
                                        <select
                                          className="frm_input combos-day"
                                          data-parsley-required="true"
                                          data-parsley-required-message="[생년월일] 날짜를 입력해주세요."
                                          data-parsley-errors-container="#join_pop .msg-wrap.birthday"
                                          name="birthday_dd"
                                          title="일"
                                          onchange="combosDatePicker.onChangeDay(this);"
                                          data-parsley-id="6320"
                                        >
                                          <option value=""> - 일</option>

                                          <option value="01">1 일</option>

                                          <option value="02">2 일</option>

                                          <option value="03">3 일</option>

                                          <option value="04">4 일</option>

                                          <option value="05">5 일</option>

                                          <option value="06">6 일</option>

                                          <option value="07">7 일</option>

                                          <option value="08">8 일</option>

                                          <option value="09">9 일</option>

                                          <option value="10">10 일</option>

                                          <option value="11">11 일</option>

                                          <option value="12">12 일</option>

                                          <option value="13">13 일</option>

                                          <option value="14">14 일</option>

                                          <option value="15">15 일</option>

                                          <option value="16">16 일</option>

                                          <option value="17">17 일</option>

                                          <option value="18">18 일</option>

                                          <option value="19">19 일</option>

                                          <option value="20">20 일</option>

                                          <option value="21">21 일</option>

                                          <option value="22">22 일</option>

                                          <option value="23">23 일</option>

                                          <option value="24">24 일</option>

                                          <option value="25">25 일</option>

                                          <option value="26">26 일</option>

                                          <option value="27">27 일</option>

                                          <option value="28">28 일</option>

                                          <option value="29">29 일</option>

                                          <option value="30">30 일</option>

                                          <option value="31">31 일</option>
                                        </select>
                                      </div> */}
                                    <div className="msg-wrap">
                                      <span>
                                        생년월일 정확하게 등록 하지 않으시면 승인이 불가능하니 정확하게 등록 바랍니다.
                                      </span>
                                    </div>
                                    {/* <div className="msg-wrap birthday">
                                      <ul className="parsley-errors-list" id="parsley-id-1840" />
                                      <ul className="parsley-errors-list" id="parsley-id-3852" />
                                      <ul className="parsley-errors-list" id="parsley-id-6320" />
                                    </div> */}
                                  </div>
                                </div>
                              </div>

                              {/* <div className="input-out-frame">
                                <div className="inputFrame verifiUse checkOk">
                                  <div className="info">
                                    <span className="ficon icon-icconPARTNER" />
                                    <div className="txt">
                                      통신사<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content btnUse01">
                                      <select
                                        className="frm_input"
                                        name="carrier"
                                        title="통신사선택"
                                        data-parsley-id="2277"
                                      >
                                        <option value="">통신사선택</option>

                                        <option value="SKT">SKT</option>

                                        <option value="KT">KT</option>

                                        <option value="LG">LG</option>

                                        <option value="SKT알뜰">SKT알뜰</option>

                                        <option value="KT알뜰">KT알뜰</option>

                                        <option value="LG알뜰">LG알뜰</option>
                                      </select>
                                      <ul className="parsley-errors-list" id="parsley-id-2277" />
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <div className="input-out-frame verifiUse">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconMOBILE" />
                                    <div className="txt">
                                      휴대폰 번호<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content">
                                      <Field name="memberPhoneNum">
                                        {({ input, meta }) => (
                                          <>
                                            <SignUpModalFormInputWrap>
                                              <SignUpModalFormInput
                                                {...input}
                                                type="text"
                                                value={memberPhoneNum}
                                                onChange={e => {
                                                  const { value } = e.target
                                                  const onlyNumber = value.replace(/[^0-9]/g, '')
                                                  setMemberPhoneNum(onlyNumber)
                                                }}
                                                placeholder="전화번호를 입력하세요."
                                                autoComplete="off"
                                              />
                                            </SignUpModalFormInputWrap>
                                            {meta.touched && meta.error && <Error error={meta.error} />}
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="input-out-frame">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconBANK" />
                                    <div className="txt">
                                      은행이름<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <Field name="bankName">
                                      {({ input, meta }) => (
                                        <>
                                          <BankSelect
                                            {...input}
                                            value={bankNameOptionValue}
                                            onChange={option => {
                                              // input.onChange(option)
                                              onChangeBankNameHandler(option)
                                            }}
                                            options={bankNameOption}
                                            placeholder="은행을 선택하세요."
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isClearable
                                          />
                                          {meta.touched && meta.error && <Error error={meta.error} />}
                                        </>
                                      )}
                                    </Field>
                                    {/* <div className="input_content btnUse01">
                                      <select
                                        className="frm_input"
                                        data-parsley-required="true"
                                        data-parsley-required-message="필수입력 항목입니다."
                                        data-parsley-errors-container="#join_pop .msg-wrap.bankname"
                                        name="bank_name"
                                        data-parsley-id="6732"
                                      >
                                        <option value="">은행선택</option>

                                        <option value="경남은행">경남은행</option>

                                        <option value="국민은행">국민은행</option>

                                        <option value="농협은행">농협은행</option>

                                        <option value="대구은행">대구은행</option>

                                        <option value="부산은행">부산은행</option>

                                        <option value="새마을금고">새마을금고</option>

                                        <option value="신한은행">신한은행</option>

                                        <option value="우리은행">우리은행</option>

                                        <option value="우체국">우체국</option>

                                        <option value="전북은행">전북은행</option>

                                        <option value="카카오뱅크">카카오뱅크</option>

                                        <option value="케이뱅크">케이뱅크</option>

                                        <option value="하나은행">하나은행</option>
                                      </select>
                                    </div>
                                    <div className="msg-wrap bankname">
                                      <ul className="parsley-errors-list" id="parsley-id-6732" />
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                              <div className="input-out-frame">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconPC" />
                                    <div className="txt">
                                      계좌번호<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content">
                                      <Field name="accountNum">
                                        {({ input, meta }) => (
                                          <>
                                            <SignUpModalFormInputWrap>
                                              <SignUpModalFormInput
                                                {...input}
                                                type="text"
                                                value={accountNum}
                                                onChange={e => {
                                                  const { value } = e.target
                                                  const onlyNumber = value.replace(/[^0-9]/g, '')
                                                  setAccountNum(onlyNumber)
                                                }}
                                                placeholder="계좌번호를 입력하세요."
                                                autoComplete="off"
                                              />
                                            </SignUpModalFormInputWrap>
                                            {meta.touched && meta.error && <Error error={meta.error} />}
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                    <div className="msg-wrap bank_accnum">
                                      <ul className="parsley-errors-list" id="parsley-id-7373" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="input-out-frame">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconMan" />
                                    <div className="txt">
                                      예금주<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content">
                                      <Field name="memberName">
                                        {({ input, meta }) => (
                                          <>
                                            <SignUpModalFormInputWrap>
                                              <SignUpModalFormInput
                                                {...input}
                                                type="text"
                                                value={memberName}
                                                onChange={e => {
                                                  setMemberName(e.target.value)
                                                }}
                                                placeholder="실제 예금주명으로 입력하세요."
                                                autoComplete="off"
                                              />
                                            </SignUpModalFormInputWrap>
                                            {meta.touched && meta.error && <Error error={meta.error} />}
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                    <div className="msg-wrap bank_holder">
                                      <ul className="parsley-errors-list" id="parsley-id-6528" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="input-out-frame verifiUse">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconPW" />
                                    <div className="txt">
                                      환전 비밀번호<span>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content">
                                      <input
                                        className="frm_input"
                                        maxlength="30"
                                        data-parsley-trigger="change"
                                        data-parsley-maxlength="30"
                                        data-parsley-maxlength-message="30 자 이하로 입력하세요."
                                        data-parsley-required="true"
                                        data-parsley-required-message="환전시 사용되는 비밀번호입니다.(필수입력 및 수정불가)"
                                        data-parsley-errors-container="#join_pop .msg-wrap.xchg_pwd"
                                        autocomplete="off"
                                        name="pwd_xchg"
                                        type="text"
                                        value=""
                                        placeholder="환전 비밀번호"
                                        data-parsley-id="2655"
                                      />
                                    </div>
                                    <div className="msg-wrap xchg_pwd">
                                      <ul className="parsley-errors-list" id="parsley-id-2655" />
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <div className="input-out-frame">
                                <div className="inputFrame verifiUse">
                                  <div className="info">
                                    <span className="ficon icon-icconRECOMMEND" />
                                    <div className="txt">
                                      가입코드<span style={{ display: 'none' }}>*</span>
                                    </div>
                                  </div>
                                  <div className="input-inner-all">
                                    <div className="input_content">
                                      <Field name="recommendCode">
                                        {({ input, meta }) => (
                                          <>
                                            <SignUpModalFormInputWrap>
                                              <SignUpModalFormInput
                                                {...input}
                                                type="text"
                                                value={recommendCode}
                                                readOnly={isConfirmRecommendCode}
                                                onChange={e => {
                                                  setRecommendCode(e.target.value)
                                                }}
                                                placeholder="추천코드를 입력하세요."
                                                autoComplete="off"
                                              />
                                            </SignUpModalFormInputWrap>
                                            {meta.touched && meta.error && <Error error={meta.error} />}
                                          </>
                                        )}
                                      </Field>
                                      <div className="checkUse ko-KR-member">
                                        <span className="icon-icconCHECK" />
                                        <CustomButton
                                          type="button"
                                          className="regBtn blueB"
                                          onClick={() => {
                                            onClickConfirmRecommendCodeHandler()
                                          }}
                                          disabled={isConfirmRecommendCode}
                                        >
                                          코드확인
                                        </CustomButton>
                                      </div>
                                    </div>
                                    <div className="msg-wrap access_code">
                                      <ul className="parsley-errors-list" id="parsley-id-4391" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="btnFrame">
                            <button type="submit" className="btn_p">
                              회원가입
                            </button>
                            <div className="txtZone">
                              <a
                                style={{ cursor: 'pointer' }}
                                className="login-link"
                                onClick={() => {
                                  onShowSignInModal()
                                }}
                              >
                                이미 계정이 있습니까?<span>여기에서 로그인 하기</span>
                              </a>
                            </div>
                          </div>
                        </form>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default SignUpPopup

// sign up form input wrap
const CustomButton = styled.button`
  &:disabled {
    background: red !important;
  }
`

const SignUpModalFormInputWrap = styled.div`
  // border: 1px solid #4a4a4a;
  position: relative;
  width: 100%;
`

// sign up form input
const SignUpModalFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;

  color: #000;

  border: transparent;
  background: #fff;

  outline: none;
`

// Select
export const BankSelect = styled(CustomSelect)`
  .react-select__control {
    height: 28px;
    background-color: transparent;
    border-radius: 5px !important;
    text-align: left;
    font-size: 14px !important;
    &.react-select__control--is-focused,
    &:hover {
      border-color: white !important;
      border: 1px solid #4a4a4a !important;
      box-shadow: none;
      background: transparent;
      .react-select__placeholder {
        color: #4f4f4f; !important;
        font-size: 14px !important;
      }
    }
  }

  .react-select__input {
    height: 28px;
    color: red !important;
    &.react-select__control--is-focused {
      color: #000 !important;
      font-size: 14px !important;
    }
  }

  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto !important;
    background: rgba(0, 0, 0, 0.3) !important;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    color: #4f4f4f !important;
  }

  .react-select__single-value {
    color: #000;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    border: 1px solid black !important;
  }

  .react-select__placeholder {
    font-size: 13px !important;
    color: #4f4f4f; !important;
    margin-left: 5px !important;
  }
`

const DatePickerWrap = styled.div`
  width: 100%;
  border-radius: 4px;
  color: #000 !important;
  outline: none;
  font-size: 14px;
  line-height: 26px;
  transition: border 0.3s;
  background: transparent;
  // border: 1px solid #4a4a4a;
  z-index: 101;

  input {
    color: #000 !important;
    width: 100%;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    border: transparent;
    background: transparent;
    outline: none;
  }

  &[disabled] {
    background-color: #d8dae2;
    color: #b1b2bb;
  }

  .react-datepicker {
    display: block !important;
  }

  .react-datepicker__input-time-container {
    background: ${colorBackground};
    padding: 10px 15px;
    margin: 0;
    color: ${colorText};
  }

  & > div,
  .react-datepicker-wrapper,
  .rc-time-picker,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;

    ${scrollbarStyles};
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
    border-radius: 0 !important;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: ${colorText};
  }

  .react-datepicker__header {
    background-color: transparent;
    border: 2px solid lightgrey;
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${colorWhite};
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--next {
    border-left-color: ${colorWhite};

    &:hover {
      border-left-color: ${colorWhite};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorWhite};

    &:hover {
      border-right-color: ${colorWhite};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent};
    transition: all 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${colorAccent};
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    border-left: 1px solid ${colorBorder};
    width: 85px !important;
  }

  .react-datepicker__time-box {
    width: 85px !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: ${colorAccentHover} !important;
  }

  .react-datepicker__month {
    margin: 0;
    background-color: lightgrey;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: ${colorBackground} !important;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: black !important;
    color: ${colorWhite} !important;
  }

  .react-datepicker__month-dropdown-container {
    .react-datepicker__month-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__month-dropdown {
    border: 2px solid lightgrey;
    color: black;
  }

  .react-datepicker__year-dropdown {
    border: 2px solid lightgrey;
    color: black;

    .react-datepicker__navigation {
      top: 4px;
    }
    .react-datepicker__navigation--years-upcoming {
      display: none;
    }

    .react-datepicker__navigation--years-previous {
      display: none;
    }
  }

  .react-datepicker__year-dropdown-container {
    .react-datepicker__year-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: ${colorText};
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: ${colorHover} !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorLightAccent};
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:hover {
      background-color: ${colorAccentHover} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colorAccent};
  }

  .react-datepicker__month-text {
    color: ${colorText};
    transition: 0.3s;

    &:hover {
      background-color: ${colorHover};
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colorAccent};
    border-radius: 0.3rem;
    transition: 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-time__input {
    outline-color: ${colorAccent};

    &::-webkit-calendar-picker-indicator {
      filter: ${props => (props.theme.mode === 'dark' ? 'invert(1)' : 'none')};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__close-icon:after {
    background-color: ${colorAccent};
  }
`
