import React from 'react'
import styled from 'styled-components'

const WrapperLoading = ({ isLoading = false }) => {
  return (
    <div className={isLoading ? 'wrapper_loading animated' : 'wrapper_loading animated hide'}>
      <div className="loading-box">
        <div className="content">
          <div className="loader-circle" />
          <div className="loader-line-mask">
            <div className="loader-line" />
          </div>
          <div className="logo animated jump">
            <img src={`${process.env.PUBLIC_URL}/img/common/logo02.png`} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WrapperLoading
