import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { searchSlotVendor } from '../../api/game/gameApi'
import SlotCasinoGameModal from '../../containers/CasinoGame/SlotCasinoGameModal'
import CustomAlert from '../../containers/CustomAlert'

const SlotCasinoPage = () => {
  const [apiLoading, setApiLoading] = useState(false)

  const [slotCasinoVendors, setSlotCasinoVendors] = useState([])
  const [vendorName, setVendorName] = useState('')
  const [vendorType, setVendorType] = useState('')
  const [agencyName, setAgencyName] = useState('')
  const [slotCasinoGameModalShow, setSlotCasinoGameModalShow] = useState(false)

  const onCloseSlotCasinoGameModal = () => {
    setSlotCasinoGameModalShow(false)
  }

  const fetchSearchSlotVendor = async () => {
    await searchSlotVendor()
      .then(res => {
        setSlotCasinoVendors(res.data.vendors)
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    setApiLoading(true)
    fetchSearchSlotVendor()
  }, [])

  const [alertModalShow, setAlertModalShow] = useState(false)
  const [alertModalMessage, setAlertModalMessage] = useState(false)
  const onClickCloseHandler = () => {
    setAlertModalShow(false)
    setAlertModalMessage('')
  }

  const casinoModalShowHandler = vendor => {
    if (vendor.vendorIsInspection) {
      setAlertModalShow(true)
      setAlertModalMessage('현재 점검중인 게임입니다.')
      return
    }

    setVendorName(vendor.vendorName)
    setVendorType(vendor.vendorType)
    setAgencyName(vendor.agencyName)
    setSlotCasinoGameModalShow(true)
  }

  return (
    <>
      {slotCasinoGameModalShow && (
        <SlotCasinoGameModal
          visible={slotCasinoGameModalShow}
          onClose={onCloseSlotCasinoGameModal}
          vendorName={vendorName}
          agencyName={agencyName}
          vendorType={vendorType}
        />
      )}
      {alertModalShow && <CustomAlert info={alertModalMessage} onClickCloseHandler={onClickCloseHandler} />}

      <div className="main_content">
        <div className="titlePic">
          <div className="titlePwrapper">
            <div className="leftZone">
              <span>슬롯게임</span>
            </div>
            <div className="line" />
          </div>
        </div>
        <div className="gameZone slot">
          <div className="gameWrapper">
            <div className="content">
              <div className="leftFrame pc">
                <div className="leftZone aniLogo01">
                  <div className="obj aniLogo" />
                </div>
              </div>
              <div className="rightZone">
                <div className="txt pc">
                  <h2>SLOT GAME</h2>
                </div>
                <div className="btns">
                  <ul>
                    {slotCasinoVendors.map(item => {
                      return (
                        <li className="casinoFrame">
                          <a className="game_item " onClick={() => casinoModalShowHandler(item)}>
                            <div
                              className="model modelA"
                              style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/${item.vendorName
                                  ?.toString()
                                  ?.replace(/(\s*)/g, '')}.png)`,
                              }}
                            />
                            <div className="txt01">
                              <h2 className="mob" style={{ fontSize: '13px' }}>
                                {item.vendorNameKo}
                              </h2>
                              <h2 className="pc" style={{ fontSize: '20px' }}>
                                {item.vendorNameKo}
                              </h2>
                              <span style={{ fontSize: '12px' }}>{item.vendorName}</span>
                            </div>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="obj aniLogo02" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SlotCasinoPage
