import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MainContentBanner from './MainContentBanner'
import MainContentGame from './MainContentGame'

const MainContent = () => {
  return (
    <>
      <MainContentBanner />
      <MainContentGame />
    </>
  )
}

export default MainContent
