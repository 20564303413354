import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import parse from 'style-to-object'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { fetchEventBoardList } from '../../redux/customCenterSlice'
import { WebPageWrap } from '../../shared/components/form/newForm'
// import CustomLoading from '../CustomLoading'
import Portal from '../../shared/components/Portal'

const EventDetailModal = ({ isModalShow = false, onChangeModalState = () => {}, hangData = {} }) => {
  useEffect(() => {
    if (isModalShow) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto' // Modal이 닫힐 때 스타일 제거
    }
  }, [isModalShow])
  return (
    <Portal elementId="signup-modal">
      <SignUpModalOverlay visible={isModalShow} />
      <SignUpModalWrapper
        // onClick={onChangeModalState}
        tabIndex="-1"
        visible={isModalShow}
        className="sidebar-container"
      >
        <SignUpModalInner tabIndex="0" isMobile={isMobile}>
          <SignUpModalHeader>
            <SignUpModalCloseButton
              onClick={onChangeModalState}
              src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
              alt=""
            />
          </SignUpModalHeader>
          <SignUpModalContentWrap
            style={{ flexDirection: isMobile ? 'column' : 'row', height: 'max-content' }}
            className="game-container"
          >
            <div style={{ width: isMobile ? '360px' : '400px', display: 'flex', justifyContent: 'center' }}>
              <SignUpModalContentFirst src={`${hangData?.imgUrl}`} alt="" />
            </div>

            <SignUpModalContentSecondWrap isMobile={isMobile} className="game-container">
              <SignUpModalContentSecond>{ReactHtmlParser(hangData?.boardTitle)}</SignUpModalContentSecond>
              <SignUpModalContentThird>{ReactHtmlParser(hangData?.boardContent)}</SignUpModalContentThird>
            </SignUpModalContentSecondWrap>
          </SignUpModalContentWrap>
        </SignUpModalInner>
      </SignUpModalWrapper>
    </Portal>
  )
}

export default EventDetailModal

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9991;
  overflow: auto;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9990;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgb(16, 16, 18);
  // border: solid 1px blue;
  // padding-bottom: 30px;
  width: ${props => (props.isMobile ? '100%' : '1000px')};
  margin: 0 auto;
  height: 490px;
  margin-top: 10vh;
  overflow: ${props => (props.isMobile ? 'auto' : 'hidden')};
  // overflow: hidden;
  color: #606266;
  word-break: break-all;
`

const SignUpModalHeader = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: 200px;
  height: 50px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  color: #606266;
  word-break: break-all;
  padding: 5px 20px;
  height: 100%;
  display: flex;
  gap: 20px;
  overflow: auto;
`

// modal body content
const SignUpModalContentFirst = styled.img`
  width: 348px;
  height: 192px;
  // border: solid 1px red;
  // margin-right: 30px;
`

const SignUpModalContentSecondWrap = styled.div`
  width: 100%;
  height: ${props => (props.isMobile ? 'max-content' : '420px')};
  overflow: auto;
  padding: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  display: flex;
  // align-items: center;
  // justify-content: centner;
  flex-direction: column;
  // border: solid 1px red;
  gap: 10px;
`

const SignUpModalContentSecond = styled.div`
  width: 100%;
  color: #fff;
  font-size: 18px;
`

const SignUpModalContentThird = styled.div`
  // height: auto;
`

const EventRealWrap = styled.div``

const EventWrap = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fill, minmax(348px, 1fr));
  margin-top: 20px;
  padding: 10px 2px;
  gap: 20px;
`

const EventContentBox = styled.div`
  width: 380px;
  height: 320px;
  background: rgb(26, 28, 31);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: center;
  gap: 10px;
  transition: transform 0.3s ease-out, background 0.3s ease-out; // 애니메이션 효과 지속 시간과 형태 설정

  &:hover {
    // 호버 상태 설정
    cursor: pointer;
    background: rgb(39, 43, 46);
    transform: translateY(-10px); // 상단으로 10픽셀만큼 올림
  }

  img {
    width: 348px;
    height: 192px;
    margin-left: 16px;
  }
`

const EventContentTitle = styled.div`
  width: 100%;
  color: #eec100;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
`
const EventContentTitle2 = styled.div`
  width: 100%;
  color: #fff;
  padding-left: 20px;
  font-size: 24px;
  font-weight: 600;
`
