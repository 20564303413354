import AOS from 'aos'
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BrowserView } from 'react-device-detect'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { fetchClientInspectionPolling } from '../api/poling/pollingApi'
import CustomAlert from '../containers/CustomAlert'
import NoticePopup from '../containers/Popup/NoticePopup'
import store from '../redux/store'
import usePollingHook from '../utils/pollingHook'
import Router from './Router'
import GlobalStyles from './globalStyles'

const App = () => {
  // 웹소켓으로 계속 받아서 바꿔야 함
  const isInspectionPopupShow = false

  const [edgeSize, setEdgeSize] = useState(0)

  const [pollingFlag, setPollingFlag] = useState(false)
  // 점검 시작일
  const [startDate, setStartDate] = useState('')

  // 점검 종료일
  const [endDate, setEndDate] = useState('')
  usePollingHook(
    async () => {
      await fetchClientInspectionPolling().then(res => {
        setEdgeSize(res.data.edgeSize)
        setStartDate(res.data.inspectionStartDate)
        setEndDate(res.data.inspectionEndDate)
      })
    },
    [],
    {},
  )

  useEffect(() => {
    AOS.init()

    // body 태그에 AOS 속성 추가
    document.body.setAttribute('data-aos-easing', 'ease')
    document.body.setAttribute('data-aos-duration', '400')
    document.body.setAttribute('data-aos-delay', '0')

    return () => {
      // 컴포넌트가 언마운트될 때 속성 제거
      document.body.removeAttribute('data-aos-easing')
      document.body.removeAttribute('data-aos-duration')
      document.body.removeAttribute('data-aos-delay')
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <GlobalStyles />
          {edgeSize !== 0 ? (
            <CustomAlert
              info={`점검 일정 : ${moment(new Date(startDate)).format('MM월 DD일 HH:mm')} ~
              ${moment(new Date(endDate)).format('MM월 DD일 HH:mm')}`}
              isInspection
            />
          ) : (
            <NoticePopup />
          )}
          <Router />
          {/* <SignUpPopup /> */}
          {/* <LoginPopup /> */}
          {/* <BrowserView>
            
          </BrowserView> */}
          {/* <MobileView>
            <MobileRouter />
          </MobileView> */}
        </Provider>
      </BrowserRouter>
    </>
  )
}

export default App
