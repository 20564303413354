import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 실시간 출금 내역
export async function searchRealTimeSliderAllList(params) {
  const response = await axios({
    url: '/tyson/member/v1/slider/realtime',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 실시간 입금 내역
export async function searchRealTimeSliderList(params) {
  const response = await axios({
    url: '/tyson/member/v1/slider/realtime/deposit',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchRealTimeSliderList(params) {
  const [content, setContent] = useState([])

  async function fetchSearchRealTimeSliderList() {
    await searchRealTimeSliderList(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchRealTimeSliderList()
  }, [params])

  return { content, fetchSearchRealTimeSliderList }
}

// 실시간 출금 내역
export async function searchWeekSliderList(params) {
  const response = await axios({
    url: '/tyson/member/v1/slider/realtime/withdrawal',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchWeekSliderList(params) {
  const [content, setContent] = useState([])

  async function fetchSearchWeekSliderList() {
    await searchWeekSliderList(params).then(response => {
      setContent(response.data.content)
    })
  }
  useEffect(() => {
    fetchSearchWeekSliderList()
  }, [params])

  return { content, fetchSearchWeekSliderList }
}
