import React, { useCallback, useEffect, useMemo, useRef, useState, Fragment } from 'react'
import { FaLock } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  addBettingCart,
  clearAllInfo,
  fetchSpecialSportsEvents,
  fetchSportsEvents,
  handleWebSocketCrossSportsEvent,
  handleWebSocketSpecialSportsEvent,
  onClickHideSportsEventMainMarket,
  onClickHideSportsEventSpecialMarket,
  selectSportsEventOptionBtn,
  setMarketTapActive,
  setSportsType,
} from '../../../redux/sportsGameBettingSlice'
import { convertToKstByFormat } from '../../../utils/dateTime'
import isLogin from '../../../utils/enums/authority'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import { decodeAccessToken } from '../../../utils/token'
import CustomPagination from '../../../shared/components/CustomPagination'

const SpecialSportsMainFrame = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(150)
  const [sortType, setSortType] = useState('TODAY_SORT')
  const [searchBoolean, setSearchBoolean] = useState(false)
  const [searchTeamName, setSearchTeamName] = useState('')

  const [showButton, setShowButton] = useState(false)
  const chooseZoneRef = useRef(null)

  const handleScroll = () => {
    const limitTop = 50
    if (chooseZoneRef.current.scrollTop > limitTop) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  const scrollToTop = () => {
    if (chooseZoneRef.current) {
      chooseZoneRef.current.scroll({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const chooseZone = chooseZoneRef.current
    chooseZone.addEventListener('scroll', handleScroll)

    return () => {
      chooseZone.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function createKey(item) {
    return `${item.sportsEventId}-${item.marketId}-${item.selectedSportsMarketOddKey}-${item.selectedOddName}-${item.name}`
  }

  function isActive(activeSportsMarketKeySet, sportsEventId, marketId, sportsMarketOddKey, selectedOddName, name) {
    const key = createKey({
      sportsEventId,
      marketId,
      selectedSportsMarketOddKey: sportsMarketOddKey,
      selectedOddName,
      name,
    })
    return activeSportsMarketKeySet.has(key)
  }

  const { totalElement, mainSportsEventList, sportsTypeInfo, bonusFolderInfo, sportsType, specialMarkets } =
    useSelector(state => {
      const { sportsGameBetting } = state

      const { SPECIAL, BONUS } = sportsGameBetting.bettingCartList

      const activeSportsMarketKeySet = new Set([
        ...SPECIAL.map(item => createKey(item)),
        ...BONUS.map(item => createKey(item)),
      ])

      const tempSpecialMarket =
        sportsGameBetting.selectedSportsEventId === -1
          ? {}
          : sportsGameBetting?.specialMarkets[sportsGameBetting.selectedSportsEventId]

      let updatedMainSportsEventList

      if (sortType === 'POPULAR_SORT') {
        updatedMainSportsEventList = Object.entries(sportsGameBetting.mainSportsEventList)
          .map(([key, group]) => {
            const updatedEvents = group.sportsEvents.map(sportsEvent => {
              return {
                ...sportsEvent,
                markets: sportsEvent.markets.map(market => ({
                  ...market,
                  isHomeActive: isActive(
                    activeSportsMarketKeySet,
                    sportsEvent.sportsEventId,
                    market.marketId,
                    market.homeSportsMarketOddKey,
                    market.homeOddName,
                    market.marketName === '승무패' ? '' : market.name,
                  ),
                  isAwayActive: isActive(
                    activeSportsMarketKeySet,
                    sportsEvent.sportsEventId,
                    market.marketId,
                    market.awaySportsMarketOddKey,
                    market.awayOddName,
                    market.marketName === '승무패' ? '' : market.name,
                  ),
                  isDrawActive: isActive(
                    activeSportsMarketKeySet,
                    sportsEvent.sportsEventId,
                    market.marketId,
                    market.drawSportsMarketOddKey,
                    'Draw',
                    market.marketName === '승무패' ? '' : market.name,
                  ),
                })),
              }
            })

            const totalMarketCount = updatedEvents.reduce((sum, event) => sum + event.markets.length, 0)

            return {
              key,
              ...group,
              sportsEvents: updatedEvents,
              totalMarketCount,
            }
          })
          .sort((a, b) => b.totalMarketCount - a.totalMarketCount)
      } else {
        updatedMainSportsEventList = Object.entries(sportsGameBetting.mainSportsEventList).map(([key, group]) => {
          const updatedEvents = group.sportsEvents.map(sportsEvent => {
            return {
              ...sportsEvent,
              markets: sportsEvent.markets.map(market => ({
                ...market,
                isHomeActive: isActive(
                  activeSportsMarketKeySet,
                  sportsEvent.sportsEventId,
                  market.marketId,
                  market.homeSportsMarketOddKey,
                  market.homeOddName,
                  market.marketName === '승무패' ? '' : market.name,
                ),
                isAwayActive: isActive(
                  activeSportsMarketKeySet,
                  sportsEvent.sportsEventId,
                  market.marketId,
                  market.awaySportsMarketOddKey,
                  market.awayOddName,
                  market.marketName === '승무패' ? '' : market.name,
                ),
                isDrawActive: isActive(
                  activeSportsMarketKeySet,
                  sportsEvent.sportsEventId,
                  market.marketId,
                  market.drawSportsMarketOddKey,
                  'Draw',
                  market.marketName === '승무패' ? '' : market.name,
                ),
              })),
            }
          })

          return {
            key,
            ...group,
            sportsEvents: updatedEvents,
          }
        })
      }

      return {
        totalElement: sportsGameBetting.totalElement,
        mainSportsEventList: updatedMainSportsEventList,
        sportsTypeInfo: sportsGameBetting.sportsTypeInfo,
        bonusFolderInfo: sportsGameBetting.bonusFolderInfo.map(bonus => ({
          ...bonus,
          isActive: isActive(activeSportsMarketKeySet, 0, bonus.marketId, null, `${bonus.bonusFolderNum}폴더 이상`, ''),
        })),
        sportsType: sportsGameBetting.selectedSportsType,
        specialMarkets: {
          ...tempSpecialMarket,
          markets: tempSpecialMarket?.markets?.map(market => ({
            ...market,
            points: market?.points?.map(point => ({
              ...point,
              isActive: isActive(
                activeSportsMarketKeySet,
                tempSpecialMarket.sportsEventId,
                market.marketId,
                point.sportsMarketOddKey,
                point.oddName,
                point.name,
              ),
            })),
          })),
        },
      }
    })

  useEffect(() => {
    setSearchBoolean(prev => !prev)
    setSearchTeamName('')
    setPage(0)
  }, [sportsType])

  const params = useMemo(() => {
    return {
      sportsType,
      page: !searchTeamName ? page : 0,
      size,
      sortType,
      searchTeamName: !searchTeamName ? null : searchTeamName,
    }
  }, [sportsType, page, size, sortType, searchBoolean])

  const socketRef = useRef(null)
  const intervalIdRef = useRef(null)

  const handleSocketOpen = useCallback(() => {
    // 웹소켓 연결 후 초기 데이터 요청
    dispatch(fetchSpecialSportsEvents(params))
  }, [dispatch])

  const handleSocketMessage = useCallback(
    event => {
      const message = JSON.parse(event.data)
      dispatch(handleWebSocketSpecialSportsEvent(message))
    },
    [dispatch],
  )

  const handleSocketError = useCallback(event => {
    console.error('WebSocket error observed:', event)
    // logout 시키기
  }, [])

  // 웹소켓 연결을 처음에만 생성합니다.
  useEffect(() => {
    if (location.pathname !== '/special-sports') {
      return
    }

    // 웹소켓 인스턴스 생성
    const userKey = decodeAccessToken().userKey || ''
    const newSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_SPECIAL_SPORTS_URL}?userKey=${userKey}`)
    socketRef.current = newSocket

    // 웹소켓 이벤트 리스너 등록
    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('error', handleSocketError)

    return () => {
      // 컴포넌트 언마운트 시 웹소켓 연결 종료
      if (socketRef.current) {
        socketRef.current.close()
      }

      dispatch(clearAllInfo())
    }
  }, [location, handleSocketOpen, handleSocketMessage, handleSocketError])

  // params가 변경될 때마다 새로운 interval을 설정합니다.
  useEffect(() => {
    // 이전 interval 해제
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      dispatch(fetchSpecialSportsEvents(params))
    }

    // 일정한 주기로 파라미터를 서버로 보내기
    const id = setInterval(() => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify(params))
      }
    }, 5000) // 1초마다 파라미터 전송

    intervalIdRef.current = id

    return () => {
      // 컴포넌트 언마운트 시 타이머 해제
      clearInterval(intervalIdRef.current)
    }
  }, [params])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (chooseZoneRef.current) {
      chooseZoneRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const onClickSportsEventType = info => {
    dispatch(setMarketTapActive('SPECIAL'))
    dispatch(setSportsType(info))
    setSortType('TODAY_SORT')
    setPage(0)
  }

  const onClickBetting = item => {
    dispatch(addBettingCart(item))
  }

  const onClickHideSportsEventMainMarketHandler = (key, visibled) => {
    dispatch(onClickHideSportsEventMainMarket({ key, isVisible: visibled }))
  }

  // Special 접기 / 펼치기
  const onClickHideSportsEventSpecialMarketHandler = (sportsEventId, marketId, visibled) => {
    dispatch(onClickHideSportsEventSpecialMarket({ sportsEventId, marketId, isVisible: visibled }))
  }

  const getMarketListType6Component = (eventInfo, market) => {
    const { sportsEventId, leagueName, homeName, awayName, ccKr, startAt, type } = eventInfo
    const { marketUiType, points } = market

    if (marketUiType !== 6) return points

    const updatedPoints = points.map(point => ({ ...point }))

    let i = 0
    while (i < updatedPoints.length) {
      if (updatedPoints[i].oddValue === '') {
        const currentOddName = updatedPoints[i].oddNameKr
        let j = i + 1
        while (j < updatedPoints.length) {
          if (updatedPoints[j].oddNameKr === currentOddName) {
            updatedPoints[i] = updatedPoints[j] // updatedPoints[i]를 updatedPoints[j]로 대체
            updatedPoints.splice(j, 1) // updatedPoints[j]를 리스트에서 제거
            break
          }
          j++
        }
        if (j === updatedPoints.length) {
          updatedPoints.splice(i, 1) // 해당 요소를 삭제
        }
      } else {
        i++
      }
    }

    const groupedPoints = updatedPoints.reduce((result, point) => {
      const { oddNameKr } = point
      if (!result[oddNameKr]) {
        result[oddNameKr] = []
      }
      result[oddNameKr].push(point)
      return result
    }, {})

    const maxValuesLength = Math.max(
      groupedPoints['홈팀승'] ? groupedPoints['홈팀승'].length : 0,
      groupedPoints['무승부'] ? groupedPoints['무승부'].length : 0,
      groupedPoints['원정승'] ? groupedPoints['원정승'].length : 0,
    )

    return (
      <>
        {Array.from({ length: maxValuesLength }, (_, idx) => (
          <div className="option-bunch" key={idx}>
            <div className="option-wrap btnZone c31">
              {groupedPoints['홈팀승'] && groupedPoints['홈팀승'][idx] ? (
                <div
                  className={
                    groupedPoints['홈팀승'][idx].isActive
                      ? 'btnM bet-pick home-pick option bet pick'
                      : 'btnM bet-pick home-pick option bet '
                  }
                  onClick={() => {
                    const item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: groupedPoints['홈팀승'][idx].name,
                      homeSportsMarketOddKey: groupedPoints['홈팀승'][idx].sportsMarketOddKey,
                      homeOddName: groupedPoints['홈팀승'][idx].oddName,
                      homeOddNameKr: groupedPoints['홈팀승'][idx].oddNameKr,
                      homeOddValue: groupedPoints['홈팀승'][idx].oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: groupedPoints['홈팀승'][idx].sportsMarketPointKey,
                      selectedSportsMarketOddKey: groupedPoints['홈팀승'][idx].sportsMarketOddKey,
                      selectedOddName: groupedPoints['홈팀승'][idx].oddName,
                      selectedOddNameKr: groupedPoints['홈팀승'][idx].oddNameKr,
                      selectedOddValue: groupedPoints['홈팀승'][idx].oddValue,
                    }

                    onClickBetting({ type, item })
                  }}
                >
                  <>
                    {groupedPoints['홈팀승'][idx].oddValue && (
                      <>
                        <div className="left">
                          <span>{groupedPoints['홈팀승'][idx].oddNameKr}</span>
                          <span style={{ marginLeft: '10px' }}>{groupedPoints['홈팀승'][idx].name}</span>
                        </div>
                        <div className="right">
                          <span>{groupedPoints['홈팀승'][idx].oddValue}</span>
                        </div>
                      </>
                    )}
                  </>
                </div>
              ) : (
                <div className="btnM bet-pick home-pick option bet">
                  <div className="left">
                    <span />
                  </div>
                  <div className="right">
                    <span />
                  </div>
                </div>
              )}
              {groupedPoints['무승부'] && groupedPoints['무승부'][idx] ? (
                <div
                  className={
                    groupedPoints['무승부'][idx].isActive
                      ? 'btnM bet-pick home-pick option bet pick'
                      : 'btnM bet-pick home-pick option  bet '
                  }
                  onClick={() => {
                    const item = {
                      sportsEventId,
                      sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: groupedPoints['무승부'][idx].name,
                      homeSportsMarketOddKey: groupedPoints['무승부'][idx].sportsMarketOddKey,
                      homeOddName: groupedPoints['무승부'][idx].oddName,
                      homeOddNameKr: groupedPoints['무승부'][idx].oddNameKr,
                      homeOddValue: groupedPoints['무승부'][idx].oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: groupedPoints['무승부'][idx].sportsMarketPointKey,
                      selectedSportsMarketOddKey: groupedPoints['무승부'][idx].sportsMarketOddKey,
                      selectedOddName: groupedPoints['무승부'][idx].oddName,
                      selectedOddNameKr: groupedPoints['무승부'][idx].oddNameKr,
                      selectedOddValue: groupedPoints['무승부'][idx].oddValue,
                    }
                    onClickBetting({ type, item })
                  }}
                >
                  <>
                    {groupedPoints['무승부'][idx].oddValue && (
                      <>
                        <div className="left">
                          <span>{groupedPoints['무승부'][idx].oddNameKr}</span>
                          <span style={{ marginLeft: '10px' }}>{groupedPoints['무승부'][idx].name}</span>
                        </div>
                        <div className="right">
                          <span>{groupedPoints['무승부'][idx].oddValue}</span>
                        </div>
                      </>
                    )}
                  </>
                </div>
              ) : (
                <div className="btnM bet-pick home-pick option bet">
                  <></>
                </div>
              )}
              {groupedPoints['원정승'] && groupedPoints['원정승'][idx] ? (
                <div
                  className={
                    groupedPoints['원정승'][idx].isActive
                      ? 'btnM bet-pick home-pick option bet pick'
                      : 'btnM bet-pick home-pick option  bet '
                  }
                  onClick={() => {
                    const item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: groupedPoints['원정승'][idx].name,
                      homeSportsMarketOddKey: groupedPoints['원정승'][idx].sportsMarketOddKey,
                      homeOddName: groupedPoints['원정승'][idx].oddName,
                      homeOddNameKr: groupedPoints['원정승'][idx].oddNameKr,
                      homeOddValue: groupedPoints['원정승'][idx].oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: groupedPoints['원정승'][idx].sportsMarketPointKey,
                      selectedSportsMarketOddKey: groupedPoints['원정승'][idx].sportsMarketOddKey,
                      selectedOddName: groupedPoints['원정승'][idx].oddName,
                      selectedOddNameKr: groupedPoints['원정승'][idx].oddNameKr,
                      selectedOddValue: groupedPoints['원정승'][idx].oddValue,
                    }
                    onClickBetting({ type, item })
                  }}
                >
                  <>
                    {groupedPoints['원정승'][idx].oddValue && (
                      <>
                        <div className="left">
                          <span>{groupedPoints['원정승'][idx].oddNameKr}</span>
                          <span style={{ marginLeft: '10px' }}>{groupedPoints['원정승'][idx].name}</span>
                        </div>
                        <div className="right">
                          <span>{groupedPoints['원정승'][idx].oddValue}</span>
                        </div>
                      </>
                    )}
                  </>
                </div>
              ) : (
                <div className="btnM bet-pick home-pick option bet">
                  <></>
                </div>
              )}
            </div>
          </div>
        ))}
      </>
    )
  }

  const getMarketListCommonComponent = (eventInfo, market) => {
    const { sportsEventId, leagueName, homeName, awayName, ccKr, startAt, type } = eventInfo
    const { marketUiType, points } = market

    const uls = []
    let num

    switch (marketUiType) {
      case 1:
      case 2:
      case 3:
        num = marketUiType
        break
      case 4:
      case 6:
        num = 3
        break
      case 5:
        num = 2
        break
      default:
        num = 1
        break
    }

    points.forEach((value, index) => {
      if (index % num === 0) {
        uls.push(
          <div>
            <div
              className={
                value.isActive ? 'btnM bet-pick home-pick option bet pick' : 'btnM bet-pick home-pick option  bet '
              }
              onClick={() => {
                let item = {}

                switch (true) {
                  case num === 2:
                    item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: points[index].sportsMarketOddKey,
                      homeOddName: points[index].oddName,
                      homeOddNameKr: points[index].oddNameKr,
                      homeOddValue: points[index].oddValue,
                      awaySportsMarketOddKey: points[index + 1].sportsMarketOddKey,
                      awayOddName: points[index + 1].oddName,
                      awayOddNameKr: points[index + 1].oddNameKr,
                      awayOddValue: points[index + 1].oddValue,
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                    break
                  case num === 3 && marketUiType === 4:
                    item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: value.sportsMarketOddKey,
                      homeOddName: value.oddName,
                      homeOddNameKr: value.oddNameKr,
                      homeOddValue: value.oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                    break
                  case num === 3 && marketUiType !== 4:
                    item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: points[index].sportsMarketOddKey,
                      homeOddName: points[index].oddName,
                      homeOddNameKr: points[index].oddNameKr,
                      homeOddValue: points[index].oddValue,
                      awaySportsMarketOddKey: points[index + 2].sportsMarketOddKey,
                      awayOddName: points[index + 2].oddName,
                      awayOddNameKr: points[index + 2].oddNameKr,
                      awayOddValue: points[index + 2].oddValue,
                      drawSportsMarketOddKey: points[index + 1].sportsMarketOddKey,
                      drawOddName: points[index + 1].oddName,
                      drawOddNameKr: points[index + 1].oddNameKr,
                      drawOddValue: points[index + 1].oddValue,
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                    break
                  default:
                    item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: value.sportsMarketOddKey,
                      homeOddName: value.oddName,
                      homeOddNameKr: value.oddNameKr,
                      homeOddValue: value.oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                    break
                }

                if (item) {
                  onClickBetting({ type, item })
                }
              }}
            >
              <div className="left">
                <span>
                  {value.oddNameKr}
                  <span style={{ marginLeft: '10px' }}>{value.name}</span>
                </span>
              </div>

              <div className="right odds-wrap bet-pick">
                <span className="percent odds">{value.oddValue}</span>
                <span className="icon-state" />
              </div>
            </div>
          </div>,
        )
      } else {
        const lastIndex = uls.length - 1

        const updatedChildren = React.Children.toArray(uls[lastIndex].props.children)
        // 핸디 , num==2 일때만 사용
        let awayPointName

        if (market.marketName.includes('핸디')) {
          const parsedValue = parseFloat(points[index - 1].name)
          awayPointName = parsedValue === 0 ? '0.0' : (-parsedValue).toFixed(1)
        } else {
          awayPointName = ''
        }
        updatedChildren.push(
          <div
            className={
              value.isActive ? 'btnM bet-pick home-pick option  bet pick' : 'btnM bet-pick home-pick option  bet '
            }
            key={index}
            onClick={() => {
              let item = {}

              switch (true) {
                case num === 2:
                  item = {
                    sportsEventId,
                    sportsType: eventInfo.sportsType,
                    ccKr,
                    startAt,
                    sportsMarketKey: market.sportsMarketKey,
                    marketId: market.marketId,
                    leagueName,
                    homeName,
                    awayName,
                    marketName: market.marketName,
                    name: value.name,
                    homeSportsMarketOddKey: points[index - 1].sportsMarketOddKey,
                    homeOddName: points[index - 1].oddName,
                    homeOddNameKr: points[index - 1].oddNameKr,
                    homeOddValue: points[index - 1].oddValue,
                    awaySportsMarketOddKey: points[index].sportsMarketOddKey,
                    awayOddName: points[index].oddName,
                    awayOddNameKr: points[index].oddNameKr,
                    awayOddValue: points[index].oddValue,
                    drawSportsMarketOddKey: '',
                    drawOddName: '',
                    drawOddNameKr: '',
                    drawOddValue: '',
                    selectedSportsMarketPointKey: value.sportsMarketPointKey,
                    selectedSportsMarketOddKey: value.sportsMarketOddKey,
                    selectedOddName: value.oddName,
                    selectedOddNameKr: value.oddNameKr,
                    selectedOddValue: value.oddValue,
                    awayPointName,
                  }
                  break
                case num === 3 && marketUiType === 4:
                  // 무승부 (가운데 선택시)
                  if (index > 0 && index < 2) {
                    item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: value.sportsMarketOddKey,
                      homeOddName: value.oddName,
                      homeOddNameKr: value.oddNameKr,
                      homeOddValue: value.oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                  } else {
                    item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: value.sportsMarketOddKey,
                      homeOddName: value.oddName,
                      homeOddNameKr: value.oddNameKr,
                      homeOddValue: value.oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                  }
                  break
                case num === 3 && marketUiType !== 4:
                  // 무승부 (가운데 선택시)
                  if (index > 0 && index < 2) {
                    item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: points[index - 1].sportsMarketOddKey,
                      homeOddName: points[index - 1].oddName,
                      homeOddNameKr: points[index - 1].oddNameKr,
                      homeOddValue: points[index - 1].oddValue,
                      awaySportsMarketOddKey: points[index + 1].sportsMarketOddKey,
                      awayOddName: points[index + 1].oddName,
                      awayOddNameKr: points[index + 1].oddNameKr,
                      awayOddValue: points[index + 1].oddValue,
                      drawSportsMarketOddKey: points[index].sportsMarketOddKey,
                      drawOddName: points[index].oddName,
                      drawOddNameKr: points[index].oddNameKr,
                      drawOddValue: points[index].oddValue,
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                  } else {
                    item = {
                      sportsEventId,
                      sportsType: eventInfo.sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: points[index - 2].sportsMarketOddKey,
                      homeOddName: points[index - 2].oddName,
                      homeOddNameKr: points[index - 2].oddNameKr,
                      homeOddValue: points[index - 2].oddValue,
                      awaySportsMarketOddKey: points[index].sportsMarketOddKey,
                      awayOddName: points[index].oddName,
                      awayOddNameKr: points[index].oddNameKr,
                      awayOddValue: points[index].oddValue,
                      drawSportsMarketOddKey: points[index - 1].sportsMarketOddKey,
                      drawOddName: points[index - 1].oddName,
                      drawOddNameKr: points[index - 1].oddNameKr,
                      drawOddValue: points[index - 1].oddValue,
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                  }
                  break
                default:
                  item = {
                    sportsEventId,
                    sportsType: eventInfo.sportsType,
                    ccKr,
                    startAt,
                    sportsMarketKey: market.sportsMarketKey,
                    marketId: market.marketId,
                    leagueName,
                    homeName,
                    awayName,
                    marketName: market.marketName,
                    name: value.name,
                    homeSportsMarketOddKey: value.sportsMarketOddKey,
                    homeOddName: value.oddName,
                    homeOddNameKr: value.oddNameKr,
                    homeOddValue: value.oddValue,
                    awaySportsMarketOddKey: '',
                    awayOddName: '',
                    awayOddNameKr: '',
                    awayOddValue: '',
                    drawSportsMarketOddKey: '',
                    drawOddName: '',
                    drawOddNameKr: '',
                    drawOddValue: '',
                    selectedSportsMarketPointKey: value.sportsMarketPointKey,
                    selectedSportsMarketOddKey: value.sportsMarketOddKey,
                    selectedOddName: value.oddName,
                    selectedOddNameKr: value.oddNameKr,
                    selectedOddValue: value.oddValue,
                  }
                  break
              }

              if (item) {
                onClickBetting({ type, item })
              }
            }}
          >
            <div className="left">
              <span>
                {value.oddNameKr}
                <span style={{ marginLeft: '10px' }}>{awayPointName || value.name}</span>
              </span>
            </div>

            <div className="right odds-wrap bet-pick">
              <span className="percent odds">{value.oddValue}</span>
              <span className="icon-state" />
            </div>
          </div>,
        )

        const updatedUl = React.cloneElement(uls[lastIndex], {
          children: updatedChildren,
        })

        uls[lastIndex] = updatedUl
      }
    })

    return uls.map((ul, index) => (
      <div className="option-bunch" key={index}>
        <div className="option-wrap btnZone c31">{ul.props.children}</div>
      </div>
    ))
  }

  return (
    <>
      <div className="chooseFrame">
        <div className="tabZone">
          <div className="tabFrame">
            <ul>
              {sportsTypeInfo.map(item => {
                return (
                  <li
                    className={
                      item.sportsType === sportsType || sportsType === 'ALL'
                        ? 'item category-all active'
                        : 'item category-all'
                    }
                    onClick={() => {
                      onClickSportsEventType(item.sportsType === 'ALL' ? null : item.sportsType)
                    }}
                    key={`${item.sportsType}-${item.typeKr}`}
                  >
                    {item.sportsType !== 'ALL' && (
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/img/sports/sportsType/side-${item.sportsType?.toLowerCase()}.png`}
                        width="20"
                        height="20"
                        alt=""
                      />
                    )}

                    <div className="txt" style={{ marginLeft: '2px' }}>
                      {item.typeKr}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="chooseZone left w_165" ref={chooseZoneRef}>
          <div className="sportZone">
            <div className="bonus-folder-wrap">
              {bonusFolderInfo.map((folder, index) => {
                return (
                  <div
                    className={folder.isActive ? 'bonus-bet pick' : 'bonus-bet'}
                    key={`${folder.bonusFolderNum}-${folder.bonusFolderPer}`}
                    id="three-bonus"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const type = 'BONUS'
                      const item = {
                        sportsEventId: 0,
                        sportsType: 'ETC',
                        sportsMarketKey: folder.marketId,
                        marketId: folder.marketId,
                        leagueName: '',
                        ccKr: '',
                        startAt: null,
                        homeName: '',
                        awayName: '',
                        marketName: '보너스 배당',
                        name: '',
                        homeSportsMarketOddKey: '',
                        homeOddName: `${folder.bonusFolderNum}폴더 이상`,
                        homeOddNameKr: `${folder.bonusFolderNum}폴더 이상`,
                        homeOddValue: folder.bonusFolderPer,
                        awaySportsMarketOddKey: '',
                        awayOddName: '',
                        awayOddNameKr: '',
                        awayOddValue: '',
                        drawSportsMarketOddKey: '',
                        drawOddName: '',
                        drawOddNameKr: '',
                        drawOddValue: '',
                        selectedSportsMarketPointKey: null,
                        selectedSportsMarketOddKey: null,
                        selectedOddName: `${folder.bonusFolderNum}폴더 이상`,
                        selectedOddNameKr: `${folder.bonusFolderNum}폴더 이상`,
                        selectedOddValue: folder.bonusFolderPer,
                      }

                      onClickBetting({ type, item })
                    }}
                  >
                    <div className="left">
                      <div className="name" style={{ color: '#fff', display: 'flex', gap: '10px' }}>
                        {folder.bonusFolderNum}폴더 보너스
                      </div>
                    </div>
                    <div className="odds-wrap right ">
                      <div className="odds" style={{ color: '#fff' }}>
                        {' '}
                        {folder.bonusFolderPer}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="folder-wrap">
              <div id="fixture-list" className="pageSport">
                <div className="cubeZone" id="game-folders">
                  <div id="top-hidden" />

                  {mainSportsEventList.map(mainSportsEvent => {
                    return (
                      <div className="cube">
                        <li
                          className={mainSportsEvent.isVisible ? 'opened switcher titleS' : 'switcher titleS'}
                          onClick={() => {
                            onClickHideSportsEventMainMarketHandler(mainSportsEvent.key, !mainSportsEvent.isVisible)
                          }}
                        >
                          <div className="left">
                            <img
                              src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${mainSportsEvent.sportsType}.png`}
                              height="20"
                              width="20"
                              alt=""
                            />

                            {mainSportsEvent.ccImage && (
                              <img src={mainSportsEvent.ccImage} height="20" width="20" alt="" />
                            )}

                            <span style={{ color: '#000', minWidth: 'max-content' }}>{mainSportsEvent.ccKr}</span>

                            <span className="icon-iconGo" />

                            {mainSportsEvent.leagueImage && (
                              <img src={mainSportsEvent.leagueImage} height="20" width="20" alt="" />
                            )}

                            {mainSportsEvent.leagueName}
                          </div>

                          <div className="right">
                            <div className="time mob">
                              {convertToKstByFormat(mainSportsEvent.sportsEvents[0]?.startAt, 'MM-DD HH:mm')}
                            </div>
                            <div className="arrowT arrowGO">
                              {mainSportsEvent.isVisible ? (
                                <span className="icon-iconARdownA" />
                              ) : (
                                <span className="icon-iconARupA" />
                              )}
                            </div>
                          </div>
                        </li>
                        <div className="switchee">
                          {mainSportsEvent.sportsEvents.map(sportsEvent => {
                            return (
                              <>
                                <div
                                  className="listS"
                                  key={`${sportsEvent.startAt}-${sportsEvent.sportsEventId}`}
                                  style={{ position: 'relative' }}
                                >
                                  {sportsEvent.isLocked && (
                                    <SportsEventContentItemOverlay>
                                      <SportsEventContentItemLockIcon />
                                    </SportsEventContentItemOverlay>
                                  )}
                                  <div className="leftZone pc">
                                    <div className="time">
                                      {convertToKstByFormat(sportsEvent.startAt, 'MM-DD HH:mm')}
                                    </div>
                                    {/* <div className="gameName">{market?.marketName}</div> */}
                                  </div>

                                  <div className="rightZone">
                                    <div className="chooseBtn">
                                      <div
                                        className={
                                          sportsEvent.sportsEventId === specialMarkets.sportsEventId
                                            ? 'nameA bet-pick home-pick pick'
                                            : 'nameA bet-pick home-pick'
                                        }
                                        onClick={() => {
                                          dispatch(
                                            selectSportsEventOptionBtn({
                                              key: mainSportsEvent.key,
                                              sportsEventId: sportsEvent.sportsEventId,
                                              isMobile: false,
                                            }),
                                          )
                                        }}
                                      >
                                        <div className="txtFrame">
                                          <img
                                            className="teamImg"
                                            src={`${process.env.PUBLIC_URL}/img/sports/default.png`}
                                            alt=""
                                          />
                                          <h2>{sportsEvent.homeName}</h2>
                                        </div>
                                        <div className="txt">
                                          <span className="icon-state" />
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          sportsEvent.sportsEventId === specialMarkets.sportsEventId
                                            ? 'nameA nameC bet-pick draw-pick pick'
                                            : 'nameA nameC bet-pick draw-pick'
                                        }
                                        onClick={() => {
                                          dispatch(
                                            selectSportsEventOptionBtn({
                                              key: mainSportsEvent.key,
                                              sportsEventId: sportsEvent.sportsEventId,
                                              isMobile: false,
                                            }),
                                          )
                                        }}
                                      >
                                        <div className="txt">
                                          <h3 className="odds">VS</h3>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          sportsEvent.sportsEventId === specialMarkets.sportsEventId
                                            ? 'nameA bet-pick away-pick pick'
                                            : 'nameA bet-pick away-pick'
                                        }
                                        onClick={() => {
                                          dispatch(
                                            selectSportsEventOptionBtn({
                                              key: mainSportsEvent.key,
                                              sportsEventId: sportsEvent.sportsEventId,
                                              isMobile: false,
                                            }),
                                          )
                                        }}
                                      >
                                        <div className="txt">
                                          <span className="icon-state" />
                                        </div>
                                        <div className="txtFrame">
                                          <h2>{sportsEvent.awayName}</h2>
                                          <img
                                            className="teamImg"
                                            src={`${process.env.PUBLIC_URL}/img/sports/default.png`}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="heartFrame heart01">
                                      <span className="icon-fa-star" />
                                    </div>

                                    <div
                                      className={
                                        sportsEvent.sportsEventId === specialMarkets.sportsEventId
                                          ? 'more switcher opened'
                                          : 'more switcher'
                                      }
                                    >
                                      {/* 추가배팅 옵션 들어갈 곳 */}
                                    </div>

                                    <div class="switchee mob-market-list mob-game-12088133-market-wrap mob">
                                      <div class="videoZone pc">
                                        <div class="title">
                                          <div class="left">
                                            {specialMarkets.ccKr}
                                            <span class="icon-iconGo" />
                                            {specialMarkets.homeName}
                                            <span class="vs">VS</span>
                                            {specialMarkets.awayName}
                                          </div>
                                          <div class="right">
                                            <div class="arrowV arrowGO" />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="bFrame option-group-wrap" style={{ marginTop: '0' }}>
                                        {specialMarkets?.markets?.map(market => {
                                          return (
                                            <Fragment key={market.sportsMarketKey}>
                                              <div className="bZone option-group">
                                                <div
                                                  className="title switcher opened"
                                                  onClick={() =>
                                                    onClickHideSportsEventSpecialMarketHandler(
                                                      specialMarkets.sportsEventId,
                                                      market.marketId,
                                                      !market.isVisible,
                                                    )
                                                  }
                                                >
                                                  <div className="left">{market.marketName || ''}</div>
                                                  <div className="right">
                                                    <div className="heart01">
                                                      <span className="icon-fa-star-o" />
                                                    </div>
                                                    <div className="arrowW arrowGO">
                                                      {market.isVisible ? (
                                                        <span className="icon-iconARupA" />
                                                      ) : (
                                                        <span className="icon-iconARdownA" />
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>

                                                {market.isVisible && (
                                                  <div className="content switchee" style={{ position: 'relative' }}>
                                                    {market.isLocked && (
                                                      <SportsEventContentItemOverlay>
                                                        <SportsEventContentItemLockIcon />
                                                      </SportsEventContentItemOverlay>
                                                    )}
                                                    {market?.marketUiType === 6
                                                      ? getMarketListType6Component(
                                                          {
                                                            ...specialMarkets,
                                                            type: 'SPECIAL',
                                                          },
                                                          market,
                                                        )
                                                      : getMarketListCommonComponent(
                                                          {
                                                            ...specialMarkets,
                                                            type: 'SPECIAL',
                                                          },
                                                          market,
                                                        )}
                                                  </div>
                                                )}
                                              </div>
                                            </Fragment>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

              {specialMarkets?.markets ? (
                <>
                  {totalElement > 0 && (
                    <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                  )}
                </>
              ) : (
                <div className="tb_empty">
                  <div className="pic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
                  </div>
                  <h2>현재 진행중인 경기가 없습니다.</h2>
                  {/* <h3>Sorry, Empty Data</h3> */}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="chooseZone right">
          {specialMarkets?.markets && (
            <>
              <div className="videoZone pc">
                <div className="title">
                  <div className="left">
                    {specialMarkets.ccKr}
                    <span className="icon-iconGo" />
                    {specialMarkets.homeName}
                    <span className="vs">VS</span>
                    {specialMarkets.awayName}
                  </div>
                  <div className="right">
                    <div className="arrowV arrowGO" />
                  </div>
                </div>
              </div>
              <div className="bFrame option-group-wrap" style={{ marginTop: '0' }}>
                {specialMarkets?.markets?.map(market => {
                  return (
                    <Fragment key={market.sportsMarketKey}>
                      <div className="bZone option-group">
                        <div
                          className="title switcher opened"
                          onClick={() =>
                            onClickHideSportsEventSpecialMarketHandler(
                              specialMarkets.sportsEventId,
                              market.marketId,
                              !market.isVisible,
                            )
                          }
                        >
                          <div className="left">{market.marketName || ''}</div>
                          <div className="right">
                            <div className="heart01">
                              <span className="icon-fa-star-o" />
                            </div>
                            <div className="arrowW arrowGO">
                              {market.isVisible ? (
                                <span className="icon-iconARupA" />
                              ) : (
                                <span className="icon-iconARdownA" />
                              )}
                            </div>
                          </div>
                        </div>

                        {market.isVisible && (
                          <div className="content switchee" style={{ position: 'relative' }}>
                            {market.isLocked && (
                              <SportsEventContentItemOverlay>
                                <SportsEventContentItemLockIcon />
                              </SportsEventContentItemOverlay>
                            )}
                            {market?.marketUiType === 6
                              ? getMarketListType6Component(
                                  {
                                    ...specialMarkets,
                                    type: 'SPECIAL',
                                  },
                                  market,
                                )
                              : getMarketListCommonComponent(
                                  {
                                    ...specialMarkets,
                                    type: 'SPECIAL',
                                  },
                                  market,
                                )}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div id="btn_top" className={showButton ? '' : 'hide'} onClick={scrollToTop} />
    </>
  )
}

export default SpecialSportsMainFrame

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);

  border: 1px solid #fff;
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`

const SlimScrollDiv = styled.div`
  overflow: hidden;
  width: auto;
  height: 100%;
  display: flex;
`

const SlimScrollInnerDiv = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`
