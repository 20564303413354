import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { searchGameUrl, searchLiveVendor } from '../../api/game/gameApi'
import isLogin from '../../utils/enums/authority'
import WrapperLoading from '../../containers/WrapperLoading'
import CasinoGameViewPopup from '../../containers/CasinoGame/CasinoGameViewPopup'
import CustomAlert from '../../containers/CustomAlert'

const LiveCasinoPage = () => {
  const [apiLoading, setApiLoading] = useState(false)

  const [liveCasinoVendors, setLiveCasinoVendors] = useState([])

  const fetchSearchLiveVendor = async () => {
    await searchLiveVendor()
      .then(res => {
        setLiveCasinoVendors(res.data.vendors)
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    setApiLoading(true)
    fetchSearchLiveVendor()
  }, [])

  const [casinoGameUrl, setCasinoGameUrl] = useState('')
  const [casinoGameViewPopupShow, setCasinoGameViewPopupShow] = useState(false)
  const onCloseCasinoGameViewPopupShowHandler = () => {
    setCasinoGameViewPopupShow(false)
  }

  const notGamePopupVendors = ['BOTA', 'ASIA GAMING LIVE']

  const [alertModalShow, setAlertModalShow] = useState(false)
  const [alertModalMessage, setAlertModalMessage] = useState(false)
  const onClickCloseHandler = () => {
    setAlertModalShow(false)
    setAlertModalMessage('')
  }

  const gameStartHandler = game => {
    if (game.vendorIsInspection) {
      setAlertModalShow(true)
      setAlertModalMessage('현재 점검중인 게임입니다.')
      return
    }
    if (apiLoading) return

    setApiLoading(true)
    searchGameUrl({
      agencyName: game.agencyName,
      vendorType: game.vendorType,
      gameKey: game.vendorKey,
    })
      .then(res => {
        const { gameUrl } = res.data
        const idx = notGamePopupVendors.findIndex(item => item === game.vendorName)

        if (idx === -1) {
          setCasinoGameUrl(gameUrl)
          setCasinoGameViewPopupShow(true)
        } else {
          window.open(
            `${gameUrl}`,
            '',
            `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
          )
        }
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
        // setAlertPopupShow(true)
      })
  }

  return (
    <>
      {apiLoading && <WrapperLoading isLoading={apiLoading} />}
      {casinoGameViewPopupShow && (
        <CasinoGameViewPopup
          casinoGameUrl={casinoGameUrl}
          onCloseCasinoGameViewPopupShowHandler={onCloseCasinoGameViewPopupShowHandler}
          gameType="LIVE"
        />
      )}
      {alertModalShow && <CustomAlert info={alertModalMessage} onClickCloseHandler={onClickCloseHandler} />}

      <div className="main_content">
        <div className="titlePic">
          <div className="titlePwrapper">
            <div className="leftZone">
              <span>카지노</span>
            </div>
            <div className="line" />
          </div>
        </div>

        <div className="gameZone casino">
          <div className="gameWrapper">
            <div className="content">
              <div className="leftFrame pc">
                <div className="leftZone aniLogo01">
                  <div className="obj aniLogo" />
                </div>
              </div>
              <div className="rightZone">
                <div className="txt pc">
                  <h2>LIVE CASINO</h2>
                </div>
                <div className="btns">
                  <ul>
                    {liveCasinoVendors.map(item => {
                      return (
                        <li className="casinoFrame">
                          <a
                            className="game_item play_live"
                            onClick={() => {
                              gameStartHandler(item)
                            }}
                          >
                            <div
                              className="model modelA"
                              style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/${item.vendorName
                                  ?.toString()
                                  ?.replace(/(\s*)/g, '')}.png)`,
                              }}
                            />
                            <div className="txt01">
                              <h2 className="mob" style={{ fontSize: '13px' }}>
                                {item.vendorNameKo}
                              </h2>
                              <h2 className="pc" style={{ fontSize: '20px' }}>
                                {item.vendorNameKo}
                              </h2>
                              <span style={{ fontSize: '12px' }}>{item.vendorName}</span>
                            </div>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="obj aniLogo02" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LiveCasinoPage
