import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMiniGameBettingPer, setMiniGameBettingType } from '../../../redux/miniGameBettingSlice'

const MiniGameNtrySpeedKeno = ({ powerBallInfo }) => {
  const dispatch = useDispatch()

  const handleBettingCart = event => {
    const miniGameBettingPer = event.currentTarget.getAttribute('name') || 0
    const miniGameBettingType = event.currentTarget.id
    dispatch(setMiniGameBettingPer(miniGameBettingPer))
    dispatch(setMiniGameBettingType(miniGameBettingType))
  }

  const getMiniGameBettingInfo = state => {
    const { miniGameType } = state

    if (miniGameType === 'NTRY_POWER_BALL') {
      return state.ntryPowerBallInfo
    }
    if (miniGameType === 'NTRY_POWER_LADDER') {
      return state.ntryPowerLadderInfo
    }
    if (miniGameType === 'NTRY_SPEED_KENO') {
      return state.ntrySpeedKenoInfo
    }
    if (miniGameType === 'NTRY_KENO_LADDER') {
      return state.ntryKenoLadderInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_1M') {
      return state.ntryEosPowerBallOneInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_2M') {
      return state.ntryEosPowerBallTwoInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_3M') {
      return state.ntryEosPowerBallThreeInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_4M') {
      return state.ntryEosPowerBallFourInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_5M') {
      return state.ntryEosPowerBallFiveInfo
    }

    return null
  }

  const { miniGameBettingType } = useSelector(state => {
    const { miniGameBetting } = state
    const miniGameBettingInfo = getMiniGameBettingInfo(miniGameBetting)
    if (miniGameBettingInfo) {
      return {
        miniGameBettingType: miniGameBettingInfo.miniGameBettingType,
      }
    }
    return {
      miniGameBettingType: '',
    }
  })

  return (
    <div className="pick_section">
      <div className="pick_list">
        <div className="pick_wrap">
          <h4 className="pick_title">홀/짝</h4>
          <div className="pick_bet grid-2">
            <div
              className={miniGameBettingType === 'SPEEDKENO_LEFT' ? 'btnBet on' : 'btnBet'}
              id="SPEEDKENO_LEFT"
              name={powerBallInfo?.miniGameBettingPer?.speedKenoLeft || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.speedKenoLeft || 0}</span>
              <div className="mark">
                <span className="ic_odd">홀</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'SPEEDKENO_RIGHT' ? 'btnBet on' : 'btnBet'}
              id="SPEEDKENO_RIGHT"
              name={powerBallInfo?.miniGameBettingPer?.speedKenoRight || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.speedKenoRight || 0}</span>
              <div className="mark">
                <span className="ic_even">짝</span>
              </div>
            </div>
          </div>
        </div>

        <div className="pick_wrap">
          <h4 className="pick_title">언더/오버</h4>
          <div className="pick_bet grid-2">
            <div
              className={miniGameBettingType === 'SPEEDKENO_UNDER' ? 'btnBet on' : 'btnBet'}
              id="SPEEDKENO_UNDER"
              name={powerBallInfo?.miniGameBettingPer?.speedKenoUnder || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.speedKenoUnder || 0}</span>
              <div className="mark">
                <span className="ic_under">언더</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'SPEEDKENO_OVER' ? 'btnBet on' : 'btnBet'}
              id="SPEEDKENO_OVER"
              name={powerBallInfo?.miniGameBettingPer?.speedKenoOver || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.speedKenoOver || 0}</span>
              <div className="mark">
                <span className="ic_over">오버</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniGameNtrySpeedKeno
