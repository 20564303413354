import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import parse from 'style-to-object'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { Collapse } from 'react-bootstrap'
import { deleteNoteList, updateNoteStatus, updateNoteStatusAll } from '../../api/customCenter/customCenterApi'
import { fetchNoteList } from '../../redux/customCenterSlice'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat, convertToKstDateTime } from '../../utils/dateTime'
import { fetchNoteSize } from '../../redux/memberInfoSlice'
import { NoteReadStatusEnums } from '../../utils/enums/Operation/NoteEnums'
import WrapperLoading from '../../containers/WrapperLoading'

const NotePage = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const dispatch = useDispatch()
  const [noteParams, setNoteParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setNoteParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [loading, setLoading] = useState(true)

  const [content, setContent] = useState([])
  const { totalElement, apiContent, noReadSize } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.noteInfo.totalElement,
      apiContent: customCenter.noteInfo.content,
      noReadSize: customCenter.noteInfo.noReadSize,
    }
  })

  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.noteKey === item.noteKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchNoteList = async () => {
    try {
      dispatch(fetchNoteList(noteParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchNoteList()
  }, [noteParams])

  const [checkRadio, setCheckRadio] = useState([])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = noteKey => {
    const idx = content.findIndex(item => item.noteKey === noteKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })

      const idx2 = content.findIndex(item => item.noteKey === noteKey)
      if (idx2 !== -1 && content[idx2]?.status === 'NOREAD') {
        updateNoteStatus({
          noteKey: content[idx2].noteKey,
        }).then(res => {
          fetchSearchNoteList()
          dispatch(fetchNoteSize())
        })
      }
    }
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = noteKey => {
    if (deleteLoading) return
    // if (checkRadio.length === 0) {
    //   alert('삭제 할 쪽지를 선택해주세요.')
    //   return
    // }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteNoteList({
        noteKeyList: [noteKey],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
    }
  }
  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  const onReadHandler = () => {
    if (deleteLoading) return

    setDeleteLoading(true)
    updateNoteStatusAll({})
      .then(res => {
        setDeleteLoading(false)
        dispatch(fetchNoteSize())
        fetchSearchNoteList()
      })
      .catch(error => {
        setDeleteLoading(false)
        dispatch(fetchNoteSize())
        fetchSearchNoteList()
      })
  }

  return (
    <div className="main-content">
      <div className="aniFrame">
        <div className="titlePic">
          <div className="titlePwrapper">
            <div className="leftZone">
              <span>쪽지목록</span>
            </div>
            <div className="line" />
          </div>
        </div>
        {deleteLoading && <WrapperLoading isLoading={deleteLoading} />}
        <div className="main_content_wrap main_content_wrap_notice">
          <div className="noticeFrame">
            <div>
              <div className="listZone">
                <table className="styled">
                  <thead>
                    <tr>
                      <th className="ac number" width="10%">
                        No.
                      </th>
                      <th className="ac">제목</th>
                      <th className="ac pc">수신일</th>
                      <th className="ac">상태</th>
                      <th className="ac">삭제</th>
                    </tr>
                  </thead>
                  <tbody>
                    {content.map(item => (
                      <>
                        <tr>
                          <td onClick={() => onClickRowHandler(item.noteKey)}> {item.num}</td>
                          <td onClick={() => onClickRowHandler(item.noteKey)}>
                            <a className="tit" data-id="344228" data-status="1">
                              {item.noteTitle}
                            </a>
                          </td>
                          <td className="pc" onClick={() => onClickRowHandler(item.noteKey)}>
                            {convertToKstByFormat(item.sendDate)}
                          </td>
                          <td className="td-status" onClick={() => onClickRowHandler(item.noteKey)}>
                            <span className="badge badge-light">{NoteReadStatusEnums[item.status]}</span>
                          </td>
                          <td>
                            <button
                              className="btn darken-hover"
                              onClick={() => {
                                onDeleteHandler(item.noteKey)
                              }}
                              type="button"
                            >
                              <span className="icon-iconTrash" />
                            </button>
                          </td>
                        </tr>
                        {item.isVisible && (
                          <>
                            <tr>
                              <td className="ac" colSpan={5} style={{ background: '#dbdbdb' }}>
                                <CustomerCenterContentDetailBox>
                                  {ReactHtmlParser(item.sendContent, { transform: transformFn })}
                                </CustomerCenterContentDetailBox>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>

                {totalElement === 0 && (
                  <div className="tb_empty">
                    <div className="pic">
                      <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
                    </div>
                    <h2>데이터 없음</h2>
                    <h3>Sorry, Empty Data</h3>
                  </div>
                )}
                {totalElement !== 0 && (
                  <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                )}

                <div
                  className="button-wrap flex-end mt0 mb45"
                  onClick={() => {
                    onReadHandler()
                  }}
                  style={{ width: 'max-content', marginLeft: 'auto' }}
                >
                  <a className="btnN redB btn-read-all disabled btn-disabled">
                    <span className="icon-iconCalendar" />
                    <span className="pc">전체읽기</span>
                  </a>
                  {/* <a
                    className="btnN blueB btn-delete-all
										"
                    onClick="deleteAllLetter()"
                  >
                    <span className="icon-iconTrash" />
                    <span className="pc">전체삭제</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotePage

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #000;
  min-height: 45px;
  padding: 20px;
  justify-content: center;
  align-items: center;
`
