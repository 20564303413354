import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FaLock } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { bettingSportsEvent } from '../../../api/game/sportsGameApi'
import BettingCartFormField from '../../../containers/Layout/BettingCartFormField'
import { fetchMemberInfo } from '../../../redux/memberInfoSlice'
import {
  clearBettingCart,
  handleWebSocketSportsBettingCart,
  onClickMaxMinRadioButtonHandler,
  processBettingLoading,
  removeBettingCart,
  resetBettingCart,
} from '../../../redux/sportsGameBettingSlice'
import isLogin from '../../../utils/enums/authority'
import { HermesMainBorder } from '../../../utils/palette'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'

const CrossSportsBettingCart = () => {
  const navigate = useNavigate()
  const [loginCheck, setLoginCheck] = useState(false)

  useEffect(() => {
    setLoginCheck(isLogin())
  }, [isLogin()])

  // redux 사용
  const dispatch = useDispatch()

  const location = useLocation()

  const {
    holdingMoney,
    sportsGameMaxBetting,
    sportsGameMinBetting,
    sportsGameMaxWinning,
    sportsGameMinFolder,
    sportsGameMaxFolder,
    bettingPerMaxCriteria,
    bettingMoney,
    bettingPer,
    bettingCartList,
    maxChecked,
    minChecked,
    bettingCartAlertMessage,
    bettingCartUpdateMessage,
    bonusFolderCount,
    reduceBettingPerForOneFolder,
    memberTotalGameMoney,
    userId,
    memberLevel,
    bettingCartCount,
    noteRedisSize,
    holdemMoneyAmount,
    holdingMemberPoint,
  } = useSelector(state => {
    const { memberInfo, sportsGameBetting } = state

    const { CROSS, SPECIAL, BONUS } = sportsGameBetting.bettingCartList

    const allBettingCartItems = [...CROSS, ...SPECIAL, ...BONUS]

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
      holdemMoneyAmount: memberInfo.holdemMoneyAmount,
      holdingMemberPoint: memberInfo.memberHoldingPoint,
      userId: memberInfo.userId,
      memberLevel: memberInfo.depositLevel,
      sportsGameMaxBetting: memberInfo.sportsGameBettingInfo.sportsGameMaxBetting,
      sportsGameMinBetting: memberInfo.sportsGameBettingInfo.sportsGameMinBetting,
      sportsGameMaxWinning: memberInfo.sportsGameBettingInfo.sportsGameMaxWinning,
      sportsGameMinFolder: memberInfo.sportsGameBettingInfo.sportsGameMinFolder,
      sportsGameMaxFolder: memberInfo.sportsGameBettingInfo.sportsGameMaxFolder,
      bettingPerMaxCriteria: memberInfo.sportsGameBettingInfo.bettingPerMaxCriteria,
      bettingMoney: sportsGameBetting.bettingMoney,
      bettingPer: sportsGameBetting.bettingPer,
      bettingCartList: allBettingCartItems,
      maxChecked: sportsGameBetting.maxChecked,
      minChecked: sportsGameBetting.minChecked,
      bettingCartAlertMessage: sportsGameBetting.bettingCartAlertMessage,
      bettingCartUpdateMessage: sportsGameBetting.bettingCartUpdateMessage,
      bonusFolderCount: BONUS.length,
      reduceBettingPerForOneFolder: sportsGameBetting.reduceBettingPerForOneFolder,
      bettingCartCount: allBettingCartItems.length,
      noteRedisSize: memberInfo.noteRedisSize,
    }
  })

  const socketRef = useRef(null)
  const intervalIdRef = useRef(null)
  const bettingCartListRef = useRef(bettingCartList)

  const handleSocketMessage = useCallback(
    event => {
      const message = JSON.parse(event.data)
      dispatch(handleWebSocketSportsBettingCart(message))
    },
    [dispatch],
  )
  const handleSocketError = useCallback(event => {
    console.error('WebSocket error observed:', event)
    // logout 시키기
  }, [])

  useEffect(() => {
    bettingCartListRef.current = bettingCartList
  }, [bettingCartList])

  // 웹소켓 연결을 처음에만 생성합니다.
  useEffect(() => {
    if (location.pathname !== '/cross-sports') {
      return
    }

    // 웹소켓 인스턴스 생성
    const userKey = decodeAccessToken().userKey || ''
    const newSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_SPORTS_BETTING_CART_URL}?userKey=${userKey}`)
    socketRef.current = newSocket

    // 웹소켓 이벤트 리스너 등록
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('error', handleSocketError)

    return () => {
      // 컴포넌트 언마운트 시 웹소켓 연결 종료
      if (socketRef.current) {
        socketRef.current.close()
      }
    }
  }, [location, handleSocketMessage, handleSocketError])

  // bettingCartList가 변경될 때마다 새로운 interval을 설정합니다.
  useEffect(() => {
    // 이전 interval 해제
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    // 일정한 주기로 파라미터를 서버로 보내기
    const id = setInterval(() => {
      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN &&
        bettingCartListRef.current.length > 0
      ) {
        const sportsMarketOddKeyList = bettingCartListRef.current.map(item => item.selectedSportsMarketOddKey)
        socketRef.current.send(JSON.stringify({ sportsMarketOddKeyList }))
      }
    }, 3000) // 1초마다 파라미터 전송

    intervalIdRef.current = id

    return () => {
      // 컴포넌트 언마운트 시 타이머 해제
      clearInterval(intervalIdRef.current)
    }
  }, [])

  const [inputBettingMoney, setInputBettingMoney] = useState(Number(bettingMoney) || 0)
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니

    let resultBettingMoney = inputRealMoney

    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
    if (maxChecked) {
      // 최대가 체크가 되어 있을때
      resultBettingMoney = Math.floor(sportsGameMaxWinning / bettingPer)
      if (resultBettingMoney > sportsGameMaxBetting) {
        // 배팅머니가 최대 배팅금액을 넘어 설때
        resultBettingMoney = sportsGameMaxBetting
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
      } else {
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
      }
    } else {
      if (inputRealMoney > sportsGameMaxBetting) {
        // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
        resultBettingMoney = sportsGameMaxBetting
        // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
        if (resultExpectMoney > sportsGameMaxBetting) {
          resultBettingMoney = Math.floor(sportsGameMaxBetting / bettingPer) // 비율에 따른 최대 배팅금액
          resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
        }
      }

      if (resultExpectMoney > sportsGameMaxWinning) {
        // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
        resultBettingMoney = Math.floor(sportsGameMaxWinning / bettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
      }
    }

    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))
    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [maxChecked, inputBettingMoney, bettingPer])

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니
    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
    if (maxChecked) {
      // 최대가 체크가 되어 있을때
      resultBettingMoney = Math.floor(sportsGameMaxWinning / bettingPer)
      if (resultBettingMoney > sportsGameMaxBetting) {
        // 배팅머니가 최대 배팅금액을 넘어 설때
        resultBettingMoney = sportsGameMaxBetting
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
      } else {
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
      }
    } else {
      if (inputRealMoney > sportsGameMaxBetting) {
        // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
        resultBettingMoney = sportsGameMaxBetting
        // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
        if (resultExpectMoney > sportsGameMaxWinning) {
          resultBettingMoney = Math.floor(sportsGameMaxWinning / bettingPer) // 비율에 따른 최대 배팅금액
          resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
        }
      }
      if (resultExpectMoney > sportsGameMaxWinning) {
        // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
        resultBettingMoney = Math.floor(sportsGameMaxWinning / bettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(bettingPer * 100)) / 100)
      }
    }
    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))
    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [inputBettingMoney, bettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney
    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue
      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }
      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  useEffect(() => {
    if (!maxChecked) {
      setInputBettingMoney(0)
    }

    if (!minChecked) {
      setInputBettingMoney(0)
    }

    if (minChecked) {
      setInputBettingMoney(sportsGameMinBetting?.toString().replace(commonReg2, ','))
    }
  }, [maxChecked, minChecked])

  // 정정 버튼 클릭 핸들러
  const onClickResetMoneyHandler = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)

    dispatch(
      onClickMaxMinRadioButtonHandler({
        maxChecked: false,
        minChecked: false,
      }),
    )
  }

  // 하나 삭제
  const onCloseSportsBettingCart = sportsMarketKey => {
    dispatch(removeBettingCart(sportsMarketKey))
  }
  // 전체 삭제
  const onClearSportsBettingCart = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(clearBettingCart())
  }

  const onSubmit = e => {
    if (apiFlag) return
    setApiFlag(true)

    if (bettingCartList.length === 0) {
      alert('배팅할 마켓을 먼저 선택해주세요.')
      setApiFlag(false)
      return
    }

    if (bettingCartList.some(item => item?.isLocked === true)) {
      alert('배팅 불가인 옵션이 있습니다.')
      setApiFlag(false)
      return
    }

    if (bettingCartList.length < sportsGameMinFolder) {
      alert(`최소 ${sportsGameMinFolder}폴더부터 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    // 보너스 배당 제외
    if (bettingCartList.length - bonusFolderCount > sportsGameMaxFolder) {
      alert(`최대 ${sportsGameMaxFolder}폴더까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    if (bettingPer > bettingPerMaxCriteria) {
      alert(`최대 ${bettingPerMaxCriteria}배당까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }
    if (Number(holdingMoney) < Number(bettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(sportsGameMinBetting)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }
    if (Number(inputBettingMoney) > Number(sportsGameMaxBetting)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }
    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(sportsGameMaxWinning)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    let confirmMessage = '배팅하시겠습니까?'

    if (bettingCartList.length === 1 && reduceBettingPerForOneFolder > 0) {
      confirmMessage = `단폴 배팅시 현재 배당(${bettingPer})의 ${reduceBettingPerForOneFolder}%만큼 배당 삭감됩니다. \n배팅하시겠습니까?`
    }

    if (window.confirm(confirmMessage)) {
      // 배팅 로딩
      dispatch(
        processBettingLoading({
          loading: true,
          loadingInfo: '배팅 처리중입니다.',
        }),
      )
      const body = {
        bettingAmount: Number(inputBettingMoney?.toString().replaceAll(',', '')),
        bettingResultAmount: Number(expectMoney?.toString().replaceAll(',', '')),
        totalOddValue: Number(bettingPer),
        detailSportsBettingList: bettingCartList.map(cartItem => ({
          ccKr: cartItem.ccKr,
          startAt: cartItem.startAt,
          leagueName: cartItem.leagueName,
          homeName: cartItem.homeName,
          awayName: cartItem.awayName,
          sportsEventId: cartItem.sportsEventId,
          sportsType: cartItem.sportsType,
          marketId: cartItem.marketId,
          marketName: cartItem.marketName,
          pointName: cartItem.name,
          homeOddName: cartItem.homeOddName,
          homeOddNameKr: cartItem.homeOddNameKr,
          homeOddValue: Number(cartItem.homeOddValue),
          awayOddName: cartItem.awayOddName,
          awayOddNameKr: cartItem.awayOddNameKr,
          awayOddValue: Number(cartItem.awayOddValue),
          drawOddName: cartItem.drawOddName,
          drawOddNameKr: cartItem.drawOddNameKr,
          drawOddValue: Number(cartItem.drawOddValue),
          selectedSportsMarketOddKey: cartItem.selectedSportsMarketOddKey,
          selectedOddName: cartItem.selectedOddName,
          selectedOddNameKr: cartItem.selectedOddNameKr,
          selectedOddValue: Number(cartItem.selectedOddValue),
        })),
      }

      bettingSportsEvent(body)
        .then(res => {
          dispatch(resetBettingCart())
          dispatch(fetchMemberInfo())
          onClickResetMoneyHandler()
          setApiFlag(false)
          dispatch(
            processBettingLoading({
              loading: false,
              loadingInfo: '',
            }),
          )
          alert('배팅이 정상적으로 이루어졌습니다.')
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          switch (errorCode) {
            case 'SYSTEM-1017':
              alert(`${message}`)
              break
            case 'SPORTS-1010':
              alert(`${message}`)
              break
            case 'MINIGAME-1003':
              alert(`${message}`)
              break
            case 'SPORTS-1030':
              alert('스포츠 배팅 규정에 의해 배팅 거절되었습니다.')
              break
            case 'SPORTS-1031':
              alert('스포츠 배팅 규정에 의해 배팅 거절되었습니다.')
              break
            case 'SPORTS-1032':
              alert('스포츠 배팅 규정에 의해 배팅 거절되었습니다.')
              break
            case 'SPORTS-1033':
              alert('선택하신 경기에 변동된 배당이 있습니다.\n다시 시도해주세요.')
              break
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            default:
              alert('배팅이 실패했습니다.')
          }
          setApiFlag(false)
          onClickResetMoneyHandler()
          dispatch(
            processBettingLoading({
              loading: false,
              loadingInfo: '',
            }),
          )
        })
    } else {
      setApiFlag(false)
    }
  }

  useEffect(() => {
    // 다른페이지 배팅카트 초기화
    if (location.pathname !== '/cross-sports') {
      onClearSportsBettingCart()
    }
  }, [location])

  const [isMobileBettingCartShow, setIsMobileBettingCartShow] = useState(false)

  return (
    <>
      <div className={isMobileBettingCartShow ? 'decideZone' : 'decideZone pc'}>
        <div className="head-top mob">
          <div className="">베팅카트</div>
          <span
            className="close icon-delet"
            onClick={() => {
              setIsMobileBettingCartShow(false)
            }}
          />
        </div>
        <div className="content">
          {/* <div className="tabBar">
            <div className="tab">
              <ul>
                <li className="activeTab" style={{ width: '100%' }}>
                  베팅카트 <span style={{ display: 'none' }}>(0)</span>
                </li>
              </ul>
            </div>
          </div> */}

          <div className="listFrame">
            <div className="listZone">
              <div className="deleteAll">
                {/* <div className="refreshZone" onclick="checkBetCartRate()">
                  <span className="icon-loop2" />
                  새로고침
                </div> */}
                <div className="deleteAll01" onClick={onClearSportsBettingCart} style={{ marginLeft: 'auto' }}>
                  모두 삭제{' '}
                  <div className="xxAll">
                    <span className="icon-delet" />
                  </div>
                </div>
              </div>

              <div className="listScroll">
                <div className="listConent">
                  {bettingCartList.map(item => {
                    return (
                      <>
                        <div className="list" style={{ position: 'relative' }}>
                          {item?.isLocked && (
                            <BettingWhatWrapOverlay>
                              <BettingWhatLockIcon />
                            </BettingWhatWrapOverlay>
                          )}
                          <div className="title ">
                            <div className="txtFrame">
                              <span className="icon-icon-icconNO" />
                              {item.homeName ? `${item.homeName} vs ${item.awayName}` : '보너스 배당'}
                            </div>
                            <div className="tooltip">{`${item.homeName} vs ${item.awayName}`}</div>
                            <div
                              className="xx"
                              onClick={() => {
                                onCloseSportsBettingCart({
                                  type: item.type,
                                  sportsMarketKey: item.sportsMarketKey,
                                })
                              }}
                              style={{ zIndex: '102' }}
                            >
                              <span className="icon-iconCross" />
                            </div>
                          </div>
                          <div className="content">
                            <div className="txtList txtList01">
                              <div className="leftZone">{item.marketName}</div>
                              <div className="rightZone redTxt" />
                            </div>
                            <div className="txtList">
                              <div className="leftZone">
                                {item.selectedOddNameKr}{' '}
                                {item.awayPointName ? `(${item.awayPointName})` : <>{item.name && `(${item.name})`}</>}
                              </div>
                              <div className="rightZone">{item.selectedOddValue}</div>
                            </div>
                          </div>
                          <div className="mask" />
                        </div>
                      </>
                    )
                  })}
                </div>

                <Form
                  onSubmit={onSubmit}
                  initialValues={{
                    bettingPer,
                    maxChecked,
                    minChecked,
                    inputBettingMoney,
                  }}
                >
                  {({ handleSubmit, form: { reset } }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="moneyZone">
                        <div className="list">
                          <div className="title">
                            선택폴더
                            <div>
                              <span className="bet-cart-cnt">{bettingCartCount}</span> 폴더
                            </div>
                          </div>
                          <div className="content">
                            <div className="txtList">
                              <div className="leftZone">보유금액</div>
                              <div className="rightZone">
                                <span className="userCashAmount">
                                  {holdingMoney?.toString().replace(commonReg2, ',')}
                                </span>{' '}
                                원
                              </div>
                            </div>

                            <div className="txtList pt10">
                              <div className="leftZone">배당률 합계</div>
                              <div className="rightZone bet-odds">{bettingPer || 0}</div>
                            </div>
                            <div className="txtList">
                              <div className="leftZone">당첨 예상금액</div>
                              <div className="rightZone bet-money">
                                <span className="cash-number">
                                  {expectMoney?.toString().replace(commonReg2, ',') || 0}
                                </span>{' '}
                                원
                              </div>
                            </div>
                            <div className="txtList">
                              <div className="leftZone">베팅 금액</div>
                              <Field
                                name="inputBettingMoney"
                                component={BettingCartFormField}
                                isAboveError={false}
                                wrapperClassName="betting-amount-input"
                              >
                                {({ input }) => (
                                  <input
                                    type="text"
                                    name="inputBettingMoney"
                                    value={inputBettingMoney}
                                    // placeholder={inputBettingMoney}
                                    className="input_content"
                                    placeholder="금액을 입력해주세요."
                                    onChange={e => {
                                      const value = e.target.value.trim()
                                      const numbersOnly = value.match(/\d+/g)?.join('')

                                      if (!numbersOnly) {
                                        setInputBettingMoney('0')
                                      } else {
                                        setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                                      }

                                      input.onChange(e)
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="content01" style={{ marginTop: '5px' }}>
                            <div className="moneyBtnFrame">
                              <div className="moneyBtns">
                                <div className="btnD" onClick={() => onClickMoneyHandler('5000')}>
                                  +5천
                                </div>
                                <div className="btnD" onClick={() => onClickMoneyHandler('10000')}>
                                  +1만
                                </div>
                                <div className="btnD" onClick={() => onClickMoneyHandler('50000')}>
                                  +5만
                                </div>
                                <div className="btnD" onClick={() => onClickMoneyHandler('100000')}>
                                  +10만
                                </div>
                                <div className="btnD" onClick={() => onClickMoneyHandler('500000')}>
                                  +50만
                                </div>
                                <div className="btnD greenBtn01" onClick={() => onClickMoneyHandler('')}>
                                  최대
                                </div>
                              </div>
                              <div className="hint" style={{ display: 'none' }}>
                                배당, 베팅항목 및 유효성이 변경되었습니다
                              </div>
                              <div className="btnFrame">
                                <div className="btnA greyBtn01" onClick={onClickResetMoneyHandler}>
                                  금액리셋
                                </div>
                                <button className="btnA" type="submit">
                                  <span className="icon-icconCHECK" />
                                  배팅하기
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Form>

                <div className="result">
                  <div className="txtList">
                    <div className="leftZone">최소 배팅금액</div>
                    <div className="rightZone single-max-cash-win">
                      {' '}
                      <span className="number">
                        {sportsGameMinBetting?.toString().replace(commonReg2, ',') || 0}
                      </span>{' '}
                      &nbsp;원{' '}
                    </div>
                  </div>
                  <div className="txtList">
                    <div className="leftZone">최대 배팅금액</div>
                    <div className="rightZone multi-min-cash-bet">
                      {' '}
                      <span className="number">
                        {sportsGameMaxBetting?.toString().replace(commonReg2, ',') || 0}
                      </span>{' '}
                      &nbsp;원
                    </div>
                  </div>
                  <div className="txtList">
                    <div className="leftZone">최대 당첨금액</div>
                    <div className="rightZone multi-max-cash-bet">
                      {' '}
                      <span className="number">
                        {sportsGameMaxWinning?.toString().replace(commonReg2, ',') || 0}
                      </span>{' '}
                      &nbsp;원{' '}
                    </div>
                  </div>
                  <div className="txtList">
                    <div className="leftZone">최대 배팅배당</div>
                    <div className="rightZone multi-max-cash-win">
                      {' '}
                      <span className="number">
                        {bettingPerMaxCriteria?.toString().replace(commonReg2, ',') || 0}
                      </span>{' '}
                      &nbsp;배{' '}
                    </div>
                  </div>
                </div>

                <div style={{ display: 'block', height: '30px' }}>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="open-cart"
        className="mob ui-draggable ui-draggable-handle"
        onClick={() => {
          setIsMobileBettingCartShow(true)
        }}
      >
        <span className="icon-cart" />
        <b>{bettingCartCount}</b>
      </div>
    </>
  )
}

export default CrossSportsBettingCart

const BettingWhatWrapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
`

const BettingWhatLockIcon = styled(FaLock)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`
