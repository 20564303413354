import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import MainContentBanner from './MainContentBanner'
import { searchGameUrl, searchLiveVendor, searchSlotVendor } from '../../../api/game/gameApi'
import isLogin from '../../../utils/enums/authority'
import SignInPopup from '../../Popup/SignIn/SignInPopup'
import SignUpPopup from '../../Popup/SignUp/SignUpPopup'
import CasinoGameViewPopup from '../../CasinoGame/CasinoGameViewPopup'
import SlotCasinoGameModal from '../../CasinoGame/SlotCasinoGameModal'
import CustomAlert from '../../CustomAlert'

const MainSlotCasino = ({ apiLoading = false, setApiLoading = () => {}, active = false }) => {
  const [slotCasinoVendors, setSlotCasinoVendors] = useState([])
  const [vendorName, setVendorName] = useState('')
  const [vendorType, setVendorType] = useState('')
  const [agencyName, setAgencyName] = useState('')
  const [slotCasinoGameModalShow, setSlotCasinoGameModalShow] = useState(false)

  const [noteAlertShow, setNoteAlertShow] = useState(false)

  const { noteRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
    }
  })

  const onCloseSlotCasinoGameModal = () => {
    setSlotCasinoGameModalShow(false)
  }

  useEffect(() => {
    searchSlotVendor().then(res => {
      setSlotCasinoVendors(res.data.vendors)
    })
  }, [])

  /**
   * 로그인 , 회원가입 부분
   */
  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  // 회원가입 -> 로그인 모달
  const onShowSignInModal = () => {
    setSignUpModalShow(false)
    setSignInModalShow(true)
  }

  // 로그인 -> 회원가입 모달
  const onShowSignUpModal = () => {
    setSignInModalShow(false)
    setSignUpModalShow(true)
  }

  const casinoModalShowHandler = vendor => {
    if (!isLogin()) {
      setSignInModalShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (vendor.vendorIsInspection) return

    setVendorName(vendor.vendorName)
    setVendorType(vendor.vendorType)
    setAgencyName(vendor.agencyName)
    setSlotCasinoGameModalShow(true)
  }

  return (
    <>
      {signInModalShow && (
        <SignInPopup
          visible={signInModalShow}
          onCloseHandler={onCloseSignInModal}
          onShowSignUpModal={onShowSignUpModal}
        />
      )}
      {signUpModalShow && (
        <SignUpPopup
          visible={signUpModalShow}
          onCloseHandler={onCloseSignUpModal}
          onShowSignInModal={onShowSignInModal}
        />
      )}
      {slotCasinoGameModalShow && (
        <SlotCasinoGameModal
          visible={slotCasinoGameModalShow}
          onClose={onCloseSlotCasinoGameModal}
          vendorName={vendorName}
          agencyName={agencyName}
          vendorType={vendorType}
        />
      )}

      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}

      <div className={active ? 'gameZone slot active' : 'gameZone slot'}>
        <div className="title pc">
          <span className="icon-iiconSlote" />
          <div className="txt">
            SLOT <h3>LIST</h3>
          </div>
        </div>
        <div className="content">
          <ul>
            {slotCasinoVendors.map(item => {
              return (
                <li className="casinoFrame fadeInUp">
                  <a className="game_item" onClick={() => casinoModalShowHandler(item)}>
                    <div className="bg">
                      <div
                        className="model modelA"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/${item.vendorName
                            ?.toString()
                            ?.replace(/(\s*)/g, '')}.png)`,
                        }}
                      />
                      <div className="txt">
                        <h2 style={{ fontSize: `${isMobile ? '13px' : '20px'}` }}>{item.vendorNameKo}</h2>
                        <span style={{ fontSize: '12px' }}>{item.vendorName}</span>
                      </div>
                    </div>
                    <div className="mask">
                      <div className="inner">
                        <div className="goldL01Btn btnP">게임시작</div>
                      </div>
                    </div>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default MainSlotCasino
