import styled from 'styled-components'
import { GiMoneyStack } from 'react-icons/gi'
import {
  colorFormButtonText,
  colorMoneyInfo,
  colorMoneySvg,
  colorWalletSvg,
  colorMoneyFormButton,
  colorMoneyFormHoverButton,
  colorMainText,
  HermesMainBorder,
} from '../../utils/palette'
import { Button } from './Button'
import { FormFieldButton, FormInputWrap, FormGroupField } from './form/FormElements'
import SilverButtonH40 from './SilverButton'
import { ButtonH40 } from './GoldButton'
import { MoneyFormModifyButtomInfo } from './MoneyCustomElement'

export const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden !important;
  outline: 0;
`
export const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  width: 100%;
`

export const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 100vw;
  background: #16171a !important;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
`

export const SignUpModalHeader = styled.div`
  position: fixed;
  background: rgb(22, 23, 26) !important;
  top: 0;
  // border 양옆 1px 생각해서
  width: calc(100% - 2px);
  background-color: black;

  height: 60px;
  // border-bottom: 1px solid #7b808c;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 980;
`

// 로고
export const SignUpModalHeaderLogo = styled.img`
  width: auto;
  height: 35px;
`

// 닫기 아이콘
export const SignUpModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

export const ContentWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px;
`

export const ContentRowThirdWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px; /* 혹은 다른 높이 값으로 설정 */
  margin-bottom: 10px;
`

export const ContentRowSecondWrap = styled.div`
  margin-top: 30px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: solid 1px red;
  span {
    color: #fff;
  }
`

export const ContentRowBig = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-weight: 700 !important;
    height: 62px;
    width: 80px;
    &:not(:last-child) {
      margin-right: 10px;
    }

    ${({ selectedIndex }) =>
      selectedIndex !== null &&
      `
      &:nth-child(${selectedIndex + 1}) {
        border-bottom: solid 1px ${HermesMainBorder};
      }
    `}
  }
`
export const ContentRowSmall = styled.div`
  width: 50%;
  color: #fff;
  display: flex;
  align-items: center; /* Optional: to align items vertically in the center */
  justify-content: space-between;
  padding-left: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    font-size: 12px;
    font-weight: 500;
  }
  & > img {
    margin-left: auto;
  }
`

export const ContentRowWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  margin-top: 15px;
  padding-left: 25px;
  background: #1e1e21;
  border-radius: 6px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`

// modal body content
export const SignUpModalContentWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  position: absolute;
  top: 60px;
  padding-bottom: 5px;
  font-weight: 300;
`

export const ContentInfo = styled.div`
  width: 100%;
`

export const CustomButtonInfo = styled(MoneyFormModifyButtomInfo)`
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 700 !important;
`

export const MoneyButton = styled.button`
  width: 90%;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

export const MoneyFormButton = styled.button`
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 2px;
  background: #343438;
`

export const CustomCenterFormField = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`

export const CustomerCenterFormGroup = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
`

export const CustomerCenterFormLabel = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
`

export const TableBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;
`
