import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {
  addLiveBettingCart,
  fetchLiveSportsEvents,
  fetchSportsBonusFolder,
  handleWebSocketLiveSportsEvent,
  onClickHideLiveSportsEventMarket,
  setSportsType,
} from '../../../redux/sportsGameBettingSlice'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { HermesMainBorder } from '../../../utils/palette'
import { decodeAccessToken } from '../../../utils/token'

const LiveSportsMainFrame = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [showButton, setShowButton] = useState(false)
  const chooseZoneRef = useRef(null)

  const handleScroll = () => {
    const limitTop = 50
    if (chooseZoneRef.current.scrollTop > limitTop) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  const scrollToTop = () => {
    if (chooseZoneRef.current) {
      chooseZoneRef.current.scroll({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const chooseZone = chooseZoneRef.current
    chooseZone.addEventListener('scroll', handleScroll)

    return () => {
      chooseZone.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function createKey(item) {
    return `${item.sportsEventId}-${item.marketId}-${item.selectedOddName}-${item.name}`
  }

  function isActive(activeSportsMarketKeySet, sportsEventId, marketId, selectedOddName, name) {
    const key = createKey({ sportsEventId, marketId, selectedOddName, name })
    return activeSportsMarketKeySet.has(key)
  }

  const { loading, loadingInfo, liveSportsEventList, sportsTypeInfo, sportsType } = useSelector(state => {
    const { sportsGameBetting } = state

    const { LIVE } = sportsGameBetting.bettingCartList

    const activeLiveSportsMarketKeySet = new Set([...LIVE.map(item => createKey(item))])

    const liveSportsTypeList = new Set(['ALL', 'BASKETBALL', 'BASEBALL', 'VOLLEYBALL', 'ICE_HOCKEY', 'E_SPORTS'])
    let updatedSportsTypeInfo = sportsGameBetting.sportsTypeInfo

    // 'ALL'을 제외한 liveSportsTypeList에 포함된 sportsType들을 필터링
    const filteredSportsTypeInfo = updatedSportsTypeInfo.filter(item => liveSportsTypeList.has(item.sportsType))

    // 'ALL'의 count 값을 'BASKETBALL', 'BASEBALL', 'VOLLEYBALL', 'ICE_HOCKEY', 'E_SPORTS'의 count 합으로 대체
    const restCountSum = filteredSportsTypeInfo
      .filter(item => item.sportsType !== 'ALL')
      .reduce((sum, item) => sum + item.count, 0)

    updatedSportsTypeInfo = filteredSportsTypeInfo.map(item => {
      if (item.sportsType === 'ALL') {
        return { ...item, count: restCountSum }
      }
      return item
    })

    return {
      sportsType: sportsGameBetting.selectedSportsType,
      loading: sportsGameBetting.loading,
      loadingInfo: sportsGameBetting.loadingInfo,
      sportsTypeInfo: updatedSportsTypeInfo,
      liveSportsEventList: sportsGameBetting.liveSportsEventList.map(event => ({
        ...event,
        markets: event.markets.map(market => ({
          ...market,
          isHomeActive: isActive(
            activeLiveSportsMarketKeySet,
            event.sportsEventId,
            market.marketId,
            market.homeOddName,
            market.marketName.includes('승무패') ? '' : market.name,
          ),
          isAwayActive: isActive(
            activeLiveSportsMarketKeySet,
            event.sportsEventId,
            market.marketId,
            market.awayOddName,
            market.marketName.includes('승무패') ? '' : market.name,
          ),
          isDrawActive: isActive(
            activeLiveSportsMarketKeySet,
            event.sportsEventId,
            market.marketId,
            'Draw',
            market.marketName.includes('승무패') ? '' : market.name,
          ),
        })),
      })),
    }
  })

  // const [sportsType, setSportsType] = useState(null)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(30)

  useEffect(() => {
    dispatch(fetchSportsBonusFolder())
  }, [])

  const params = useMemo(() => {
    return {
      sportsType,
      page,
      size,
    }
  }, [sportsType, page, size])

  const onClickSportsEventType = info => {
    dispatch(setSportsType(info))
    setPage(0)
  }

  const socketRef = useRef(null)
  const intervalIdRef = useRef(null)

  const handleSocketOpen = useCallback(() => {
    // 웹소켓 연결 후 초기 데이터 요청
    dispatch(fetchLiveSportsEvents(params))
  }, [dispatch])

  const handleSocketMessage = useCallback(
    event => {
      const message = JSON.parse(event.data)
      dispatch(handleWebSocketLiveSportsEvent(message))
    },
    [dispatch],
  )

  const handleSocketError = useCallback(event => {
    console.error('WebSocket error observed:', event)
    // logout 시키기
  }, [])

  useEffect(() => {
    // 웹소켓 인스턴스 생성
    if (location.pathname !== '/live-sports') {
      return
    }

    const userKey = decodeAccessToken().userKey || ''
    const newSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_LIVE_SPORTS_URL}?userKey=${userKey}`)
    socketRef.current = newSocket

    // 웹소켓 이벤트 리스너 등록
    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('error', handleSocketError)

    return () => {
      // 컴포넌트 언마운트 시 웹소켓 연결 종료
      if (socketRef.current) {
        socketRef.current.close()
      }
    }
  }, [location, handleSocketOpen, handleSocketMessage, handleSocketError])

  // params가 변경될 때마다 새로운 interval을 설정합니다.
  useEffect(() => {
    // 이전 interval 해제
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      dispatch(fetchLiveSportsEvents(params))
    }

    // 일정한 주기로 파라미터를 서버로 보내기
    const id = setInterval(() => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify(params))
      }
    }, 1000) // 1초마다 파라미터 전송

    intervalIdRef.current = id

    return () => {
      // 컴포넌트 언마운트 시 타이머 해제
      clearInterval(intervalIdRef.current)
    }
  }, [params])

  const onClickHideSportsEventMainMarketHandler = (sportsEventId, visibled) => {
    dispatch(onClickHideLiveSportsEventMarket({ sportsEventId, isVisible: visibled }))
  }

  const onClickBetting = (type, item) => {
    dispatch(addLiveBettingCart({ type, item }))
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case marketName.includes('승무패') || marketName.includes('승패'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
            alt=""
            style={{ width: '10px', height: '7px' }}
          />
        )
      case marketName.includes('핸디'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('언더'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('오버'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      default:
    }
  }

  const getSportsTypeIconUrl = type => {
    switch (type) {
      case 'SOCCER':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-soccer.png`} alt="" />
      case 'BASKETBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-basketball.png`} alt="" />
      case 'BASEBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-baseball.png`} alt="" />
      case 'VOLLEYBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-volleyball.png`} alt="" />
      case 'ICE_HOCKEY':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-ice-hockey.png`} alt="" />
      case 'HANDBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-handball.png`} alt="" />
      case 'TENNIS':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-tennis.png`} alt="" />
      case 'AMERICAN_FOOTBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-football.png`} alt="" />
      case 'TABLE_TENNIS':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-table-tennis.png`} alt="" />
      case 'BOXING':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-boxing.png`} alt="" />
      case 'E_SPORTS':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-esports.png`} alt="" />
      default:
        return <div style={{ width: '16px', height: '23px' }} />
    }
  }

  return (
    <>
      <div className="chooseFrame">
        <div className="tabZone">
          <div className="tabFrame">
            <ul>
              {sportsTypeInfo.map(item => {
                return (
                  <li
                    className={
                      item.sportsType === sportsType || sportsType === 'ALL'
                        ? 'item category-all active'
                        : 'item category-all'
                    }
                    onClick={() => {
                      onClickSportsEventType(item.sportsType === 'ALL' ? null : item.sportsType)
                    }}
                    key={`${item.sportsType}-${item.typeKr}`}
                  >
                    {item.sportsType !== 'ALL' && (
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/img/sports/sportsType/side-${item.sportsType?.toLowerCase()}.png`}
                        width="20"
                        height="20"
                        alt=""
                      />
                    )}

                    <div className="txt" style={{ marginLeft: '2px' }}>
                      {item.typeKr}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="chooseZone" ref={chooseZoneRef}>
          <div className="sportZone">
            <div className="folder-wrap">
              <div id="fixture-list" className="pageSport">
                <div className="cubeZone" id="game-folders">
                  <div id="top-hidden" />

                  {liveSportsEventList.map(sportsEvent => {
                    return (
                      <div className="cube" style={{ marginTop: '10px' }}>
                        <li
                          className={sportsEvent.isVisible ? 'opened switcher titleS' : 'switcher titleS'}
                          onClick={() => {
                            onClickHideSportsEventMainMarketHandler(sportsEvent.sportsEventId, !sportsEvent.isVisible)
                          }}
                        >
                          <div className="left">
                            <img
                              src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${sportsEvent.sportsType}.png`}
                              height="20"
                              width="20"
                              alt=""
                            />

                            {sportsEvent.ccImage && <img src={sportsEvent.ccImage} height="20" width="20" alt="" />}

                            <span style={{ color: '#000', minWidth: 'max-content' }}>{sportsEvent.ccKr}</span>

                            <span className="icon-iconGo" />

                            {sportsEvent.leagueImage && (
                              <img src={sportsEvent.leagueImage} height="20" width="20" alt="" />
                            )}

                            {sportsEvent.leagueName}
                          </div>

                          <div className="right">
                            <div className="time mob">{convertToKstByFormat(sportsEvent?.startAt, 'MM-DD HH:mm')}</div>
                            <div className="arrowT arrowGO">
                              {sportsEvent.isVisible ? (
                                <span className="icon-iconARdownA" />
                              ) : (
                                <span className="icon-iconARupA" />
                              )}
                            </div>
                          </div>
                        </li>
                        <div className="switchee">
                          {sportsEvent.markets.map((market, index) => {
                            if (market.marketStop) return

                            return (
                              <div
                                className="listS"
                                key={`${market.sportsMarketKey}-${market.sportsMarketPointKey}`}
                                style={{ position: 'relative' }}
                              >
                                {sportsEvent.isLocked && (
                                  <SportsEventContentItemOverlay>
                                    <SportsEventContentItemLockIcon />
                                  </SportsEventContentItemOverlay>
                                )}
                                <div className="leftZone pc">
                                  <div className="time">{convertToKstByFormat(sportsEvent.startAt, 'MM-DD HH:mm')}</div>
                                  <div className="gameName">{market?.marketName}</div>
                                </div>
                                <div className="rightZone">
                                  <div className="chooseBtn">
                                    <div
                                      className={
                                        market.isHomeActive
                                          ? 'nameA bet-pick home-pick pick'
                                          : 'nameA bet-pick home-pick'
                                      }
                                      onClick={() => {
                                        const type = 'LIVE'

                                        const item = {
                                          sportsEventId: sportsEvent.sportsEventId,
                                          sportsType: sportsEvent.sportsType,
                                          sportsMarketKey: market.sportsMarketKey,
                                          marketId: market.marketId,
                                          leagueName: sportsEvent.leagueName,
                                          ccKr: sportsEvent.ccKr,
                                          startAt: sportsEvent.startAt,
                                          homeName: sportsEvent.homeName,
                                          awayName: sportsEvent.awayName,
                                          marketName: market.marketName,
                                          name: market.name,
                                          homeSportsMarketOddKey: market.homeSportsMarketOddKey,
                                          homeOddName: market.homeOddName,
                                          homeOddNameKr: market.homeOddNameKr,
                                          homeOddValue: market.homeOddValue,
                                          awaySportsMarketOddKey: market.awaySportsMarketOddKey,
                                          awayOddName: market.awayOddName,
                                          awayOddNameKr: market.awayOddNameKr,
                                          awayOddValue: market.awayOddValue,
                                          drawSportsMarketOddKey: market.drawSportsMarketOddKey,
                                          drawOddName: market.drawOddName,
                                          drawOddNameKr: market.drawOddNameKr,
                                          drawOddValue: market.drawOddValue,
                                          selectedSportsMarketPointKey: market.sportsMarketPointKey,
                                          selectedSportsMarketOddKey: market.homeSportsMarketOddKey,
                                          selectedOddName: market.homeOddName,
                                          selectedOddNameKr: market.homeOddNameKr,
                                          selectedOddValue: market.homeOddValue,
                                        }

                                        onClickBetting(type, item)
                                      }}
                                    >
                                      <div className="txtFrame" style={{ width: '50%' }}>
                                        <img
                                          className="teamImg"
                                          src={`${process.env.PUBLIC_URL}/img/sports/default.png`}
                                          alt=""
                                        />
                                        <SlimScrollDiv>
                                          <SlimScrollInnerDiv>
                                            <h2>{sportsEvent.homeName}</h2>
                                          </SlimScrollInnerDiv>
                                        </SlimScrollDiv>
                                      </div>
                                      <div className="txt">
                                        {getMarketIconComponent(market.marketName, market.homeOddNameKr)}
                                        <span className="icon-state" />
                                        <h3 className="odds">{market?.homeOddValue}</h3>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        market.isDrawActive
                                          ? 'nameA nameC bet-pick draw-pick pick'
                                          : 'nameA nameC bet-pick draw-pick'
                                      }
                                      onClick={() => {
                                        if (!market.marketName.includes('승무패')) return

                                        const type = 'LIVE'

                                        const item = {
                                          sportsEventId: sportsEvent.sportsEventId,
                                          sportsType: sportsEvent.sportsType,
                                          sportsMarketKey: market.sportsMarketKey,
                                          marketId: market.marketId,
                                          leagueName: sportsEvent.leagueName,
                                          ccKr: sportsEvent.ccKr,
                                          startAt: sportsEvent.startAt,
                                          homeName: sportsEvent.homeName,
                                          awayName: sportsEvent.awayName,
                                          marketName: market.marketName,
                                          name: '',
                                          homeSportsMarketOddKey: market.homeSportsMarketOddKey,
                                          homeOddName: market.homeOddName,
                                          homeOddNameKr: market.homeOddNameKr,
                                          homeOddValue: market.homeOddValue,
                                          awaySportsMarketOddKey: market.awaySportsMarketOddKey,
                                          awayOddName: market.awayOddName,
                                          awayOddNameKr: market.awayOddNameKr,
                                          awayOddValue: market.awayOddValue,
                                          drawSportsMarketOddKey: market.drawSportsMarketOddKey,
                                          drawOddName: market.drawOddName,
                                          drawOddNameKr: market.drawOddNameKr,
                                          drawOddValue: market.drawOddValue,
                                          selectedSportsMarketPointKey: market.sportsMarketPointKey,
                                          selectedSportsMarketOddKey: market.drawSportsMarketOddKey,
                                          selectedOddName: market.drawOddName,
                                          selectedOddNameKr: market.drawOddNameKr,
                                          selectedOddValue: market.drawOddValue,
                                        }

                                        onClickBetting(type, item)
                                      }}
                                    >
                                      <div className="txt">
                                        <h3 className="odds">{market.drawOddValue || market?.name || 'VS'}</h3>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        market.isAwayActive
                                          ? 'nameA bet-pick away-pick pick'
                                          : 'nameA bet-pick away-pick'
                                      }
                                      onClick={() => {
                                        const type = 'LIVE'

                                        const item = {
                                          sportsEventId: sportsEvent.sportsEventId,
                                          sportsType: sportsEvent.sportsType,
                                          sportsMarketKey: market.sportsMarketKey,
                                          marketId: market.marketId,
                                          leagueName: sportsEvent.leagueName,
                                          ccKr: sportsEvent.ccKr,
                                          startAt: sportsEvent.startAt,
                                          homeName: sportsEvent.homeName,
                                          awayName: sportsEvent.awayName,
                                          marketName: market.marketName,
                                          name: market.name,
                                          homeSportsMarketOddKey: market.homeSportsMarketOddKey,
                                          homeOddName: market.homeOddName,
                                          homeOddNameKr: market.homeOddNameKr,
                                          homeOddValue: market.homeOddValue,
                                          awaySportsMarketOddKey: market.awaySportsMarketOddKey,
                                          awayOddName: market.awayOddName,
                                          awayOddNameKr: market.awayOddNameKr,
                                          awayOddValue: market.awayOddValue,
                                          drawSportsMarketOddKey: market.drawSportsMarketOddKey,
                                          drawOddName: market.drawOddName,
                                          drawOddNameKr: market.drawOddNameKr,
                                          drawOddValue: market.drawOddValue,
                                          selectedSportsMarketPointKey: market.sportsMarketPointKey,
                                          selectedSportsMarketOddKey: market.awaySportsMarketOddKey,
                                          selectedOddName: market.awayOddName,
                                          selectedOddNameKr: market.awayOddNameKr,
                                          selectedOddValue: market.awayOddValue,
                                        }

                                        onClickBetting(type, item)
                                      }}
                                    >
                                      <div className="txt">
                                        <h3 className="odds">{market?.awayOddValue}</h3>
                                        <span className="icon-state" />
                                        {getMarketIconComponent(market.marketName, market.awayOddNameKr)}
                                      </div>
                                      <div
                                        className="txtFrame"
                                        style={{
                                          width: '50%',
                                          justifyContent: 'end',
                                        }}
                                      >
                                        <SlimScrollDiv>
                                          <SlimScrollInnerDiv>
                                            <h2>{sportsEvent.awayName}</h2>
                                          </SlimScrollInnerDiv>
                                        </SlimScrollDiv>

                                        <img
                                          className="teamImg"
                                          src={`${process.env.PUBLIC_URL}/img/sports/default.png`}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="heartFrame heart01">
                                    <span className="icon-fa-star" />
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

              {liveSportsEventList.length > 0 ? (
                <>
                  {/* {totalElement > 0 && (
                    <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                  )} */}
                </>
              ) : (
                <div className="tb_empty">
                  <div className="pic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
                  </div>
                  <h2>현재 진행중인 경기가 없습니다.</h2>
                  {/* <h3>Sorry, Empty Data</h3> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="btn_top" className={showButton ? '' : 'hide'} onClick={scrollToTop} />
    </>
  )
}

export default LiveSportsMainFrame

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);

  border: 1px solid #fff;
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`

const SlimScrollDiv = styled.div`
  overflow: hidden;
  width: auto;
  height: 100%;
  display: flex;
`

const SlimScrollInnerDiv = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`
