import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import isLogin from '../../../utils/enums/authority'
import { fetchMemberInfo } from '../../../redux/memberInfoSlice'
import SignInPopup from '../../Popup/SignIn/SignInPopup'
import SignUpPopup from '../../Popup/SignUp/SignUpPopup'
import { searchPartnerUrl, siginOut } from '../../../api/authManage/authManageApi'
import { toggleLeftMenuSidebarShow } from '../../../redux/modalSlice'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { withdrawHoldemMoney } from '../../../api/game/gameApi'
import { withdrawCasinoMoney } from '../../../api/memberManage/memberManageApi'
import WrapperLoading from '../../WrapperLoading'
import CustomAlert from '../../CustomAlert'
import { HermesMainText } from '../../../utils/palette'

const Header = () => {
  const loginCheck = isLogin()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [apiLoading, setApiLoading] = useState(false)

  const {
    holdingMoney,
    totalGameMoney,
    holdingMemberPoint,
    holdemMoneyAmount,
    memberLevel,
    isShowLeftMenuSidebar,
    userId,
  } = useSelector(state => {
    const { memberInfo, modalInfo } = state

    return {
      memberLevel: memberInfo.depositLevel,
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      holdemMoneyAmount: memberInfo.holdemMoneyAmount,
      holdingMemberPoint: memberInfo.memberHoldingPoint,
      userId: memberInfo.userId,
      isShowLeftMenuSidebar: modalInfo.isShowLeftMenuSidebar,
    }
  })

  const fetchMemberInfoData = async () => {
    if (!isLogin()) return
    try {
      await dispatch(fetchMemberInfo()).unwrap()
    } catch (error) {
      console.log('error ', error)
    }
  }

  useEffect(() => {
    fetchMemberInfoData()

    const intervalId = setInterval(fetchMemberInfoData, 5000)

    return () => {
      clearInterval(intervalId) // 컴포넌트 언마운트 시 인터벌 정리
    }
  }, [])

  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  // 회원가입 -> 로그인 모달
  const onShowSignInModal = () => {
    setSignUpModalShow(false)
    setSignInModalShow(true)
  }

  // 로그인 -> 회원가입 모달
  const onShowSignUpModal = () => {
    setSignInModalShow(false)
    setSignUpModalShow(true)
  }

  const getLevelIcon = () => {
    switch (true) {
      case memberLevel === 'ONE':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv01.png`} alt="" />
      case memberLevel === 'TWO':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv02.png`} alt="" />
      case memberLevel === 'THREE':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv03.png`} alt="" />
      case memberLevel === 'FOUR':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv04.png`} alt="" />
      case memberLevel === 'FIVE':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv05.png`} alt="" />
      case memberLevel === 'VIP1':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lvVIP01.png`} alt="" />
      case memberLevel === 'VIP2':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lvVIP02.png`} alt="" />
      case memberLevel === 'VIP3':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lvVIP03.png`} alt="" />
      default:
        return <></>
    }
  }

  const onClickPartnerUrlHandler = () => {
    searchPartnerUrl().then(res => {
      const { partnerUrl } = res.data

      if (partnerUrl.includes('localhost')) {
        window.open(`http://${partnerUrl}`)
      } else {
        window.open(`https://${partnerUrl}`)
      }
    })
  }

  const onClickSignOutHandler = () => {
    siginOut()
      .then(res => {
        window.location.href = '/'
      })
      .catch(error => {
        window.location.href = '/'
      })
  }

  const onClickCasinoMoneyWithdrawHandler = () => {
    if (apiLoading) {
      alert('카지노 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (totalGameMoney <= 0) {
      alert('0원 회수는 불가능합니다.')
      return
    }
    setApiLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfo())
        setApiLoading(false)
        alert('회수가 성공했습니다.')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('처리 실패했습니다.')
        }
        setApiLoading(false)
      })
  }

  const onClickHoldemMoneyWithdrawHandler = () => {
    if (apiLoading) {
      alert('홀덤 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (holdemMoneyAmount <= 0) {
      alert('0원 회수는 불가능합니다.')
      return
    }
    setApiLoading(true)

    withdrawHoldemMoney()
      .then(res => {
        dispatch(fetchMemberInfo())
        setApiLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'CMD-FEIGN-1002':
            alert('게임 진행중에는 회수가 불가능합니다.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('홀덤 머니 회수에 실패했습니다. 다시 시도해주세요.')
            break
        }
        setApiLoading(false)
      })
  }

  const onClickMenuHandler = link => {
    navigate(link)
  }
  return (
    <>
      {signInModalShow && (
        <SignInPopup
          visible={signInModalShow}
          onCloseHandler={onCloseSignInModal}
          onShowSignUpModal={onShowSignUpModal}
        />
      )}
      {signUpModalShow && (
        <SignUpPopup
          visible={signUpModalShow}
          onCloseHandler={onCloseSignUpModal}
          onShowSignInModal={onShowSignInModal}
        />
      )}
      {apiLoading && <WrapperLoading isLoading={apiLoading} />}

      <div className="header headerSuccess test">
        <div className="headerWrapper mob">
          <div className="mask" style={{ display: 'none' }} />
          <div className="wrapper">
            <a
              className="icon-left-menu"
              onClick={() => {
                dispatch(toggleLeftMenuSidebarShow(!isShowLeftMenuSidebar))
              }}
            >
              <span className="icon-nav2" />
            </a>
            <a href="/" className="logo">
              <img
                src={`${process.env.PUBLIC_URL}/img/common/logo.png`}
                alt="logo"
                style={{ width: 'auto', height: '70px' }}
              />
            </a>
            {!isLogin() && (
              <div class="btn-box">
                <button
                  class="signup-btn join-link"
                  type="button"
                  style={{ fontSize: '14px' }}
                  onClick={() => {
                    setSignUpModalShow(true)
                  }}
                >
                  회원가입
                </button>
                <button
                  class="login-btn login-link"
                  type="button"
                  style={{ fontSize: '14px' }}
                  onClick={() => {
                    setSignInModalShow(true)
                  }}
                >
                  로그인
                </button>
              </div>
            )}

            {/* <div className="btn-box">
              <div className="moneyZone">
                <div className="point">
                  <span className="money userCashPoint icon-point">0</span>
                </div>
                <div className="cash">
                  <span className="money userCashAmount icon-icconMoneyKOREA">70,614</span>
                  <span className="refresh btn-refresh-cash">
                    <img src={`${process.env.PUBLIC_URL}/img/common/refresh.png`} alt="" />
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="headerWrapper pc v_deep_header">
          <div className="home_notice">
            <div className="notice_wrap">
              <div className="noticeIcon">
                <img src={`${process.env.PUBLIC_URL}/img/common/megaPhone.svg`} alt="" />
              </div>
              <div className="notice_text">
                <div className="marquee">
                  <p style={{ color: '#000' }}>
                    <strong>
                      고객만족도 1위 위너스입니다. 많은성원에 감사드리며 더욱 안전하고 즐거운 놀이터로써 보답하겠습니다.{' '}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="rightZone">
            {!loginCheck ? (
              <>
                <a
                  className="btnN loginIcon redB login-link"
                  onClick={() => {
                    setSignInModalShow(true)
                  }}
                >
                  <span className="icon-icconlogin" />
                  로그인
                </a>

                <a
                  className="btnN signupIcon redB join-link"
                  onClick={() => {
                    setSignUpModalShow(true)
                  }}
                >
                  <span className="icon-icconReg" />
                  회원가입
                </a>
              </>
            ) : (
              <>
                <div className="moneyZone" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <div className="cash" style={{ display: 'flex' }}>
                    <span
                      className="money userCashAmount icon-icconMoneyKOREA"
                      style={{ display: 'block', width: 'max-content', gap: '2px', fontSize: '15px' }}
                    >
                      <span style={{ color: '#413b3b' }}>보유머니: </span>
                      {holdingMoney?.toString().replace(commonReg2, ',')}원
                    </span>
                  </div>
                  {/* <div className="point" style={{ display: 'flex' }}>
                    <span
                      className="money userCashPoint icon-point"
                      style={{ display: 'block', width: 'max-content', gap: '2px', fontSize: '15px' }}
                      onClick={() => {
                        onClickMenuHandler('/point/exchange')
                      }}
                    >
                      포인트: {holdingMemberPoint?.toString().replace(commonReg2, ',')}원
                    </span>
                    <span
                      className="money refresh btn-refresh-cash icon-iconReloadA"
                      style={{ display: 'block', width: 'max-content', fontSize: '17px' }}
                      onClick={() => {
                        onClickMenuHandler('/point/exchange')
                      }}
                    />
                  </div> */}
                </div>
                <div className="moneyZone" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <div className="point" style={{ display: 'flex' }}>
                    <span
                      className="money userCashPoint icon-icconMoneyKOREA"
                      style={{ display: 'block', width: 'max-content', gap: '2px', fontSize: '15px' }}
                    >
                      <span style={{ color: '#413b3b' }}>카지노 :</span>
                      {totalGameMoney?.toString().replace(commonReg2, ',')}원
                    </span>
                    <span
                      className="money refresh btn-refresh-cash icon-iconReloadA"
                      style={{ display: 'block', width: 'max-content', fontSize: '17px' }}
                      onClick={onClickCasinoMoneyWithdrawHandler}
                    />
                  </div>
                  <div className="point" style={{ display: 'flex' }}>
                    <span
                      className="money userCashPoint icon-point"
                      style={{ display: 'block', width: 'max-content', gap: '2px', fontSize: '15px' }}
                      onClick={() => {
                        onClickMenuHandler('/point/exchange')
                      }}
                    >
                      <span style={{ color: '#413b3b' }}>포인트 :</span>{' '}
                      {holdingMemberPoint?.toString().replace(commonReg2, ',')}원
                    </span>
                    <span
                      className="money refresh btn-refresh-cash icon-iconReloadA"
                      style={{ display: 'block', width: 'max-content', fontSize: '17px' }}
                      onClick={() => {
                        onClickMenuHandler('/point/exchange')
                      }}
                    />
                  </div>
                  {/* <div className="point" style={{ display: 'flex' }}>
                    <span
                      className="money userCashPoint icon-icconMoneyKOREA"
                      style={{ display: 'block', width: 'max-content', gap: '2px', fontSize: '15px' }}
                    >
                      홀덤: {holdemMoneyAmount?.toString().replace(commonReg2, ',')}원
                    </span>
                    <span
                      className="money refresh btn-refresh-cash icon-iconReloadA"
                      style={{ display: 'block', width: 'max-content', fontSize: '17px' }}
                      onClick={onClickHoldemMoneyWithdrawHandler}
                    />
                  </div> */}
                </div>

                <div className="memberZone">
                  {getLevelIcon()}
                  <span className="nick" style={{ color: `${HermesMainText}` }}>
                    {userId}님
                  </span>
                </div>

                {decodeAccessToken()?.auth === 'PARTNER' && (
                  <a className="btnN redB partner-link" onClick={onClickPartnerUrlHandler}>
                    <span className="icon-icconlogin" />
                    파트너페이지
                  </a>
                )}

                <a className="btnN loginIcon redB logout-link" onClick={onClickSignOutHandler}>
                  <span className="icon-logout" />
                  로그아웃
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
