import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { MiniGameTypeEnums } from '../../utils/enums/MiniGame/PowerBallEnums'
import { HermesMainText } from '../../utils/palette'

const MiniGameNtryView = ({ isShowVideo, activeMenu }) => {
  const iframeWrapperRef = useRef(null)
  const iframeRef = useRef(null)
  const [scale, setScale] = useState(1)
  const [marginTop, setMarginTop] = useState(0)

  const [iframeWrapperStyle, setIframeWrapperStyle] = useState({})
  const { miniGameInspect } = useSelector(state => {
    return {
      miniGameInspect: state.miniGameBetting.miniGameInspect,
    }
  })

  useEffect(() => {
    function resizeIframe() {
      const iframew = iframeWrapperRef.current
      const iframe = iframeRef.current

      const rw = iframew.offsetWidth
      const iw = iframe.offsetWidth

      const scl = Math.min(rw / iw, 1)
      setScale(scl)

      // 추가적인 스타일 조정이 필요한 경우 여기서 수행
    }

    window.addEventListener('resize', resizeIframe)
    resizeIframe() // 초기 크기 조정

    return () => {
      window.removeEventListener('resize', resizeIframe)
    }
  }, [])

  useEffect(() => {
    setIframeWrapperStyle({
      '-webkit-transform': `scale(${scale})`,
      '-moz-transform': `scale(${scale})`,
      '-ms-transform': `scale(${scale})`,
      '-o-transform': `scale(${scale})`,
      transform: `scale(${scale})`,
    })
  }, [scale])

  const getMiniGameIframe = () => {
    switch (activeMenu) {
      case 'NTRY_EOS_POWER_BALL_1M':
        return (
          <iframe
            src="https://ntry.com/scores/eos_powerball/1min/main.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
      case 'NTRY_EOS_POWER_BALL_2M':
        return (
          <iframe
            src="https://ntry.com/scores/eos_powerball/2min/main.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
      case 'NTRY_EOS_POWER_BALL_3M':
        return (
          <iframe
            src="https://ntry.com/scores/eos_powerball/3min/main.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
      case 'NTRY_EOS_POWER_BALL_4M':
        return (
          <iframe
            src="https://ntry.com/scores/eos_powerball/4min/main.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
      case 'NTRY_EOS_POWER_BALL_5M':
        return (
          <iframe
            src="https://ntry.com/scores/eos_powerball/5min/main.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
      case 'NTRY_POWER_BALL':
        return (
          <iframe
            src="https://ntry.com/scores/powerball/live.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
      case 'NTRY_SPEED_KENO':
        return (
          <iframe
            src="https://ntry.com/scores/speedkeno/live.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
      case 'NTRY_KENO_LADDER':
        return (
          <iframe
            src="https://ntry.com/scores/keno_ladder/live.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
      case 'NTRY_POWER_LADDER':
        return (
          <iframe
            src="https://ntry.com/scores/power_ladder/live.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
      default:
        return (
          <iframe
            src="https://ntry.com/scores/powerball/live.php"
            scrolling="no"
            width="830"
            height="650"
            ref={iframeRef}
          />
        )
    }
  }

  return (
    <>
      <div className={isShowVideo ? 'view_section' : 'view_section hide'} style={{ position: 'relative' }}>
        {miniGameInspect && (
          <InspectOverlay>
            <BiLock />
          </InspectOverlay>
        )}
        <div className="view-wrapper" style={{ aspectRatio: '830 / 650' }}>
          <div className="iframe-wrapper" style={iframeWrapperStyle} ref={iframeWrapperRef}>
            {getMiniGameIframe()}
          </div>
        </div>
      </div>
    </>
  )
}

export default MiniGameNtryView

const InspectOverlay = styled.div`
  position: absolute;
  z-index: 102;
  text-align: center;
  color: ${HermesMainText};
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  span {
    position: absolute;
    flex-direction: column;
    margin-top: 15px;
    top: 55%;
    left: 50%;
    font-size: 18px;
    transform: translate(-50%, -50%);
  }

  svg {
    /* 아이콘 스타일 */
    position: absolute;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
  }
`
