import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const Footer = () => {
  return (
    <>
      <div className="footer mob">
        <ul>
          <li className="">
            <a href="/money/deposit" aria-label="입금">
              <span className="icon-iiconDeposit" />
              <div>입금</div>
            </a>
          </li>
          <li className="">
            <a href="/money/withdrawal" aria-label="출금">
              <span className="icon-iiconWithdraw" />
              <div>출금</div>
            </a>
          </li>
          <li className="active">
            <a href="/" aria-label="홈">
              <span className="icon-home1" />
            </a>
          </li>
          <li className="">
            <a href="/event" aria-label="이벤트">
              <span className="icon-iiconSpeaker" />
              <div>이벤트</div>
            </a>
          </li>
          <li className="">
            <a href="/one-to-one" aria-label="고객센터">
              <span className="icon-iiconService" />
              <div>고객센터</div>
            </a>
          </li>
        </ul>
      </div>

      <div className="footer footer_notice pc">
        <div className="footerWrapper">
          <div className="copyright">
            <a href="https://t.me/winer222" target="_blank" className="bann-te" rel="noreferrer">
              <img src={`${process.env.PUBLIC_URL}/img/common/teleba01.png`} alt="" />
            </a>
            <img src={`${process.env.PUBLIC_URL}/img/common/logo.png`} className="footer-logo" alt="" />
            <a href="https://t.me/winer222" target="_blank" className="bann-pt" rel="noreferrer">
              <img src={`${process.env.PUBLIC_URL}/img/common/teleba02.png`} alt="" />
            </a>
            <div className="license">
              <span>
                {' '}
                Copyright ⓒ2021 <strong>WINNERS casino</strong> All rights reserved
              </span>
            </div>
          </div>
          <div className="downZone">
            <img src={`${process.env.PUBLIC_URL}/img/common/footer-logo.png`} className="footer-logo" alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
