import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Layout from '../containers/Layout/Layout'
import MainContent from '../containers/Layout/Right/MainContent'
import NotFound404 from '../containers/NotFound/NotFound404'
import isLogin from '../utils/enums/authority'
import BettingHistoryPage from '../view/bettingHistory/BettingHistoryPage'
import LiveCasinoPage from '../view/casino/LiveCasinoPage'
import SlotCasinoPage from '../view/casino/SlotCasinoPage'
import MoneyDepositPage from '../view/deposit/MoneyDepositPage'
import EventPage from '../view/event/EventPage'
import FriendHistoryPage from '../view/friendHistory/FriendHistoryPage'
import MiniGameNtryPage from '../view/miniGame/MiniGameNtryPage'
import MiniGamePage from '../view/miniGame/MiniGamePage'
import MyPage from '../view/myPage/MyPage'
import NotePage from '../view/note/NotePage'
import NoticePage from '../view/notice/NoticePage'
import OneToOnePage from '../view/oneToOne/OneToOnePage'
import PointExchangePage from '../view/pointExchange/PointExchangePage'
import CrossSportsPage from '../view/sports/cross/CrossSportsPage'
import LiveSportsPage from '../view/sports/live/LiveSportsPage'
import SpecialSportsPage from '../view/sports/special/SpecialSportsPage'
import MoneyWithdrawalPage from '../view/withdrawal/MoneyWithdrawalPage'
import HoldemPage from '../view/holdem/HoldemPage'

const PrivateRoute = ({ alertMessage = '로그인 후 이용가능합니다.' }) => {
  if (!isLogin()) {
    return <Navigate to="/" replace state={{ alertMessage }} />
  }

  return <Outlet />
}

// 긴급 점검일때는 url 막아야 함
const Router = () => {
  return (
    <AnimatePresence>
      <Routes>
        {/* 공통으로 들어갈 컴포넌트 들 */}
        <Route element={<Layout />}>
          {/* 공통으로 들어갈 컴포넌트 들 */}
          {/* 메인페이지 */}
          <Route index element={<MainContent />} />
          {/* 그외 페이지 */}
          <Route element={<PrivateRoute />}>
            <Route path="/cross-sports" element={<CrossSportsPage />} />
            <Route path="/special-sports" element={<SpecialSportsPage />} />
            <Route path="/live-sports" element={<LiveSportsPage />} />
            <Route path="/live-casino" element={<LiveCasinoPage />} />
            <Route path="/slot-casino" element={<SlotCasinoPage />} />
            <Route path="/holdem" element={<HoldemPage />} />
            <Route path="/minigame" element={<MiniGamePage />} />
            <Route path="/minigame-ntry" element={<MiniGameNtryPage />} />
            <Route path="/event" element={<EventPage />} />

            <Route path="/money/deposit" element={<MoneyDepositPage />} />
            <Route path="/money/withdrawal" element={<MoneyWithdrawalPage />} />
            <Route path="/point/exchange" element={<PointExchangePage />} />
            <Route path="/betting-history" element={<BettingHistoryPage />} />
            <Route path="/notice" element={<NoticePage />} />
            <Route path="/note" element={<NotePage />} />
            <Route path="/one-to-one" element={<OneToOnePage />} />
            <Route path="/mypage" element={<MyPage />} />
            <Route path="/friend-history" element={<FriendHistoryPage />} />
            {/* <Route path="/live-casino" element={<LiveCasino />} />
            <Route path="/slot-casino" element={<SlotCasino />} />
            <Route path="/mypage" element={<MyInfo />} />
            <Route path="/gamemoney" element={<GameMoneyPage />} />
            <Route path="/money/deposit" element={<MoneyDepositForm />} />
            <Route path="/money/withdrawal" element={<MoneyWithdrawalForm />} />
            <Route path="/money/moneyexchange" element={<MoneyExchangeForm />} />
            <Route path="/money/couponuse" element={<CouponUseForm />} />
            <Route path="/money/pointexchange" element={<PointExchangeForm />} />
            <Route path="/customcenter/notice" element={<NoticeListPage />} />
            <Route path="/customcenter/event" element={<EventListPage />} />
            <Route path="/minigame" element={<MiniGame />} />
            <Route path="/sportsgame" element={<SportsGamePage />} />
            <Route path="/special-sports" element={<SportsSpecialGamePage />} />
            <Route path="/live-sports" element={<LiveSportsGamePage />} />
            <Route path="/attendance" element={<AttendanceForm />} />
            <Route path="/betting-history" element={<BettingHistoryPage />} />
            <Route path="/one-to-one" element={<OnetoOneList />} />
            <Route path="/note" element={<NoteList />} />
            <Route path="/friend-list" element={<FriendList />} /> */}
          </Route>
          {/* <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} /> */}
        </Route>
        {/* 공통 Layout 이 안보여야 하는 컴포넌트 들 */}
        {/* 아래는 페이지 not found  */}
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </AnimatePresence>
  )
}

export default Router
