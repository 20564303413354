import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMiniGameBettingPer, setMiniGameBettingType } from '../../../redux/miniGameBettingSlice'

const MiniGameNtryKenoLadder = ({ powerBallInfo }) => {
  const dispatch = useDispatch()

  const handleBettingCart = event => {
    const miniGameBettingPer = event.currentTarget.getAttribute('name') || 0
    const miniGameBettingType = event.currentTarget.id
    dispatch(setMiniGameBettingPer(miniGameBettingPer))
    dispatch(setMiniGameBettingType(miniGameBettingType))
  }

  const getMiniGameBettingInfo = state => {
    const { miniGameType } = state

    if (miniGameType === 'NTRY_POWER_BALL') {
      return state.ntryPowerBallInfo
    }
    if (miniGameType === 'NTRY_POWER_LADDER') {
      return state.ntryPowerLadderInfo
    }
    if (miniGameType === 'NTRY_SPEED_KENO') {
      return state.ntrySpeedKenoInfo
    }
    if (miniGameType === 'NTRY_KENO_LADDER') {
      return state.ntryKenoLadderInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_1M') {
      return state.ntryEosPowerBallOneInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_2M') {
      return state.ntryEosPowerBallTwoInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_3M') {
      return state.ntryEosPowerBallThreeInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_4M') {
      return state.ntryEosPowerBallFourInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_5M') {
      return state.ntryEosPowerBallFiveInfo
    }

    return null
  }

  const { miniGameBettingType } = useSelector(state => {
    const { miniGameBetting } = state
    const miniGameBettingInfo = getMiniGameBettingInfo(miniGameBetting)
    if (miniGameBettingInfo) {
      return {
        miniGameBettingType: miniGameBettingInfo.miniGameBettingType,
      }
    }
    return {
      miniGameBettingType: '',
    }
  })

  return (
    <div className="pick_section">
      <div className="pick_list">
        <div className="pick_wrap">
          <h4 className="pick_title">시작</h4>
          <div className="pick_bet grid-2">
            <div
              className={miniGameBettingType === 'LADDER_LEFT' ? 'btnBet on' : 'btnBet'}
              id="LADDER_LEFT"
              name={powerBallInfo?.miniGameBettingPer?.ladderLeft || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.ladderLeft || 0}</span>
              <div className="mark">
                <span className="ic_left">좌</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'LADDER_RIGHT' ? 'btnBet on' : 'btnBet'}
              id="LADDER_RIGHT"
              name={powerBallInfo?.miniGameBettingPer?.ladderRight || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.ladderRight || 0}</span>
              <div className="mark">
                <span className="ic_right">우</span>
              </div>
            </div>
          </div>
        </div>

        <div className="pick_wrap">
          <h4 className="pick_title">줄 수</h4>
          <div className="pick_bet grid-2">
            <div
              className={miniGameBettingType === 'LADDER_THREE' ? 'btnBet on' : 'btnBet'}
              id="LADDER_THREE"
              name={powerBallInfo?.miniGameBettingPer?.ladderThree || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.ladderThree || 0}</span>
              <div className="mark">
                <span className="ic_num3">3</span>
              </div>
            </div>
            <div
              className={miniGameBettingType === 'LADDER_FOUR' ? 'btnBet on' : 'btnBet'}
              id="LADDER_FOUR"
              name={powerBallInfo?.miniGameBettingPer?.ladderFour || 0}
              onClick={handleBettingCart}
            >
              <span className="bedd">{powerBallInfo?.miniGameBettingPer?.ladderFour || 0}</span>
              <div className="mark">
                <span className="ic_num4">4</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniGameNtryKenoLadder
