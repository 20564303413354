import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useAudio from '../../utils/audioHook'
import NoteAlert from '../NoteAlert'
import LeftContent from './Left/LeftContent'
import Footer from './Right/Footer'
import Header from './Right/Header'
import OneToOneAlert from '../OneToOneAlert'

// TODO  https://fourwingsy.medium.com/%ED%8C%9D%EC%97%85%EC%99%80-%EB%A9%94%EC%8B%9C%EC%A7%80-with-react-afd1923797d8
const Layout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [pathName, setPathName] = useState('')

  const [alarmSrc, setAlarmSrc] = useState(`${process.env.PUBLIC_URL}/audio/noteAudio.mp3`)

  const [play, pause, paused, audioRef] = useAudio(alarmSrc, {
    // playbackRate: 2,
  })

  useEffect(() => {
    setPathName(location.pathname)
  }, [location])

  const { oneToOneRedisSize, noteRedisSize, noteMessageInfo, sideMenuModalShow, modalTitle, isShowLeftMenuSidebar } =
    useSelector(state => {
      const { memberInfo, modalInfo } = state

      return {
        oneToOneRedisSize: memberInfo.oneToOneRedisSize,
        noteRedisSize: memberInfo.noteRedisSize,
        noteMessageInfo: memberInfo.noteMessageInfo,
        sideMenuModalShow: modalInfo.modalShow,
        modalTitle: modalInfo.modalTitle,
        isShowLeftMenuSidebar: modalInfo.isShowLeftMenuSidebar,
      }
    })

  // 쪽지 모달이 켜져 있으면 false
  const [isValidPath, setIsValidPath] = useState(false)

  useEffect(() => {
    if (pathName !== '/note' && pathName !== '/one-to-one') {
      setIsValidPath(true)

      if (noteRedisSize > 0 && paused()) {
        play()
      }

      if (noteRedisSize === 0 && oneToOneRedisSize > 0 && paused()) {
        play()
      }
    } else {
      setIsValidPath(false)
    }
  }, [oneToOneRedisSize, pathName, noteRedisSize, sideMenuModalShow, modalTitle, paused, play])

  return (
    <div className={isShowLeftMenuSidebar ? 'allContent aside' : 'allContent'}>
      {isValidPath && noteRedisSize > 0 && <NoteAlert noteSize={noteRedisSize} />}
      {isValidPath && noteRedisSize === 0 && oneToOneRedisSize > 0 && <OneToOneAlert noteSize={oneToOneRedisSize} />}
      <LeftContent />
      <div className="rightContent homePage">
        <div className="contents">
          <Header />
          <div id="app_content">
            <div className="main_content_continer">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Layout

const ContainerWrap = styled.div`
  // width: 100%;
  // display: flex;
  // flex-direction: column;
  // height: 100vh;
`

const LeftSidebarWrap = styled.div`
  position: fixed;
  top: 160px;
  left: 0;
  margin-top: 10px;
  width: 300px;
  height: calc(100vh - 240px);
  border: solid 1px #edae07;
  // z-index: 301;
`

const RightSidebarWrap = styled.div`
  position: fixed;
  top: 160px;
  right: 5px;
  margin-top: 10px;
  width: 300px;
  height: calc(100vh - 240px);
  // border: solid 1px #edae07;
  // z-index: 301;
`

const BettingCartButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const ContainerSecondWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll !important;
`

const Container = styled.div`
  margin-top: 65px;
  background-color: #111314;
  transition: all 0.5s ease-out 0s;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-family: 'Malgun Gothic', dotum;

  width: 100%;
  padding-left: 310px;
  padding-right: 330px;

  ${props =>
    !props.isShowLeftMenuSidebar &&
    `
    padding-left: 200px;
    padding-right: 440px;
`}

  @media (max-width: 1670px) {
    /* 대형 데스크탑보다 작은 화면에 적용되는 스타일 */
    padding-left: 70px;
    padding-right: 310px;
  }

  @media (max-width: 1440px) {
    padding-left: 70px;
    padding-right: 30px;
    min-width: max-content;
  }
`
