export const MemberMoneyLogTypeEnums = {
  MEMBER_DEPOSIT: '회원 입금',
  MEMBER_WITHDRAWAL: '회원 출금',
  COMP_EXCHANGE: '롤링 전환액',
  ADMIN_DEPOSIT: '관리자 입금',
  ADMIN_WITHDRAWAL: '관리자 출금',
}

export const MemberMoneyLogTypeEnumsSelectOptions = () => {
  return Object.entries(MemberMoneyLogTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MoneyTypeEnums = {
  DEPOSIT: '입금',
  WITHDRAWAL: '출금',
}
