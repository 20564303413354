import React, { useState } from 'react'
import styled from 'styled-components'
import WrapperLoading from '../../containers/WrapperLoading'
import CasinoBettingHistoryPage from './CasinoBettingHistoryPage'
import MiniBettingHistoryPage from './MiniBettingHistoryPage'
import SportsBettingHistoryPage from './SportsBettingHistoryPage'

const BettingHistoryPage = () => {
  const [active, setActive] = useState('casino')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const getBettingHistoryComponent = () => {
    switch (active) {
      // case 'sports':
      //   return <SportsBettingHistoryPage deleteLoading={deleteLoading} setDeleteLoading={setDeleteLoading} />
      // case 'minigame':
      //   return <MiniBettingHistoryPage deleteLoading={deleteLoading} setDeleteLoading={setDeleteLoading} />
      case 'casino':
        return <CasinoBettingHistoryPage deleteLoading={deleteLoading} setDeleteLoading={setDeleteLoading} />
      default:
        return <></>
    }
  }

  const handleTabClick = tab => {
    setActive(tab)
  }

  const isSportsActive = active === 'sports'
  const isCasinoActive = active === 'casino'
  const isMiniActive = active === 'minigame'

  return (
    <div className="main-content">
      {deleteLoading && <WrapperLoading isLoading={deleteLoading} />}
      <div className="aniFrame">
        <div className="titlePic">
          <div className="titlePwrapper">
            <div className="leftZone">
              <span>베팅내역</span>
            </div>
            <div className="line" />
          </div>
        </div>
        <div className="main_content_wrap main_content_wrap_notice">
          <div className="noticeFrame">
            <div className="list-wrap animated fadeIn delay2">
              <div className="game-category tabZone">
                <ul>
                  {/* <li
                    className={`game-sub-tab tab-betlist-1 ${isSportsActive ? 'active' : ''}`}
                    data-id="1"
                    onClick={() => setActive('sports')}
                  >
                    <span className="pc icon-icconSPORT" />
                    <h4>스포츠</h4>
                  </li> */}

                  <li
                    className={`game-sub-tab tab-betlist-2 ${isCasinoActive ? 'active' : ''}`}
                    data-id="2"
                    onClick={() => setActive('casino')}
                  >
                    <span className="pc icon-icconCARD" />
                    <h4>카지노</h4>
                  </li>

                  {/* <li
                    className={`game-sub-tab tab-betlist-3 ${isMiniActive ? 'active' : ''}`}
                    data-id="3"
                    onClick={() => setActive('minigame')}
                  >
                    <span className="pc icon-icconVR" />
                    <h4>미니게임</h4>
                  </li> */}

                  {/* <li className="game-sub-tab tab-betlist-4" onClick="location.href='#/game/betlog/virtual'">
                    <span className="pc icon-icconVR" />
                    <h4>가상게임</h4>
                  </li>

                  <li className="game-sub-tab tab-betlist-5" onClick="location.href='#/game/betlog/touch'">
                    <span className="pc icon-icconVR" />
                    <h4>터치게임</h4>
                  </li> */}
                </ul>
              </div>
              <BettingHistoryContentWrap>{getBettingHistoryComponent()}</BettingHistoryContentWrap>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BettingHistoryPage

const BettingHistoryContentWrap = styled.div``
