import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const MiniGamePage = () => {
  return (
    <div className="main_content" id="mini">
      <div className="titlePic">
        <div className="titlePwrapper">
          <div className="leftZone">
            <span>미니게임</span>
          </div>
          <div className="line" />
        </div>
      </div>
      <div className="gameZone mini">
        <div className="gameWrapper">
          <div className="content">
            <div className="leftFrame pc">
              <div className="leftZone aniLogo01">
                <div className="obj aniLogo" />
              </div>
            </div>
            <div className="rightZone">
              <div className="txt pc">
                <h2>미니게임</h2>
              </div>
              <div className="btns">
                <ul>
                  <li>
                    <a href="/minigame-ntry">
                      <div
                        className="model modelA"
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/mini/ntry.png)` }}
                      />
                      <div className="txt01">
                        <h2>엔트리</h2>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="obj aniLogo02" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniGamePage
