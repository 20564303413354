import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import WrapperLoading from '../../containers/WrapperLoading'
import {
  deleteSportsBettingHistory,
  cancelSportsBettingHistory,
  searchSportsBettingHistory,
} from '../../api/game/sportsGameApi'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import { SportTypeEnums, SportsResultStatusEnums } from '../../utils/enums/SportsGame/SportsGameEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import { ListButton, MoneyFormModifyButtomInfo } from '../../shared/components/MoneyCustomElement'
import { HermesMainBorder, HermesMainSelect, HermesMainText } from '../../utils/palette'
import { CustomButtonInfos } from '../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { CustomDeleteButton } from '../../mobile/mobileCasinoGame/MobileLiveCasino'
import { deleteMiniGameBettingHistory, searchMiniGameBettingLogAll } from '../../api/game/miniGameApi'
import { MiniGameTypeEnums, PowerBallTypeEnums } from '../../utils/enums/MiniGame/PowerBallEnums'

const MiniBettingHistoryPage = ({ deleteLoading, setDeleteLoading }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [miniGameBettingHistoryContent, setMiniGameBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchMiniGameBettingHistory = async params => {
    setDeleteLoading(true)
    await searchMiniGameBettingLogAll(params)
      .then(res => {
        setTotalElements(res.data.totalElement)
        setMiniGameBettingHistoryContent(res.data.content)
        setDeleteLoading(false)
      })
      .catch(error => setDeleteLoading(false))
  }

  useEffect(() => {
    fetchMiniGameBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])

  // 삭제하기 버튼
  const onDeleteHandler = miniGameBettingLogKey => {
    if (deleteLoading) return

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteMiniGameBettingHistory({
        miniGameBettingLogKeyList: [miniGameBettingLogKey],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  const getColor = bettingResult => {
    switch (true) {
      case bettingResult === 'WIN':
        return 'blue'
      case bettingResult === 'LOSE':
        return 'red'
      case bettingResult === 'DRAW':
        return 'grey'
      case bettingResult === 'ING':
        return 'lightblue'
      case bettingResult === 'CANCEL':
        return 'red'
      default:
        return 'white'
    }
  }

  const onAllDeleteHandler = () => {
    if (deleteLoading) return
    // if (checkRadio.length === 0) {
    //   alert('삭제 할 내역을 선택해주세요.')
    //   return
    // }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteMiniGameBettingHistory({
        miniGameBettingLogKeyList: miniGameBettingHistoryContent
          .filter(value => value.bettingResult !== 'ING')
          .map(value => value.miniGameBettingLogKey),
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  return (
    <>
      {deleteLoading && (
        <WrapperLoading style={{ position: 'relative', top: '0', left: '0' }} isLoading={deleteLoading} />
      )}
      <div className="btn-wrap">
        <button
          className=""
          onClick={() => {
            onAllDeleteHandler()
          }}
          type="button"
        >
          전체내역삭제
        </button>
      </div>
      <div className="bet-list-wrap listZone">
        {miniGameBettingHistoryContent.map((item, index) => (
          <>
            <table className="styled" key={item.miniGameBettingLogKey}>
              <thead>
                <tr>
                  <th className="ac pc">배팅일시</th>
                  <th className="ac pc">게임명</th>
                  <th className="ac pc">배팅종목</th>
                  <th className="ac pc">회차</th>
                  <th className="ac pc">배당</th>
                  <th className="ac pc">배팅액</th>
                  <th className="ac pc">당첨액</th>
                  <th className="ac pc">결과</th>
                  <th className="ac mob" style={{ fontSize: '12px', padding: '10px 0px' }}>
                    배팅일시
                  </th>
                  <th className="ac mob" style={{ fontSize: '12px', padding: '10px 0px' }}>
                    게임명
                  </th>
                  <th className="ac mob" style={{ fontSize: '12px', padding: '10px 0px' }}>
                    배팅종목
                  </th>
                  <th className="ac mob" style={{ fontSize: '12px', padding: '10px 0px' }}>
                    회차
                  </th>
                  <th className="ac mob" style={{ fontSize: '12px', padding: '10px 0px' }}>
                    배당
                  </th>
                  <th className="ac mob" style={{ fontSize: '12px', padding: '10px 0px' }}>
                    배팅액
                  </th>
                  <th className="ac mob" style={{ fontSize: '12px', padding: '10px 0px' }}>
                    당첨액
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={item.miniGameBettingLogKey}>
                  <td class="pc">{convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}</td>
                  <td class="pc">
                    <div className="league-wrap" style={{ color: `${HermesMainText}` }}>
                      {MiniGameTypeEnums[item.miniGameType]}
                    </div>
                  </td>
                  <td className="pc team-info-wrap">
                    <span className="team-name">{PowerBallTypeEnums[item.miniGameBettingType]}</span>
                  </td>
                  <td className="pc">{item.gameRound}</td>
                  <td className="pc team-info-wrap">
                    <span className="team-name">{item.bettingPer}</span>
                  </td>
                  <td className="pc sel-pick">{item.bettingAmount?.toString().replace(commonReg2, ',')}</td>
                  <td class="pc">
                    <span style={{ color: `${HermesMainText}` }}>
                      {item.winningAmount?.toString().replace(commonReg2, ',')}
                    </span>
                  </td>
                  <td class="pc" rowSpan="1">
                    <CustomResultSpan className="status bet-status-win" backgroundColor={getColor(item.bettingResult)}>
                      {SportsResultStatusEnums[item.bettingResult]}
                    </CustomResultSpan>
                  </td>
                  <td class="mob" style={{ fontSize: '12px' }}>
                    {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                  </td>
                  <td class="mob" style={{ fontSize: '12px' }}>
                    <div className="league-wrap" style={{ color: `${HermesMainText}` }}>
                      {MiniGameTypeEnums[item.miniGameType]}
                    </div>
                  </td>
                  <td className="mob team-info-wrap" style={{ padding: '0px' }}>
                    <span className="team-name" style={{ fontSize: '12px' }}>
                      {PowerBallTypeEnums[item.miniGameBettingType]}
                    </span>
                  </td>
                  <td className="mob" style={{ fontSize: '12px' }}>
                    {item.gameRound}
                  </td>
                  <td className="mob team-info-wrap">
                    <span className="team-name" style={{ fontSize: '12px' }}>
                      {item.bettingPer}
                    </span>
                  </td>
                  <td className="mob sel-pick" style={{ fontSize: '12px' }}>
                    {item.bettingAmount?.toString().replace(commonReg2, ',')}
                  </td>
                  <td class="mob">
                    <span style={{ color: `${HermesMainText}`, fontSize: '12px' }}>
                      {item.winningAmount?.toString().replace(commonReg2, ',')}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="bet-result sports">
              <div>
                <button
                  className="btn-delete"
                  onClick={() => onDeleteHandler(item.miniGameBettingLogKey)}
                  type="button"
                >
                  내역삭제
                </button>

                {/* <div className="chk-wrap btn-sel">
                        <input type="checkbox" id="sel-bet-39" name="sel-bet" value="39" />
                        <label htmlFor="sel-bet-39" />
                      </div> */}
              </div>
            </div>
          </>
        ))}
      </div>
      {totalElements === 0 && (
        <div className="tb_empty">
          <div className="pic">
            <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
          </div>
          <h2>데이터 없음</h2>
          <h3>Sorry, Empty Data</h3>
        </div>
      )}
      {totalElements !== 0 && (
        <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
      )}
    </>
  )
}

export default MiniBettingHistoryPage

const CustomResultSpan = styled.span`
  color: #fff;
  background: ${props => props.backgroundColor || 'white'} !important;
`
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: #000;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`
