import React, { useEffect, useState, Fragment, useRef } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { GrFormClose } from 'react-icons/gr'
import { searchNormalPopupImgList } from '../../api/popupImg/popupImgApi'
import {
  CloseButton,
  CloseButtonWrap,
  PopupBody,
  PopupContent,
  PopupHeader,
  PopupImgItem,
  PopupImgWrap,
  PopupOverlay,
  PopupWrapper,
  MobilePopupWrapper,
  MobilePopupContent,
  MobilePopupBody,
  MobilePopupImgItem,
  CloseButtonWrap2,
  CloseButton2,
  CloseButton3,
} from './PopupElements'
import Portal from '../../shared/components/Portal'
import CustomLoading from '../CustomLoading'
import WrapperLoading from '../WrapperLoading'

const NoticePopup = () => {
  const [popupHeight, setPopupHeight] = useState('100px') // 초기 상태 설정

  const popupWrapperRef = useRef(null)

  useEffect(() => {
    if (popupWrapperRef.current) {
      // console.log(); // clientHeight 값을 콘솔에 출력
      setPopupHeight(popupWrapperRef.current.clientHeight)
    }
  }, [])

  const [popupList, setPopupList] = useState([])
  const [loading, setLoading] = useState(false)

  const checkVisible = title => {
    const check = localStorage.getItem(`popup-${title}`)
    const today = new Date().getDate()
    if (!check) {
      return true
    }
    return Number(check) !== today
  }

  useEffect(() => {
    if (loading) return
    setLoading(true)
    searchNormalPopupImgList({ page: 0, size: 4 }).then(res => {
      res.data.content.map(item =>
        setPopupList(prev => [
          ...prev,
          {
            title: item.title,
            imgName: item.imgName,
            visible: checkVisible(item.title),
          },
        ]),
      )

      setLoading(false)
    })
  }, [])

  const [totalVisible, setTotalVisible] = useState(false)

  const checkTotalVisible = () => {
    const visiblePopup = popupList.filter(value => value.visible === true)

    if (visiblePopup.length > 0) {
      setTotalVisible(true)
    } else {
      setTotalVisible(false)
    }
  }

  const closeHandler = (e, isDayClose = false) => {
    checkTotalVisible()
    const title = e
    // 오늘 그만보기 일때
    if (isDayClose) {
      // +1일 계산
      const expiredDate = new Date().getDate()
      // 로컬스토리지 저장
      localStorage.setItem(`popup-${title}`, expiredDate)
    }

    setPopupList(prev => prev.map(item => (item.title === `${title}` ? { ...item, visible: false } : item)))
  }

  useEffect(() => {
    checkTotalVisible()
  }, [popupList])

  // 스타일 객체 정의
  const style = {
    maxHeight: `var(--popup-area-height)`,
    '--popup-area-height': popupHeight, // 동적으로 변경되는 변수
  }

  return (
    <>
      {/* {loading && <WrapperLoading isLoading={loading} />} */}
      <Portal elementId="normal-popup">
        <div className={totalVisible ? 'notice_popup_area' : 'notice_popup_area hide'}>
          <div className="popup_wrapper" style={style} ref={popupWrapperRef}>
            {popupList.map((item, index) => {
              return (
                <div
                  className={item.visible ? 'notice_popup' : 'notice_popup hide'}
                  data-id={index}
                  style={{ zIndex: '1', width: '390px' }}
                >
                  <div className="pop_header">
                    <img
                      style={{ width: '100px', height: 'auto' }}
                      src={`${process.env.PUBLIC_URL}/img/common/logo.png`}
                      alt=""
                    />
                    <button
                      className="btn_close"
                      type="button"
                      value={item.title}
                      onClick={e => {
                        closeHandler(item.title)
                      }}
                    >
                      <span className="icon-iconCross" />
                    </button>
                  </div>
                  <div className="pop_box no-padding" style={{}}>
                    <div className="notice_popup_font1"> </div>
                    <div className="notice_popup_font2">
                      <p>
                        <img
                          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                          src={item.imgName}
                          alt=""
                          width="100%"
                          height="600"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="pop_footer">
                    <button
                      className="btn_today_close"
                      type="button"
                      value={item.title}
                      onClick={e => {
                        closeHandler(item.title, true)
                      }}
                    >
                      오늘 하루 열지 않기
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Portal>
    </>
  )
}

export default React.memo(NoticePopup)
