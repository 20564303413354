import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import MainContentBanner from './MainContentBanner'
import { searchGameUrl, searchLiveVendor, searchSlotVendor } from '../../../api/game/gameApi'
import isLogin from '../../../utils/enums/authority'
import SignInPopup from '../../Popup/SignIn/SignInPopup'
import SignUpPopup from '../../Popup/SignUp/SignUpPopup'
import CasinoGameViewPopup from '../../CasinoGame/CasinoGameViewPopup'
import { searchMainNoticeEventList } from '../../../api/customCenter/customCenterApi'
import { searchRealTimeSliderAllList } from '../../../api/sliderMoney/sliderMoneyApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { MoneyTypeEnums } from '../../../utils/enums/MemberManage/MemberMoneyLogEnums'

const MainInfoZone = ({ apiLoading = false, setApiLoading = () => {}, active = false }) => {
  const navigate = useNavigate()
  /**
   * 로그인 , 회원가입 부분
   */
  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  // 회원가입 -> 로그인 모달
  const onShowSignInModal = () => {
    setSignUpModalShow(false)
    setSignInModalShow(true)
  }

  // 로그인 -> 회원가입 모달
  const onShowSignUpModal = () => {
    setSignInModalShow(false)
    setSignUpModalShow(true)
  }

  const [noticeList, setNoticeist] = useState([])
  const [eventList, setEventList] = useState([])

  useEffect(() => {
    searchMainNoticeEventList().then(res => {
      setNoticeist(res.data.noticeList)
      setEventList(res.data.eventList)
    })
  }, [])

  // 스타일 객체 정의
  const style = {
    '--tl-charge-offset': '-60em',
    '--tl-charge-duration': '20s',
  }

  const [sliderContent, setSliderContent] = useState([])

  const makeMemberId = memberId => {
    const preserveLen = 3 // 유지할 문자열의 길이

    // 유지할 길이가 전체 길이보다 크거나 같으면 원본 문자열 반환
    if (memberId.length <= preserveLen) {
      return memberId
    }

    let temp = memberId.slice(0, preserveLen) // 처음 3자리를 유지

    // 나머지 부분을 '*'로 대체
    for (let i = preserveLen; i < memberId.length; i++) {
      temp += '*'
    }

    return temp
  }

  const fetchRealTimeSliderAllList = async () => {
    await searchRealTimeSliderAllList().then(res => {
      setSliderContent(res.data.content)
    })
  }

  useEffect(() => {
    fetchRealTimeSliderAllList()

    const intervalId = setInterval(fetchRealTimeSliderAllList, 5000)

    return () => {
      clearInterval(intervalId) // 컴포넌트 언마운트 시 인터벌 정리
    }
  }, [])

  return (
    <>
      {signInModalShow && (
        <SignInPopup
          visible={signInModalShow}
          onCloseHandler={onCloseSignInModal}
          onShowSignUpModal={onShowSignUpModal}
        />
      )}
      {signUpModalShow && (
        <SignUpPopup
          visible={signUpModalShow}
          onCloseHandler={onCloseSignUpModal}
          onShowSignInModal={onShowSignInModal}
        />
      )}

      <div className="infoZone pc">
        <div className="info01 wow fadeInUp animated" style={{ animationName: 'none' }}>
          <div className="title">
            <span>공지사항</span>
            <span
              style={{ display: 'inline-block', float: 'right', fontSize: '14px', marginTop: '5px', cursor: 'pointer' }}
              onClick={() => {
                window.scroll({
                  top: 0,
                  behavior: 'smooth',
                })
                navigate('/notice')
              }}
            >
              더보기
            </span>
          </div>
          <div className="listFrame">
            <div className="list">
              {noticeList.map(item => {
                return (
                  <div>
                    <a href="/notice">
                      <div className="message">
                        <font color="#5e5e5e">{item.title}</font>
                        <br />
                      </div>
                      <div className="time"> - </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="info01 wow fadeInUp animated" style={{ animationName: 'none' }}>
          <div className="title">
            <span>실시간 입출금현황</span>
          </div>
          <div className="listFrame">
            <div className="list listC">
              <div className="list-charge flow-list" style={style}>
                {sliderContent.map(item => {
                  return (
                    <div>
                      <a>
                        <div className="time">{convertToKstByFormat(item.time, 'DD일 HH:mm')}</div>
                        <div className="text-left" style={{ fontSize: '12px', color: '#000' }}>
                          {makeMemberId(item.memberId)}
                        </div>
                        <div className="ar col-orange" style={{ fontSize: '12px' }}>
                          {MoneyTypeEnums[item.moneyType]}
                        </div>
                        <div className="ar cash" style={{ fontSize: '12px', color: 'red' }}>
                          {item.amount?.toString().replace(commonReg2, ',')} 원
                        </div>
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="info01 wow fadeInUp animated" style={{ animationName: 'none' }}>
          <div className="title">
            <span>이벤트</span>
            <span
              style={{
                display: 'inline-block',
                float: 'right',
                fontSize: '14px',
                marginTop: '5px',
                cursor: 'pointer',
              }}
              onClick={() => {
                window.scroll({
                  top: 0,
                  behavior: 'smooth',
                })
                navigate('/event')
              }}
            >
              더보기
            </span>
          </div>
          <div className="listFrame">
            <div className="list">
              {eventList.map(item => {
                return (
                  <div>
                    <a href="/event">
                      <div className="message">
                        <font color="#5e5e5e">{item.title}</font>
                        <br />
                      </div>
                      <div className="time"> - </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainInfoZone
