import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { createOnetoOne } from '../../api/customCenter/customCenterApi'
import {
  createUserMoneyDeposit,
  searchDepositRule,
  searchMinimumAmount,
  updateMemberMoneyHistoryDelete,
} from '../../api/memberManage/memberManageApi'
import WrapperLoading from '../../containers/WrapperLoading'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { searchMemberMoneyDeposit } from '../../api/memberHistory/memberHistoryApi'
import { convertToKstByFormat, convertToKstDateTime } from '../../utils/dateTime'
import { MemberDepositStatusEnums } from '../../utils/enums/MemberHistory/MemberDepositEnums'
import CustomPagination from '../../shared/components/CustomPagination'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import { HermesMainText } from '../../utils/palette'

const MoneyDepositPage = () => {
  const [apiLoading, setApiLoading] = useState(false)
  const dispatch = useDispatch()

  /**
   * 입금 내역
   */

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberMoneyDeposit = async () => {
    setApiLoading(true)
    await searchMemberMoneyDeposit(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberMoneyDeposit()
  }, [historyParams])

  // 모두 지우기
  const onDeleteAllHandler = () => {
    if (apiLoading) return

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setApiLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'DEPOSIT',
        moneyHistoryKeyList: content.map(item => item.moneyDepositKey),
      })
        .then(res => {
          setApiLoading(false)
          alert('삭제 완료 되었습니다.')
          fetchSearchMemberMoneyDeposit()
        })
        .catch(error => {
          setApiLoading(false)
          alert('삭제 실패했습니다.')
          fetchSearchMemberMoneyDeposit()
        })
    }
  }

  // 하나 지우기
  const onDeleteHandler = moneyDepositKey => {
    if (apiLoading) return

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setApiLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'DEPOSIT',
        moneyHistoryKeyList: [moneyDepositKey],
      })
        .then(res => {
          setApiLoading(false)
          alert('삭제 완료 되었습니다.')
          fetchSearchMemberMoneyDeposit()
        })
        .catch(error => {
          setApiLoading(false)
          alert('삭제 실패했습니다.')
          fetchSearchMemberMoneyDeposit()
        })
    }
  }

  /**
   * 입금 신청
   */
  const [applyMoney, setApplyMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })

  const [depositBonusList, setDepositBonusList] = useState([])

  // 선택한 보너스 타입
  const [selectedBonusType, setSelectedBonusType] = useState(null)

  const onClickDepositBonusTypeHandler = key => {
    const data = depositBonusList.find(item => item.moneyDepositBonusKey === key)

    if (data) {
      setSelectedBonusType({
        moneyDepositBonusKey: data.moneyDepositBonusKey,
        title: data.title,
        bonusType: data.bonusType,
      })
    }
  }
  const [minimumDeposit, setMinimumDeposit] = useState('0')
  const [depositMaxAmount, setDepositMaxAmount] = useState('0')
  const [myInfoLoading, setMyInfoLoading] = useState(false)
  useEffect(() => {
    searchDepositRule().then(res => {
      setDepositBonusList(res.data.depositBonusList)

      const firstItem = res.data.depositBonusList[0]

      setSelectedBonusType({
        moneyDepositBonusKey: firstItem.moneyDepositBonusKey,
        title: firstItem.title,
        bonusType: firstItem.bonusType,
      })
    })
    searchMinimumAmount()
      .then(res => {
        setMinimumDeposit(res.data.minimumDeposit)
        setDepositMaxAmount(res.data.depositMaxAmount)
        setMyInfoLoading(true)
      })
      .catch(error => {
        setMyInfoLoading(true)
      })
  }, [])

  const onSubmit = () => {
    if (apiLoading) return
    if (!selectedBonusType) {
      alert('충전보너스를 선택해주세요')
      return
    }

    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) > Number(depositMaxAmount)) {
      alert(`최대 ${depositMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 입금 가능합니다.`)
      return
    }

    if (Number(checkMoney) < Number(minimumDeposit)) {
      alert(`최소 ${minimumDeposit?.toString().replace(commonReg2, ',')}원 이상으로 입금 가능합니다.`)
      return
    }

    const body = {
      moneyType: 'DEPOSIT',
      applyMoney: checkMoney,
      bonusType: selectedBonusType?.bonusType || 'BONUS_NONE',
      bonusTypeKr: selectedBonusType?.title || '보너스를 선택하지 않음',
    }

    setApiLoading(true)

    createUserMoneyDeposit(body)
      .then(res => {
        alert('입금신청이 완료되었습니다.')
        setApiLoading(false)
        fetchSearchMemberMoneyDeposit()
        onClearClickHandler()
        dispatch(fetchMemberInfo())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        setApiLoading(false)
        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1010':
            alert('입금이 불가능한 시간입니다.')
            break
          case 'ASSET-1004':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('입금신청이 실패했습니다. 다시 시도해주세요.')
        }

        setApiLoading(false)
        fetchSearchMemberMoneyDeposit()
      })
  }

  const onQuestionAccountHandler = () => {
    if (apiLoading) return
    const body = {
      questionTitle: '자동 계좌문의',
      questionContent: '계좌문의 드립니다.',
    }
    setApiLoading(true)
    createOnetoOne(body)
      .then(res => {
        alert('계좌문의가 접수되었습니다.')
        setApiLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(`${message}`)
            break
          default:
            alert('계좌문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setApiLoading(false)
      })
  }

  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  return (
    <>
      {(apiLoading || myInfoLoading) && <WrapperLoading isLoading={apiLoading} />}
      <div className="main-content">
        <div className="aniFrame">
          <div className="titlePic">
            <div className="titlePwrapper">
              <div className="leftZone">
                <span>입금신청</span>
              </div>
              <div className="line" />
            </div>
          </div>
          <div className="main_content_wrap main_content_wrap_notice">
            <div className="noticeFrame">
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  memberId: userId,
                  memberName: userName,
                  bankName,
                  accountHolder: accountNum,
                  applyMoney,
                  holdingMoney: holdingMoney.toString().replace(commonReg2, ','),
                  depositMaxAmount,
                }}
                // validate={MoneyDepositValidate}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <form className="animated fadeIn delay2" name="frm_cash_in" method="post" onSubmit={handleSubmit}>
                    <div className="info-wrap memo pc">
                      <div className="tit">확인/필독사항</div>
                      <div className="content">
                        ※ 회원가입시 등록된 계좌로만 입금 가능합니다.
                        <br />※ 진행중인 입출금 신청 내역이 있으면 중복 신청 불가능합니다.
                        <br />※ 입금 최소액은{' '}
                        <span style={{ color: 'red' }}>
                          {' '}
                          {minimumDeposit?.toString().replace(commonReg2, ',') || 0}원
                        </span>
                        , 1회 입금 최대액
                        <span style={{ color: 'red' }}> (레벨별 상이)</span>은{' '}
                        <span style={{ color: 'red' }}>
                          {' '}
                          {depositMaxAmount?.toString().replace(commonReg2, ',') || 0}원
                        </span>{' '}
                        입니다.
                        {/* <br />
                        ※ 코인충전시 사이트 입금신청금액과 동일한 금액 충전하셔야 정상처리가 됩니다. */}
                        <br />
                      </div>
                    </div>
                    <div className="info-wrap memo mob">
                      <div className="tit">확인/필독사항</div>
                      <div className="content" style={{ fontSize: '12px' }}>
                        ※ 회원가입시 등록된 계좌로만 입금 가능합니다.
                        <br />※ 진행중인 입출금 신청 내역이 있으면 중복 신청 불가능합니다.
                        <br />※ 입금 최소액은{' '}
                        <span style={{ color: 'red' }}>
                          {' '}
                          {minimumDeposit?.toString().replace(commonReg2, ',') || 0}원
                        </span>
                        <br /> 1회 입금 최대액
                        <span style={{ color: 'red' }}> (레벨별 상이)</span>은{' '}
                        <span style={{ color: 'red' }}>
                          {' '}
                          {depositMaxAmount?.toString().replace(commonReg2, ',') || 0}원
                        </span>{' '}
                        입니다.
                        {/* <br />
                        ※ 코인충전시 사이트 입금신청금액과 동일한 금액 충전하셔야 정상처리가 됩니다. */}
                        <br />
                      </div>
                    </div>
                    <div className="info-wrap cash mt15">
                      <div className="input-wrap">
                        <div className="desc">은행명</div>
                        <Field name="bankName">{({ input, meta }) => <input {...input} type="text" readOnly />}</Field>
                      </div>

                      <div className="input-wrap">
                        <div className="desc">예금주</div>
                        <Field name="memberName">
                          {({ input, meta }) => <input {...input} type="text" readOnly />}
                        </Field>
                      </div>

                      {/* <div className="input-wrap">
                        <div className="desc">계좌번호</div>
                        <Field name="accountHolder">
                          {({ input, meta }) => <input {...input} type="text" readOnly />}
                        </Field>
                      </div> */}

                      <div className="input-wrap">
                        <div className="desc">보유 금액</div>
                        <Field name="holdingMoney">
                          {({ input, meta }) => <input {...input} type="text" className="userCashAmount" readOnly />}
                        </Field>
                      </div>

                      <div className="input-wrap pc">
                        <div className="desc">
                          충전보너스 선택 <span />
                        </div>
                        <Field name="bonusType">
                          {({ input, meta }) => (
                            <select
                              {...input}
                              type="text"
                              name="bonus_id"
                              value={selectedBonusType?.moneyDepositBonusKey}
                              onChange={e => {
                                onClickDepositBonusTypeHandler(e.target.value)
                              }}
                            >
                              {depositBonusList.map(item => (
                                <option value={item.moneyDepositBonusKey} key={item.moneyDepositBonusKey}>
                                  {item.title} {item.content && `- ${item.content}`}
                                </option>
                              ))}
                            </select>
                          )}
                        </Field>
                      </div>
                      <div className="input-wrap mob">
                        <div className="desc">
                          충전보너스 선택 <span />
                        </div>
                        <Field name="bonusType">
                          {({ input, meta }) => (
                            <select
                              {...input}
                              type="text"
                              style={{ fontSize: '11px' }}
                              name="bonus_id"
                              value={selectedBonusType?.moneyDepositBonusKey}
                              onChange={e => {
                                onClickDepositBonusTypeHandler(e.target.value)
                              }}
                            >
                              {depositBonusList.map(item => (
                                <option value={item.moneyDepositBonusKey} key={item.moneyDepositBonusKey}>
                                  {item.title} {item.content && `- ${item.content}`}
                                </option>
                              ))}
                            </select>
                          )}
                        </Field>
                      </div>

                      <div className="input-wrap">
                        <div className="desc">
                          입금 금액 <span>*</span>
                        </div>
                        <Field name="applyMoney">
                          {({ input, meta }) => (
                            <input
                              {...input}
                              type="text"
                              value={applyMoney}
                              name="amount"
                              onChange={e => {
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setApplyMoney(onlyNumber.toString().replace(commonReg2, ','))
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className="money-wrap">
                        <button
                          type="button"
                          className="money btn_plus"
                          data-value="10000"
                          onClick={e => {
                            onClickMoneyHandler('10000')
                          }}
                        >
                          1만원
                        </button>
                        <button
                          type="button"
                          className="money btn_plus"
                          data-value="50000"
                          onClick={e => {
                            onClickMoneyHandler('50000')
                          }}
                        >
                          5만원
                        </button>
                        <button
                          type="button"
                          className="money btn_plus"
                          data-value="100000"
                          onClick={e => {
                            onClickMoneyHandler('100000')
                          }}
                        >
                          10만원
                        </button>
                        <button
                          type="button"
                          className="money btn_plus"
                          data-value="500000"
                          onClick={e => {
                            onClickMoneyHandler('500000')
                          }}
                        >
                          50만원
                        </button>
                        <button
                          type="button"
                          className="money btn_plus"
                          data-value="1000000"
                          onClick={e => {
                            onClickMoneyHandler('1000000')
                          }}
                        >
                          100만원
                        </button>
                        <button
                          type="button"
                          className="money btn_plus"
                          data-value="5000000"
                          onClick={e => {
                            onClickMoneyHandler('5000000')
                          }}
                        >
                          500만원
                        </button>
                        <button
                          type="button"
                          className="money btn_reset"
                          data-other="reset"
                          onClick={() => {
                            onClearClickHandler()
                          }}
                        >
                          초기화
                        </button>
                      </div>
                    </div>

                    <div className="button-wrap mt20">
                      <button type="button" className="btnN redB deposit-qa" onClick={onQuestionAccountHandler}>
                        계좌문의
                      </button>

                      <button type="submit" className="btnN blueB btn-primary">
                        입금신청
                      </button>
                    </div>
                    <div className="bank-wrap bank_req hide">
                      <p>
                        <strong id="bank_info" />
                      </p>
                      <p>
                        <strong id="bank_info_req" />
                      </p>
                    </div>
                  </form>
                )}
              </Form>
              <div>
                <div className="listZone">
                  <table>
                    <thead>
                      <tr>
                        <th className="ac number" width="10%">
                          No.
                        </th>
                        <th className="ac" scope="col">
                          신청액
                        </th>
                        <th className="ac pc">지급액</th>
                        <th className="ac">처리일</th>
                        <th className="ac">상태</th>
                        <th className="ac">
                          <button
                            className={
                              totalElement === 0
                                ? 'btn darken-hover btn-delete-all disabled'
                                : 'btn darken-hover btn-delete-all'
                            }
                            type="button"
                            onClick={() => {
                              onDeleteAllHandler()
                            }}
                          >
                            <span className="icon-iconTrash" />
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {content.map(item => (
                        <tr>
                          <td>{item.num}</td>
                          <td>
                            <p className="money">{item.applyMoney?.toString().replace(commonReg2, ',')}</p>
                          </td>
                          <td className="pc">
                            <p className="money">{item.paymentMoney?.toString().replace(commonReg2, ',')}</p>
                          </td>
                          <td className="pc">{convertToKstDateTime(item.executeDate)}</td>
                          <td className="mob">{convertToKstByFormat(item.executeDate, 'MM-DD')}</td>
                          <td>{MemberDepositStatusEnums[item.status]}</td>
                          <td>
                            <button
                              className="btn badge darken-hover"
                              type="button"
                              onClick={() => {
                                onDeleteHandler(item.moneyDepositKey)
                              }}
                            >
                              <span className="icon-iconTrash" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {totalElement === 0 && (
                    <div className="tb_empty">
                      <div className="pic">
                        <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
                      </div>
                      <h2>데이터 없음</h2>
                      <h3>Sorry, Empty Data</h3>
                    </div>
                  )}

                  {totalElement !== 0 && (
                    <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MoneyDepositPage
