import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomAlert from '../../../containers/CustomAlert'
import WrapperLoading from '../../../containers/WrapperLoading'
import {
  onClickBettingCartAlertCloseHandler,
  onClickBettingCartUpdateMessageCloseHandler,
} from '../../../redux/sportsGameBettingSlice'
import CrossSportsBettingCart from './CrossSportsBettingCart'
import CrossSportsMainFrame from './CrossSportsMainFrame'
import CrossSportsSideMenu from './CrossSportsSideMenu'

const CrossSportsPage = () => {
  const dispatch = useDispatch()

  const { loading, bettingCartAlertMessage, bettingCartUpdateMessage } = useSelector(state => {
    const { sportsGameBetting } = state

    return {
      loading: sportsGameBetting.loading,
      bettingCartAlertMessage: sportsGameBetting.bettingCartAlertMessage,
      bettingCartUpdateMessage: sportsGameBetting.bettingCartUpdateMessage,
    }
  })

  const onClickCloseHandler = () => {
    dispatch(onClickBettingCartAlertCloseHandler())
  }

  const onClickBettingCartUpdateCloseHandler = () => {
    dispatch(onClickBettingCartUpdateMessageCloseHandler())
  }

  return (
    <>
      {loading && <WrapperLoading isLoading={loading} />}
      {bettingCartAlertMessage && (
        <CustomAlert info={bettingCartAlertMessage} onClickCloseHandler={onClickCloseHandler} />
      )}
      {bettingCartUpdateMessage && (
        <CustomAlert info={bettingCartUpdateMessage} onClickCloseHandler={onClickBettingCartUpdateCloseHandler} />
      )}
      <div className="main_content_sport SportContent" style={{}}>
        <div className="sportFrame">
          <CrossSportsSideMenu />

          <CrossSportsMainFrame />

          <CrossSportsBettingCart />
        </div>
      </div>
    </>
  )
}

export default CrossSportsPage
