import axios from '../base/axios'

// 파워볼 정보 조회
export async function searchPowerball(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/powerball',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 미니게임 배팅
export async function bettingForMiniGame(body) {
  const response = await axios({
    url: '/tyson/member/v1/game/mini-game/betting',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 미니게임 배팅 이력 조회
export async function searchMiniGameBettingLog(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/mini-game/betting',
    method: 'Get',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 미니게임 배팅 이력 전부 조회
export async function searchMiniGameBettingLogAll(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/mini-game/betting-all',
    method: 'Get',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 미니게임 배팅내역 삭제
export async function deleteMiniGameBettingHistory(body) {
  const response = await axios({
    url: '/tyson/member/v1/game/mini-game/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}
