import { createGlobalStyle } from 'styled-components'

import { reset } from 'styled-reset'
import {
  colorAccent,
  colorAdditional,
  colorBackgroundBody,
  colorBlue,
  colorBlueHover,
  colorMainBody,
  colorMainBodyBackground,
  colorRed,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '../utils/palette'

import '../css/animate.css'
import '../css/sweetalert2.css'
import '../css/icomoon.css'
import '../css/slick.css'
import '../css/daterangepicker.css'
import '../css/pagination.css'
import '../css/common.css'
import '../css/style.css'
import '../css/main.css'
import '../css/games.css'
import '../css/sports.css'
import '../css/mini.css'
import '../css/mobile.css'

/**
 * @see : https://spring-in-wonderland.tistory.com/15 [스크롤]
 */

const GlobalStyles = createGlobalStyle`
  ${reset} 

  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 300;
    src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Light.otf) format('woff2'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Light.otf) format('woff'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Light.otf) format('truetype');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 400;
    src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Regular.otf) format('woff2'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Regular.otf) format('woff'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Regular.otf) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 500;
    src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Medium.otf) format('woff2');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Bold.otf) format('woff2'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Bold.otf) format('woff'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Bold.otf) format('truetype');
  }

  
`

export default GlobalStyles
