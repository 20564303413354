import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { bettingForMiniGame, searchPowerball } from '../../api/game/miniGameApi'
import BettingCartFormField from '../../containers/Layout/BettingCartFormField'
import WrapperLoading from '../../containers/WrapperLoading'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import {
  setBettingCloseHandler,
  setBettingMoneyHandler,
  setCloseHandler,
  setMaxChecked,
  setMinChecked,
  setMiniGameBettingLogTableFlag,
  setMiniGameCompleteFlag,
  setMiniGameInspect,
  setMiniGameRefreshFlag,
  setResetData,
  setResetRoundAndGameTypeHandler,
} from '../../redux/miniGameBettingSlice'
import { MiniGameTypeEnums, PowerBallTypeEnums } from '../../utils/enums/MiniGame/PowerBallEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'
import MiniGameNtryEosPowerBallOne from './ntry/MiniGameNtryEosPowerBallOne'
import MiniGameNtryEosPowerBallTwo from './ntry/MiniGameNtryEosPowerBallTwo'
import MiniGameNtryEosPowerBallThree from './ntry/MiniGameNtryEosPowerBallThree'
import MiniGameNtryEosPowerBallFour from './ntry/MiniGameNtryEosPowerBallFour'
import MiniGameNtryEosPowerBallFive from './ntry/MiniGameNtryEosPowerBallFive'
import MiniGameNtryPowerBall from './ntry/MiniGameNtryPowerBall'
import MiniGameNtryPowerLadder from './ntry/MiniGameNtryPowerLadder'
import MiniGameNtryKenoLadder from './ntry/MiniGameNtryKenoLadder'
import MiniGameNtrySpeedKeno from './ntry/MiniGameNtrySpeedKeno'
import { HermesMainText } from '../../utils/palette'

// 현재시간 기준 seconds 단위 가져오기
const getCurrentTime = () => {
  const currentTime = new Date()
  const timestampInSeconds = Math.floor(currentTime.getTime() / 1000)
  return timestampInSeconds
}

// 현재시간 기준 00초로 변환해서 seconds 단위 가져오기
const getCurrentTimeWithZeroSeconds = () => {
  const currentTime = new Date()
  currentTime.setSeconds(0)
  const timestampInSeconds = Math.floor(currentTime.getTime() / 1000)
  return timestampInSeconds
}

// 현재시간 기준 +1 분 00초로 변환해서 seconds 단위 가져오기
const getCurrentTimePlusOneMinuteWithZeroSeconds = () => {
  const currentTime = new Date()
  currentTime.setSeconds(0)
  currentTime.setMinutes(currentTime.getMinutes() + 1)
  const timestampInSeconds = Math.floor(currentTime.getTime() / 1000)
  return timestampInSeconds
}

const MiniGameNtryBettingCart = ({ activeMenu }) => {
  const [apiLoading, setApiLoading] = useState(false)

  const dispatch = useDispatch()

  const [powerBallInfo, setPowerBallInfo] = useState({})

  const [startTime, setStartTime] = useState(getCurrentTimeWithZeroSeconds())
  const [endTime, setEndTime] = useState(getCurrentTimePlusOneMinuteWithZeroSeconds())
  // 서버 시간 == 현재 시간
  const [currentTime, setCurrentTime] = useState(getCurrentTime())

  const intervalRef = useRef(null)

  // svt : serverTime , stt : startTime
  const getCurrentTimeDifference = deadLine => {
    let totalSeconds = endTime - currentTime - deadLine

    if (totalSeconds < 0) {
      totalSeconds = 0
    }

    return {
      minutes: Math.floor(totalSeconds / 60),
      seconds: totalSeconds % 60,
    }
  }

  const [time, setTime] = useState(getCurrentTimeDifference(30))
  const [deadLineTime, setDeadLineTime] = useState(0)
  const [bettingCountOkFlag, setBettingCountOkFlag] = useState(false)

  const formatSeconds = seconds => {
    if (seconds === -1) {
      return '00'
    }
    return seconds < 10 ? `0${seconds}` : seconds
  }

  const updateTimer = () => {
    setCurrentTime(getCurrentTime())
    setTime(getCurrentTimeDifference(deadLineTime))
  }

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(updateTimer, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [time])

  const getMiniGameBettingInfo = state => {
    const { miniGameType } = state

    if (miniGameType === 'NTRY_POWER_BALL') {
      return state.ntryPowerBallInfo
    }
    if (miniGameType === 'NTRY_POWER_LADDER') {
      return state.ntryPowerLadderInfo
    }
    if (miniGameType === 'NTRY_SPEED_KENO') {
      return state.ntrySpeedKenoInfo
    }
    if (miniGameType === 'NTRY_KENO_LADDER') {
      return state.ntryKenoLadderInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_1M') {
      return state.ntryEosPowerBallOneInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_2M') {
      return state.ntryEosPowerBallTwoInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_3M') {
      return state.ntryEosPowerBallThreeInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_4M') {
      return state.ntryEosPowerBallFourInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_5M') {
      return state.ntryEosPowerBallFiveInfo
    }

    return null
  }

  const {
    miniGameBettingPer,
    miniGameType,
    miniGameBettingType,
    miniGameRound,
    miniGameBettingLogTableFlag,
    bettingCloseFlag,
    bettingCompleteFlag,
    bettingRefreshFlag,
    bettingMoney,
    holdingMoney,
    miniGameMaxBetting,
    miniGameMinBetting,
    miniGameMaxWinning,
    memberTotalGameMoney,
    userId,
    memberLevel,
    bettingCartNum,
    noteRedisSize,
    miniGameInspect,
  } = useSelector(state => {
    const { miniGameBetting, memberInfo } = state
    const miniGameBettingInfo = getMiniGameBettingInfo(miniGameBetting)
    if (miniGameBettingInfo) {
      return {
        miniGameInspect: state.miniGameBetting.miniGameInspect,
        miniGameType: state.miniGameBetting.miniGameType,
        miniGameBettingLogTableFlag: state.miniGameBetting.miniGameBettingLogTableFlag,
        bettingCloseFlag: miniGameBettingInfo.bettingCloseFlag,
        bettingCompleteFlag: miniGameBettingInfo.bettingCompleteFlag,
        bettingRefreshFlag: miniGameBettingInfo.bettingRefreshFlag,
        miniGameBettingPer: miniGameBettingInfo.miniGameBettingPer,
        miniGameBettingType: miniGameBettingInfo.miniGameBettingType,
        miniGameRound: miniGameBettingInfo.miniGameRound,
        bettingMoney: miniGameBettingInfo.bettingMoney,
        holdingMoney: memberInfo.memberHoldingMoney,
        memberTotalGameMoney: memberInfo.memberTotalGameMoney,
        userId: memberInfo.userId,
        memberLevel: memberInfo.depositLevel,
        miniGameMaxBetting: memberInfo.miniGameBettingInfo.miniGameMaxBetting,
        miniGameMinBetting: memberInfo.miniGameBettingInfo.miniGameMinBetting,
        miniGameMaxWinning: memberInfo.miniGameBettingInfo.miniGameMaxWinning,
        bettingCartNum: miniGameBettingInfo.miniGameBettingPer && 1,
        noteRedisSize: memberInfo.noteRedisSize,
      }
    }
    return {
      miniGameType: '',
      miniGameInspect: state.miniGameBetting.miniGameInspect,
      miniGameBettingLogTableFlag: false,
      bettingCloseFlag: false,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      miniGameBettingPer: 0,
      miniGameBettingType: '',
      miniGameRound: 0,
      bettingMoney: 0,
      holdingMoney: memberInfo.memberHoldingMoney,
      miniGameMaxBetting: memberInfo.miniGameBettingInfo.miniGameMaxBetting,
      miniGameMinBetting: memberInfo.miniGameBettingInfo.miniGameMinBetting,
      miniGameMaxWinning: memberInfo.miniGameBettingInfo.miniGameMaxWinning,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      memberLevel: memberInfo.depositLevel,
      bettingCartNum: 0,
      noteRedisSize: memberInfo.noteRedisSize,
    }
  })

  const [powerBallParams, setPowerBallParams] = useState({
    miniGameType: activeMenu,
  })

  useEffect(() => {
    setPowerBallParams({
      miniGameType: activeMenu,
    })
  }, [activeMenu])

  const fetchSearchPowerball = async () => {
    await searchPowerball(powerBallParams)
      .then(res => {
        setPowerBallInfo({
          ...res.data,
          miniGameBettingPer: JSON.parse(res.data.miniGameBettingPer),
        })
        setBettingCountOkFlag(res.data.bettingCountOkFlag)
        setStartTime(res.data.startTime)
        setEndTime(res.data.endTime)
        setCurrentTime(res.data.serverTime)
        setDeadLineTime(res.data.deadLineTime)
        setTime(getCurrentTimeDifference(res.data.deadLineTime))
        dispatch(setMiniGameInspect(!res.data.isShow))
        dispatch(
          setResetRoundAndGameTypeHandler({
            miniGameType: res.data.miniGameType,
            miniGameRound: res.data.round,
          }),
        )
        if (!bettingCompleteFlag) {
          dispatch(setResetData())
        }
      })
      .catch(error => {})
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentTime >= endTime) {
        fetchSearchPowerball()
        dispatch(setMiniGameCompleteFlag(false))
        dispatch(setResetData())
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [currentTime, endTime])

  useEffect(() => {
    setApiLoading(true)
    dispatch(setMinChecked(false))
    dispatch(setMaxChecked(false))
    fetchSearchPowerball()

    const timeoutId = setTimeout(() => {
      setApiLoading(false)
    }, 600)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [powerBallParams, bettingCompleteFlag])

  const [inputBettingMoney, setInputBettingMoney] = useState(Number(bettingMoney) || 0)
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)
  const [cartFlag, setCartFlag] = useState(false) // true일 때 빠지는거

  const handleMiniGame = type => {
    // 해당 테이블을 fetch 해줄려고 쓰는거
    dispatch(setMiniGameBettingLogTableFlag(!miniGameBettingLogTableFlag))
  }

  const maxChecked = useSelector(state => state.miniGameBetting.maxChecked)
  const minChecked = useSelector(state => state.miniGameBetting.minChecked)

  const onClickCloseHandler = event => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(setCloseHandler())
  }

  const isDisabled = time.minutes === 0 && time.seconds === 0

  // 배팅 마감일 때 배팅카트 초기화
  useEffect(() => {
    dispatch(setBettingCloseHandler(isDisabled))
  }, [isDisabled])

  const onSubmit = e => {
    if (apiFlag) return

    setApiFlag(true)
    if (!miniGameBettingPer || miniGameBettingPer === 0 || inputBettingMoney === 0) {
      setApiFlag(false)
      return
    }

    if (Number(holdingMoney) < Number(bettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(miniGameMinBetting)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (Number(inputBettingMoney) > Number(miniGameMaxBetting)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(miniGameMaxWinning)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (window.confirm('배팅하시겠습니까?')) {
      const body = {
        miniGameType,
        miniGameBettingType,
        bettingAmount: inputBettingMoney?.toString().replaceAll(',', ''),
        expectMoney: expectMoney?.toString().replaceAll(',', ''),
        bettingPer: miniGameBettingPer,
        bettingAt: moment().tz('Asia/Seoul').format(),
        gameRound: miniGameRound,
      }

      bettingForMiniGame(body)
        .then(res => {
          setApiFlag(false)
          dispatch(setMiniGameCompleteFlag(!bettingCompleteFlag))
          dispatch(setBettingMoneyHandler(inputBettingMoney?.toString().replaceAll(',', '')))
          alert('배팅이 정상적으로 이루어졌습니다.')
          handleMiniGame(miniGameType)
          dispatch(fetchMemberInfo())
          dispatch(setResetData())
          dispatch(setMaxChecked(false))
          dispatch(setMinChecked(false))
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          switch (errorCode) {
            case 'SYSTEM-1017':
              alert(`${message}`)
              break
            case 'SYSTEM-1018':
              alert(`${message}`)
              break
            case 'MINIGAME-1003':
              alert(`${message}`)
              break
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            default:
              alert('배팅이 실패했습니다.')
          }
          setApiFlag(false)
          onClickCloseHandler()
          dispatch(setResetData())
          handleMiniGame(miniGameType)
          dispatch(fetchMemberInfo())
          dispatch(setMaxChecked(false))
          dispatch(setMinChecked(false))
        })
    } else {
      setApiFlag(false)
    }
  }

  useEffect(() => {
    // if (bettingCompleteFlag) return
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니
    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
    if (maxChecked) {
      // 최대가 체크가 되어 있을때
      resultBettingMoney = Math.floor(miniGameMaxWinning / miniGameBettingPer)
      if (resultBettingMoney > miniGameMaxBetting) {
        // 배팅머니가 최대 배팅금액을 넘어 설때
        resultBettingMoney = miniGameMaxBetting
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
      } else {
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
      }
    } else {
      if (inputRealMoney > miniGameMaxBetting) {
        // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
        resultBettingMoney = miniGameMaxBetting
        // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
        if (resultExpectMoney > miniGameMaxWinning) {
          resultBettingMoney = Math.floor(miniGameMaxWinning / miniGameBettingPer) // 비율에 따른 최대 배팅금액
          resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
        }
      }

      if (resultExpectMoney > miniGameMaxWinning) {
        // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
        resultBettingMoney = Math.floor(miniGameMaxWinning / miniGameBettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
      }
    }
    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))
    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [maxChecked, inputBettingMoney, miniGameBettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney
    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue

      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }

      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  const onClickMaxMoneyHandler = event => {}

  const onClickMinMoneyHandler = event => {
    setInputBettingMoney(miniGameMinBetting)
  }

  const onClickResetMoneyHandler = event => {
    setInputBettingMoney(0)
    dispatch(setBettingMoneyHandler(0))
  }
  const formRef = useRef(null)
  useEffect(() => {
    let result = false
    if (bettingCompleteFlag) {
      result = false
      if (miniGameBettingPer !== 0) {
        result = true
      }
    } else if (miniGameRound && miniGameBettingPer) {
      result = true
      if (bettingCloseFlag) {
        dispatch(setResetData())
        result = false
      }
    } else {
      result = false
      setCartFlag(result)
      if (formRef.current) {
        formRef.current.reset()
      }
      const timeoutId = setTimeout(() => {
        dispatch(setMiniGameRefreshFlag(!bettingRefreshFlag))
      }, 7000)
      return () => {
        clearTimeout(timeoutId)
      }
    }
    setCartFlag(result)
  }, [bettingCloseFlag, bettingCompleteFlag, miniGameRound, miniGameBettingPer, miniGameType])

  useEffect(() => {
    if (bettingCompleteFlag) return
    if (!maxChecked) {
      setInputBettingMoney(0)
    }

    if (!minChecked) {
      setInputBettingMoney(0)
    }

    if (minChecked) {
      setInputBettingMoney(miniGameMinBetting?.toString().replace(commonReg2, ','))
    }
  }, [maxChecked, minChecked])

  useEffect(() => {
    setInputBettingMoney(bettingMoney)
  }, [bettingMoney, miniGameType])

  const getPickSectionCompo = () => {
    switch (activeMenu) {
      case 'NTRY_EOS_POWER_BALL_1M':
        return <MiniGameNtryEosPowerBallOne powerBallInfo={powerBallInfo} />
      case 'NTRY_EOS_POWER_BALL_2M':
        return <MiniGameNtryEosPowerBallTwo powerBallInfo={powerBallInfo} />
      case 'NTRY_EOS_POWER_BALL_3M':
        return <MiniGameNtryEosPowerBallThree powerBallInfo={powerBallInfo} />
      case 'NTRY_EOS_POWER_BALL_4M':
        return <MiniGameNtryEosPowerBallFour powerBallInfo={powerBallInfo} />
      case 'NTRY_EOS_POWER_BALL_5M':
        return <MiniGameNtryEosPowerBallFive powerBallInfo={powerBallInfo} />
      case 'NTRY_POWER_BALL':
        return <MiniGameNtryPowerBall powerBallInfo={powerBallInfo} />
      case 'NTRY_SPEED_KENO':
        return <MiniGameNtrySpeedKeno powerBallInfo={powerBallInfo} />
      case 'NTRY_KENO_LADDER':
        return <MiniGameNtryKenoLadder powerBallInfo={powerBallInfo} />
      case 'NTRY_POWER_LADDER':
        return <MiniGameNtryPowerLadder powerBallInfo={powerBallInfo} />
      default:
        return <MiniGameNtryEosPowerBallOne powerBallInfo={powerBallInfo} />
    }
  }

  const [isMobileBettingCartShow, setIsMobileBettingCartShow] = useState(false)

  // 1. 배팅하고나서 배팅 마감 됐을 때
  // 2. 배팅 완료 됐을 떄
  // 22 . 배팅 마감 시간 됐을 때, 잠기는것
  // 3. 해당 종목 점검일 떄, 잠기는것
  // 4. 새로운 회차 열릴 때 => 배팅 카트 다음 회차로 초기화  -> O
  // 5. 다른 탭 갔다 와도 배팅 완료된것 + 배탕 마감된것 + 점검인것 유지 되어야함

  return (
    <>
      {(apiLoading || apiFlag) && <WrapperLoading isLoading={apiLoading} />}
      <div className="bet_section">
        <div className={isDisabled || !bettingCountOkFlag || miniGameInspect ? 'bet-overlay show' : 'bet-overlay'} />
        {getPickSectionCompo()}
        <div className={isMobileBettingCartShow ? 'board-box aside' : 'board-box'}>
          <div className="title-wrap">
            <div
              className="mob x-nav"
              onClick={() => {
                setIsMobileBettingCartShow(false)
              }}
            >
              <span className="icon-iconCross" />
            </div>
            <div className="title">
              {MiniGameTypeEnums[miniGameType]} {miniGameRound} 회
            </div>
            <div className="round-info">
              <span className="round" style={{ color: 'red' }}>
                배팅마감 [ {time.minutes} : {formatSeconds(time.seconds)} ]
              </span>
            </div>
          </div>
          <div className="bet-info">
            <span className="mark" />
          </div>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              miniGameBettingPer,
              maxChecked,
              minChecked,
              inputBettingMoney,
            }}
          >
            {({ handleSubmit, form: { reset } }) => (
              <form onSubmit={handleSubmit}>
                <div className="bet-cart-box">
                  <div className="info-box">
                    <div className="">
                      <span className="txt">선택</span>
                      <span className="pickName" style={{ color: 'red' }}>
                        {PowerBallTypeEnums[miniGameBettingType]}
                      </span>
                    </div>
                    <div className="">
                      <span className="txt">배당</span>
                      <span className="alloc">{miniGameBettingPer || 0}</span>
                    </div>
                    <div className="">
                      <span className="txt">보유액</span>
                      <span className="myCash">{holdingMoney?.toString().replace(commonReg2, ',') || 0}</span>
                    </div>
                    <div className="">
                      <span className="txt">적중액</span>
                      <span className="hitCash" style={{ color: 'red' }}>
                        {expectMoney?.toString().replace(commonReg2, ',') || 0}
                      </span>
                    </div>
                  </div>
                  <div className="bet-cash">
                    <span className="txt">베팅금액</span>
                    <Field
                      name="inputBettingMoney"
                      component={BettingCartFormField}
                      isAboveError={false}
                      wrapperClassName="betting-amount-input"
                    >
                      {({ input }) => (
                        <input
                          type="text"
                          name="inputBettingMoney"
                          value={inputBettingMoney}
                          placeholder={inputBettingMoney}
                          onChange={e => {
                            const value = e.target.value.trim()
                            const numbersOnly = value.match(/\d+/g)?.join('')

                            if (!numbersOnly) {
                              setInputBettingMoney('0')
                            } else {
                              setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                            }

                            input.onChange(e)
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="bet-btn-box">
                  <button type="button" className="btn btn-plus" onClick={() => onClickMoneyHandler('10000')}>
                    1만
                  </button>
                  <button type="button" className="btn btn-plus" onClick={() => onClickMoneyHandler('30000')}>
                    3만
                  </button>
                  <button type="button" className="btn btn-plus" onClick={() => onClickMoneyHandler('50000')}>
                    5만
                  </button>
                  <button type="button" className="btn btn-plus" onClick={() => onClickMoneyHandler('100000')}>
                    10만
                  </button>
                  <button type="button" className="btn btn-plus" onClick={() => onClickMoneyHandler('300000')}>
                    30만
                  </button>
                  <button type="button" className="btn btn-plus" onClick={() => onClickMoneyHandler('500000')}>
                    50만
                  </button>
                  <button type="button" className="btn btn-plus" onClick={() => onClickMoneyHandler('1000000')}>
                    100만
                  </button>
                  <button type="button" className="btn btn-reset" onClick={() => onClickResetMoneyHandler()}>
                    초기화
                  </button>
                  <button type="button" className="btn btn-max" onClick={() => onClickMoneyHandler('')}>
                    MAX
                  </button>
                  <button type="submit" className="btn btn-bet">
                    Betting
                  </button>
                </div>

                <div className="bet-cart-box">
                  <div className="bet-cash">
                    <span className="txt">최소 배팅금액</span>
                    <span className="hitCash" style={{ width: '100%', textAlign: 'end', float: 'right' }}>
                      {miniGameMinBetting?.toString().replace(commonReg2, ',') || 0} 원
                    </span>
                  </div>
                  <div className="bet-cash">
                    <span className="txt">최대 배팅금액</span>
                    <span className="hitCash" style={{ width: '100%', textAlign: 'end', float: 'right' }}>
                      {miniGameMaxBetting?.toString().replace(commonReg2, ',') || 0} 원
                    </span>
                  </div>
                  <div className="bet-cash">
                    <span className="txt">최대 당첨금액</span>
                    <span className="hitCash" style={{ width: '100%', textAlign: 'end', float: 'right' }}>
                      {miniGameMaxWinning?.toString().replace(commonReg2, ',') || 0} 원
                    </span>
                  </div>
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>

      {/* <div
        className="bet_cart mob"
        onClick={() => {
          setIsMobileBettingCartShow(true)
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/img/common/bet_cart.png`} alt="" />
      </div> */}
      {!isMobileBettingCartShow && (
        <div
          id="open-cart"
          className="mob ui-draggable ui-draggable-handle"
          onClick={() => {
            setIsMobileBettingCartShow(true)
          }}
        >
          <span className="icon-cart" />
          <b>{bettingCartNum}</b>
        </div>
      )}
    </>
  )
}

export default MiniGameNtryBettingCart
