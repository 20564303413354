import { createSlice } from '@reduxjs/toolkit'

const miniGameBettingSlice = createSlice({
  name: 'miniGameBetting',
  initialState: {
    miniGameType: null,
    miniGameInspect: false,
    ntryPowerBallInfo: {
      miniGameBettingPer: null,
      bettingCloseFlag: false,
      miniGameBettingType: null,
      miniGameRound: null,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      bettingMoney: 0,
    },
    ntryPowerLadderInfo: {
      miniGameBettingPer: null,
      bettingCloseFlag: false,
      miniGameBettingType: null,
      miniGameRound: null,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      bettingMoney: 0,
    },
    ntrySpeedKenoInfo: {
      miniGameBettingPer: null,
      bettingCloseFlag: false,
      miniGameBettingType: null,
      miniGameRound: null,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      bettingMoney: 0,
    },
    ntryKenoLadderInfo: {
      miniGameBettingPer: null,
      bettingCloseFlag: false,
      miniGameBettingType: null,
      miniGameRound: null,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      bettingMoney: 0,
    },
    ntryEosPowerBallOneInfo: {
      miniGameBettingPer: null,
      bettingCloseFlag: false,
      miniGameBettingType: null,
      miniGameRound: null,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      bettingMoney: 0,
    },
    ntryEosPowerBallTwoInfo: {
      miniGameBettingPer: null,
      bettingCloseFlag: false,
      miniGameBettingType: null,
      miniGameRound: null,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      bettingMoney: 0,
    },
    ntryEosPowerBallThreeInfo: {
      miniGameBettingPer: null,
      bettingCloseFlag: false,
      miniGameBettingType: null,
      miniGameRound: null,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      bettingMoney: 0,
    },
    ntryEosPowerBallFourInfo: {
      miniGameBettingPer: null,
      bettingCloseFlag: false,
      miniGameBettingType: null,
      miniGameRound: null,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      bettingMoney: 0,
    },
    ntryEosPowerBallFiveInfo: {
      miniGameBettingPer: null,
      bettingCloseFlag: false,
      miniGameBettingType: null,
      miniGameRound: null,
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
      bettingMoney: 0,
    },
    memberLevel: null,
    miniGameBettingLogTableFlag: false,
    memberHoldingMoney: null,
    memberTotalGameMoney: null,
    memberHoldingPoint: null,
    miniGameMaxBetting: null,
    miniGameMinBetting: null,
    miniGameMaxWinning: null,
    maxChecked: false,
    minChecked: false,
  },
  reducers: {
    // 배팅 money 바꿔주기
    setBettingMoneyHandler: (state, action) => {
      switch (state.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.bettingMoney = action.payload
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.bettingMoney = action.payload
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.bettingMoney = action.payload
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.bettingMoney = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.bettingMoney = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.bettingMoney = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.bettingMoney = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.bettingMoney = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.bettingMoney = action.payload
          break
        default:
      }
    },
    // tab이동 시, minigameType 담아주기
    setResetRoundAndGameTypeHandler: (state, action) => {
      state.miniGameType = action.payload.miniGameType
      switch (action.payload.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.miniGameRound = action.payload.miniGameRound
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.miniGameRound = action.payload.miniGameRound
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.miniGameRound = action.payload.miniGameRound
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.miniGameRound = action.payload.miniGameRound
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.miniGameRound = action.payload.miniGameRound
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.miniGameRound = action.payload.miniGameRound
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.miniGameRound = action.payload.miniGameRound
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.miniGameRound = action.payload.miniGameRound
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.miniGameRound = action.payload.miniGameRound
          break
        default:
      }
    },
    // 배팅 마감됐을 때 용도
    setBettingCloseHandler: (state, action) => {
      switch (state.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.bettingCloseFlag = action.payload
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.bettingCloseFlag = action.payload
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.bettingCloseFlag = action.payload
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.bettingCloseFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.bettingCloseFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.bettingCloseFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.bettingCloseFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.bettingCloseFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.bettingCloseFlag = action.payload
          break
        default:
      }
    },
    // X 눌렀을떄 용도
    setCloseHandler: (state, action) => {
      switch (state.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.miniGameBettingPer = 0
          state.ntryPowerBallInfo.miniGameBettingType = null
          state.ntryPowerBallInfo.bettingMoney = 0
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.miniGameBettingPer = 0
          state.ntryPowerLadderInfo.miniGameBettingType = null
          state.ntryPowerLadderInfo.bettingMoney = 0
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.miniGameBettingPer = 0
          state.ntrySpeedKenoInfo.miniGameBettingType = null
          state.ntrySpeedKenoInfo.bettingMoney = 0
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.miniGameBettingPer = 0
          state.ntryKenoLadderInfo.miniGameBettingType = null
          state.ntryKenoLadderInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallOneInfo.miniGameBettingType = null
          state.ntryEosPowerBallOneInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallTwoInfo.miniGameBettingType = null
          state.ntryEosPowerBallTwoInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallThreeInfo.miniGameBettingType = null
          state.ntryEosPowerBallThreeInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallFourInfo.miniGameBettingType = null
          state.ntryEosPowerBallFourInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallFiveInfo.miniGameBettingType = null
          state.ntryEosPowerBallFiveInfo.bettingMoney = 0
          break
        default:
      }
      state.maxChecked = false
      state.minChecked = false
    },
    setResetData: (state, action) => {
      switch (state.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.miniGameBettingPer = 0
          state.ntryPowerBallInfo.miniGameBettingType = null
          state.ntryPowerBallInfo.bettingMoney = 0
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.miniGameBettingPer = 0
          state.ntryPowerLadderInfo.miniGameBettingType = null
          state.ntryPowerLadderInfo.bettingMoney = 0
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.miniGameBettingPer = 0
          state.ntrySpeedKenoInfo.miniGameBettingType = null
          state.ntrySpeedKenoInfo.bettingMoney = 0
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.miniGameBettingPer = 0
          state.ntryKenoLadderInfo.miniGameBettingType = null
          state.ntryKenoLadderInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallOneInfo.miniGameBettingType = null
          state.ntryEosPowerBallOneInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallTwoInfo.miniGameBettingType = null
          state.ntryEosPowerBallTwoInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallThreeInfo.miniGameBettingType = null
          state.ntryEosPowerBallThreeInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallFourInfo.miniGameBettingType = null
          state.ntryEosPowerBallFourInfo.bettingMoney = 0
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.miniGameBettingPer = 0
          state.ntryEosPowerBallFiveInfo.miniGameBettingType = null
          state.ntryEosPowerBallFiveInfo.bettingMoney = 0
          break
        default:
      }
      state.maxChecked = false
      state.minChecked = false
    },
    setMiniGameBettingPer: (state, action) => {
      switch (state.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.miniGameBettingPer = action.payload
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.miniGameBettingPer = action.payload
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.miniGameBettingPer = action.payload
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.miniGameBettingPer = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.miniGameBettingPer = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.miniGameBettingPer = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.miniGameBettingPer = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.miniGameBettingPer = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.miniGameBettingPer = action.payload
          break
        default:
      }
    },
    setMiniGameType: (state, action) => {
      state.miniGameType = action.payload
    },
    setMiniGameBettingType: (state, action) => {
      switch (state.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.miniGameBettingType = action.payload
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.miniGameBettingType = action.payload
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.miniGameBettingType = action.payload
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.miniGameBettingType = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.miniGameBettingType = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.miniGameBettingType = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.miniGameBettingType = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.miniGameBettingType = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.miniGameBettingType = action.payload
          break
        default:
      }
    },
    setMiniGameRound: (state, action) => {
      switch (state.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.miniGameRound = action.payload
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.miniGameRound = action.payload
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.miniGameRound = action.payload
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.miniGameRound = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.miniGameRound = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.miniGameRound = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.miniGameRound = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.miniGameRound = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.miniGameRound = action.payload
          break
        default:
      }
    },
    setMiniGameCompleteFlag: (state, action) => {
      switch (state.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.bettingCompleteFlag = action.payload
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.bettingCompleteFlag = action.payload
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.bettingCompleteFlag = action.payload
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.bettingCompleteFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.bettingCompleteFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.bettingCompleteFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.bettingCompleteFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.bettingCompleteFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.bettingCompleteFlag = action.payload
          break
        default:
      }
    },
    setMiniGameRefreshFlag: (state, action) => {
      switch (state.miniGameType) {
        case 'NTRY_POWER_BALL':
          state.ntryPowerBallInfo.bettingRefreshFlag = action.payload
          break
        case 'NTRY_POWER_LADDER':
          state.ntryPowerLadderInfo.bettingRefreshFlag = action.payload
          break
        case 'NTRY_SPEED_KENO':
          state.ntrySpeedKenoInfo.bettingRefreshFlag = action.payload
          break
        case 'NTRY_KENO_LADDER':
          state.ntryKenoLadderInfo.bettingRefreshFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_1M':
          state.ntryEosPowerBallOneInfo.bettingRefreshFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_2M':
          state.ntryEosPowerBallTwoInfo.bettingRefreshFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_3M':
          state.ntryEosPowerBallThreeInfo.bettingRefreshFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_4M':
          state.ntryEosPowerBallFourInfo.bettingRefreshFlag = action.payload
          break
        case 'NTRY_EOS_POWER_BALL_5M':
          state.ntryEosPowerBallFiveInfo.bettingRefreshFlag = action.payload
          break
        default:
      }
    },
    setMiniGameInspect: (state, action) => {
      state.miniGameInspect = action.payload
    },
    setMiniGameBettingLogTableFlag: (state, action) => {
      state.miniGameBettingLogTableFlag = action.payload
    },
    setMemberHoldingMoney: (state, action) => {
      state.memberHoldingMoney = action.payload
    },
    setMemberTotalGameMoney: (state, action) => {
      state.memberTotalGameMoney = action.payload
    },
    setMemberHoldingPoint: (state, action) => {
      state.memberHoldingPoint = action.payload
    },
    setMiniGameMaxBetting: (state, action) => {
      state.miniGameMaxBetting = action.payload
    },
    setMiniGameMinBetting: (state, action) => {
      state.miniGameMinBetting = action.payload
    },
    setMiniGameMaxWinning: (state, action) => {
      state.miniGameMaxWinning = action.payload
    },
    setMemberLevel: (state, action) => {
      state.memberLevel = action.payload
    },
    setMaxChecked: (state, action) => {
      state.maxChecked = action.payload
    },
    setMinChecked: (state, action) => {
      state.minChecked = action.payload
    },
  },
})

export const {
  setResetData,
  setMiniGameBettingPer,
  setMiniGameType,
  setMiniGameBettingType,
  setMiniGameRound,
  setMiniGameBettingLogTableFlag,
  setBettingCloseHandler,
  setMemberHoldingMoney,
  setMiniGameMaxBetting,
  setMiniGameMaxWinning,
  setMiniGameMinBetting,
  setMemberTotalGameMoney,
  setMemberLevel,
  setCloseHandler,
  setResetRoundAndGameTypeHandler,
  setMaxChecked,
  setMinChecked,
  setMiniGameCompleteFlag,
  setBettingMoneyHandler,
  setMiniGameRefreshFlag,
  setMemberHoldingPoint,
  setMiniGameInspect,
} = miniGameBettingSlice.actions

export default miniGameBettingSlice.reducer
