import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const FriendHistoryPage = () => {
  return (
    <div className="main-content">
      <div className="aniFrame">
        <div className="titlePic">
          <div className="titlePwrapper">
            <div className="leftZone">
              <span>추천인목록</span>
            </div>
            <div className="line" />
          </div>
        </div>
        <div className="main_content_wrap main_content_wrap_notice">
          <div className="noticeFrame">
            <div>
              <div className="listZone">
                <table>
                  <thead>
                    <tr>
                      <th className="ac number" width="10%">
                        No.
                      </th>
                      <th className="ac">아이디</th>
                      <th className="ac">이름</th>
                      <th className="ac">마지막 접속시간</th>
                      <th className="ac">가입일</th>
                    </tr>
                  </thead>
                  <tbody />
                </table>

                <div className="tb_empty ">
                  <div className="pic">
                    <img src="/soul1/images/common/no_data.png" alt="no-data" />
                  </div>
                  <h2>데이터 없음</h2>
                  <h3>Sorry, Empty Data</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FriendHistoryPage
