import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { MiniGameTypeEnums } from '../../utils/enums/MiniGame/PowerBallEnums'
import MiniGameNtryBettingCart from './MiniGameNtryBettingCart'
import MiniGameNtryBettingHistory from './MiniGameNtryBettingHistory'
import MiniGameNtryView from './MiniGameNtryView'
import { HermesMainBorder } from '../../utils/palette'

const MiniGameNtryPage = () => {
  const [currentTime, setCurrentTime] = useState(moment().tz('Asia/Seoul'))

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().tz('Asia/Seoul'))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  const [activeMenu, setActiveMenu] = useState('NTRY_EOS_POWER_BALL_1M')

  const onChangeActiveMenu = menu => {
    setActiveMenu(menu)
  }

  // 처음엔 영상 보이기
  const [isShowVideo, setIsShowVideo] = useState(true)

  return (
    <div className="mini_content">
      <div className="tab_wrap">
        <ul className="main_tap">
          <li className="item active" data-mode="1">
            <a>엔트리</a>
          </li>
        </ul>

        <ul className="sub_tap">
          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_1M' ? 'item active' : 'item'}
            onClick={() => {
              onChangeActiveMenu('NTRY_EOS_POWER_BALL_1M')
            }}
          >
            <a className="pc">EOS파워볼 1분</a>
            <a className="mob" style={{ fontSize: '13px' }}>
              EOS파워볼 1분
            </a>
          </li>

          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_2M' ? 'item active' : 'item'}
            onClick={() => {
              onChangeActiveMenu('NTRY_EOS_POWER_BALL_2M')
            }}
          >
            <a className="pc">EOS파워볼 2분</a>
            <a className="mob" style={{ fontSize: '13px' }}>
              EOS파워볼 2분
            </a>
          </li>
          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_3M' ? 'item active' : 'item'}
            onClick={() => {
              onChangeActiveMenu('NTRY_EOS_POWER_BALL_3M')
            }}
          >
            <a className="pc">EOS파워볼 3분</a>
            <a className="mob" style={{ fontSize: '13px' }}>
              EOS파워볼 3분
            </a>
          </li>
          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_4M' ? 'item active' : 'item'}
            onClick={() => {
              onChangeActiveMenu('NTRY_EOS_POWER_BALL_4M')
            }}
          >
            <a className="pc">EOS파워볼 4분</a>
            <a className="mob" style={{ fontSize: '13px' }}>
              EOS파워볼 4분
            </a>
          </li>
          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_5M' ? 'item active' : 'item'}
            onClick={() => {
              onChangeActiveMenu('NTRY_EOS_POWER_BALL_5M')
            }}
          >
            <a className="pc">EOS파워볼 5분</a>
            <a className="mob" style={{ fontSize: '13px' }}>
              EOS파워볼 5분
            </a>
          </li>
          <li
            className={activeMenu === 'NTRY_POWER_BALL' ? 'item active' : 'item'}
            onClick={() => {
              onChangeActiveMenu('NTRY_POWER_BALL')
            }}
          >
            <a className="pc">파워볼</a>
            <a className="mob" style={{ fontSize: '13px' }}>
              파워볼
            </a>
          </li>
          <li
            className={activeMenu === 'NTRY_POWER_LADDER' ? 'item active' : 'item'}
            onClick={() => {
              onChangeActiveMenu('NTRY_POWER_LADDER')
            }}
          >
            <a className="pc">파워사다리</a>
            <a className="mob" style={{ fontSize: '13px' }}>
              파워사다리
            </a>
          </li>
          <li
            className={activeMenu === 'NTRY_KENO_LADDER' ? 'item active' : 'item'}
            onClick={() => {
              onChangeActiveMenu('NTRY_KENO_LADDER')
            }}
          >
            <a className="pc">키노사다리</a>
            <a className="mob" style={{ fontSize: '13px' }}>
              키노사다리
            </a>
          </li>
          <li
            className={activeMenu === 'NTRY_SPEED_KENO' ? 'item active' : 'item'}
            onClick={() => {
              onChangeActiveMenu('NTRY_SPEED_KENO')
            }}
          >
            <a className="pc">스피드키노</a>
            <a className="mob" style={{ fontSize: '13px' }}>
              스피드키노
            </a>
          </li>
        </ul>
      </div>

      <div className="game_panel">
        <div className="mini_game_bet">
          <div className="game-title">
            <span className="top-time pc">{currentTime.locale('ko').format('HH:mm:ss')}</span>
            <span className="top-time mob" style={{ fontSize: '15px' }}>
              {currentTime.locale('ko').format('HH:mm:ss')}
            </span>
            <span className="pc game-name">{MiniGameTypeEnums[activeMenu]}</span>
            <span className="mob game-name" style={{ fontSize: '15px' }}>
              {MiniGameTypeEnums[activeMenu]}
            </span>
            <button
              type="button"
              style={{ color: '#fff' }}
              className="game-show-btn pc"
              onClick={() => {
                setIsShowVideo(prev => !prev)
              }}
            >
              {isShowVideo ? '영상 감추기' : '영상 보이기'}
            </button>
            <button
              type="button"
              style={{ fontSize: '15px', color: '#fff', width: '100px' }}
              className="game-show-btn mob"
              onClick={() => {
                setIsShowVideo(prev => !prev)
              }}
            >
              {isShowVideo ? '영상 감추기' : '영상 보이기'}
            </button>
          </div>
          <div className="game-title2" />

          <div>
            {/* 영상 */}
            <MiniGameNtryView isShowVideo={isShowVideo} activeMenu={activeMenu} />

            {/* 배팅 선택 */}
            <MiniGameNtryBettingCart activeMenu={activeMenu} />

            {/* 배팅 내역 */}
            <MiniGameNtryBettingHistory activeMenu={activeMenu} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniGameNtryPage
