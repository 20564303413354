import styled from 'styled-components'

export const PopupHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PopupBody = styled.div`
  position: relative;
  width: 100%;
  border: none;
  box-shadow: 5px 4px 3px 2px rgb(0 1 0 / 30%);
  margin-bottom: 10px;
`

export const MobilePopupBody = styled.div`
  position: relative;
  width: 100%;
  border: none;
  box-shadow: 5px 4px 3px 2px rgb(0 1 0 / 30%);
`

export const PopupImgWrap = styled.div`
  width: 100%;
  height: 604px;
  border: 2px solid #c8af77;
`

export const PopupImgItem = styled.img`
  width: 100%;
  height: 600px;
`

export const MobilePopupImgItem = styled.img`
  width: 100%;
  height: 600px;
`

export const CloseButtonWrap = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;

  background: #2f3238 !important;
  &:hover {
    cursor: pointer;
    background: black;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid 2px rgb(42, 45, 50);
  border-right: solid 2px rgb(42, 45, 50);
`

export const CloseButtonWrap2 = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: solid 2px rgb(42, 45, 50);
  border-right: solid 2px rgb(42, 45, 50);
  background: #2f3238 !important;
`

export const CloseButton3 = styled.span`
  position: absolute;
  left: 20px;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* 엘로우폰트 */
  color: #fff;
  font-weight: 700;
`

export const CloseButton2 = styled.span`
  position: absolute;
  right: 20px;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* 엘로우폰트 */
  color: #fff;
  font-weight: 700;

  &:hover {
    cursor: pointer;
  }
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: #fff;
      stroke-width: 4;
    }
  }
`

export const CloseButton = styled.span`
  position: absolute;
  width: 148px;
  height: 40px;
  left: calc(50% - 148px / 2 - 0.5px);
  top: calc(50% - 40px / 2 - 1px);
  font-size: 14px;
  font-family: 'Noto Sans KR';
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* 엘로우폰트 */
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ4IiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgMTQ4IDQwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9kKSI+CjxwYXRoIGQ9Ik0yOC41NjE0IDBIMTQ0TDEyNC43NzUgMzJINEwyOC41NjE0IDBaIiBmaWxsPSIjRjU2NDA2Ii8+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZCIgeD0iMCIgeT0iMCIgd2lkdGg9IjE0OCIgaGVpZ2h0PSI0MCIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4KPGZlT2Zmc2V0IGR5PSI0Ii8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIiLz4KPGZlQ29tcG9zaXRlIGluMj0iaGFyZEFscGhhIiBvcGVyYXRvcj0ib3V0Ii8+CjxmZUNvbG9yTWF0cml4IHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjM3IDAiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3ciLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3ciIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==);
    no-repeat !important;
  color: #fff;
  font-weight: 900;
  padding-bottom: 7px;
`

// export const PopupWrapper = styled.div`
//   border: solid 3px red;
//   box-sizing: border-box;
//   display: ${props => (props.visible ? 'block' : 'none')};
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1000;
//   overflow: auto;
//   outline: 0;
// `

export const PopupWrapper = styled.div`
  // padding-top: 40px;
  box-sizing: border-box;
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 0px;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  overflow: auto;
  outline: 0;
`

export const MobilePopupWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  overflow: auto;
  outline: 0;
`

export const PopupOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.totalVisible ? 'block' : 'none')};
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9990;
`

export const PopupContent = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none !important')};
  position: absolute;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const MobilePopupContent = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none !important')};
  position: absolute;
  width: 100%;
  max-width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // 팝업 이미지는 400 x 600 으로 해야 함
  @media screen and (max-height: 740px) {
    top: 56%;
  }
  @media screen and (max-height: 667px) {
    top: 62%;
  }
`
