import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSelector } from 'react-redux'
import {
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
  PowerBallTypeEnums,
} from '../../utils/enums/MiniGame/PowerBallEnums'
import { searchMiniGameBettingLog } from '../../api/game/miniGameApi'
import CustomPagination from '../../shared/components/CustomPagination'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { convertToKstByFormat } from '../../utils/dateTime'

const MiniGameNtryBettingHistory = ({ activeMenu }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [miniGameBettingLogParams, setMiniGameBettingLogParams] = useState({
    miniGameType: activeMenu,
    page,
    size,
  })

  useEffect(() => {
    setMiniGameBettingLogParams(prev => ({
      ...prev,
      miniGameType: activeMenu,
      page,
    }))
  }, [page, activeMenu])

  const getMiniGameBettingInfo = state => {
    const { miniGameType } = state
    if (miniGameType === 'NTRY_POWER_BALL') {
      return state.ntryPowerBallInfo
    }
    if (miniGameType === 'NTRY_POWER_LADDER') {
      return state.ntryPowerLadderInfo
    }
    if (miniGameType === 'NTRY_SPEED_KENO') {
      return state.ntrySpeedKenoInfo
    }
    if (miniGameType === 'NTRY_KENO_LADDER') {
      return state.ntryKenoLadderInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_1M') {
      return state.ntryEosPowerBallOneInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_2M') {
      return state.ntryEosPowerBallTwoInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_3M') {
      return state.ntryEosPowerBallThreeInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_4M') {
      return state.ntryEosPowerBallFourInfo
    }
    if (miniGameType === 'NTRY_EOS_POWER_BALL_5M') {
      return state.ntryEosPowerBallFiveInfo
    }

    return null
  }

  const { bettingCompleteFlag, bettingRefreshFlag } = useSelector(state => {
    const { miniGameBetting, memberInfo } = state
    const miniGameBettingInfo = getMiniGameBettingInfo(miniGameBetting)
    if (miniGameBettingInfo) {
      return {
        bettingCompleteFlag: miniGameBettingInfo.bettingCompleteFlag,
        bettingRefreshFlag: miniGameBettingInfo.bettingRefreshFlag,
      }
    }
    return {
      bettingCompleteFlag: false,
      bettingRefreshFlag: false,
    }
  })

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    console.log(
      'miniGameBettingLogParams, bettingRefreshFlag, bettingCompleteFlag :: ',
      miniGameBettingLogParams,
      bettingRefreshFlag,
      bettingCompleteFlag,
    )
    searchMiniGameBettingLog(miniGameBettingLogParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
      })
      .catch(error => {})
  }, [miniGameBettingLogParams, bettingRefreshFlag, bettingCompleteFlag])

  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  return (
    <>
      <div className="minigame-betlist">
        <div className="tb-all-bet-board">
          <table className="bet-table">
            <thead>
              <tr>
                <th className="mini-list-title">회차</th>
                <th className="mini-list-title">배팅</th>
                <th className="mini-list-title">배당</th>
                <th className="mini-list-title">배팅금</th>
                <th className="mini-list-title">당첨금</th>
                <th className="mini-list-title pc">배팅일시</th>
                <th className="mini-list-title pc">결과</th>
              </tr>
            </thead>
            <tbody>
              {content.map(item => {
                return (
                  <tr className="tr-round-odd">
                    <td className="">{item.gameRound}</td>
                    <td className="">{PowerBallTypeEnums[item.miniGameBettingType]}</td>
                    <td className="">{item.bettingPer}</td>
                    <td className="">{item.bettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <td className="">{item.winningAmount?.toString().replace(commonReg2, ',')}</td>
                    <td className="pc">{convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}</td>
                    <td className={item.bettingResult === 'WIN' ? 'bet-success pc' : 'bet-fail pc'}>
                      {' '}
                      {MiniGameBettingTypeEnums[item.bettingResult]}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          {totalElement === 0 && <div className="tb_empty ">데이터가 없습니다.</div>}

          {totalElement !== 0 && (
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          )}
        </div>
      </div>
    </>
  )
}

export default MiniGameNtryBettingHistory
