import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import MainContentBanner from './MainContentBanner'
import { searchGameUrl, searchLiveVendor, searchSlotVendor } from '../../../api/game/gameApi'
import isLogin from '../../../utils/enums/authority'
import SignInPopup from '../../Popup/SignIn/SignInPopup'
import SignUpPopup from '../../Popup/SignUp/SignUpPopup'
import CasinoGameViewPopup from '../../CasinoGame/CasinoGameViewPopup'
import MainLiveCasino from './MainLiveCasino'
import WrapperLoading from '../../WrapperLoading'
import MainSlotCasino from './MainSlotCasino'
import MainInfoZone from './MainInfoZone'
import CustomAlert from '../../CustomAlert'

const MainContentGame = () => {
  const navigate = useNavigate()
  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [apiLoading, setApiLoading] = useState(false)

  const [activeMenu, setActiveMenu] = useState('CASINO')

  const { noteRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
    }
  })

  /**
   * 로그인 , 회원가입 부분
   */
  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  // 회원가입 -> 로그인 모달
  const onShowSignInModal = () => {
    setSignUpModalShow(false)
    setSignInModalShow(true)
  }

  // 로그인 -> 회원가입 모달
  const onShowSignUpModal = () => {
    setSignInModalShow(false)
    setSignUpModalShow(true)
  }

  const onClickMenuHandler = path => {
    if (!isLogin()) {
      setSignInModalShow(true)
      return
    }

    if (noteRedisSize > 0 && path !== '/note') {
      setNoteAlertShow(true)
      return
    }

    navigate(path)
  }

  return (
    <>
      {signInModalShow && (
        <SignInPopup
          visible={signInModalShow}
          onCloseHandler={onCloseSignInModal}
          onShowSignUpModal={onShowSignUpModal}
        />
      )}
      {signUpModalShow && (
        <SignUpPopup
          visible={signUpModalShow}
          onCloseHandler={onCloseSignUpModal}
          onShowSignInModal={onShowSignInModal}
        />
      )}
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {apiLoading && <WrapperLoading isLoading={apiLoading} />}
      <div id="home" className="v_deep_home">
        <div className="main_content webp">
          <div className="main_content_wrap avoidFlicker">
            <div className="marquee mob">
              <img src={`${process.env.PUBLIC_URL}/img/common/speaker.png`} alt="" />
              <p style={{ color: '#000' }}>
                <strong>
                  고객만족도 1위 위너스입니다. 많은성원에 감사드리며 더욱 안전하고 즐거운 놀이터로써 보답하겠습니다.{' '}
                </strong>
              </p>
            </div>
            <ul className="gameTab mob">
              <li
                className={activeMenu === 'CASINO' ? 'item active' : 'item'}
                onClick={() => {
                  setActiveMenu('CASINO')
                }}
              >
                <span className="" />
                카지노
              </li>

              <li
                className={activeMenu === 'SLOT' ? 'item active' : 'item'}
                onClick={() => {
                  setActiveMenu('SLOT')
                }}
              >
                <span className="" />
                슬롯
              </li>

              {/* <li
                className={activeMenu === 'SPORTS' ? 'item active' : 'item'}
                onClick={() => {
                  setActiveMenu('SPORTS')
                }}
              >
                <span className="" />
                스포츠
              </li>

              <li
                className={activeMenu === 'MINI_GAME' ? 'item active' : 'item'}
                onClick={() => {
                  setActiveMenu('MINI_GAME')
                }}
              >
                <span className="" />
                미니게임
              </li> */}
              {/* <li
                className={activeMenu === 'HOLDEM' ? 'item active' : 'item'}
                onClick={() => {
                  setActiveMenu('HOLDEM')
                }}
              >
                <span className="" />
                홀덤
              </li> */}
            </ul>

            <MainLiveCasino apiLoading={apiLoading} setApiLoading={setApiLoading} active={activeMenu === 'CASINO'} />
            <MainSlotCasino apiLoading={apiLoading} setApiLoading={setApiLoading} active={activeMenu === 'SLOT'} />

            <div className={activeMenu === 'SPORTS' ? 'gameZone sports mob active' : 'gameZone sports mob'}>
              <div className="content">
                <ul>
                  <li className="casinoFrame fadeInUp">
                    <a
                      className="game_gameType "
                      onClick={() => {
                        onClickMenuHandler('/cross-sports')
                      }}
                    >
                      <div className="bg">
                        <div
                          className="model modelA"
                          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/sports/cross.png)` }}
                        />
                        <div className="txt">
                          <h2>크로스</h2>
                        </div>
                      </div>
                      <div className="mask">
                        <div className="inner">
                          <div className="goldL01Btn btnP">게임시작</div>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li className="casinoFrame fadeInUp">
                    <a
                      className="game_gameType "
                      onClick={() => {
                        onClickMenuHandler('/special-sports')
                      }}
                    >
                      <div className="bg">
                        <div
                          className="model modelA"
                          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/sports/special.png)` }}
                        />
                        <div className="txt">
                          <h2>스페셜</h2>
                        </div>
                      </div>
                      <div className="mask">
                        <div className="inner">
                          <div className="goldL01Btn btnP">게임시작</div>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li className="casinoFrame fadeInUp">
                    <a
                      className="game_gameType "
                      onClick={() => {
                        onClickMenuHandler('/live-sports')
                      }}
                    >
                      <div className="bg">
                        <div
                          className="model modelA"
                          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/sports/live.png)` }}
                        />
                        <div className="txt">
                          <h2>라이브</h2>
                        </div>
                      </div>
                      <div className="mask">
                        <div className="inner">
                          <div className="goldL01Btn btnP">게임시작</div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className={activeMenu === 'MINI_GAME' ? 'gameZone mini mob active' : 'gameZone mini mob'}>
              <div className="content">
                <ul>
                  <li className="casinoFrame fadeInUp">
                    <a
                      onClick={() => {
                        onClickMenuHandler('/miniGame')
                      }}
                    >
                      <div className="bg">
                        <div
                          className="model modelA"
                          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/mini/ntry.png)` }}
                          alt=""
                        />
                        <div className="txt">
                          <h2>엔트리</h2>
                        </div>
                      </div>
                      <div className="mask pc">
                        <div className="inner">
                          <div className="goldL01Btn btnP">게임시작</div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className={activeMenu === 'HOLDEM' ? 'gameZone mini mob active' : 'gameZone mini mob'}>
              <div className="content">
                <ul>
                  <li className="casinoFrame fadeInUp">
                    <a
                      onClick={() => {
                        onClickMenuHandler('/holdem')
                      }}
                    >
                      <div className="bg">
                        <div
                          className="model modelA"
                          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/provider/holdem/holdem.png)` }}
                          alt=""
                        />
                        <div className="txt">
                          <h2>홀덤</h2>
                        </div>
                      </div>
                      <div className="mask pc">
                        <div className="inner">
                          <div className="goldL01Btn btnP">게임시작</div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <MainInfoZone />
          </div>
        </div>
      </div>
    </>
  )
}

export default MainContentGame
