import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import {
  addBettingCart,
  clearAllInfo,
  fetchSportsEvents,
  handleWebSocketCrossSportsEvent,
  onClickHideSportsEventMainMarket,
  setMarketTapActive,
  setSportsType,
} from '../../../redux/sportsGameBettingSlice'
import { convertToKstByFormat } from '../../../utils/dateTime'
import isLogin from '../../../utils/enums/authority'
import { HermesMainBorder, HermesMainText } from '../../../utils/palette'
import { decodeAccessToken } from '../../../utils/token'
import CustomPagination from '../../../shared/components/CustomPagination'

const CrossSportsMainFrame = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(150)
  const [sortType, setSortType] = useState('TODAY_SORT')
  const [searchBoolean, setSearchBoolean] = useState(false)
  const [searchTeamName, setSearchTeamName] = useState('')

  const [showButton, setShowButton] = useState(false)
  const chooseZoneRef = useRef(null)

  const handleScroll = () => {
    const limitTop = 50
    if (chooseZoneRef.current.scrollTop > limitTop) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  const scrollToTop = () => {
    if (chooseZoneRef.current) {
      chooseZoneRef.current.scroll({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const chooseZone = chooseZoneRef.current
    chooseZone.addEventListener('scroll', handleScroll)

    return () => {
      chooseZone.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function createKey(item) {
    return `${item.sportsEventId}-${item.marketId}-${item.selectedSportsMarketOddKey}-${item.selectedOddName}-${item.name}`
  }

  function isActive(activeSportsMarketKeySet, sportsEventId, marketId, sportsMarketOddKey, selectedOddName, name) {
    const key = createKey({
      sportsEventId,
      marketId,
      selectedSportsMarketOddKey: sportsMarketOddKey,
      selectedOddName,
      name,
    })

    return activeSportsMarketKeySet.has(key)
  }

  const { totalElement, mainSportsEventList, sportsTypeInfo, bonusFolderInfo, sportsType } = useSelector(state => {
    const { sportsGameBetting } = state

    const { CROSS, BONUS } = sportsGameBetting.bettingCartList

    const activeSportsMarketKeySet = new Set([
      ...CROSS.map(item => createKey(item)),
      ...BONUS.map(item => createKey(item)),
    ])

    let updatedMainSportsEventList

    if (sortType === 'POPULAR_SORT') {
      updatedMainSportsEventList = Object.entries(sportsGameBetting.mainSportsEventList)
        .map(([key, group]) => {
          const updatedEvents = group.sportsEvents.map(sportsEvent => {
            return {
              ...sportsEvent,
              markets: sportsEvent.markets.map(market => ({
                ...market,
                isHomeActive: isActive(
                  activeSportsMarketKeySet,
                  sportsEvent.sportsEventId,
                  market.marketId,
                  market.homeSportsMarketOddKey,
                  market.homeOddName,
                  market.marketName === '승무패' ? '' : market.name,
                ),
                isAwayActive: isActive(
                  activeSportsMarketKeySet,
                  sportsEvent.sportsEventId,
                  market.marketId,
                  market.awaySportsMarketOddKey,
                  market.awayOddName,
                  market.marketName === '승무패' ? '' : market.name,
                ),
                isDrawActive: isActive(
                  activeSportsMarketKeySet,
                  sportsEvent.sportsEventId,
                  market.marketId,
                  market.drawSportsMarketOddKey,
                  'Draw',
                  market.marketName === '승무패' ? '' : market.name,
                ),
              })),
            }
          })

          const totalMarketCount = updatedEvents.reduce((sum, event) => sum + event.markets.length, 0)

          return {
            key,
            ...group,
            sportsEvents: updatedEvents,
            totalMarketCount,
          }
        })
        .sort((a, b) => b.totalMarketCount - a.totalMarketCount)
    } else {
      updatedMainSportsEventList = Object.entries(sportsGameBetting.mainSportsEventList).map(([key, group]) => {
        const updatedEvents = group.sportsEvents.map(sportsEvent => {
          return {
            ...sportsEvent,
            markets: sportsEvent.markets.map(market => ({
              ...market,
              isHomeActive: isActive(
                activeSportsMarketKeySet,
                sportsEvent.sportsEventId,
                market.marketId,
                market.homeSportsMarketOddKey,
                market.homeOddName,
                market.marketName === '승무패' ? '' : market.name,
              ),
              isAwayActive: isActive(
                activeSportsMarketKeySet,
                sportsEvent.sportsEventId,
                market.marketId,
                market.awaySportsMarketOddKey,
                market.awayOddName,
                market.marketName === '승무패' ? '' : market.name,
              ),
              isDrawActive: isActive(
                activeSportsMarketKeySet,
                sportsEvent.sportsEventId,
                market.marketId,
                market.drawSportsMarketOddKey,
                'Draw',
                market.marketName === '승무패' ? '' : market.name,
              ),
            })),
          }
        })

        return {
          key,
          ...group,
          sportsEvents: updatedEvents,
        }
      })
    }

    return {
      totalElement: sportsGameBetting.totalElement,
      mainSportsEventList: updatedMainSportsEventList,
      sportsTypeInfo: sportsGameBetting.sportsTypeInfo,
      bonusFolderInfo: sportsGameBetting.bonusFolderInfo.map(bonus => ({
        ...bonus,
        isActive: isActive(activeSportsMarketKeySet, 0, bonus.marketId, null, `${bonus.bonusFolderNum}폴더 이상`, ''),
      })),
      sportsType: sportsGameBetting.selectedSportsType,
    }
  })

  useEffect(() => {
    setSearchBoolean(prev => !prev)
    setSearchTeamName('')
    setPage(0)
  }, [sportsType])

  const params = useMemo(() => {
    return {
      sportsType,
      page: !searchTeamName ? page : 0,
      size,
      sortType,
      searchTeamName: !searchTeamName ? null : searchTeamName,
    }
  }, [sportsType, page, size, sortType, searchBoolean])

  const socketRef = useRef(null)
  const intervalIdRef = useRef(null)

  const handleSocketOpen = useCallback(() => {
    // 웹소켓 연결 후 초기 데이터 요청
    dispatch(fetchSportsEvents(params))
  }, [dispatch])

  const handleSocketMessage = useCallback(
    event => {
      const message = JSON.parse(event.data)
      dispatch(handleWebSocketCrossSportsEvent(message))
    },
    [dispatch],
  )

  const handleSocketError = useCallback(event => {
    console.error('WebSocket error observed:', event)
    // logout 시키기
  }, [])

  // 웹소켓 연결을 처음에만 생성합니다.
  useEffect(() => {
    if (!isLogin()) {
      return
    }
    if (location.pathname !== '/cross-sports') {
      return
    }
    // 웹소켓 인스턴스 생성
    const userKey = decodeAccessToken().userKey || ''
    const newSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_CROSS_SPORTS_URL}?userKey=${userKey}`)
    socketRef.current = newSocket

    // 웹소켓 이벤트 리스너 등록
    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('error', handleSocketError)

    return () => {
      // 컴포넌트 언마운트 시 웹소켓 연결 종료
      if (socketRef.current) {
        socketRef.current.close()
      }

      dispatch(clearAllInfo())
    }
  }, [location, handleSocketOpen, handleSocketMessage, handleSocketError])

  // params가 변경될 때마다 새로운 interval을 설정합니다.
  useEffect(() => {
    // 이전 interval 해제
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      dispatch(fetchSportsEvents(params))
    }

    // 일정한 주기로 파라미터를 서버로 보내기
    const id = setInterval(() => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify(params))
      }
    }, 5000) // 1초마다 파라미터 전송

    intervalIdRef.current = id

    return () => {
      // 컴포넌트 언마운트 시 타이머 해제
      clearInterval(intervalIdRef.current)
    }
  }, [params])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (chooseZoneRef.current) {
      chooseZoneRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const onClickSportsEventType = info => {
    dispatch(setMarketTapActive('CROSS'))
    dispatch(setSportsType(info))
    setSortType('TODAY_SORT')
    setPage(0)
  }

  const onClickBetting = (type, item) => {
    dispatch(addBettingCart({ type, item }))
  }

  const onClickHideSportsEventMainMarketHandler = (key, visibled) => {
    dispatch(onClickHideSportsEventMainMarket({ key, isVisible: visibled }))
  }

  const getMarketName = (marketName, oddName, teamName, sportsSort) => {
    switch (true) {
      case marketName?.includes('언더오버') &&
        oddName?.includes('언더') &&
        (sportsSort === 'BASEBALL' || sportsSort === 'BASKETBALL'):
        return `${marketName}(연장포함)`
      case marketName?.includes('언더오버') &&
        oddName?.includes('오버') &&
        (sportsSort === 'BASEBALL' || sportsSort === 'BASKETBALL'):
        return `${marketName}(연장포함)`
      case marketName?.includes('언더오버') && oddName?.includes('언더'):
        return `${marketName}`
      case marketName?.includes('언더오버') && oddName?.includes('오버'):
        return `${marketName}`

      case marketName?.includes('핸디캡') && (sportsSort === 'BASEBALL' || sportsSort === 'BASKETBALL'):
        return `${marketName} (연장포함)`

      default:
        return marketName
    }
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case (marketName.includes('승무패') || marketName.includes('승패')) && oddName?.includes('홈팀'):
        return (
          <div className="left-handi-mark">
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
              alt=""
              style={{ width: '10px', height: '7px' }}
            />
          </div>
        )
      case (marketName.includes('승무패') || marketName.includes('승패')) && oddName?.includes('원정'):
        return (
          <div className="right-handi-mark">
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
              alt=""
              style={{ width: '10px', height: '7px' }}
            />
          </div>
        )
      case marketName.includes('핸디') && oddName?.includes('홈팀'):
        return (
          <div className="left-handi-mark">
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
              alt=""
              style={{ width: '12px', height: '12px' }}
            />
          </div>
        )
      case marketName.includes('핸디') && oddName?.includes('원정'):
        return (
          <div className="right-handi-mark">
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
              alt=""
              style={{ width: '12px', height: '12px' }}
            />
          </div>
        )
      case marketName?.includes('언더오버') && oddName?.includes('언더'):
        return (
          <div className="left-handi-mark">
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
              alt=""
              style={{ width: '12px', height: '12px' }}
            />
          </div>
        )
      case marketName.includes('언더오버') && oddName?.includes('오버'):
        return (
          <div className="right-handi-mark">
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
              alt=""
              style={{ width: '12px', height: '12px' }}
            />
          </div>
        )
      default:
    }
  }

  return (
    <>
      <div className="chooseFrame">
        <div className="tabZone">
          <div className="tabFrame">
            <ul>
              {sportsTypeInfo.map(item => {
                return (
                  <li
                    className={
                      item.sportsType === sportsType || sportsType === 'ALL'
                        ? 'item category-all active'
                        : 'item category-all'
                    }
                    onClick={() => {
                      onClickSportsEventType(item.sportsType === 'ALL' ? null : item.sportsType)
                    }}
                    key={`${item.sportsType}-${item.typeKr}`}
                  >
                    {item.sportsType !== 'ALL' && (
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/img/sports/sportsType/side-${item.sportsType?.toLowerCase()}.png`}
                        width="20"
                        height="20"
                        alt=""
                      />
                    )}

                    <div className="txt" style={{ marginLeft: '2px' }}>
                      {item.typeKr}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="chooseZone" ref={chooseZoneRef}>
          <div className="sportZone">
            <div className="bonus-folder-wrap pc">
              {bonusFolderInfo.map((folder, index) => {
                return (
                  <div
                    className={folder.isActive ? 'bonus-bet pick' : 'bonus-bet'}
                    key={`${folder.bonusFolderNum}-${folder.bonusFolderPer}`}
                    id="three-bonus"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const type = 'BONUS'
                      const item = {
                        sportsEventId: 0,
                        sportsType: 'ETC',
                        sportsMarketKey: folder.marketId,
                        marketId: folder.marketId,
                        leagueName: '',
                        ccKr: '',
                        startAt: null,
                        homeName: '',
                        awayName: '',
                        marketName: '보너스 배당',
                        name: '',
                        homeSportsMarketOddKey: '',
                        homeOddName: `${folder.bonusFolderNum}폴더 이상`,
                        homeOddNameKr: `${folder.bonusFolderNum}폴더 이상`,
                        homeOddValue: folder.bonusFolderPer,
                        awaySportsMarketOddKey: '',
                        awayOddName: '',
                        awayOddNameKr: '',
                        awayOddValue: '',
                        drawSportsMarketOddKey: '',
                        drawOddName: '',
                        drawOddNameKr: '',
                        drawOddValue: '',
                        selectedSportsMarketPointKey: null,
                        selectedSportsMarketOddKey: null,
                        selectedOddName: `${folder.bonusFolderNum}폴더 이상`,
                        selectedOddNameKr: `${folder.bonusFolderNum}폴더 이상`,
                        selectedOddValue: folder.bonusFolderPer,
                      }

                      onClickBetting(type, item)
                    }}
                  >
                    <div className="left">
                      <div className="name" style={{ color: '#fff', display: 'flex', gap: '10px' }}>
                        <img
                          src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                          style={{ width: '25px' }}
                          alt=""
                        />
                        {folder.bonusFolderNum}폴더 보너스
                      </div>
                    </div>
                    <div className="odds-wrap right ">
                      <div className="odds" style={{ color: '#fff' }}>
                        {folder.bonusFolderPer}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="bonus-folder-wrap mob" style={{ display: 'flex', flexDirection: 'column' }}>
              {bonusFolderInfo.map((folder, index) => {
                return (
                  <div
                    className={folder.isActive ? 'bonus-bet pick' : 'bonus-bet'}
                    key={`${folder.bonusFolderNum}-${folder.bonusFolderPer}`}
                    id="three-bonus"
                    style={{ cursor: 'pointer', height: '35px', paddingBottom: '10px' }}
                    onClick={() => {
                      const type = 'BONUS'
                      const item = {
                        sportsEventId: 0,
                        sportsType: 'ETC',
                        sportsMarketKey: folder.marketId,
                        marketId: folder.marketId,
                        leagueName: '',
                        ccKr: '',
                        startAt: null,
                        homeName: '',
                        awayName: '',
                        marketName: '보너스 배당',
                        name: '',
                        homeSportsMarketOddKey: '',
                        homeOddName: `${folder.bonusFolderNum}폴더 이상`,
                        homeOddNameKr: `${folder.bonusFolderNum}폴더 이상`,
                        homeOddValue: folder.bonusFolderPer,
                        awaySportsMarketOddKey: '',
                        awayOddName: '',
                        awayOddNameKr: '',
                        awayOddValue: '',
                        drawSportsMarketOddKey: '',
                        drawOddName: '',
                        drawOddNameKr: '',
                        drawOddValue: '',
                        selectedSportsMarketPointKey: null,
                        selectedSportsMarketOddKey: null,
                        selectedOddName: `${folder.bonusFolderNum}폴더 이상`,
                        selectedOddNameKr: `${folder.bonusFolderNum}폴더 이상`,
                        selectedOddValue: folder.bonusFolderPer,
                      }

                      onClickBetting(type, item)
                    }}
                  >
                    <div className="left">
                      <div className="name" style={{ color: '#fff', display: 'flex', gap: '10px' }}>
                        <img
                          src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                          style={{ width: '20px' }}
                          alt=""
                        />
                        {folder.bonusFolderNum}폴더 보너스
                      </div>
                    </div>
                    <div className="odds-wrap right ">
                      <div className="odds" style={{ color: '#fff' }}>
                        {folder.bonusFolderPer}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="folder-wrap">
              <div id="fixture-list" className="pageSport">
                <div className="cubeZone" id="game-folders">
                  <div id="top-hidden" />

                  {mainSportsEventList.map(mainSportsEvent => {
                    return (
                      <div className="cube">
                        <li
                          className={mainSportsEvent.isVisible ? 'opened switcher titleS' : 'switcher titleS'}
                          onClick={() => {
                            onClickHideSportsEventMainMarketHandler(mainSportsEvent.key, !mainSportsEvent.isVisible)
                          }}
                        >
                          <div className="left">
                            <img
                              src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${mainSportsEvent.sportsType}.png`}
                              height="20"
                              width="20"
                              alt=""
                            />

                            {mainSportsEvent.ccImage && (
                              <img src={mainSportsEvent.ccImage} height="20" width="20" alt="" />
                            )}

                            <span style={{ color: '#000', minWidth: 'max-content' }}>{mainSportsEvent.ccKr}</span>

                            <span className="icon-iconGo" />

                            {mainSportsEvent.leagueImage && (
                              <img src={mainSportsEvent.leagueImage} height="20" width="20" alt="" />
                            )}

                            {mainSportsEvent.leagueName}
                          </div>

                          <div className="right">
                            <div className="time mob">
                              {convertToKstByFormat(mainSportsEvent.sportsEvents[0]?.startAt, 'MM-DD HH:mm')}
                            </div>
                            <div className="arrowT arrowGO">
                              {mainSportsEvent.isVisible ? (
                                <span className="icon-iconARdownA" />
                              ) : (
                                <span className="icon-iconARupA" />
                              )}
                            </div>
                          </div>
                        </li>
                        <div className="switchee">
                          {mainSportsEvent.sportsEvents.map(sportsEvent => {
                            return (
                              <>
                                {sportsEvent.markets.map((market, index) => {
                                  return (
                                    <CustomWrapDiv
                                      className="listS"
                                      key={`${market.sportsMarketKey}-${market.sportsMarketPointKey}`}
                                      style={{ position: 'relative' }}
                                    >
                                      {sportsEvent.isLocked && (
                                        <SportsEventContentItemOverlay>
                                          <SportsEventContentItemLockIcon />
                                        </SportsEventContentItemOverlay>
                                      )}
                                      <div className="leftZone pc">
                                        <div className="time" style={{ width: '40%' }}>
                                          {convertToKstByFormat(sportsEvent.startAt, 'MM-DD HH:mm')}
                                        </div>
                                        <div className="gameName" style={{ width: '60%' }}>
                                          {getMarketName(
                                            market.marketName,
                                            market.homeOddNameKr,
                                            sportsEvent.homeName,
                                            sportsEvent.sportsType,
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        className="leftZone mob"
                                        style={{
                                          justifyContent: 'left',
                                          width: '100%',
                                          paddingLeft: '10px',
                                          marginBottom: '7px',
                                        }}
                                      >
                                        <div className="gameName">
                                          {' '}
                                          {getMarketName(
                                            market.marketName,
                                            market.homeOddNameKr,
                                            sportsEvent.homeName,
                                            sportsEvent.sportsType,
                                          )}
                                        </div>
                                      </div>
                                      <div className="rightZone">
                                        <div className="chooseBtn">
                                          <div
                                            className={
                                              market.isHomeActive
                                                ? 'nameA bet-pick home-pick pick'
                                                : 'nameA bet-pick home-pick'
                                            }
                                            onClick={() => {
                                              const type = 'CROSS'
                                              const item = {
                                                sportsEventId: sportsEvent.sportsEventId,
                                                sportsType: sportsEvent.sportsType,
                                                sportsMarketKey: market.sportsMarketKey,
                                                marketId: market.marketId,
                                                leagueName: sportsEvent.leagueName,
                                                ccKr: sportsEvent.ccKr,
                                                startAt: sportsEvent.startAt,
                                                homeName: sportsEvent.homeName,
                                                awayName: sportsEvent.awayName,
                                                marketName: market.marketName,
                                                name: market.name,
                                                homeSportsMarketOddKey: market.homeSportsMarketOddKey,
                                                homeOddName: market.homeOddName,
                                                homeOddNameKr: market.homeOddNameKr,
                                                homeOddValue: market.homeOddValue,
                                                awaySportsMarketOddKey: market.awaySportsMarketOddKey,
                                                awayOddName: market.awayOddName,
                                                awayOddNameKr: market.awayOddNameKr,
                                                awayOddValue: market.awayOddValue,
                                                drawSportsMarketOddKey: market.drawSportsMarketOddKey,
                                                drawOddName: market.drawOddName,
                                                drawOddNameKr: market.drawOddNameKr,
                                                drawOddValue: market.drawOddValue,
                                                selectedSportsMarketPointKey: market.sportsMarketPointKey,
                                                selectedSportsMarketOddKey: market.homeSportsMarketOddKey,
                                                selectedOddName: market.homeOddName,
                                                selectedOddNameKr: market.homeOddNameKr,
                                                selectedOddValue: market.homeOddValue,
                                              }

                                              onClickBetting(type, item)
                                            }}
                                          >
                                            <div className="txtFrame" style={{ width: '70%' }}>
                                              <img
                                                className="teamImg"
                                                src={`${process.env.PUBLIC_URL}/img/sports/default.png`}
                                                alt=""
                                              />
                                              <SlimScrollDiv>
                                                <SlimScrollInnerDiv>
                                                  <h2>{sportsEvent.homeName}</h2>
                                                </SlimScrollInnerDiv>
                                              </SlimScrollDiv>
                                            </div>
                                            <div className="txt">
                                              {getMarketIconComponent(market.marketName, market.homeOddNameKr)}
                                              <span className="icon-state" />
                                              <h3 className="odds">{market?.homeOddValue}</h3>
                                            </div>
                                          </div>
                                          <div
                                            className={
                                              market.isDrawActive
                                                ? 'nameA nameC bet-pick draw-pick pick'
                                                : 'nameA nameC bet-pick draw-pick'
                                            }
                                            onClick={() => {
                                              if (!market.marketName.includes('승무패')) return
                                              const type = 'CROSS'

                                              const item = {
                                                sportsEventId: sportsEvent.sportsEventId,
                                                sportsType: sportsEvent.sportsType,
                                                sportsMarketKey: market.sportsMarketKey,
                                                marketId: market.marketId,
                                                leagueName: sportsEvent.leagueName,
                                                ccKr: sportsEvent.ccKr,
                                                startAt: sportsEvent.startAt,
                                                homeName: sportsEvent.homeName,
                                                awayName: sportsEvent.awayName,
                                                marketName: market.marketName,
                                                name: market.name,
                                                homeSportsMarketOddKey: market.homeSportsMarketOddKey,
                                                homeOddName: market.homeOddName,
                                                homeOddNameKr: market.homeOddNameKr,
                                                homeOddValue: market.homeOddValue,
                                                awaySportsMarketOddKey: market.awaySportsMarketOddKey,
                                                awayOddName: market.awayOddName,
                                                awayOddNameKr: market.awayOddNameKr,
                                                awayOddValue: market.awayOddValue,
                                                drawSportsMarketOddKey: market.drawSportsMarketOddKey,
                                                drawOddName: market.drawOddName,
                                                drawOddNameKr: market.drawOddNameKr,
                                                drawOddValue: market.drawOddValue,
                                                selectedSportsMarketPointKey: market.sportsMarketPointKey,
                                                selectedSportsMarketOddKey: market.drawSportsMarketOddKey,
                                                selectedOddName: market.drawOddName,
                                                selectedOddNameKr: market.drawOddNameKr,
                                                selectedOddValue: market.drawOddValue,
                                              }

                                              onClickBetting(type, item)
                                            }}
                                          >
                                            <div className="txt">
                                              <h3 className="odds">{market.drawOddValue || market?.name || 'VS'}</h3>
                                            </div>
                                          </div>
                                          <div
                                            className={
                                              market.isAwayActive
                                                ? 'nameA bet-pick away-pick pick'
                                                : 'nameA bet-pick away-pick'
                                            }
                                            onClick={() => {
                                              const type = 'CROSS'

                                              const item = {
                                                sportsEventId: sportsEvent.sportsEventId,
                                                sportsType: sportsEvent.sportsType,
                                                sportsMarketKey: market.sportsMarketKey,
                                                marketId: market.marketId,
                                                leagueName: sportsEvent.leagueName,
                                                ccKr: sportsEvent.ccKr,
                                                startAt: sportsEvent.startAt,
                                                homeName: sportsEvent.homeName,
                                                awayName: sportsEvent.awayName,
                                                marketName: market.marketName,
                                                name: market.name,
                                                homeSportsMarketOddKey: market.homeSportsMarketOddKey,
                                                homeOddName: market.homeOddName,
                                                homeOddNameKr: market.homeOddNameKr,
                                                homeOddValue: market.homeOddValue,
                                                awaySportsMarketOddKey: market.awaySportsMarketOddKey,
                                                awayOddName: market.awayOddName,
                                                awayOddNameKr: market.awayOddNameKr,
                                                awayOddValue: market.awayOddValue,
                                                drawSportsMarketOddKey: market.drawSportsMarketOddKey,
                                                drawOddName: market.drawOddName,
                                                drawOddNameKr: market.drawOddNameKr,
                                                drawOddValue: market.drawOddValue,
                                                selectedSportsMarketPointKey: market.sportsMarketPointKey,
                                                selectedSportsMarketOddKey: market.awaySportsMarketOddKey,
                                                selectedOddName: market.awayOddName,
                                                selectedOddNameKr: market.awayOddNameKr,
                                                selectedOddValue: market.awayOddValue,
                                              }

                                              onClickBetting(type, item)
                                            }}
                                          >
                                            <div className="txt">
                                              <h3 className="odds">{market?.awayOddValue}</h3>
                                              <span className="icon-state" />
                                              {getMarketIconComponent(market.marketName, market.awayOddNameKr)}
                                            </div>
                                            <div
                                              className="txtFrame"
                                              style={{
                                                width: '70%',
                                                justifyContent: 'end',
                                              }}
                                            >
                                              <SlimScrollDiv>
                                                <SlimScrollInnerDiv>
                                                  <h2>{sportsEvent.awayName}</h2>
                                                </SlimScrollInnerDiv>
                                              </SlimScrollDiv>

                                              <img
                                                className="teamImg"
                                                src={`${process.env.PUBLIC_URL}/img/sports/default.png`}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="heartFrame heart01">
                                          <span className="icon-fa-star" />
                                        </div>
                                        {/* <div className="more">
                                          <a
                                            data-fixture-id="12119911"
                                            className="cnt-12119911"
                                            onclick="showRateDetail(12119911)"
                                          >
                                            +0
                                          </a>
                                        </div> */}
                                      </div>
                                    </CustomWrapDiv>
                                  )
                                })}
                              </>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

              {mainSportsEventList.length > 0 ? (
                <>
                  {totalElement > 0 && (
                    <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                  )}
                </>
              ) : (
                <div className="tb_empty">
                  <div className="pic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/no_data.png`} alt="no-data" />
                  </div>
                  <h2>현재 진행중인 경기가 없습니다.</h2>
                  {/* <h3>Sorry, Empty Data</h3> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="btn_top" className={showButton ? '' : 'hide'} onClick={scrollToTop} />
    </>
  )
}

export default CrossSportsMainFrame

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);

  border: 1px solid #fff;
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`

const SlimScrollDiv = styled.div`
  overflow: hidden;
  width: auto;
  height: 100%;
  display: flex;
`

const SlimScrollInnerDiv = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`

const CustomWrapDiv = styled.div`
  flex-direction: row;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`
