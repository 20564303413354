import React, { useEffect, useState } from 'react'
import { getAccessToken } from '../cookie'

/**
 * @See : https://ko.javascript.info/cookie
 */

/**
 * @TODO : https://www.npmjs.com/package/secure-web-storage 암호화 적용하기
 */

/**
 * Local Storage 에 Access Token 저장
 */
export function setAccessToken(accessToken) {
  sessionStorage.setItem('ACCESSTOKEN', accessToken)
}

/**
 * Local Storage Access Token 조회
 */
// export function getAccessToken() {
//   return sessionStorage.getItem('ACCESSTOKEN')
// }

/**
 * Local Storage Clear
 */
export function clearLocalStorage() {
  sessionStorage.clear()
}

// 추천 코드 저장 - 헤더에서 사용
export function setRecommendCode(recommendCode) {
  sessionStorage.setItem('RC', recommendCode)
}
export function getRecommendCode() {
  return sessionStorage.getItem('RC')
}
export function deleteRecommendCode() {
  sessionStorage.removeItem('RC')
}

// edge key 저장
export function setEdgeKey(edgeKey) {
  sessionStorage.setItem('EDGE_KEY', edgeKey)
}
export function getEdgeKey() {
  return sessionStorage.getItem('EDGE_KEY')
}

// 유저 명을 로컬 스토리지에 저장
export function setUserName(userName) {
  sessionStorage.setItem('USER_NAME', userName)
}
export function getUserName() {
  return sessionStorage.getItem('USER_NAME')
}

// User Id 저장
export function setUserId(userId) {
  sessionStorage.setItem('USER_ID', userId)
}

// User Id 가져오기
export function getUserId() {
  return sessionStorage.getItem('USER_ID')
}

export function getAuthority() {
  return 'MEMBER'
}

/**
 * 로그인 여부 확인
 * @see : sessiong storage 에 access token 존재 여부로 파악
 * @return : accessToken 이 undefined, '' , 0  이면 false , 존재하면 true
 */
const isLogin = () => !!getAccessToken()

export default isLogin

// TODO : 환경변수 이용해서 분기 태우기
// local => `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
// real or dev => `${window.location.protocol}//${window.location.hostname}`
export function getEdgeUrl() {
  return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
}
