import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import SignInPopup from '../../Popup/SignIn/SignInPopup'
import isLogin from '../../../utils/enums/authority'
import { decodeAccessToken } from '../../../utils/token'
import { searchPartnerUrl, siginOut } from '../../../api/authManage/authManageApi'
import SignUpPopup from '../../Popup/SignUp/SignUpPopup'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { withdrawCasinoMoney } from '../../../api/memberManage/memberManageApi'
import { withdrawHoldemMoney } from '../../../api/game/gameApi'
import { fetchMemberInfo } from '../../../redux/memberInfoSlice'
import CustomAlert from '../../CustomAlert'
import WrapperLoading from '../../WrapperLoading'
import { toggleLeftMenuSidebarShow } from '../../../redux/modalSlice'

const LeftContent = () => {
  const dispatch = useDispatch()
  const [hover, setHover] = useState(false)
  const location = useLocation()
  const [pathName, setPathName] = useState(null)
  const [isSportsPage, setIsSportsPage] = useState(false)
  const [apiLoading, setApiLoading] = useState(false)
  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneShow, setOneToOneShow] = useState(false)

  const {
    holdingMoney,
    totalGameMoney,
    holdingMemberPoint,
    holdemMoneyAmount,
    userId,
    memberLevel,
    noteRedisSize,
    oneToOneRedisSize,
    isShowLeftMenuSidebar,
  } = useSelector(state => {
    const { memberInfo, modalInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      holdemMoneyAmount: memberInfo.holdemMoneyAmount,
      holdingMemberPoint: memberInfo.memberHoldingPoint,
      userId: memberInfo.userId,
      memberLevel: memberInfo.depositLevel,
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      isShowLeftMenuSidebar: modalInfo.isShowLeftMenuSidebar,
    }
  })

  useEffect(() => {
    setPathName(location.pathname)
  }, [location.pathname])

  // 스포츠 관련 페이지만 변경
  useEffect(() => {
    if (pathName === '/cross-sports' || pathName === '/special-sports' || pathName === '/live-sports') {
      setIsSportsPage(true)
    } else {
      setIsSportsPage(false)
    }
  }, [pathName])

  // 스포츠 페이지일때는 LeftContent zoomIN 구조로 가야함
  const getClassName = () => {
    if (hover) {
      return 'LeftContent zoomOUT'
    }
    if (isSportsPage) {
      return 'LeftContent zoomIN'
    }
    return 'LeftContent'
  }

  const getLevelIcon = () => {
    switch (true) {
      case memberLevel === 'ONE':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv01.png`} alt="" />
      case memberLevel === 'TWO':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv02.png`} alt="" />
      case memberLevel === 'THREE':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv03.png`} alt="" />
      case memberLevel === 'FOUR':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv04.png`} alt="" />
      case memberLevel === 'FIVE':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lv05.png`} alt="" />
      case memberLevel === 'VIP1':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lvVIP01.png`} alt="" />
      case memberLevel === 'VIP2':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lvVIP02.png`} alt="" />
      case memberLevel === 'VIP3':
        return <img src={`${process.env.PUBLIC_URL}/img/level/lvVIP03.png`} alt="" />
      default:
        return <></>
    }
  }

  const loginCheck = isLogin()
  const navigate = useNavigate()

  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  // 회원가입 -> 로그인 모달
  const onShowSignInModal = () => {
    setSignUpModalShow(false)
    setSignInModalShow(true)
  }

  // 로그인 -> 회원가입 모달
  const onShowSignUpModal = () => {
    setSignInModalShow(false)
    setSignUpModalShow(true)
  }

  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setSignInModalShow(true)
      return
    }

    if (noteRedisSize > 0 && link !== '/note') {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0 && link !== '/one-to-one') {
      setOneToOneShow(true)
      return
    }
    dispatch(toggleLeftMenuSidebarShow(!isShowLeftMenuSidebar))
    navigate(link)
  }

  const onClickPartnerUrlHandler = () => {
    searchPartnerUrl().then(res => {
      const { partnerUrl } = res.data

      if (partnerUrl.includes('localhost')) {
        window.open(`http://${partnerUrl}`)
      } else {
        window.open(`https://${partnerUrl}`)
      }
    })
  }

  const onClickSignOutHandler = () => {
    siginOut()
      .then(res => {
        window.location.href = '/'
      })
      .catch(error => {
        window.location.href = '/'
      })
  }

  const onClickCasinoMoneyWithdrawHandler = () => {
    if (apiLoading) {
      alert('카지노 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (totalGameMoney <= 0) {
      alert('0원 회수는 불가능합니다.')
      return
    }
    setApiLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfo())
        setApiLoading(false)
        alert('회수가 성공했습니다.')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('처리 실패했습니다.')
        }
        setApiLoading(false)
      })
  }

  const onClickHoldemMoneyWithdrawHandler = () => {
    if (apiLoading) {
      alert('홀덤 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (holdemMoneyAmount <= 0) {
      alert('0원 회수는 불가능합니다.')
      return
    }
    setApiLoading(true)

    withdrawHoldemMoney()
      .then(res => {
        dispatch(fetchMemberInfo())
        setApiLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'CMD-FEIGN-1002':
            alert('게임 진행중에는 회수가 불가능합니다.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('홀덤 머니 회수에 실패했습니다. 다시 시도해주세요.')
            break
        }
        setApiLoading(false)
      })
  }

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneShow && (
        <CustomAlert
          info="읽지 않은 문의답변이 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setOneToOneShow(false)
          }}
          checkIsRequired
        />
      )}
      {apiLoading && <WrapperLoading isLoading={apiLoading} />}
      <div className={getClassName()} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {signInModalShow && (
          <SignInPopup
            visible={signInModalShow}
            onCloseHandler={onCloseSignInModal}
            onShowSignUpModal={onShowSignUpModal}
          />
        )}
        {signUpModalShow && (
          <SignUpPopup
            visible={signUpModalShow}
            onCloseHandler={onCloseSignUpModal}
            onShowSignInModal={onShowSignInModal}
          />
        )}
        <div className="logo">
          <a href="/" />
        </div>
        <div className="scrollFrame">
          <div className="info mob" style={{ flexDirection: 'column' }}>
            <CloseBtn onClick={() => dispatch(toggleLeftMenuSidebarShow(!isShowLeftMenuSidebar))}>
              <img src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`} alt="" />
            </CloseBtn>
            <div className="info">
              {getLevelIcon()}
              <span className="nick" style={{ color: '#000' }}>
                {userId}님
              </span>
            </div>

            {isLogin() && (
              <div className="btn-box" style={{ width: '100%' }}>
                <div className="moneyZone" style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <div className="cash">
                    <span
                      className="money userCashAmount icon-icconMoneyKOREA"
                      style={{ fontSize: '14px', color: '#000' }}
                    >
                      &nbsp;보유머니: {holdingMoney?.toString().replace(commonReg2, ',')}원
                    </span>
                  </div>
                  <div className="point" style={{ display: 'flex' }}>
                    <span
                      className="money userCashPoint icon-icconMoneyKOREA"
                      style={{ display: 'block', width: 'max-content', fontSize: '14px', color: '#000' }}
                    >
                      &nbsp;카지노: {totalGameMoney?.toString().replace(commonReg2, ',')}원
                    </span>
                    <span
                      style={{
                        display: 'block',
                        width: 'max-content',
                        marginLeft: '5px',
                        fontSize: '12px',
                        color: '#fff',
                        background: 'blue',
                        padding: '3px',
                      }}
                      onClick={onClickCasinoMoneyWithdrawHandler}
                    >
                      회수
                    </span>
                  </div>
                  {/* <div className="point" style={{ display: 'flex' }}>
                    <span
                      className="money userCashPoint icon-icconMoneyKOREA"
                      style={{ display: 'block', width: 'max-content', fontSize: '14px' }}
                    >
                      홀덤: {holdemMoneyAmount?.toString().replace(commonReg2, ',')}원
                    </span>
                    <span
                      className="money refresh btn-refresh-cash icon-iconReloadA"
                      style={{
                        display: 'block',
                        width: 'max-content',
                        marginLeft: '5px',
                        fontSize: '12px',
                        marginTop: '1px',
                      }}
                      onClick={onClickHoldemMoneyWithdrawHandler}
                    />
                  </div> */}
                  <div
                    className="point"
                    style={{ display: 'flex' }}
                    onClick={() => {
                      onClickMenuHandler('/point/exchange')
                    }}
                  >
                    <span className="money userCashPoint icon-point" style={{ fontSize: '14px', color: '#000' }}>
                      &nbsp;포인트: {holdingMemberPoint?.toString().replace(commonReg2, ',')}원
                    </span>
                    <span
                      style={{
                        display: 'block',
                        width: 'max-content',
                        marginLeft: '5px',
                        fontSize: '12px',
                        color: '#fff',
                        background: 'blue',
                        padding: '3px',
                      }}
                      onClick={() => {
                        onClickMenuHandler('/point/exchange')
                      }}
                    >
                      전환
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="leftMenu gameMenu">
            <ul>
              {/* <li>
                <a
                  aria-label="EuropeanSport"
                  className=""
                  onClick={() => {
                    onClickMenuHandler('/cross-sports')
                  }}
                >
                  <span className="icon-icconSPORT" />
                  <div className="hovePic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/ic-left03.png`} alt="" />
                  </div>
                  <div className="txt">스포츠(크로스)</div>
                </a>
              </li>

              <li>
                <a
                  aria-label="EuropeanSport"
                  onClick={() => {
                    onClickMenuHandler('/special-sports')
                  }}
                >
                  <span className="icon-icconSPORT" />
                  <div className="hovePic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/ic-left03.png`} alt="" />
                  </div>
                  <div className="txt">스포츠(스페셜)</div>
                </a>
              </li>

              <li>
                <a
                  aria-label="EuropeanSport"
                  className=""
                  onClick={() => {
                    onClickMenuHandler('/live-sports')
                  }}
                >
                  <span className="icon-icconSPORT" />
                  <div className="hovePic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/ic-left03.png`} alt="" />
                  </div>
                  <div className="txt">스포츠(라이브)</div>
                </a>
              </li> */}

              <li>
                <a
                  className=""
                  aria-label="카지노"
                  onClick={() => {
                    onClickMenuHandler('/live-casino')
                  }}
                >
                  <span className="icon-iiconDice" />
                  <div className="hovePic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/ic-left01.png`} alt="" />
                  </div>
                  <div className="txt">카지노</div>
                </a>
              </li>

              <li>
                <a
                  className=""
                  aria-label="슬롯머신"
                  onClick={() => {
                    onClickMenuHandler('/slot-casino')
                  }}
                >
                  <span className="icon-iiconSlote" />
                  <div className="hovePic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/ic-left02.png`} alt="" />
                  </div>
                  <div className="txt">슬롯머신</div>
                </a>
              </li>

              {/* <li>
                <a
                  className=""
                  aria-label="라이브 홀덤"
                  onClick={() => {
                    onClickMenuHandler('/holdem')
                  }}
                >
                  <span className="icon-iiconPoker" />
                  <div className="hovePic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/ic-left01.png`} alt="" />
                  </div>
                  <div className="txt">라이브 홀덤</div>
                </a>
              </li> */}

              {/* <li>
                <a
                  className=""
                  aria-label="미니게임"
                  onClick={() => {
                    onClickMenuHandler('/minigame')
                  }}
                >
                  <span className="icon-iiconGame" />
                  <div className="hovePic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/ic-left04.png`} alt="" />
                  </div>
                  <div className="txt">미니게임</div>
                </a>
              </li> */}

              <li>
                <a
                  className=""
                  aria-label="이벤트"
                  onClick={() => {
                    onClickMenuHandler('/event')
                  }}
                >
                  <span className="icon-iiconSpeaker" />
                  <div className="hovePic">
                    <img src={`${process.env.PUBLIC_URL}/img/common/ic-left06.png`} alt="" />
                  </div>
                  <div className="txt">이벤트</div>
                </a>
              </li>
            </ul>
          </div>
          <div className="leftMenu leftMenu01">
            <ul>
              <li>
                <a
                  className=""
                  aria-label="입금"
                  onClick={() => {
                    onClickMenuHandler('/money/deposit')
                  }}
                >
                  <span className="icon-iiconDeposit" />
                  <div className="txt">입금</div>
                </a>
              </li>
              <li>
                <a
                  className=""
                  aria-label="출금"
                  onClick={() => {
                    onClickMenuHandler('/money/withdrawal')
                  }}
                >
                  <span className="icon-iiconWithdraw" />
                  <div className="txt">출금</div>
                </a>
              </li>
              <li>
                <a
                  className=""
                  aria-label="포인트전환"
                  onClick={() => {
                    onClickMenuHandler('/point/exchange')
                  }}
                >
                  <span className="icon-icconPOINT" />
                  <div className="txt">포인트전환</div>
                </a>
              </li>
              {/* <li>
              <a href="#/header/comp_xchg" className="" aria-label="콤프전환">
                <span className="icon-iiconListB" />
                <div className="txt">콤프전환</div>
              </a>
            </li> */}
              <li>
                <a
                  className=""
                  aria-label="베팅내역"
                  onClick={() => {
                    onClickMenuHandler('/betting-history')
                  }}
                >
                  <span className="icon-iiconListA" />
                  <div className="txt">베팅내역</div>
                </a>
              </li>
              {/* <li>
              <a href="#/game/bet_result_list" className="" aria-label="경기결과">
                <span className="icon-trophy" />
                <div className="txt">경기결과</div>
              </a>
            </li> */}
              <li>
                <a
                  className=""
                  aria-label="공지사항"
                  onClick={() => {
                    onClickMenuHandler('/notice')
                  }}
                >
                  <span className="icon-iiconRing" />
                  <div className="txt">공지사항</div>
                </a>
              </li>

              <li>
                <a
                  className=""
                  aria-label="쪽지"
                  onClick={() => {
                    onClickMenuHandler('/note')
                  }}
                >
                  <span className="icon-iiconMail" />
                  <div className="txt">
                    쪽지(<span className="userLetterCount">{noteRedisSize}</span>){' '}
                  </div>
                </a>
              </li>
              <li>
                <a
                  className=""
                  aria-label="고객센터"
                  onClick={() => {
                    onClickMenuHandler('/one-to-one')
                  }}
                >
                  <span className="icon-iiconService" />
                  <div className="txt">
                    1:1문의(<span className="userLetterCount">{oneToOneRedisSize}</span>){' '}
                  </div>
                </a>
              </li>
              <li>
                <a
                  className=""
                  aria-label="마이페이지"
                  onClick={() => {
                    onClickMenuHandler('/mypage')
                  }}
                >
                  <span className="icon-iiconMember" />
                  <div className="txt">마이페이지</div>
                </a>
              </li>

              {/* <li>
              <a
                className=""
                aria-label="지인추천"
                onClick={() => {
                  onClickMenuHandler('/friend-history')
                }}
              >
                <span className="icon-icconReferral" />
                <div className="txt">지인추천</div>
              </a>
            </li> */}
              {decodeAccessToken()?.auth === 'PARTNER' && (
                <li>
                  <a className="partner-link" onClick={onClickPartnerUrlHandler}>
                    <span className="icon-icconlogin" />
                    <div className="txt">파트너페이지</div>
                  </a>
                </li>
              )}
              {isLogin() && (
                <li>
                  <a onClick={onClickSignOutHandler} aria-label="이벤트">
                    <span className="icon-logout" />
                    <div className="txt">로그아웃</div>
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="bottom mob">
            <a href="https://t.me/winer222" target="_blank" className="bann-te" rel="noreferrer">
              <img src={`${process.env.PUBLIC_URL}/img/common/teleba01.png`} alt="" />
            </a>
            <a href="https://t.me/winer222" target="_blank" className="bann-pt" rel="noreferrer">
              <img src={`${process.env.PUBLIC_URL}/img/common/teleba02.png`} alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeftContent
export const CloseBtn = styled.div`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background: red;
  }
  position: absolute;
  top: 2%;
  right: 2%;
  width: 30px;
  height: 30px;
  background: red;
  border-radius: 41px;
  padding-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
`
