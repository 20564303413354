// 피해 유형

export const MiniGameBettingTypeEnums = {
  ING: '진행중',
  WIN: '당첨',
  LOSE: '낙첨',
}

export const MiniGameBettingTypeEnumsSelectOptions = () => {
  return Object.entries(MiniGameBettingTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
export const MiniGameTypeEnums = {
  NTRY_POWER_BALL: '파워볼',
  NTRY_EOS_POWER_BALL_1M: 'EOS파워볼 1분',
  NTRY_EOS_POWER_BALL_2M: 'EOS파워볼 2분',
  NTRY_EOS_POWER_BALL_3M: 'EOS파워볼 3분',
  NTRY_EOS_POWER_BALL_4M: 'EOS파워볼 4분',
  NTRY_EOS_POWER_BALL_5M: 'EOS파워볼 5분',
  NTRY_SPEED_KENO: '스피드 키노',
  NTRY_KENO_LADDER: '키노 사다리',
  NTRY_POWER_LADDER: '파워 사다리',
}

export const PowerBallTypeEnums = {
  POWER_BALL_ODD: '파워볼 - 홀',
  POWER_BALL_EVEN: '파워볼 - 짝',
  POWER_BALL_UNDER: '파워볼 - 언더(4.5)',
  POWER_BALL_OVER: '파워볼 - 오버(4.5)',
  NORMAL_BALL_ODD: '일반볼 - 홀',
  NORMAL_BALL_EVEN: '일반볼 - 짝',
  NORMAL_BALL_UNDER: '일반볼 - 언더(72.5)',
  NORMAL_BALL_OVER: '일반볼 - 오버(72.5)',
  POWER_BALL_ODD_UNDER: '파워볼 - 홀+언더',
  POWER_BALL_ODD_OVER: '파워볼 - 홀+오버',
  POWER_BALL_EVEN_UNDER: '파워볼 - 짝+언더',
  POWER_BALL_EVEN_OVER: '파워볼 - 짝+오버',
  NORMAL_BALL_ODD_UNDER: '일반볼 - 홀+언더',
  NORMAL_BALL_ODD_OVER: '일반볼 - 홀+오버',
  NORMAL_BALL_EVEN_UNDER: '일반볼 - 짝+언더',
  NORMAL_BALL_EVEN_OVER: '일반볼 - 짝+오버',
  NORMAL_BALL_ODD_MAJOR: '일반볼 - 홀+대',
  NORMAL_BALL_ODD_MIDDLE: '일반볼 - 홀+중',
  NORMAL_BALL_ODD_MINOR: '일반볼 - 홀+소',
  NORMAL_BALL_EVEN_MAJOR: '일반볼 - 짝+대',
  NORMAL_BALL_EVEN_MIDDLE: '일반볼 - 짝+중',
  NORMAL_BALL_EVEN_MINOR: '일반볼 - 짝+소',
  NORMAL_BALL_MAJOR: '일반볼 - 대(81~130)',
  NORMAL_BALL_MIDDLE: '일반볼 - 중(65~80)',
  NORMAL_BALL_MINOR: '일반볼 - 소(15~64)',
  POWER_BALL_ODD_NORMAL_BALL_ODD: '파워볼 홀 + 일반볼 홀',
  POWER_BALL_ODD_NORMAL_BALL_EVEN: '파워볼 홀 + 일반볼 짝',
  POWER_BALL_EVEN_NORMAL_BALL_ODD: '파워볼 짝 + 일반볼 홀',
  POWER_BALL_EVEN_NORMAL_BALL_EVEN: '파워볼 짝 + 일반볼 짝',
  POWER_BALL_NUM_0: '파워볼 - 0',
  POWER_BALL_NUM_1: '파워볼 - 1',
  POWER_BALL_NUM_2: '파워볼 - 2',
  POWER_BALL_NUM_3: '파워볼 - 3',
  POWER_BALL_NUM_4: '파워볼 - 4',
  POWER_BALL_NUM_5: '파워볼 - 5',
  POWER_BALL_NUM_6: '파워볼 - 6',
  POWER_BALL_NUM_7: '파워볼 - 7',
  POWER_BALL_NUM_8: '파워볼 - 8',
  POWER_BALL_NUM_9: '파워볼 - 9',
  LADDER_LEFT: '좌',
  LADDER_RIGHT: '우',
  LADDER_ODD: '홀',
  LADDER_EVEN: '짝',
  LADDER_THREE: '3줄',
  LADDER_FOUR: '4줄',
  LADDER_LEFT_THREE_EVEN: '좌-3-짝',
  LADDER_LEFT_FOUR_ODD: '좌-4-홀',
  LADDER_RIGHT_THREE_ODD: '우-3-홀',
  LADDER_RIGHT_FOUR_EVEN: '우-4-짝',
  SPEEDKENO_LEFT: '홀',
  SPEEDKENO_RIGHT: '짝',
  SPEEDKENO_UNDER: '언더',
  SPEEDKENO_OVER: '오버',
}
